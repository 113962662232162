import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { TbCalendarTime } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillBagCheckFill } from "react-icons/bs";
import { MdDriveFileRenameOutline, MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import PopUp from "../../common/PopUp";
import { NavigationBar } from "../Landing/NavigationBar";

const Detail = () => {
    const { id } = useParams();
    const user = useSelector((state) => state.user)
    const [data, setData] = useState();
    const [popUp, setPopUp] = useState({ modal: false, title: "", message: "", buttonText: "", image: "" })
    const history = useNavigate();

    const height = window.innerHeight - (window.innerWidth < 1024 ? 56 : 0) + 'px';

    useEffect(() => {
        if (user?.role === "venue" || user?.role === "host") {
            ApiClient.put(`ticket/update/checkIn/status`, { trackingId: id }, "", true).then(res => {
                setPopUp({
                    modal: true,
                    title: "",
                    image: res?.success ? "/assets/img/verify.png" : "/assets/img/warning.png",
                    message: res?.success ? "Check In Successfully" : "Already Check In",
                    buttonText: "Ok"
                })
            })
        }
        if (id) {
            getDetail();
        }
    }, []);

    const getDetail = () => {
        loader(true);
        ApiClient.get(`ticket/detail`, { trackingId: id }).then((res) => {
            if (res.success) {
                let data = res?.data
                if (data?.printType === "multiple") {
                    data.ticket_data = data?.ticket_data?.filter(itm => data?.ticketId?.some((item) => item?.id === itm?.id))
                }
                setData(data)
            }
            loader(false);
        });
    };

    const totalPrice = () => {
        let value = 0
        data?.ticket_data?.map((item, index) => {
            value += Number(item?.ticket_price || 0) * Number(item?.quantity || 0)
        })
        return parseFloat(value).toFixed(2)
    }

    return (
        <>
            <div className="bg-white w-full h-screen" style={{ maxHeight: height }}>
                <NavigationBar />
                <div className="wrapper_section w-full">
                    <div className="container mx-auto p-5">
                        <div className="flex items-center mb-2">
                            <Tooltip placement="top" title="Back">
                                <span
                                    onClick={() => history(-1)}
                                    className="!px-1 py-1 cursor-pointer flex items-center justify-center rounded-full shadow-btn hover:bg-[#F3F2F5] border transition-all mr-3 bg-[#05388fed] text-white hover:text-black"
                                >
                                    <IoIosArrowBack size={20} />
                                </span>
                            </Tooltip>
                            <h3 className="text-lg  font-[500] text-[#134896]">
                                Order Details
                            </h3>
                        </div>
                        <div className="bg-[#FFFCF5]">
                            <div className="bg-[#FFFCF5] rounded-[15px] p-5 sm:p-8 gap-6">
                                <div className="relative overflow-x-auto">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-[#F2F4F5]">
                                            <tr className="border">
                                                <th scope="col" className="font-[400] px-6 py-3 whitespace-nowrap">
                                                    Ticket Name
                                                </th>
                                                <th scope="col" className="font-[400] px-6 py-3 whitespace-nowrap">
                                                    Ticket Price
                                                </th>
                                                <th scope="col" className="font-[400] px-6 py-3 whitespace-nowrap">
                                                    Quantity
                                                </th>
                                                <th scope="col" className="font-[400] px-6 py-3 whitespace-nowrap">
                                                    Paid Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.ticket_data?.map((item, index) => {
                                                return <tr className="border-b" key={index}>
                                                    <td className="text-[15px] font-[500] text-[#191C1F] px-6 py-6 capitalize">
                                                        <span className="whitespace-nowrap">
                                                            {item?.ticket_name || "--"}
                                                        </span>
                                                    </td>
                                                    <td className="text-[#475156] text-[14px] px-6 py-3">
                                                        {item?.type === "free" ? "Free" : `$${Number(item?.ticket_price || 0)}`}
                                                    </td>
                                                    <td className="text-[#475156] text-[14px] px-6 py-3">
                                                        {data?.ticketId?.find((itm) => itm?.id === item?.id)?.quantity || 0}
                                                        {/* x{item?.quantity || 0} */}
                                                    </td>
                                                    <td className="text-[#191C1F] text-[14px] px-6 py-3">
                                                        {data?.ticket_data?.length === (index + 1) && <>${data?.total_amount / 100}</>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <div className="grid grid-cols-12 gap-5 lg:gap-10 mt-10">
                                        <div className="col-span-12 lg:col-span-7 pr-10 lg:border-r-[1px]">
                                            <div className="text-[20px] text-[#134896] ">Event Details</div>
                                            <div className="w-[40px] h-[2px] bg-[#134896] mt-1 mb-5"></div>
                                            <div className="flex gap-1 mb-4">
                                                <Tooltip position="top" title="Click To Open Event Detail Page">
                                                    <span className="cursor-pointer flex gap-2">
                                                        <MdDriveFileRenameOutline className="text-[#134896] text-[18px] relative top-[1px]" />
                                                        <p className="text-[14px] text-[#134896]" onClick={e => { history(`/EventDetails/${data?.event_id?._id || data?.event_id?.id}`) }}>Event Name: <span className="text-[#191C1F]">{data?.event_id?.title}</span></p>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="flex gap-1 mb-4">
                                                <TbCalendarTime className="text-[#134896] text-[18px] relative top-[1px]" />
                                                <div className="flex flex-wrap gap-2">
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Start Date: </span> {moment(data?.event_id?.eventStartDate).format("DD MMM, YYYY hh:mm A")} </p>
                                                    |
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> End Date: </span> {moment(data?.event_id?.eventEndDate).format("DD MMM, YYYY hh:mm A")} </p>
                                                </div>
                                            </div>
                                            <div className="flex gap-1">
                                                <FaLocationDot className="text-[#134896] text-[18px] relative top-[1px]" />
                                                <p className="text-[14px] text-[#191C1F]">{data?.event_id?.location}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-5">
                                            <div className="text-[20px] text-[#134896]">Booking Details</div>
                                            <div className="w-[40px] h-[2px] bg-[#134896] mt-1 mb-5"></div>
                                            <div className="flex gap-1 mb-4">
                                                <TbCalendarTime className="text-[#134896] text-[18px] relative top-[1px]" />
                                                <div className="flex flex-wrap gap-2">
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Date of purchase: </span> {moment(data?.event_id?.purchasedAt).format("DD MMM, YYYY")} </p>
                                                </div>
                                            </div>
                                            <div className="border bg-[#fffbf1] rounded-lg p-5 mb-3 w-full">
                                                <div className="flex gap-1 mb-4">
                                                    <BsFillBagCheckFill className="text-[#134896] text-[18px] relative top-[1px]" />
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Purchased By: </span> {data?.purchasedBy?.fullName || `${data?.purchasedBy?.firstName || ""} ${data?.purchasedBy?.lastName || ""}`} </p>
                                                </div>
                                                <div className="flex gap-1 mb-4">
                                                    <MdOutlineEmail className="text-[#134896] text-[18px] relative top-[1px]" />
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Email: </span> {data?.purchasedBy?.email} </p>
                                                </div>
                                                <div className="flex gap-1 mb-4">
                                                    <FaPhoneAlt className="text-[#134896] text-[18px] relative top-[1px]" />
                                                    <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Mobile Number: </span> {data?.purchasedBy?.mobileNo ? `+${data?.purchasedBy?.mobileNo}` : "--"} </p>
                                                </div>
                                            </div>
                                            {data?.purchasedFor?.map((item, index) => {
                                                if (item?.email !== data?.purchasedBy?.email) {
                                                    return <div className="border bg-[#fffbf1] rounded-lg p-5 w-full" key={index}>
                                                        <div className="flex gap-1 mb-4">
                                                            <BsFillBagCheckFill className="text-[#134896] text-[18px] relative top-[1px]" />
                                                            <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Purchased For: </span> {item?.fullName || `${item?.firstName || ""} ${item?.lastName || ""}`} </p>
                                                        </div>
                                                        <div className="flex gap-1 mb-4">
                                                            <MdOutlineEmail className="text-[#134896] text-[18px] relative top-[1px]" />
                                                            <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Email: </span> {item?.email} </p>
                                                        </div>
                                                        <div className="flex gap-1 mb-4">
                                                            <FaPhoneAlt className="text-[#134896] text-[18px] relative top-[1px]" />
                                                            <p className="text-[14px] text-[#191C1F]"><span className="text-[#134896]"> Mobile Number: </span> {item?.mobileNo ? `+${item?.mobileNo}` : "--"} </p>
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopUp popUp={popUp} setPopUp={setPopUp} />
        </>
    );
};

export default Detail;