import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import shared from "./shared";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import methodModel from "../../methods/methods";
import moment from "moment";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { IoIosArrowBack } from "react-icons/io";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useSelector } from "react-redux";

const EventsView = () => {
  const user = useSelector((state) => state.user)
  const [data, setData] = useState();
  const history = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, []);

  const getDetail = () => {
    loader(true);
    ApiClient.get(shared.detailApi, { id: id }).then((res) => {
      if (res.success) {
        setData(res?.payload)
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="bg-white w-full h-screen">
        <DesktopSidebar />
        <div className="container mx-auto mt-6">
          <div className="flex items-center mb-5">
            <Tooltip placement="top" title="Back">
              <span
                onClick={() => history(-1)}
                className="!px-1 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all mr-3 bg-[#05388fed] text-white hover:text-black"
              >
                <IoIosArrowBack size={20} />
              </span>
            </Tooltip>
            <div>
              <h3 className="text-[15px] font-semibold text-[#111827]">
                {shared.addTitle} Details
              </h3>
            </div>
          </div>

          <div className="border-[1px] rounded-[15px] p-5 gap-6">
            <TabGroup>
              <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                <Tab
                  key="Info"
                  className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap	"
                >
                  Info
                </Tab>
                <Tab
                  key="Gallery"
                  className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap	"
                >
                  Gallery
                </Tab>
                <Tab
                  key="EventDetail"
                  className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap	"
                >
                  Event Detail
                </Tab>
                <Tab
                  key="Food"
                  className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap	"
                >
                  Food & Drinks
                </Tab>
              </TabList>
              <TabPanels className="mt-3">
                <TabPanel key="Info" className="">
                  <ul>
                    <li key="Info" className="relative rounded-md text-sm/6 transition">
                      <div className="shadow-box overflow-hidden rounded-lg bg-white gap-4 shrink-0">
                        <div className="grid grid-cols-12 gap-5 py-4">
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Event Name:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data && data?.title}
                            </p>
                          </div>
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Category:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data && data?.category?.name}
                            </p>
                          </div>
                          {data?.subCategory?.length > 0 ?
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Sub Category:</label>
                              <p className="text-[13px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                                {data?.subCategory?.map((item, index) => {
                                  return <span key={index} className="bg-[#063688] p-2 rounded-lg text-white">{item?.name}</span>
                                })}
                              </p>
                            </div>
                            : null}
                          {user?.role === "host" &&
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Venue:</label>
                              <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                {data && data?.venue?.venue_name}
                              </p>
                            </div>
                          }
                          {data?.venue_location ?
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Venue Location:</label>
                              <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                {data && data?.venue_location}
                              </p>
                            </div>
                            : null}
                          {user?.role === "venue" &&
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Host:</label>
                              <p className="text-[13px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                                {data?.host?.map((item, index) => {
                                  return <span key={index} className="bg-[#063688] p-2 rounded-lg text-white">{item?.fullName || item?.firstName}</span>
                                })}
                              </p>
                            </div>
                          }
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Co-host:</label>
                            <p className="text-[13px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                              {data?.coHost?.map((item, index) => {
                                return <span key={index} className="bg-[#063688] p-2 rounded-lg text-white">{item?.fullName || item?.firstName}</span>
                              })}
                            </p>
                          </div>
                          {data?.vendors?.length > 0 &&
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Vendor/Food Truck Vendor:</label>
                              <p className="text-[13px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                                {data?.vendors?.map((item, index) => {
                                  return <span key={index} className="bg-[#063688] p-2 rounded-lg text-white">{item?.fullName || item?.firstName}</span>
                                })}
                              </p>
                            </div>
                          }
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Event Location:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.location}
                            </p>
                          </div>
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Country:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.country}
                            </p>
                          </div>
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">State:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.state}
                            </p>
                          </div>
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">City:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.city}
                            </p>
                          </div>
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Zip Code:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.zipCode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel key="Gallery" className="">
                  <ul>
                    <li key="Gallery" className="relative rounded-md text-sm/6 transition">
                      <div className="shadow-box overflow-hidden rounded-lg bg-white gap-4 shrink-0">
                        <div className="grid grid-cols-12 gap-5 py-4">
                          {data?.images?.length > 0 ?
                            <div className="col-span-12 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Images:</label>
                              <div className="flex flex-wrap gap-3 mt-3">
                                {data?.images?.map((item, index) => {
                                  return <Tooltip position="top" title="Click to open">
                                    <img src={methodModel.noImg(item)} onClick={e => { window.open(methodModel.noImg(item), "_blank") }} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain mr-4 cursor-pointer" key={index} />
                                  </Tooltip>
                                })}
                              </div>
                            </div>
                            : null}
                          <div className="col-span-12 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Short Description:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words" dangerouslySetInnerHTML={{ __html: data?.short_description || "--" }}></p>
                          </div>
                          <div className="col-span-12 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Long Description:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words" dangerouslySetInnerHTML={{ __html: data?.description || "--" }}></p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel key="EventDetail" className="">
                  <ul>
                    <li key="EventDetail" className="relative rounded-md text-sm/6 transition">
                      <div className="shadow-box overflow-hidden rounded-lg bg-white gap-4 shrink-0">
                        <div className="grid grid-cols-12 gap-5 py-4">
                          {/* <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">URL:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center px-3 py-2 leading-[18px] break-words">
                              {data && data?.link}
                            </p>
                          </div> */}
                          <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Event Type:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data && data?.type}
                            </p>
                          </div>
                          {data?.type === "paid" &&
                            <div className="col-span-12 lg:col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Platform Fee Type:</label>
                              <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                {data?.platformFeeType}
                              </p>
                            </div>
                          }
                          <div className="col-span-12">
                            {data?.type === "paid" ?
                              <>
                                {data?.ticket_details?.map((item, index) => {
                                  return <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 border rounded-lg shadow-lg p-5 mb-5" key={index}>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Ticket Name:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        {item?.ticket_name}
                                      </p>
                                    </div>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Tickets:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        {item?.ticket_count}
                                      </p>
                                    </div>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Ticket Price:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        ${item?.ticket_price || 0}
                                      </p>
                                    </div>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Ticket Per Order:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        {item?.tickets_per_order}
                                      </p>
                                    </div>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Ticket Purchase Online:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        {item?.isOnline ? "Yes" : "No"}
                                      </p>
                                    </div>
                                    <div className="">
                                      <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Platform Fee Type:</label>
                                      <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                        {item?.platformFeeType}
                                      </p>
                                    </div>
                                  </div>
                                })}
                              </>
                              :
                              <div className="">
                                <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Audience Capacity:</label>
                                <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                  {data?.tickets}
                                </p>
                              </div>
                            }
                          </div>
                          <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Event Start Date & Time:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {moment(data?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                            </p>
                          </div>
                          <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Event End Date & Time:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {moment(data?.eventEndDate).format("DD MMM, YYYY hh:mm A")}
                            </p>
                          </div>
                          <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Audience:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.audience}
                            </p>
                          </div>
                          <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Attention:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.attention}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </TabPanel>
                <TabPanel key="Food" className="">
                  <ul>
                    <li key="Food" className="relative rounded-md text-sm/6 transition">
                      <div className="shadow-box overflow-hidden rounded-lg bg-white gap-4 shrink-0">
                        <div className="grid grid-cols-12 gap-5 py-4">
                          <div className="col-span-12 sm:col-span-6 flex flex-col">
                            <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Special Food:</label>
                            <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                              {data?.extraFood}
                            </p>
                          </div>
                          {data?.extraFood === "yes" &&
                            <div className="col-span-12 sm:col-span-6 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Menu Format:</label>
                              <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">
                                {data?.menu_item_format || '--'}
                              </p>
                            </div>
                          }
                          {data?.foodImages?.length > 0 && data?.menu_item_format === "upload" ?
                            <div className="col-span-12 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Food Images:</label>
                              <div className="flex gap-3">
                                {data?.foodImages?.map((item, index) => {
                                  return <Tooltip position="top" title="Click to open">
                                    <img src={methodModel.noImg(item)} onClick={e => { window.open(methodModel.noImg(item), "_blank") }} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain cursor-pointer" key={index} />
                                  </Tooltip>
                                })}
                              </div>
                            </div>
                            : null}
                          {data?.menu_item_format === "manual" && data?.foods?.length > 0 ?
                            <div className="col-span-12 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Foods:</label>
                              <div className="grid grid-cols-12 gap-5 mt-3">
                                {data?.foods?.map((item, index) => {
                                  return <span key={index} className="col-span-12 lg:col-span-6 shadow-box border-[1px] rounded-md p-5">
                                    <div className="grid grid-cols-12 gap-5 mb-3">
                                      <div className="col-span-12 sm:col-span-6">
                                        <labe>Item Name:</labe>
                                        <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">{item?.item || "--"}</p>
                                      </div>
                                      <div className="col-span-12 sm:col-span-6">
                                        <labe>Price:</labe>
                                        <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">${item?.price || 0}</p>
                                      </div>
                                    </div>
                                    {item?.image && <>
                                      <labe>Image:</labe>
                                      <Tooltip position="top" title="Click to open">
                                        <img src={methodModel.noImg(item?.image)} onClick={e => { window.open(methodModel.noImg(item?.image), "_blank") }} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain cursor-pointer" />
                                      </Tooltip>
                                    </>
                                    }
                                  </span>
                                })}
                              </div>
                            </div>
                            : null}
                          {data?.menu_item_format === "manual" && data?.drinks?.length > 0 ?
                            <div className="col-span-12 flex flex-col">
                              <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Drinks:</label>
                              <div className="grid grid-cols-12 gap-5 mt-3">
                                {data?.drinks?.map((item, index) => {
                                  return <span key={index} className="col-span-12 lg:col-span-6 shadow-box border-[1px] rounded-md p-5">
                                    <div className="grid grid-cols-12 gap-5 mb-3">
                                      <div className="col-span-12 sm:col-span-6">
                                        <labe>Item Name:</labe>
                                        <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">{item?.item || "--"}</p>
                                      </div>
                                      <div className="col-span-12 sm:col-span-6">
                                        <labe>Price:</labe>
                                        <p className="text-[13px] text-black bg-[#f5f5f5] min-h-[35px] rounded-md items-center capitalize px-3 py-2 leading-[18px] break-words">${item?.price || 0}</p>
                                      </div>
                                    </div>
                                    {item?.image && <>
                                      <labe>Image:</labe>
                                      <Tooltip position="top" title="Click to open">
                                        <img src={methodModel.noImg(item?.image)} onClick={e => { window.open(methodModel.noImg(item?.image), "_blank") }} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain cursor-pointer" />
                                      </Tooltip>
                                    </>
                                    }
                                  </span>
                                })}
                              </div>
                            </div>
                            : null}
                        </div>
                      </div>
                    </li>
                  </ul>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsView;
