import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import loader from "../../methods/loader";
import { useNavigate } from "react-router-dom";
import Location from '../../assets/images/Icons/location.svg';
import methodModel from "../../methods/methods";
import { MdOutlineClear } from "react-icons/md";
import { useSelector } from "react-redux";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { FaHandshakeSimple } from "react-icons/fa6";
import { TfiTicket } from "react-icons/tfi";

const Followers = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [data, setData] = useState([])
    const [followersCount, setFollowersCount] = useState({ memberCount: 0, overAllCount: 0 })
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "" })
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getFollowers()
    }, [])

    const getFollowers = (f = {}) => {
        const filter = { ...filters, ...f, userId: user?._id || user?.id }
        loader(true)
        ApiClient.get(`follow//follower/listing`, filter).then(res => {
            if (res.success) {
                setData(res?.data?.map((item) => {
                    return ({ ...item, ...item?.addedBy })
                }))
                setFollowersCount(res?.followerCount)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const handlePagination = (e) => {
        setFilters((prev) => ({ ...prev, page: e }))
        getFollowers({ page: e })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        getFollowers(filters)
    }
    const searchInput = (e) => {
        if (e?.length !== 0) {
            setFilters({ ...filters, search: e })
        } else {
            setFilters({ ...filters, search: "" })
            getFollowers({ search: "" })
        }
    }
    const clearSearch = () => {
        setFilters({ ...filters, search: "" })
        getFollowers({ search: "" })
    }

    const handleRedirection = (item) => {
        let url = ""
        if (item?.role === "venue") {
            url = `/venue/detail/${item?._id || item?.id}`
        } else if (item?.role === "host") {
            url = `/hostProfile/${item?._id || item?.id}`
        } else if (item?.role === "crew") {
            url = `/entertainmentCrewProfile/${item?._id || item?.id}`
        } else if (item?.role === "crew_member") {
            url = `/crewProfile/${item?._id || item?.id}`
        } else {
            url = ""
        }
        if (url) navigate(url)
    }

    return (
        <>
            <div className="">
                <div className="bg-[#D9D9D938]">
                    <div className="bg-white w-full h-screen">
                        <DesktopSidebar />
                        <div className="container mx-auto px-5 pb-5">
                            <div className="grid grid-cols-12 gap-6 my-3">
                                <div className="col-span-6 sm:col-span-4 lg:col-span-3 bg-white shadow rounded-lg">
                                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover bg-no-repeat">
                                        <div>
                                            <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total <br /> Followers</h3>
                                            <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{followersCount?.overAllCount || 0}</p>
                                        </div>
                                        <div>
                                            <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                                                <FaHandshakeSimple />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6 sm:col-span-4 lg:col-span-3 bg-white shadow rounded-lg">
                                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover bg-no-repeat">
                                        <div>
                                            <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total <br /> Members</h3>
                                            <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{followersCount?.memberCount || 0}</p>
                                        </div>
                                        <div>
                                            <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                                                <TfiTicket />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6">
                                <div className="border-[1px] rounded-[15px] p-5 gap-6">
                                    <div className="">
                                        <div className="flex justify-between flex-wrap border-b pb-5 gap-x-5">
                                            <div className="flex flex-row justify-between items-center">
                                                <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">Followers</h3>
                                            </div>
                                            <div className="flex flex-row justify-between items-center">
                                                <form onSubmit={handleSearch} className="flex items-center gap-4">
                                                    <div className="relative">
                                                        <input type="text" value={filters?.search} onChange={e => searchInput(e.target.value)} placeholder="Search ..." className="bg-white w-full rounded-full h-10 overflow-hidden px-3 border border-[#00000036] text-[12px]" />
                                                        {filters?.search?.length > 0 && <MdOutlineClear size={20} onClick={() => clearSearch()} className="cursor-pointer absolute top-2.5 right-4" />}
                                                    </div>
                                                    <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">Search</button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 gap-5 mt-5 md:mt-12 md:grid-cols-2 xl:grid-cols-3">
                                            {data?.map((item, index) => (
                                                <div key={index}>
                                                    <div className="cursor-pointer overflow-hidden bg-white rounded-lg dark:bg-gray-800 bg-[#004d9208] hover:shadow-lg">
                                                        <div className="flex gap-3 p-3">
                                                            <div className="relative">
                                                                <img className="object-cover w-[50px] h-[50px] sm:w-[80px] sm:h-[80px] rounded-full shadow-lg bg-white cursor-pointer" onClick={e => handleRedirection(item)} src={methodModel.noImg(item?.image)} alt="Article" />
                                                            </div>
                                                            <div className="w-[calc(100%_-_60px)] sm:w-[calc(100%_-_90px)]" onClick={() => { navigate(`/hostProfile/${item?._id || item?.id}`) }}>
                                                                <h2 className="ellipises_heading font-[500] text-[16px] capitalize">
                                                                    {item?.role === "venue" ? item?.venue_name : item?.fullName || `${item?.firstName || ""} ${item?.lastName || ""}`}
                                                                </h2>
                                                                <p className="font-[400] text-[13px] capitalize">
                                                                    {item?.role === "crew" ? "Entertainment Crew" : item?.role === "crew_member" ? "Crew Member" : item?.role}
                                                                </p>
                                                                <p className="font-[400] break-all leading-[16px] text-[13px]">
                                                                    {item?.email}
                                                                </p>
                                                                <p className="flex items-center gap-2 font-[400] text-[13px] mt-1">

                                                                    {item?.role === "venue" ?
                                                                        <>
                                                                            {item?.locations?.map((itm) => {
                                                                                return <>
                                                                                    <img src={Location} alt="location_icon" />
                                                                                    <span className='ellipsis_class text-[#585858] leading-[17px]'> {itm?.location}</span>
                                                                                </>
                                                                            })}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <img src={Location} alt="location_icon" />
                                                                            <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                                                        </>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {data?.length === 0 ?
                                            <div className='text-center'>No Records Found</div>
                                            : null}
                                        {total > filters?.count ?
                                            <div className="text-right pagination_div">
                                                <Pagination
                                                    activePage={filters?.page}
                                                    itemsCountPerPage={filters?.count}
                                                    totalItemsCount={total}
                                                    pageRangeDisplayed={5}
                                                    hideFirstLastPages
                                                    prevPageText="<"
                                                    nextPageText=">"
                                                    onChange={e => handlePagination(e)}
                                                />
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Followers