const shared = {
  title: "Crew Members",
  addTitle: "Crew Member",
  url: "crew/member",
  role: "crew_member",
  detailPageUrl: "crewProfile",
  addApi: "crew/add",
  editApi: "user/admin/update-profile",
  detailApi: "user/detail",
  listApi: "crew/listing",
  statusApi: "user/activate-deactivate/profile",
  deleteApi: "user/delete",
};

export default shared;
