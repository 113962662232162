import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Html from "./html";
import { useNavigate } from "react-router-dom";
import shared from "./shared";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const Posts = () => {
  const searchState = { data: "" };
  const user = useSelector((state) => state.user)
  const history = useNavigate();
  const [filters, setFilter] = useState({ page: 1, count: 10, search: "", postType: "" });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState({ modal: false, message: "" })

  useEffect(() => {
    setFilter({ ...filters, search: searchState.data });
    getData({ search: searchState.data, page: 1 });
  }, [])

  const sortClass = (key) => {
    let cls = "fa-sort";
    if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
    else if (filters.key == key && filters.sorder == "desc") cls = "fa-sort-down";
    return "fa " + cls;
  }

  const sorting = (key) => {
    let sorder = "asc";
    if (filters.key == key) {
      if (filters.sorder == "asc") sorder = "desc";
      else sorder = "asc";
    }
    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder });
    getData({ sortBy, key, sorder });
  }

  const getData = (p = {}) => {
    let filter = { ...filters, ...p, addedFor: user?._id || user?.id, };
    loader(true)
    ApiClient.get(shared.listApi, filter).then((res) => {
      if (res.success) {
        setData(res.data.map((itm) => {
          itm.id = itm._id;
          return itm;
        })
        );
        setTotal(res.total);
      }
      loader(false)
    });
  }

  const clear = () => {
    let f = { search: "", status: "", page: 1, postType: "" }
    setFilter({ ...filters, ...f });
    getData({ ...f });
  }

  const filter = (p = {}) => {
    let f = { page: 1, ...p }
    setFilter({ ...filters, ...f });
    getData({ ...f });
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this ${shared?.addTitle}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#063688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(shared.deleteApi, { id: id }).then((res) => {
          if (res.success) {
            clear();
          }
          loader(false);
        });
      }
    });
  }

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  }
  const count = (e) => {
    setFilter({ ...filters, count: e });
    getData({ ...filters, count: e });
  }
  const handleFilters = (e, key) => {
    setFilter({ ...filters, [key]: e, page: 1 });
    getData({ [key]: e, page: 1 });
  }

  const statusChange = (itm) => {
    let status = "active";
    if (itm.status == "active") status = "deactive";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${status == "active" ? "Activate" : "Inactivate"
        } this ${shared?.addTitle}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#063688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put(shared.statusApi, { id: itm.id, status }).then((res) => {
          if (res.success) {
            getData();
          }
          loader(false);
        });
      }
    });
  }

  const edit = (id) => {
    history(`/${shared.url}/edit/${id}`);
  }

  const view = (id) => {
    let url = `/${shared.url}/detail/${id}`;
    history(url);
  }

  const handleAddPosts = () => {
    if (user?.role === "host" && user?.activePlanId?.managePostsTypePerMonth === "count" && user?.managePostsPerMonth >= user?.activePlanId?.managePostsPerMonth) {
      setModal({ modal: true, message: "Your current plan limit has been reached. To add more posts, please consider upgrading your plan.", image: "/assets/img/waiting.png", buttonText: "Ok" })
    } else {
      history(`/${shared.url}/add`)
    }
  }

  return (
    <>
      <Html
        edit={edit}
        view={view}
        clear={clear}
        user={user}
        sortClass={sortClass}
        sorting={sorting}
        count={count}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        setFilter={setFilter}
        filter={filter}
        data={data}
        total={total}
        statusChange={statusChange}
        handleFilters={handleFilters}
        handleAddPosts={handleAddPosts}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
};

export default Posts;
