import { useState } from "react";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { IoCloseOutline, IoDocumentTextOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import loader from "../../methods/loader";
import PopUp from "../../common/PopUp";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../redux/actions/user";


const ReApply = () => {
    const { role, userId } = useParams()
    const user = useSelector((state)=>state.user)
    const dispatch = useDispatch()
    const [form, setform] = useState({
        venue_name: "",
        name: "",
        email: "",
        mobileNo: "",
        role: role,
        company_ein: "",
        ein_image: "",
    })
    const [submitted, setSubmitted] = useState(false)
    const inValidEmail = methodModel.emailvalidation(form?.email)
    const [popUp, setPopUp] = useState({ modal: false, title: "", message: "", buttonText: "" })

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!form?.ein_image || !inValidEmail) return
        let payload = {
            ...form,
            userId: userId
        }
        loader(true)
        ApiClient.post(`users/reapply`, payload).then(res => {
            if (res.success) {
                dispatch(login_success({ ...user, doc_status: "pending", docVerified: "N" }))
                setPopUp({ modal: true, title: "Re-Apply Successfully", message: "You have successfully Re-Apply your document.Please wait until admin verify your request", buttonText: "Done" })
            }
            loader(false)
        })
    }

    const handleDrop = (e) => {
        e.preventDefault();
        uploadEIN(e);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const uploadEIN = (e) => {
        let files = e?.target?.files || e?.dataTransfer?.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only doc, pdf, jpeg and png are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    return (
        <>
            <section className="relative pb-4 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/providersignup.jpeg')] h-[553px] flex flex-col items-center justify-center">
                {/* <div className="w-full absolute top-0">
                    <NavigationBar />
                </div> */}
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-4xl md:text-5xl">
                    <span className="text-white text-[28px] leading-[35px]">Elevate Your Business and Expand Your Reach​</span>
                    <p className="text-[18px] max-w-[520px] leading-[27px] mx-auto"> Join Marc and connect with a nationwide audience. Unlock the potential to expand your reach, showcase your talents, 
                        and discover opportunities that help your business thrive. Sign up today to take the first
                         step toward limitless possibilities!​</p>
                    </h1>
                </div>
            </section>
            <div className="bg-[#f9f9f9] p-5">
                <div className="container mx-auto">
                    <form onSubmit={handleSubmit} autoComplete="one-time-code" className="">
                        <div>
                            <div className="shadow-md bg-white rounded-md border-[#ededed] max-w-[700px] mx-auto p-8 mt-4 mb-4">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    {form?.role !== "host" ?
                                        <>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    label="Contact Person Name"
                                                    placeholder="Enter Contact Person Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.name}
                                                    onChange={(e) => setform({ ...form, name: e })}
                                                    required
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="firstName"
                                                    label="First Name"
                                                    placeholder="Enter First Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.firstName}
                                                    onChange={(e) => setform({ ...form, firstName: e })}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="lastName"
                                                    label="Last Name"
                                                    placeholder="Enter Last Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.lastName}
                                                    onChange={(e) => setform({ ...form, lastName: e })}
                                                    required
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className="">
                                        <FormControl
                                            type="text"
                                            name="email"
                                            label="Email"
                                            placeholder="Enter Email"
                                            autoComplete="one-time-code"
                                            value={form.email}
                                            onChange={(e) => setform({ ...form, email: e })}
                                            required
                                        />
                                        {form?.email && !inValidEmail && submitted && (
                                            <div className="d-block text-red-600">Please enter valid email</div>
                                        )}
                                    </div>
                                    <div className="mobile_number">
                                        <FormControl
                                            type="phone"
                                            name="mobileNo"
                                            autoComplete="one-time-code"
                                            label="Mobile Number"
                                            value={form?.mobileNo}
                                            onChange={(e) => setform({ ...form, mobileNo: e })}
                                        />
                                    </div>
                                    {form?.role !== "host" &&
                                        <div>
                                            <FormControl
                                                type="text"
                                                name="venue_name"
                                                label="Company Name"
                                                placeholder="Enter Company Name"
                                                autoComplete="one-time-code"
                                                value={form?.venue_name}
                                                onChange={(e) => setform({ ...form, venue_name: e })}
                                                required
                                            />
                                        </div>
                                    }
                                    <div className="">
                                        <FormControl
                                            type="text"
                                            name="company_ein"
                                            label={form?.role === "host" ? "Driving Licence/State Id" : "Company EIN"}
                                            placeholder={`Enter ${form?.role === "host" ? "Driving Licence/State Id" : "Company EIN"}`}
                                            autoComplete="one-time-code"
                                            value={form?.company_ein}
                                            onChange={(e) => setform({ ...form, company_ein: e })}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                        Upload {form?.role === "host" ? "Driving Licence/State Id" : "Company EIN"} <span className="text-red-600">*</span>
                                    </label>
                                    {form?.ein_image ?
                                        <div className="relative mt-3">
                                            <IoDocumentTextOutline size={20} className="bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                            <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />
                                        </div>
                                        :
                                        <label onDrop={handleDrop} onDragOver={handleDragOver} className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer">
                                            <div className="text-center">
                                                <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                    <FiPlus />
                                                    <input
                                                        id="dropzone-file"
                                                        type="file"
                                                        className="hidden"
                                                        multiple
                                                        onChange={e => uploadEIN(e)}
                                                    />
                                                    {form?.role === "host" ? "Upload Or Drag & Drop Driving Licence/State Id" : "Upload Or Drag & Drop EIN"}
                                                </div>
                                            </div>
                                        </label>
                                    }
                                    {!form?.ein_image && submitted &&
                                        <span className="text-red-600">{form?.role === "host" ? "Driving Licence/State Id" : "Company EIN"} is required</span>
                                    }
                                </div>
                                <div className="w-[250px] mx-auto mt-4">
                                    <button type="submit" className="text-white w-full bg-[#005AAB] font-[18px] font-[500] rounded-full px-5 py-2.5 text-center hover:opacity-[90%]">
                                        Re Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            <PopUp popUp={popUp} setPopUp={setPopUp} redirectUrl={"/venue/profile"} />
        </>
    );
};

export default ReApply;