import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.png"
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import { useEffect, useState } from 'react';
import loader from '../../methods/loader';
import { FiEye, FiEyeOff } from "react-icons/fi";

export const Reset = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({ confirmPassword: "", newPassword: "", verificationCode: "", id: "" })
    const [submitted, setSubmitted] = useState(false);
    const [eyes, setEyes] = useState({ newPassword: false, confirmPassword: false });
    const formValidation = [
        {
            key: "confirmPassword",
            minLength: 8,
            confirmMatch: ["confirmPassword", "newPassword"],
        },
        { key: "newPassword", minLength: 8 },
    ];
    const inValidNewPassword = methodModel.passwordValidation(form?.newPassword);

    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            navigate("/");
        }
        let prm = {
            id: methodModel.getPrams("id"),
            verificationCode: methodModel.getPrams("code"),
        };
        setForm({ ...form, ...prm });
    }, []);

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        let invalid = methodModel.getFormError(formValidation, form);
        if (invalid || !inValidNewPassword) return;
        loader(true);
        let payload = {
            password: form?.newPassword,
            verificationCode: form?.verificationCode,
            id: form?.id,
        };
        let url = "users/reset/password"
        ApiClient.put(url, payload).then((res) => {
            if (res.success) {
                if (form?.role) {
                    navigate(`/signin?role=${form?.role}`)
                } else {
                    navigate("/signin")
                }
            }
            loader(false);
        });
    };

    return (
        <div className="light">
            <section className="pt-8 pb-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                        <div className="px-5 space-y-4 md:space-y-6 pb-4">
                            <div className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="h-8 rounded p-1 mr-2" src={logo} alt="logo" />
                            </div>
                            <h2 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
                                Create new password
                            </h2>
                            <form onSubmit={handleSubmit} className="items-center space-y-4 md:space-y-6">
                                <div className="mb-3">
                                    <div className="relative">
                                        <input
                                            type={eyes.newPassword ? "text" : "password"}
                                            className="mb-5 relative bg-white w-full rounded-lg h-12 flex items-center gap-2 overflow-hidden"
                                            value={form.newPassword}
                                            minLength={8}
                                            maxLength={16}
                                            onChange={(e) =>
                                                setForm({ ...form, newPassword: e.target.value })
                                            }
                                            placeholder="New Password"
                                            required
                                        />
                                        {form?.newPassword && !inValidNewPassword && submitted && (
                                            <div className="text-red-600">{methodModel.passwordValidationMessages(form?.newPassword)}</div>
                                        )}
                                        {eyes.newPassword ? (
                                            <FiEye
                                                className="top-4 right-3 absolute text-[#333] cursor-pointer"
                                                onClick={() =>
                                                    setEyes({ ...eyes, newPassword: !eyes.newPassword })
                                                }
                                            />
                                        ) : (
                                            <FiEyeOff
                                                className="top-4 right-3 absolute text-[#333] cursor-pointer"
                                                onClick={() =>
                                                    setEyes({ ...eyes, newPassword: !eyes.newPassword })
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <div className="relative">
                                            <input
                                                type={eyes.confirmPassword ? "text" : "password"}
                                                className="mb-5 relative bg-white w-full rounded-lg h-12 flex items-center gap-2 overflow-hidden"
                                                value={form.confirmPassword}
                                                minLength={8}
                                                maxLength={16}
                                                onChange={(e) =>
                                                    setForm({ ...form, confirmPassword: e.target.value })
                                                }
                                                placeholder="Confirm Password"
                                                required
                                            />
                                            {eyes.confirmPassword ? (
                                                <FiEye
                                                    className="top-4 right-3 absolute text-[#333] cursor-pointer"
                                                    onClick={() =>
                                                        setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })
                                                    }
                                                />
                                            ) : (
                                                <FiEyeOff
                                                    className="top-4 right-3 absolute text-[#333] cursor-pointer"
                                                    onClick={() =>
                                                        setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })
                                                    }
                                                />
                                            )}
                                        </div>
                                        {submitted && getError("confirmPassword").err.confirmMatch ? (
                                            <div className="d-block text-red-600">
                                                Confirm Password is not matched with New Password
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <button type="submit" className={`w-full text-white bg-[#005AAB] font-medium rounded-full text-sm px-5 py-3 text-center hover:opacity-[90%]`}>
                                    Confirm
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
