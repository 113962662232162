import { Tooltip } from "antd";
import { FiPlus } from "react-icons/fi";
import statusModel from "../../../models/status.model";
import shared from "./shared";
import { PiEyeLight } from "react-icons/pi";
import { LiaEdit, LiaTrashAlt } from "react-icons/lia";
import DesktopSidebar from "../../Home/Sidebars/DesktopSidebar";
import Table from "../../../common/Table";
import SelectDropdown from "../../../common/SelectDropdown";
import { RxCross2 } from "react-icons/rx";
import PopUp from "../../../common/PopUp";

const Html = ({
  sorting,
  filter,
  edit,
  view,
  docStatusOptions,
  statusChange,
  pageChange,
  count,
  deleteItem,
  clear,
  filters,
  setFilter,
  data,
  handleFilters,
  total = { total },
  sortClass,
  user,
  handleAddCrewMember,
  modal,
  setModal
}) => {

  const columns = [
    {
      key: "fullName",
      name: "Full Name",
      render: (row) => {
        return <span className="capitalize">{row?.fullName}</span>
      },
    },
    {
      key: "email",
      name: "Email",
      render: (row) => {
        return <span>{row?.email}</span>
      },
    },
    {
      key: "mobileNo",
      name: "Mobile Number",
      render: (row) => {
        return <span>{row?.mobileNo ? `+${row?.mobileNo}` : "--"}</span>
      },
    },
    {
      key: "status",
      name: "Status",
      render: (row) => {
        return (
          <>
            <div className="w-32" onClick={() => statusChange(row)}>
              <span className={`cursor-pointer text-sm !px-3 h-[30px] w-[100px] flex items-center justify-center border border-[#EBEBEB] rounded-full capitalize ${row.status == "deactive" ? " bg-gray-200 text-black" : "bg-[#063688] text-white"}`}>
                {row.status == "deactive" ? "inactive" : "active"}
              </span>
            </div>
          </>
        )
      },
    },
    {
      key: "doc_status",
      name: "Document Verification",
      render: (row) => {
        return <>
          <div className="w-32">
            <span className={`text-sm h-[30px] w-[100px] flex items-center capitalize ${row?.doc_status == "rejected" ? "text-[red]" : row?.doc_status == "verified" ? "text-[green]" : " text-[#ff9d00]"}`}>
              {row?.doc_status ? row?.doc_status : "Pending"}
            </span>
          </div>
        </>
      },
    },
    {
      key: "action",
      name: "Action",
      render: (itm) => {
        return (
          <>
            <div className="flex items-center justify-start gap-1.5">
              <Tooltip placement="top" title="View">
                <a onClick={(e) => view(itm.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-primary flex items-center justify-center text-lg">
                  <PiEyeLight />
                </a>
              </Tooltip>
              <Tooltip placement="top" title="Edit">
                <a onClick={(e) => edit(itm.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-primary flex items-center justify-center text-lg">
                  <LiaEdit />
                </a>
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <span onClick={() => deleteItem(itm.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-primary flex items-center justify-center text-lg">
                  <LiaTrashAlt />
                </span>
              </Tooltip>
            </div>
          </>
        )
      },
    },
  ];

  return (
    <div className="bg-white w-full h-screen">
      <DesktopSidebar />
      <div className="container mx-auto px-5 mt-6">
        <div className="border-[1px] rounded-[15px] p-5 gap-6">
          <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
            <div>
              <h3 className="text-2xl font-semibold text-[#111827]">{shared.title}</h3>
            </div>
              <div className="flex justify-end ml-auto">
                <div onClick={()=>handleAddCrewMember()} className="bg-[#134896] cursor-pointer leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2">
                  <FiPlus className="text-xl text-white" /> Add {shared.addTitle}
                </div>
              </div>
          </div>
          <div className="flex py-4 items-center flex-wrap gap-2">
            <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-full">
                <input
                  type="text"
                  id="simple-search"
                  value={filters.search}
                  onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                  className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                  placeholder="Search"
                />
                {filters?.search && (
                  <RxCross2 size={20} className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer" onClick={(e) => clear()} />
                )}
              </div>
              <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#134896] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </form>
            <div className="flex gap-2 ml-auto flex-wrap justify-end">
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="All Status"
                theme="search"
                isClearable={false}
                intialValue={filters?.status}
                result={(e) => { handleFilters(e.value, "status") }}
                options={statusModel.list}
              />
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="Select Document Verification"
                theme="search"
                isClearable={false}
                intialValue={filters?.docStatus}
                result={(e) => { handleFilters(e.value, 'docStatus') }}
                options={docStatusOptions}
              />
              {filters?.status || filters?.docStatus ? (
                <>
                  <button onClick={() => clear()} className="bg-[#134896] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full">
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Table
            className="mb-3"
            firstColumnClass="width_row"
            data={data}
            columns={columns}
            page={filters.page}
            count={filters.count}
            filters={filters}
            nodata="No Data Found"
            total={total}
            result={(e) => {
              if (e.event == "page") pageChange(e.value);
              if (e.event == "sort") {
                sorting(e.value);
                sortClass(e.value);
              }
              if (e.event == "count") count(e.value);
            }}
          />
        </div>
      </div>
      <PopUp popUp={modal} setPopUp={setModal} />
    </div>
  );
};

export default Html;