import { useEffect, useState } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import Slider from "react-slick";
import Footer from '../Footer';
import 'swiper/css/navigation';
import 'swiper/css';
import { Link, useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import DatePicker from 'react-datepicker';
import { IoIosSearch, IoMdClose } from 'react-icons/io';
import moment from "moment"
import methodModel from '../../methods/methods';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { CiLocationOn } from "react-icons/ci";

const Events = () => {
    const navigate = useNavigate()
    const [events, setEvents] = useState([])
    const search = useSelector((state) => state.search.data)
    const [filters, setFilters] = useState({ search: "", location: "", date: "", status: "active" })

    useEffect(() => {
        setFilters({ ...filters, search: search?.name || "", location: search?.location || "" })
        getEvents({ search: search?.name || "", location: search?.location || "" })
    }, [search])

    const getEvents = (f = {}) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        const filter = { ...filters, ...f, value: "upcoming", lat: userLat, long: userLong }
        loader(true)
        ApiClient.get("event/listing", filter).then(res => {
            if (res.success) {
                setEvents(res?.data)
            }
            loader(false)
        })
    }

    var banner = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    const clearSearch = () => {
        const filter = { search: "", date: "", status: "active" }
        setFilters(filter)
        getEvents(filter)
    }

    const handleSearch = () => {
        let eventDate = filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : ""
        getEvents({ ...filters, date: String(eventDate) })
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="">
                    <div className="banner_home event_palce relative">
                        <Slider {...banner}>
                            <div className=" bg-[url('assets/images/event-banner1.jpeg')] h-[400px] lg:h-[500px] bg-no-repeat bg-cover">
                                <div className='flex flex-col items-center justify-center py-[80px] bg-[#0000008c] h-full px-5'></div>
                            </div>
                            <div className=" bg-[url('assets/images/event-banner2.jpeg')] h-[400px] lg:h-[500px] bg-no-repeat bg-cover">
                                <div className='flex flex-col items-center justify-center py-[80px] bg-[#0000008c] h-full px-5'></div>
                            </div>
                            <div className=" bg-[url('assets/images/event-banner3.jpeg')] h-[400px] lg:h-[500px] bg-no-repeat bg-cover">
                                <div className='flex flex-col items-center justify-center py-[80px] bg-[#0000008c] h-full px-5'></div>
                            </div>
                        </Slider>
                        <div className='absolute top-[0px] h-[400px] lg:h-[500px] px-5 py-[2rem] w-full'>
                            <div className='flex flex-col items-center justify-center h-full px-5'>
                                <h2 className="font-[700] text-[32px] sm:text-[45px] text-[#E1B524] text-center leading-[38px] sm:leading-[48px]">
                                    Search, Explore, Experience
                                </h2>
                                <div className="w-full flex md:flex-row flex-col justify-center items-center gap-2 pt-[60px]">
                                    <div className="search_all max-w-[605px] w-full flex justify-between rounded-[30px] border border-[1px] bg-white !border-white px-3 py-1">
                                        <input
                                            type="text"
                                            value={filters?.search}
                                            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                            className="max-[499px]:w-[50px] bg-transparent flex-1 text-[14px]] outline-none border-0 focus:outline-none border-r focus:ring-0"
                                            placeholder="Search... "
                                        />
                                        <DatePicker
                                            selected={filters?.date}
                                            onChange={(date) => setFilters({ ...filters, date: date })}
                                            className="bg-transparent border-0 text-[#AEAEAE] text-[14px] outline-none focus:outline-none focus:ring-0 max-[639px]:w-[130px] sm:w-[180px]"
                                            placeholderText='Date'
                                            minDate={new Date()}
                                        />
                                    </div>
                                    <div className='flex gap-2'>
                                        {filters?.search || filters?.date
                                            ?
                                            <button type='button' onClick={e => clearSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                                                <IoMdClose className='mr-1' /> Clear
                                            </button>
                                            : null
                                        }
                                        <button type='button' onClick={e => handleSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                                            <IoIosSearch className='text-[18px] mr-1' /> Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="upcoming py-[40px]">
                <div className="container mx-auto px-5 pb-10">
                    <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 gap-5 mb-8">
                        <div className='relative cursor-pointer text-white ' onClick={() => { navigate(`/eventmap`) }}>
                            <div className='rounded-[20px] overflow-hidden'>
                                <img src="/assets/img/event_img1.jpg" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] rounded-[20px] object-cover transition-all duration-300 hover:scale-110'></img>
                            </div>
                            <div className='text-[15px] md:text-[18px] font-[500] leading-[18px] rounded-b-[20px] drop-shadow-[0px_2px_3px_#000] text-center absolute bottom-0 bg-[#0000004a] w-full px-3 py-3'>Happy Hours ​</div>
                        </div>
                        <div className='relative cursor-pointer text-white' onClick={() => { navigate(`/eventmap`) }}>
                            <div className='rounded-[20px] overflow-hidden'>
                                <img src="/assets/img/event_img2.jpeg" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] rounded-[20px] object-cover transition-all duration-300 hover:scale-110'></img>
                            </div>
                            <div className='text-[15px] md:text-[18px] font-[500] leading-[18px] rounded-b-[20px] drop-shadow-[0px_2px_3px_#000] text-center absolute bottom-0 bg-[#0000004a] w-full px-3 py-3'>Popular near you​​</div>
                        </div>
                        <div className='relative cursor-pointer text-white' onClick={() => { navigate(`/eventmap`) }}>
                            <div className='rounded-[20px] overflow-hidden'>
                                <img src="/assets/img/event_img3.webp" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] rounded-[20px] object-cover transition-all duration-300 hover:scale-110'></img>
                            </div>
                            <div className='text-[15px] md:text-[18px] font-[500] leading-[18px] rounded-b-[20px] drop-shadow-[0px_2px_3px_#000] text-center absolute bottom-0 bg-[#0000004a] w-full px-3 py-3'>Local Favorites​ ​</div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-5">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[24px] text-[#1B1B25]">
                            Events {search?.location && `in ${search?.location}`}
                        </h3>
                        <Link to="/eventmap">
                            <button className="font-[400] text-[14px] text-[#005AAB]  font-open-sans">
                                See the map {'>'}
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="container mx-auto px-5 grid gap-5 mt-5 max-[480px]:grid-cols-1 grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                    {events.map((item, index) => (
                        <div className="overflow-hidden bg-white" key={index}>
                            <div className='event_card relative cursor-pointer rounded-md overflow-hidden'>
                                <img className="object-cover w-full rounded-md max-[480px]:h-[250px] h-[180px] lg:h-[200px] 2xl:h-[220px]  transition-all duration-300 hover:scale-110" src={methodModel.noImg(item?.images?.[0])} onClick={e => { navigate(`/EventDetails/${item?._id || item?.id}`) }} alt="event_image" />
                            </div>
                            <div onClick={e => { navigate(`/EventDetails/${item?._id || item?.id}`) }} className="cursor-pointer p-3 min-h-[115px]">
                                <h2 className="p_text font-[500] text-[16px] leading-[18px] mb-2 capitalize">
                                    {item?.title}
                                </h2>
                                <p className="font-[400] text-[13px]">
                                    {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                </p>
                                <p className="flex items-center gap-1 font-[400] text-[13px] mt-1">
                                    <CiLocationOn className='text-[18px]' />
                                    <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location} </span>
                                </p>
                                <p className='ellipsis_class text-[#585858] text-[13px] leading-[16px] mt-1' dangerouslySetInnerHTML={{ __html: item?.short_description }}></p>
                            </div>
                        </div>
                    ))}
                </div>
                {events?.length === 0 ?
                    <div className='text-center'>No Records Found</div>
                    : null}
            </div>
            <div className="upcoming py-[40px]">
                <div className="container mx-auto px-5">
                    <div className='w-full flex flex-row justify-between items-center'>
                        <h2 className='font-semibold text-[24px] text-[#1B1B25]'>Our Top Picks!</h2>
                        <Link to="/eventmap">
                            <button className="font-[400] text-[14px] text-[#005AAB] font-open-sans">
                                See the map {'>'}
                            </button>
                        </Link>
                    </div>
                    <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 gap-5 mb-8 mt-5">
                        <div className='relative'>
                            <img src="/assets/img/our-top-picks-1.jpg" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover'></img>
                        </div>
                        <div className='relative'>
                            <img src="/assets/img/our-top-picks-2.jpeg" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover'></img>
                        </div>
                        <div className='relative'>
                            <img src="/assets/img/our-top-picks-3.jpeg" className='w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover'></img>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Events;
