import { useState, Fragment } from 'react';
import Img1 from "../../assets/images/event-img1.png";
import Img2 from "../../assets/images/event-img2.png";
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
// Import Swiper React components
// import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { NavigationBar } from './NavigationBar';

const EventCard = [
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img2,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
  {
    img: Img1,
    head: "Lorem ISpum Lorem ISpum Lorem ISpum",
    date: "Sat, 4 April 2024",
    time: "4:30 pm",
    location: "Lorem ispum Lorem ISpum"
  },
]

function Banner({setIsModalOpen}) {
  const navigate = useNavigate();

  return (
      <div className=" w-full pt-[10rem]">
      <div className="mx-auto px-6 py-12 lg:px-8 lg:py-16">
        <div className="rounded-3xl bg-blue-800 px-6 py-10 sm:px-12 sm:py-16 lg:flex lg:items-center lg:p-20">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-4xl font-bold tracking-tight text-white">Sign up for our newsletter</h2>
            <p className="mt-4 mb-8 text-xl text-indigo-100">
             
              Join Marc to explore events and seemless bookings for venues and guests.
            </p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-8 text-blue-800 flex w-full items-center justify-center rounded-md border border-transparent bg-white px-7 py-4 font-medium text-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-700 sm:mt-3 sm:w-auto sm:flex-shrink-0"
            >
              Get Started
            </button>
          </div>
          <div className="mt-12 sm:w-full sm:max-w-md lg:ml-8 lg:mt-0 flex flex-col">
            <form className="sm:flex">
        {/*      <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email-address"
                type="email"
                required
                onClick={() => setIsModalOpen(true)}
                placeholder="Enter your email"
                autoComplete="email"
                className="w-full rounded-md border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700"
              />*/}
            </form>
     {/*       <p className="mt-3 text-sm text-indigo-100">
              We care about the protection of your data. Read our{' '}
              <a href="#" className="font-medium text-white underline">
                Privacy Policy.
              </a>
            </p>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

const HeroSection = () => {
  const token = sessionStorage.getItem("token")
  const [inputType, setInputType] = useState('text');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = (role="") => {
    setIsModalOpen(false);  
  };

  const handleInputClick = () => {
    setInputType('date');
  };
  
  return (
    <>
      {/* header section */}

      <section className="h-screen bg-cover bg-[url('assets/images/Homepage.jpg')]">
        <div className='relative w-full bg-[#ffffff70] h-full'>
        <div className="w-full absolute top-0">
          <NavigationBar />
        </div>
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative w-screen z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <iframe src="https://gdfm7gtlbij.typeform.com/to/i3fT6Ny3" style={{ width: '100%', height: '80vh', border: 'none' }}></iframe>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {!token && <Banner setIsModalOpen={setIsModalOpen}/>}
         </div>
      </section>
{/*     
      <div className="event py-[40px] flex flex-col items-center">
        <h2 className="text-[#777777] text-[28px] font-[600] font-montserrat">
          Events
        </h2>
        <p className="font-poppins text-center">
          m ipsum dolor sit amet consectetur adipiscing elit Ut et massa
        </p>
        <div className="w-full flex flex-col sm:flex-row justify-center gap-2">
          <div className='relative'>
            <span className='absolute top-5'>
              <img className="ml-3" src={calender} alt="" />
            </span>
            <input
              className={`mt-2 block max-w-[105px] pl-11 h-[46px] py-2 px-3 text-white placeholder-white bg-[#005AAB] border border-[#005AAB] rounded-full dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40`}
              placeholder='Date'
              type={inputType}
              name=""
              id=""
              onClick={handleInputClick}
            />

          </div>

          <div className="relative flex items-center mt-2 max-w-[485px] w-full">
            <span className="absolute">
              <img className="ml-3" src={searchIcon} alt="" />

            </span>

            <input type="text" placeholder="Search an Event" className="block w-full py-2.5 text-white placeholder-white bg-[#005AAB] border border-[#005AAB] rounded-full pl-11 pr-5 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
            <button className="absolute right-1 bg-white rounded-full px-[21px] py-[5px]">
              GO
            </button>
          </div>
        </div>

      </div> */}
{/*    
      <section className="bg-[#D9D9D938]">
        <div className="max-w-screen-xl mx-auto py-10 md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">

            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
              <h3 className="text-[28px] font-[600]">
                Why Marc
              </h3>

              <p className="mt-3font-[400]">
                m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massam ipsum dolor sit amet t et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              </p>
              <button>

                <Link to="/UserSignUp" className="px-3 py-2 w-full bg-[#005AAB] text-white text-center rounded-full shadow-md block sm:w-auto text-[18px] text-nowrap font-[500]">
                  Create Account
                </Link>
              </button>
            </div>
            <div className="flex-1 mt-4 sm:mt-0
                         sm:hidden lg:block flex justify-center items-center">
              <img src={AboutImg1} className="md:max-w-lg sm:rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section> */}
          {/*  
      <section className="bg-white dark:bg-gray-900 max-w-screen-xl mx-auto">
        <div className=" px-6 py-10 mx-auto">

          <h3 className="text-[#000000] text-[28px] font-[600]">Events in United States</h3>
          <p className="text-[#000000] text-[18px] font-[400]">m ipsum dolor sit amet consectetur adipiscing elit Ut et massa</p>
          <div className="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-4">
            {
              EventCard.map((cards) => (
                <div className=" overflow-hidden bg-white rounded-lg shadow-md">
                  <img className="object-cover w-full h-[146px]" src={cards.img} alt="Article" />

                  <div className="p-4">
                    <h2 className="font-[500] text-[18px]">{cards.head}</h2>
                    <p className='font-[400] text-[14px]'>
                      {cards.date} {" "}<span className="">{cards.time}
                      </span></p>
                    <p className="flex gap-2 font-[400] text-[14px]">
                      <img src={Location} alt="" />
                      {cards.location}</p>
                  </div>
                </div>
              ))
            }

          </div >
          <div className='mt-10 flex justify-center'>
            <a href="" className="max-w-[344px] w-full py-2 flex justify-center px-10 border border-[#005AAB] text-[#005AAB] font-medium hover:bg-[#005AAB] duration-150  rounded-md hover:text-white">
              Explore More
            </a>
          </div>
        </div >
      </section >

      < div className="relative z-10 max-w-screen-xl mx-auto px-4 md:text-center md:px-8 bg-[#005AAB] py-[34px] rounded-xl" >
        <div className="max-w-xl md:mx-auto flex flex-col items-center">
          <p className="text-white text-3xl font-semibold sm:text-4xl">
            Let's Get Started!
          </p>
          <p className="text-blue-100 mt-3 text-center max-w-[519px] px-4">
            Start For FREE Now And Have Your Remarketing Campaigns Live Within Days
          </p>
        </div>
        <div className="mt-4 flex justify-center">
          <a href=".." className="inline-block py-2 px-4 border border-[#E1B524] text-white font-medium bg-[#E1B524] duration-150  rounded-md hover:text-[#E1B524] hover:bg-transparent">
            Start Today For Free
          </a>
        </div>
      </div >

      <div className="py-10">
        <div className=" max-w-screen-xl mx-auto">
          <div className='px-4 px-md-0'>
            <h3 className="text-[#000000] text-[28px] font-[600]">Popular Venues in United States</h3>
            <p className="text-[#000000] text-[18px] font-[400]">m ipsum dolor sit amet consectetur adipiscing elit Ut et massa</p>
          </div>
          <div className="mt-[40px] px-4 px-md-0">
            <Swiper
              loop={true}
              slidesPerView={1}
              spaceBetween={30}
              centeredSlides={true}
              effect={'coverflow'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 1,
                scale: 0.7,
                slideShadows: false,
              }}
              breakpoints={
                {
                  640: {
                    slidesPerView: 3,
                    spaceBetween: -30
                  },
                }
              }
              navigation={true}
              modules={[EffectCoverflow, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="bg-[url('/src/assets/images/Rectangle-8.png')] bg-cover max-w-[457px] w-full mx-auto h-[494px] rounded-[30px] flex justify-center items-end ">
                  <div className='swiper-content '>
                    <p className='text-[24px] text-[#FFFFFF] font-[500] mb-[88px]'>
                      Lorem ISpum Lorem <br /> ISpum Lorem ISpum
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-[url('/src/assets/images/Rectangle-8.png')] bg-cover  max-w-[457px] w-full mx-auto h-[494px]
                                 rounded-[30px] flex justify-center items-end ">

                  <div className='swiper-content '>
                    <p className='text-[24px] text-[#FFFFFF] font-[500] mb-[88px]'>
                      Lorem ISpum Lorem <br /> ISpum Lorem ISpum
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-[url('/src/assets/images/Rectangle-8.png')] bg-cover max-w-[457px] w-full mx-auto h-[494px] rounded-[30px] flex justify-center items-end ">
                  <div className='swiper-content '>
                    <p className='text-[24px] text-[#FFFFFF] font-[500] mb-[88px]'>
                      Lorem ISpum Lorem <br /> ISpum Lorem ISpum
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-[url('/src/assets/images/Rectangle-8.png')] bg-cover max-w-[457px] w-full mx-auto h-[494px] 
                                rounded-[30px] flex justify-center items-end ">
                  <div className='swiper-content '>
                    <p className='text-[24px] text-[#FFFFFF] font-[500] mb-[88px]'>
                      Lorem ISpum Lorem <br /> ISpum Lorem ISpum
                    </p>
                  </div>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </div>
*/}
    </>
  );
};

export default HeroSection;