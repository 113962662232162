import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/linkedin.svg';
import lock from '../../assets/images/Icons/lock.svg';
import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import { login_success } from '../../redux/actions/user';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import methodModel from '../../methods/methods';
import FormControl from '../../common/FormControl';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import loader from '../../methods/loader';
import { useNavigate } from 'react-router-dom';

const ProviderSignIn = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState({ email: "", password: "" })
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const [submitted, setSubmitted] = useState(false)
    const [eye, setEye] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const role = methodModel.getPrams("role")
        const email = methodModel.getPrams("email")
        if (role) {
            setForm((prev) => ({ ...prev, role: role }))
        }
        if (email) {
            ApiClient.post(`user/auto/login`, { email: email }).then(res => {
                if (res.success) {
                    dispatch(login_success(res?.data))
                    sessionStorage.setItem('token', res?.data?.access_token)
                    if (res?.data?.role === "venue") {
                        navigate("/venue/profile")
                    } else if (res?.data?.role === "host") {
                        navigate("/host/profile")
                    } else {
                        navigate("/home")
                    }
                }
            })
        }
        if (sessionStorage.getItem("token")) {
            navigate("/");
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (!inValidEmail) return
        const payload = {
            email: form?.email,
            password: form?.password,
        }
        const apiUrl = "users/login"
        loader(true)
        ApiClient.post(apiUrl, payload).then((res) => {
            if (res.success) {
                dispatch(login_success(res?.data))
                sessionStorage.setItem('token', res?.data?.access_token)
                if (res?.data?.role === "venue") {
                    navigate("/venue/profile")
                } else if (res?.data?.role === "host") {
                    navigate("/host/profile")
                } else {
                    navigate("/home")
                }
            }
            loader(false)
        })
    };

    const handleRedirection = (url) => {
        if (url === "/auth" && form?.role === "venue") {
            return navigate("/providersignup")
        }
        if (form?.role) {
            navigate(`${url}?role=${form?.role}`)
        } else {
            navigate(url)
        }
    }

    return (
        <>
            <section className="relative pb-4 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/providersignup.jpeg')] h-[553px] flex flex-col items-center justify-center">
                <div className="w-full absolute top-0">
                    <NavigationBar />
                </div>
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-4xl md:text-5xl">
                        <span className="text-white text-[28px] leading-[35px]">Elevate Your Business and Expand Your Reach​</span>
                        <p className="text-[18px] max-w-[520px] leading-[27px] mx-auto"> Join Marc and connect with a nationwide audience. Unlock the potential to expand your reach, showcase your talents, 
                        and discover opportunities that help your business thrive. Sign up today to take the first
                         step toward limitless possibilities!​</p>
                    </h1>
                </div>
            </section>
            <div className='bg-[#f9f9f9] p-5'>
            <div className="container mx-auto">
            <div className="flex flex-col items-center py-10">
                <h3 className="text-center font-[500] text-[25px] md:text-[44px]">Lorem Ispum</h3>
                <p className="text-center text-[#646464] max-w-[1100px] mx-auto w-full text-[14px] md:text-[16px] font-[400] px-3 mt-[20px]">
                    m ipsum dolor sit amet consectetur adipiscing elit Ut et mm
                    ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massaassam ipsum dolor sit amet consectetur adipiscing elit
                    Ut et massa m ipsum dolor sit amet consectetur adipiscing
                    elit Ut et massa m ipsum dolor sit amet consectetur adipiscm
                    ipsum dolor sit amet t et massa m ipsum dolor sit amet
                    consectetur adipiscing elit Ut et massa
                </p>
            </div>
            <div className="shadow-md bg-white rounded-md border-[#ededed] max-w-[700px] mx-auto p-8 mt-4 mb-4">
                <form onSubmit={handleSubmit} autoComplete="one-time-code" className="w-full">
                    <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full capitalize">Sign In {form?.role && `As ${form?.role}`}</h2>
                    <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                        <div className="">
                            <FormControl
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                autoComplete="one-time-code" 
                                className="relative bg-white w-full rounded-lg h-10  overflow-hidden px-2 border border-[#00000036] "
                                value={form?.email}
                                onChange={(e) => setForm({ ...form, email: e })}
                                required
                            />
                            {form?.email && !inValidEmail && submitted && (
                                <div className="d-block text-red-600">Please enter valid email</div>
                            )}
                        </div>
                        <div className="relative flex mt-2">
                            <FormControl
                                type={eye ? "text" : "password"}
                                name="password"
                                placeholder="Enter Password"
                                autoComplete="one-time-code"
                                className="relative bg-white w-full rounded-lg h-10  overflow-hidden px-2 border border-[#00000036] "
                                value={form?.password}
                                onChange={(e) => setForm({ ...form, password: e })}
                                required
                            />
                            {!eye ?
                                <IoEyeOffOutline size={20} onClick={e => setEye(true)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                :
                                <IoEyeOutline size={20} onClick={e => setEye(false)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="flex justify-end mt-6 cursor-pointer" onClick={e => handleRedirection('/auth/forgot-password')}>
                        <img className="me-1" src={lock} alt="lock-icon" />
                        <p className="text-[11.86px] font-[400] text-[#6B6B6B]">Forgot Password</p>
                    </div>
                    <div className="flex flex-col pt-[20px] w-full gap-4 md:gap-[35px]">
                        <button type='submit' className="text-[18px] font-[500] text-[#FFFFFF] bg-[#005AAB] rounded-lg py-2 cursor-pointer">
                            Sign In
                        </button>
                        {/* <div className="flex justify-center items-center gap-5">
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                                <span className="text-[14.4px] font-[500] text-[#005AAB]">Or Sign Up With </span>
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            </div>
                            <div>
                                <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                    <img className='cursor-pointer' src={google} onClick={() => thirdPartySignInClicked('google')} alt="google_icon" />
                                    <img className='cursor-pointer' src={facebook} onClick={() => thirdPartySignInClicked('google')} alt="facebook_icon" />
                                    <img className='cursor-pointer' src={linkedin} onClick={() => thirdPartySignInClicked('google')} alt="linkedin_icon" />
                                    <img className='cursor-pointer' src={instagram} onClick={() => thirdPartySignInClicked('google')} alt="instagram_icon" />
                                </div>
                            </div> */}
                        <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                            Don't have an account yet?{' '}
                            <span onClick={() => handleRedirection("/providersignup")} className="cursor-pointer font-medium text-[#3163b2] hover:underline dark:text-[#3163b2]">
                                Create account
                            </span>
                        </p>
                    </div>
                </form>
            </div>
            </div>
            </div>
            <Footer />
        </>
    );
};

export default ProviderSignIn;
