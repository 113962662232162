import { useEffect, useState } from "react";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { MdClear } from "react-icons/md";
import SelectDropdown from "../../common/SelectDropdown";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import methodModel from "../../methods/methods";
import Pagination from "react-js-pagination";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Tooltip } from "antd";
import HirePostModal from "./HirePostModal";
import moment from "moment";

const Listing = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [filters, setFilter] = useState({ page: 1, count: 10, search: "", request_status: "" })
    const [data, setData] = useState([])
    const [shortlisted, setShortlisted] = useState([])
    const [messaged, setMessaged] = useState([])
    const [total, setTotal] = useState(0)
    const [tab, setTab] = useState(0)
    const [hireModal, setHireModal] = useState({ modal: false, jobEndDate: "", payment: "" })

    const statusOptions = [
        { id: "accepted", name: "Hire" },
        { id: "rejected", name: "Rejected" },
        { id: "pending", name: "Pending" },
    ]

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, addedFor: user?._id || user?.id }
        loader(true)
        ApiClient.get(`host/post/apply/listing`, filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const getShortlistedListing = (p = {}) => {
        let filter = { ...filters, ...p, addedFor: user?._id || user?.id, shortListed: true }
        loader(true)
        ApiClient.get(`host/post/apply/listing`, filter).then((res) => {
            if (res.success) {
                setShortlisted(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const getMessagedListing = (p = {}) => {
        let filter = { ...filters, ...p, venueId: user?._id || user?.id }
        loader(true)
        ApiClient.get(`venue/messaged`, filter).then((res) => {
            if (res.success) {
                setMessaged(res.data.map((item) => {
                    return ({ ...item?.applyPostId })
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const handleFilter = (e, key) => {
        setFilter({ ...filters, [key]: e });
        if (tab === 0) getData({ [key]: e });
        else if (tab === 1) getShortlistedListing({ [key]: e });
        else if (tab === 2) getMessagedListing({ [key]: e });
        else return
    }
    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        if (tab === 0) getData({ ...f });
        else if (tab === 1) getShortlistedListing({ ...f });
        else if (tab === 2) getMessagedListing({ ...f });
        else return
    }
    const clear = () => {
        let f = { search: "", request_status: "" }
        setFilter({ ...filters, ...f });
        if (tab === 0) getData({ ...f });
        else if (tab === 1) getShortlistedListing({ ...f });
        else if (tab === 2) getMessagedListing({ ...f });
        else return
    }
    const handlePagination = (e) => {
        setFilter({ ...filters, page: e });
        if (tab === 0) getData({ page: e });
        else if (tab === 1) getShortlistedListing();
        else if (tab === 2) getMessagedListing({ page: e });
        else return
    }

    const handleMessage = (item) => {
        navigate(`/message/${item?.addedBy?._id || item?.addedBy?.id}?venueId=${user?._id || user?.id}&postId=${item?.post_id}&applyPostId=${item?._id || item?.id}`)
    }

    const handleTabChange = (e) => {
        setTab(e)
        if (e === 0) getData()
        else if (e === 1) getShortlistedListing()
        else if (e === 2) getMessagedListing()
        else return
    }

    const handleHirePayment = (e, item, type) => {
        e.preventDefault()
        const amount = type === "hire" ? hireModal?.payment === "50%" ? Number(item?.salary / 2) : Number(item?.salary) : Number(item?.pendingAmount / 100)
        if(type === "hire" || amount !== 0){
            let paymentPayload = {
                postId: item?.post_id,
                productData: {
                    productName: item?.post_details?.title
                },
                paidBy: user?._id || user?.id,
                paidTo: item?.addedBy?._id || item?.addedBy?.id,
                email: user?.email,
                totalPrice: Number(item?.salary || 0),
                applicationId: item?._id || item?.id,
                type: type,
                halfPrice: amount,
                pendingAmount: hireModal?.payment === "50%" ? Number(item?.salary / 2) : 0,
            }
            if (type === "hire") {
                paymentPayload = { ...paymentPayload, jobEndDate: hireModal?.jobEndDate }
            }
            if(item?.paymentRequestStatus !== "pending" && item?.jobPostStatus === "working"){ // For payment request mail
                paymentPayload = { ...paymentPayload, jobPaymentRequest: "sent" }
            }
            loader(true)
            ApiClient.post(`job/post/payment`, paymentPayload).then(res => {
                loader(false)
                if (res.success) {
                    window.open(res?.data, "_self")
                }
            })
        }else{
            ApiClient.post(`job/mark/complete`,{ appliedPostId: item?._id || item?.id }).then(res=>{
                if(res.success){
                    getData()
                }
            })         
        }
    }

    const handleShortListing = (item) => {
        if (item?.shortListed) {
            loader(true)
            ApiClient.delete(`venue/shortlist/remove?id=${item?._id || item?.id}`).then(res => {
                if (res.success) {
                    if (tab === 0) getData()
                    else if (tab === 1) getShortlistedListing()
                    else if (tab === 2) getMessagedListing()
                    else return
                }
                loader(false)
            })
        } else {
            const payload = {
                id: item?._id || item?.id,
                type: "shortlist"
            }
            loader(true)
            ApiClient.post(`venue/shortlist`, payload).then(res => {
                if (res.success) {
                    if (tab === 0) getData()
                    else if (tab === 1) getShortlistedListing()
                    else if (tab === 2) getMessagedListing()
                    else return
                }
                loader(false)
            })
        }
    }

    const rejectPaymentRequest = (item) => {
        const payload = {
            appliedPostId: item?.id || item?._id,
            userId: item?.post_details?.addedFor
        }
        loader(true)
        ApiClient.post("job/reject/payment/request", payload).then(res => {
            if (res.success) {
                getData()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 pb-5">
                    <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6">
                        <div className="flex justify-between flex-wrap gap-5 mb-5">
                            <div>
                                <h3 className="text-2xl font-semibold text-[#134896]">Applied Post Requests</h3>
                            </div>
                        </div>
                        <TabGroup selectedIndex={tab} onChange={e => handleTabChange(e)}>
                            <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                                <Tab key={"All_Requests"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    All Requests
                                </Tab>
                                <Tab key={"Shortlisted"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Shortlisted
                                </Tab>
                                <Tab key={"Messaged"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Messaged
                                </Tab>
                            </TabList>
                            <TabPanels className="mt-3">
                                <div className="flex justify-between flex-wrap gap-5 mb-5">
                                    <div className="flex items-center flex-wrap gap-2 ml-auto">
                                        <div className="flex gap-2 ml-auto flex-wrap justify-end">
                                            <SelectDropdown
                                                id="statusDropdown"
                                                displayValue="name"
                                                placeholder="All Status"
                                                theme="search"
                                                isClearable={false}
                                                intialValue={filters?.request_status}
                                                result={(e) => { handleFilter(e.value, 'request_status') }}
                                                options={statusOptions}
                                            />
                                            {filters?.request_status ?
                                                <button onClick={() => clear()} className="bg-[#063688] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
                                                    Reset
                                                </button>
                                                : null}
                                        </div>
                                        <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                                            <label htmlFor="simple-search" className="sr-only">Search</label>
                                            <div className="relative w-full">
                                                <input
                                                    type="text"
                                                    id="simple-search"
                                                    value={filters?.search}
                                                    onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                                    autoComplete="one-time-code"
                                                    className="text-gray-900 text-sm rounded-lg  focus:border-[#063688] block w-full ps-5 p-2.5 pr-10 border border-[#d1d1d1]"
                                                    placeholder="Search"
                                                />
                                                {filters?.search && (
                                                    <MdClear className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                                )}
                                                <button type="submit" className="absolute right-[3px] top-[2px] p-2.5 text-sm font-medium text-white bg-[#063688] rounded-lg border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                    </svg>
                                                    <span className="sr-only">Search</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="border-[1px] rounded-[15px] max-[480px]:p-3 p-5 gap-6">
                                    <TabPanel key={"All_Requests"} className="rounded-xl bg-white/5">
                                        <div className="grid grid-cols-1 gap-5 sm:gap-8">
                                            {data?.map((item, index) => {
                                                return <div className="hover:bg-[#f6f6f6] max-[480px]:p-3 p-5 rounded-[15px]" key={index}>
                                                    <div className="flex flex-wrap justify-between gap-5">
                                                        <div>
                                                            <div className="flex items-center gap-3 capitalize">
                                                                <img src={methodModel.userImg(item?.addedBy?.image)} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full" alt="avaitar" />
                                                                <div className="grid grid-cols-1 gap-1 capitalize">
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.fullName || `${item?.addedBy?.firstName || ""} ${item?.addedBy?.lastName || ""}`}</p>
                                                                    <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.post_details?.title || "--"}</h2>
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.location}</p>
                                                                </div>
                                                            </div>
                                                            {item?.jobPostStatus === "working" &&
                                                                <p className="mt-3">Job Post End Date & Time: <span className="text-[#134896]">{moment(item?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></p>
                                                            }
                                                            <p className="flex flex-wrap gap-x-3 mt-5 max-[499px]:!text-[12px] !text-[14px] !font-[500]">
                                                                <span>Salary: {item?.salary ? `$${item?.salary}` : "--"}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="flex items-center flex-wrap gap-2">
                                                                {item?.shortListed ?
                                                                    <FaHeart size={25} className="cursor-pointer text-[#134896]" onClick={e => handleShortListing(item)} />
                                                                    :
                                                                    <Tooltip title="Like to Shortlist">
                                                                        <FaRegHeart size={25} className="cursor-pointer text-[#a1a1a1]" onClick={e => handleShortListing(item)} />
                                                                    </Tooltip>
                                                                }
                                                                <button type="button" onClick={e => handleMessage(item)} className="max-[499px]:min-w-[100px] bg-white px-4 py-[5px] text-[#134896] border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit hover:opacity-[80%]">Message</button>
                                                                <button type="button" onClick={e => setHireModal((prev) => ({ ...prev, modal: true, jobEndDate: "", payment: "", data: item }))} disabled={item?.request_status !== "pending"} className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit ${item?.request_status === "pending" && "hover:opacity-[80%]"}`}>{item?.request_status === "pending" ? "Hire" : item?.request_status === "accepted" ? "Hired" : "Rejected"}</button>
                                                                {item?.jobPostStatus === "working" &&
                                                                    <>
                                                                        <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className={`max-[499px]:min-w-[100px] min-w-[120px] bg-[#134896] px-5 py-2 text-white border border-[#134896] max-[499px]:text-[12px] text-[14px] rounded-lg h-fit hover:opacity-[80%]`}>Mark As Complete</button>
                                                                    </>
                                                                }
                                                                {item?.paymentRequestStatus !== "pending" && item?.jobPostStatus === "working" ?
                                                                    <>
                                                                        <p className="flex gap-2 items-center">Payment Request:
                                                                            {item?.paymentRequestStatus === "sent" ?
                                                                                <>
                                                                                    <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                                    <button type="button" onClick={e => rejectPaymentRequest(item)} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                                </>
                                                                                :
                                                                                <span className="text-red-600">Rejected</span>
                                                                            }
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {item?.jobPostStatus === "completed" &&
                                                                    <button type="button" className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit`}>Job Completed</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="max-[499px]:text-[12px] text-[14px] mt-5 text_3line w-full" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                        <div className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto" onClick={e => { navigate(`/apply/post/${item?._id || item?.id}`) }}>See More</div>
                                                    </div>
                                                </div>
                                            })}
                                            {data?.length === 0 &&
                                                <div className="text-center">No Data Found</div>
                                            }
                                        </div>
                                    </TabPanel>
                                    <TabPanel key={"Shortlisted"} className="rounded-xl bg-white/5">
                                        <div className="grid grid-cols-1 gap-5 sm:gap-8">
                                            {shortlisted?.map((item, index) => {
                                                return <div className="hover:bg-[#f6f6f6] p-5 rounded-[15px]" key={index}>
                                                    <div className="flex flex-wrap justify-between gap-5">
                                                        <div>
                                                            <div className="flex items-center gap-3 capitalize">
                                                                <img src={methodModel.userImg(item?.addedBy?.image)} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full" alt="avaitar" />
                                                                <div className="grid grid-cols-1 gap-1 capitalize">
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.fullName || `${item?.addedBy?.firstName || ""} ${item?.addedBy?.lastName || ""}`}</p>
                                                                    <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.post_details?.title || "--"}</h2>
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.location}</p>
                                                                </div>
                                                            </div>
                                                            {item?.jobPostStatus === "working" &&
                                                                <p className="mt-3">Job Post End Date & Time: <span className="text-[#134896]">{moment(item?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></p>
                                                            }
                                                            <p className="flex flex-wrap gap-x-3 mt-5 max-[499px]:!text-[12px] !text-[14px] !font-[500]">
                                                                <span>Salary: {item?.salary ? `$${item?.salary}` : "--"}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="flex items-center flex-wrap gap-2">
                                                                {item?.shortListed ?
                                                                    <FaHeart size={25} className="cursor-pointer text-[#134896]" onClick={e => handleShortListing(item)} />
                                                                    :
                                                                    <Tooltip title="Like to Shortlist">
                                                                        <FaRegHeart size={25} className="cursor-pointer text-[#a1a1a1]" onClick={e => handleShortListing(item)} />
                                                                    </Tooltip>
                                                                }
                                                                <button type="button" onClick={e => handleMessage(item)} className="max-[499px]:min-w-[100px] bg-white px-4 py-[5px] text-[#134896] border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit hover:opacity-[80%]">Message</button>
                                                                <button type="button" onClick={e => setHireModal((prev) => ({ ...prev, modal: true, jobEndDate: "", payment: "", data: item }))} disabled={item?.request_status !== "pending"} className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit ${item?.request_status === "pending" && "hover:opacity-[80%]"}`}>{item?.request_status === "pending" ? "Hire" : item?.request_status === "accepted" ? "Hired" : "Rejected"}</button>
                                                                {item?.jobPostStatus === "working" &&
                                                                    <>
                                                                        <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className={`max-[499px]:min-w-[100px] min-w-[120px] bg-[#134896] px-5 py-2 text-white border border-[#134896] max-[499px]:text-[12px] text-[14px] rounded-lg h-fit hover:opacity-[80%]`}>Mark As Complete</button>
                                                                    </>
                                                                }
                                                                {item?.paymentRequestStatus !== "pending" && item?.jobPostStatus === "working" ?
                                                                    <>
                                                                        <p className="flex gap-2 items-center">Payment Request:
                                                                            {item?.paymentRequestStatus === "sent" ?
                                                                                <>
                                                                                    <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                                    <button type="button" onClick={e => rejectPaymentRequest(item)} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                                </>
                                                                                :
                                                                                <span className="text-red-600">Rejected</span>
                                                                            }
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {item?.jobPostStatus === "completed" &&
                                                                    <button type="button" className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit`}>Job Completed</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="max-[499px]:text-[12px] text-[14px] mt-5 text_3line w-full" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                        <div className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto" onClick={e => { navigate(`/apply/post/${item?._id || item?.id}`) }}>See More</div>
                                                    </div>
                                                </div>
                                            })}
                                            {shortlisted?.length === 0 &&
                                                <div className="text-center">No Data Found</div>
                                            }
                                        </div>
                                    </TabPanel>
                                    <TabPanel key={"Messaged"} className="rounded-xl bg-white/5">
                                        <div className="grid grid-cols-1 gap-5 sm:gap-8">
                                            {messaged?.map((item, index) => {
                                                return <div className="hover:bg-[#f6f6f6] p-5 rounded-[15px]" key={index}>
                                                    <div className="flex flex-wrap justify-between gap-5">
                                                        <div>
                                                            <div className="flex items-center gap-3 capitalize">
                                                                <img src={methodModel.userImg(item?.addedBy?.image)} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full" alt="avaitar" />
                                                                <div className="grid grid-cols-1 gap-1 capitalize">
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.fullName || `${item?.addedBy?.firstName || ""} ${item?.addedBy?.lastName || ""}`}</p>
                                                                    <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.post_details?.title || "--"}</h2>
                                                                    <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.location}</p>
                                                                </div>
                                                            </div>
                                                            {item?.jobPostStatus === "working" &&
                                                                <p className="mt-3">Job Post End Date & Time: <span className="text-[#134896]">{moment(item?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></p>
                                                            }
                                                            <p className="flex flex-wrap gap-x-3 mt-5 max-[499px]:!text-[12px] !text-[14px] !font-[500]">
                                                                <span>Salary: {item?.salary ? `$${item?.salary}` : "--"}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="flex items-center flex-wrap gap-2">
                                                                {item?.shortListed ?
                                                                    <FaHeart size={25} className="cursor-pointer text-[#134896]" onClick={e => handleShortListing(item)} />
                                                                    :
                                                                    <Tooltip title="Like to Shortlist">
                                                                        <FaRegHeart size={25} className="cursor-pointer text-[#a1a1a1]" onClick={e => handleShortListing(item)} />
                                                                    </Tooltip>
                                                                }
                                                                <button type="button" onClick={e => handleMessage(item)} className="max-[499px]:min-w-[100px] bg-white px-4 py-[5px] text-[#134896] border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit hover:opacity-[80%]">Message</button>
                                                                <button type="button" onClick={e => setHireModal((prev) => ({ ...prev, modal: true, jobEndDate: "", payment: "", data: item }))} disabled={item?.request_status !== "pending"} className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit ${item?.request_status === "pending" && "hover:opacity-[80%]"}`}>{item?.request_status === "pending" ? "Hire" : item?.request_status === "accepted" ? "Hired" : "Rejected"}</button>
                                                                {item?.jobPostStatus === "working" &&
                                                                    <>
                                                                        <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className={`max-[499px]:min-w-[100px] min-w-[120px] bg-[#134896] px-5 py-2 text-white border border-[#134896] max-[499px]:text-[12px] text-[14px] rounded-lg h-fit hover:opacity-[80%]`}>Mark As Complete</button>
                                                                    </>
                                                                }
                                                                {item?.paymentRequestStatus !== "pending" && item?.jobPostStatus === "working" ?
                                                                    <>
                                                                        <p className="flex gap-2 items-center">Payment Request:
                                                                            {item?.paymentRequestStatus === "sent" ?
                                                                                <>
                                                                                    <button type="button" onClick={e => handleHirePayment(e, item, "complete")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                                    <button type="button" onClick={e => rejectPaymentRequest(item)} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                                </>
                                                                                :
                                                                                <span className="text-red-600">Rejected</span>
                                                                            }
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {item?.jobPostStatus === "completed" &&
                                                                    <button type="button" className={`bg-[#134896] px-4 py-[5px] text-white border border-[#134896] max-[499px]:text-[12px] text-[12px] rounded-lg h-fit`}>Job Completed</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="max-[499px]:text-[12px] text-[14px] mt-5 text_3line w-full" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                        <div className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto" onClick={e => { navigate(`/apply/post/${item?._id || item?.id}`) }}>See More</div>
                                                    </div>
                                                </div>
                                            })}
                                            {messaged?.length === 0 &&
                                                <div className="text-center">No Data Found</div>
                                            }
                                        </div>
                                    </TabPanel>
                                    {total > filters?.count ?
                                        <div className="pagination_div w-full text-right">
                                            <Pagination
                                                activePage={filters?.page}
                                                itemsCountPerPage={filters?.count}
                                                totalItemsCount={total}
                                                pageRangeDisplayed={5}
                                                hideFirstLastPages
                                                prevPageText="Previous"
                                                nextPageText="Next"
                                                itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                                                activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                                                onChange={e => handlePagination(e)}
                                            />
                                        </div>
                                        : null}
                                </div>
                            </TabPanels>
                        </TabGroup>
                    </div>
                </div>
            </div>
            <HirePostModal hireModal={hireModal} setHireModal={setHireModal} handleHirePayment={handleHirePayment} />
        </>
    )
}

export default Listing