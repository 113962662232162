import { useNavigate } from "react-router-dom"
import Footer from "../Footer"
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import Logo from "../../assets/images/logo.png"

const JoinMarc = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                {/* <div className="container mx-auto py-12">
                    <div className="flex items-center justify-center gap-8">
                        <div className="max-w-xl">
                            <h1 className="text-3xl font-bold text-[#005AAB] mb-4 flex gap-4">Join <img src={Logo} /></h1>
                            <p className="text-lg text-gray-600 mb-6">
                                Never miss out on what’s happening in your city. Discover your new favorite spot, lock in your weekend plans, and let Marc do the rest. Ready to find something to do?
                            </p>
                            <button type="button" onClick={e=>{ navigate(`/providersignup`) }} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                                Sign Up
                            </button>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-48 h-48">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3.25 3.25m3.25-3.25l-3.25 3.25m3.25-3.25L12 9.75l-3.25 3.25M12 9.75l3.25 3.25m-3.25-3.25l3.25 3.25M9 12.75l3.25-3.2 m3.25 3.25l-3.25-3.25m3.25 3.25L12 15.75l-3.25-3.25M12 15.75l3.25 3.25m-3.25-3.25l3.25 3.25" />
                            </svg>
                        </div>
                    </div>
                </div> */}
                <section className="py-10 md:py-20 lg:py-[6rem] relative">
                    <div className="container mx-auto px-5">
                        <div className="max-[499px]:p-5 p-10 bg-[#edf7ff] rounded-[20px]">
                            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
                                <div className="w-full flex-col justify-start items-start gap-10 inline-flex">
                                    <div className="w-full flex-col justify-start items-start gap-4 flex">
                                        <h1 className="text-[42px] font-bold text-[#005AAB] mb-4 flex items-center gap-4">Join <img src={Logo} className="w-[120px]" /></h1>
                                        <p className="text-gray-500 text-[18px] font-normal leading-relaxed max-w-[580px]">Never miss out on what’s happening in your city. Discover your new favorite spot, lock in your weekend plans, and let Marc do the rest. Ready to find something to do?</p>
                                        <p className="text-gray-500 text-[18px] font-normal leading-relaxed max-w-[580px]">Starting in May 2025, platform fees will increase to 5% for Starter, 3% for Pro, and 2% for Elite subscriptions. Additionally, the free trial will no longer be available. These changes are designed to support ongoing improvements to the platform and the introduction of enhanced features for our subscribers.</p>
                                    </div>
                                    <button type="text" onClick={e => { navigate(`/providersignup`) }} className="w-fit px-3.5 py-2 bg-[#005AAB] hover:opacity-[90%] transition-all duration-700 ease-in-out rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
                                        <span className="px-6 text-white text-sm font-medium leading-6">Sign Up</span>
                                    </button>
                                </div>
                                <img className="lg:mx-0 mx-auto max-[499px]:h-[250px] h-[450px] w-full rounded-3xl object-cover" src="/assets/img/signup-img.jpeg" alt="about Us image" />
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}

export default JoinMarc