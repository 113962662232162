import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import { socket } from '../../../../socket';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const Item = ({ closeSidebar }) => {
    const navigate = useNavigate();
    const { id } = useParams()
    const user = useSelector((state) => state.user)
    const { pathname } = useLocation();
    const [unReadCount, setUnReadCount] = useState(0)

    useEffect(() => {
        socket.emit("unread-count", { senderId: user?._id || user?.id })
    }, [user?.read_all]) // Updating user?.read_all in chat module so unread message count update in sidebar also

    const modules = [
        { url: "/home", title: "Dashboard", role: [] },
        { url: "/events", title: "My Events", role: ["venue", "host"] },
        { url: "/post", title: "My Posts", role: ["venue", "host", "influencer", "crew", `${user?.role === "crew_member" && user?.permission?.readPost ? "crew_member" : ""}`, "vendor", "food_truck_vendor"] },
        { url: "/crew/member", title: "Crew Member", role: ["crew"] },
        { url: "/jobposts", title: "Job Posts", role: ["host", "food_truck_vendor"] },
        { url: "/collaborations", title: "Collaborations", role: ["venue", "host", "influencer", "crew", "vendor", "food_truck_vendor"] },
        { url: "/apply/posts", title: "Applied Posts", role: ["venue", "vendor"] },
        { url: "/post/list", title: "Posts", role: [] },
        { url: "/invites", title: user?.role === "influencer" || user?.role === "crew" ? "Invitation" : "Invites/Invitation", role: ["venue", "host", "influencer", "crew"] },
        { url: "/wishlist", title: `Wishlist ${user?.role === "host" ? "Event" : ""}`, role: [] },
        { url: "/follow", title: "Following", role: [] },
        { url: "/followers", title: "Followers", role: ["influencer"] },
        { url: id ? `/message/${id}` : '/message', title: (<span>Messages {unReadCount !== 0 ? (<span className='bg-red-500 text-[12px] rounded-full px-1'>{unReadCount}</span>) : ""}</span>), role: [] },
        { url: "/transaction", title: "Transactions", role: ["venue", "host", "influencer", "crew","vendor","food_truck_vendor"] },
        { url: "/manage/schedule", title: "Manage Schedule", role: ["host", "food_truck_vendor"] },
        { url: "/boosting", title: "Boosting", role: ["venue", "host", "crew", "food_truck_vendor"] },
    ]

    socket.on("unread-count-received", (response => {
        setUnReadCount(response?.unreadCount)
    }))

    const redirect = (link) => {
        navigate(link);
    };

    const activeLink = (path) => {
        return pathname.includes(path)
    }
    var sidebar = {
        dots: false,
        autoplay: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
    };

    return (
        <>
            <div className='sidebar_slide'>
                <Slider {...sidebar}>
                    {modules?.map((item, index) => {
                        if (item?.role?.length === 0 || item?.role?.some((itm) => itm === user?.role)) {
                            return <p key={index} onClick={() => redirect(item?.url)} className={classNames('text-white hover:!text-white font-[300]', 'group flex items-center gap-[12px] rounded-md px-4 text-sm cursor-pointer', activeLink(item?.url) ? 'font-[600] !text-white' : '')}>
                                <span className="truncate text-inherit leading-none sidebar_text">{item?.title}</span>
                            </p>
                        }
                    })}
                </Slider>
            </div>
        </>
    );
};
