const shared = {
  title: "My Posts",
  addTitle: "Post",
  url: "post",
  hostPostType: "advertise",
  addApi: "venue/add-venue-post",
  editApi: "venue/update-venue-post",
  detailApi: "venue/get-venue-post-detail",
  listApi: "venue/get-all-venue-post",
  statusApi: "venue/change-venue-post-status",
  deleteApi: "venue/delete-venue-post",
  postTypeOptions: [
    { id: "promote", name: "Promote Event/Offer" },
    { id: "talent", name: "Talent Announcement" },
    { id: "openOpportunities", name: "Open Opportunities" },
    { id: "collaborateConnect", name: "Collaborate & Connect" },
  ],
  hostPostTypeOptions: [
    { id: "promote", name: "Promote Event/Offer" },
    { id: "talent", name: "Talent Announcement" },
    { id: "openOpportunities", name: "Open Opportunities" },
  ],
  venuePostTypeOptions: [
    { id: "promote", name: "Promote Event/Offer" },
    { id: "openOpportunities", name: "Open Opportunities" },
    { id: "collaborateConnect", name: "Collaborate & Connect" },
  ],
  influencerPostTypeOptions: [
    { id: "promote", name: "Promote Event/Offer" },
    { id: "collaborateConnect", name: "Collaborate & Connect" },
  ],
  entertainmentCrewPostTypeOptions: [
    { id: "promote", name: "Promote Event/Offer" },
    { id: "collaborateConnect", name: "Collaborate & Connect" },
  ],
  vendorPostTypeOptions: [
    { id: "openOpportunities", name: "Open Opportunities" },
    { id: "collaborateConnect", name: "Collaborate & Connect" },
  ],
};

export default shared;
