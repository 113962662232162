import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { IoMdClose } from 'react-icons/io'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader'
import SelectDropdown from '../../common/SelectDropdown'
import { useDispatch } from 'react-redux'
import { login_success } from '../../redux/actions/user'
import { useEffect, useState } from 'react'
import MultiSelectDropdown from '../../common/MultiSelectDropdown'

const UseModal = ({ useModal, setUseModal, user, getBoostingDetails, getBoostingListing }) => {
    const dispatch = useDispatch()
    const [eventsOptions, setEventsOptions] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [amenitiesOptions, setAmenitiesOptions] = useState([])

    useEffect(() => {
        getEvents()
        getLocations()
        if(user?.role === "venue"){
            setAmenitiesOptions(user?.subAmenities?.map((item)=>{
                return ({ id: item?._id || item?.id, name: item?.title })
            }))
        }
    }, [])

    const getEvents = () => {
        const payload = {
            [user?.role]: user?._id || user?.id
        }
        ApiClient.get('event/get-all-events', payload).then(res => {
            if (res.success) {
                setEventsOptions(res?.data?.map((item) => {
                    return ({ id: item?._id || item?.id, name: item?.title })
                }))
            }
        })
    }
    const getLocations = () => {
        ApiClient.get(`host/availability/listing?hostId=${user?._id || user?.id}`).then(res => {
            if (res.success) {
                setLocationOptions(res?.data?.location_availability?.map((item) => {
                    return ({ id: item?.location, name: item?.location })
                }))
            }
        })
    }

    const handleUseBoosting = (e) => {
        e.preventDefault()
        let payload = {
            usedBy: user?._id || user?.id,
            usedCategory: useModal?.usedCategory,
            boostType: useModal?.boostType,
        }
        if (useModal?.usedCategory === "event") {
            payload = { ...payload, eventId: useModal?.eventId }
        }
        if (useModal?.usedCategory === "location") {
            payload = { ...payload, location: useModal?.location }
        }
        if (useModal?.usedCategory === "amenities") {
            payload = { ...payload, amenities: useModal?.amenities }
        }
        loader(true)
        ApiClient.post(`boost/use`, payload).then(res => {
            if (res.success) {
                getBoostingDetails()
                getBoostingListing()
                dispatch(login_success({ ...user, isBoostUsed: true, boostType: useModal?.boostType }))
                setUseModal({ modal: false, boostType: "", usedCategory: "" })
            } else {
                loader(false)
            }
        })
    }

    const getCategoryOptions = () => {
        let options = []
        if (user?.role === "venue") {
            options.push(
                { id: "profile", name: "Profile" },
                { id: "event", name: "Event" },
                { id: "amenities", name: "Amenities" },
            )
        } else if (user?.role === "host") {
            options.push(
                { id: "profile", name: "Profile" },
                { id: "event", name: "Event" },
                { id: "location", name: "Location" },
            )
        } else if (user?.role === "crew") {
            options.push({ id: "profile", name: "Profile" })
        } else if (user?.role === "food_truck_vendor") {
            options.push(
                { id: "profile", name: "Profile" },
                { id: "location", name: "Location" },
            )
        } else options = []
        return options
    }

    return (
        <Dialog open={useModal?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setUseModal((prev) => ({ ...prev, modal: true }))}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                    <DialogPanel transition className="bg-cover bg-[url('assets/images/bg-ticket.png')] relative w-full max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                        <form onSubmit={handleUseBoosting}>
                            <div className='bg-[#ffffffa8] rounded-xl p-6'>
                                <div className="flex justify-center border-[#e9e9e9] pb-3 mb-3">
                                    <DialogTitle as="h3" className="text-[22px] text-[#005AAB] capitalize font-[600]">Use {useModal?.boostType} Boosting</DialogTitle>
                                    <IoMdClose onClick={() => setUseModal((prev) => ({ ...prev, modal: false }))} className="text-[24px] absolute top-5 right-5 text-[#7a7a7a] cursor-pointer hover:text-black" />
                                </div>
                                <div>
                                    <div className="my-2">
                                        <label>Category</label>
                                        <SelectDropdown
                                            id="statusDropdown"
                                            displayValue="name"
                                            className="mt-1"
                                            placeholder="Select Category"
                                            theme="search"
                                            intialValue={useModal?.usedCategory}
                                            result={(e) => setUseModal((prev) => ({ ...prev, usedCategory: e?.value }))}
                                            options={getCategoryOptions()}
                                            isClearable={false}
                                            required
                                        />
                                    </div>
                                    {useModal?.usedCategory === "event" ?
                                        <div className="my-2">
                                            <label>Event</label>
                                            <SelectDropdown
                                                id="statusDropdown"
                                                displayValue="name"
                                                className="mt-1"
                                                placeholder="Select Event"
                                                theme="search"
                                                intialValue={useModal?.eventId}
                                                result={(e) => setUseModal((prev) => ({ ...prev, eventId: e?.value }))}
                                                options={eventsOptions}
                                                isClearable={false}
                                                required
                                            />
                                        </div>
                                        : null}
                                    {useModal?.usedCategory === "amenities" ?
                                        <div className="my-2">
                                            <label>Amenities</label>
                                            <MultiSelectDropdown
                                                id="statusDropdown"
                                                placeholder="Select Amenities"
                                                intialValue={useModal?.amenities}
                                                className="mt-1 capitalize"
                                                maxSelect={5}
                                                result={(e) => setUseModal((prev) => ({ ...prev, amenities: e?.value }))}
                                                options={amenitiesOptions}
                                                required
                                            />
                                        </div>
                                        : null}
                                    {useModal?.usedCategory === "location" ?
                                        <div className="my-2">
                                            <label>Location</label>
                                            <SelectDropdown
                                                id="statusDropdown"
                                                displayValue="name"
                                                className="mt-1"
                                                placeholder="Select Location"
                                                theme="search"
                                                intialValue={useModal?.location}
                                                result={(e) => setUseModal((prev) => ({ ...prev, location: e?.value }))}
                                                options={locationOptions}
                                                isClearable={false}
                                                required
                                            />
                                        </div>
                                        : null}
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button type='submit' className="bg-[#005AAB] w-[150px] p-2 text-white rounded-full shadow-md block">Use</button>
                                </div>
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </Dialog >
    )
}

export default UseModal