import { useEffect, useState } from "react";
import { NavigationBar } from "../Landing/NavigationBar";
import Footer from "../Footer";
import ApiClient from "../../methods/api/apiClient";
import { MdEmail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import FormControl from "../../common/FormControl";
import environment from "../../environment";
import { FaXTwitter } from "react-icons/fa6";

const ContactUs = () => {
  const [formData, setFormData] = useState({ fullName: "", email: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [contactUsDetail, setContactUsDetail] = useState({})

  useEffect(() => {
    getContactUsDetail()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const transformedData = {
      from: formData.email,
      to: "manjeet1@yopmail.com",
      userName: formData.fullName,
      message: formData.message,
    };
    const url = `contact/submit`;
    try {
      await ApiClient.post(url, transformedData);
      setFormData({ fullName: "", email: "", message: "" });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getContactUsDetail = () => {
    ApiClient.get(`platform/fee/ticket/detail?slug=${environment.settingSlug}`).then(res => {
      if (res.success) {
        setContactUsDetail(res?.data)
      }
    })
  }

  return (
    <>
      <div className="bg-white w-full h-screen">
        <NavigationBar />
        <div className="container mx-auto px-5 py-10">
          <section className="text-center mb-12">
            <h2 className="text-[32px] sm:text-[40px] font-[800] text-[#005AAB]">
              Contact Us
            </h2>
            <p className="text-[16px] text-[#3a3a3a] font-[400]">
              We’d love to hear from you! Whether you have questions, feedback,
              or just want to say hello, our team is here to help.
            </p>
          </section>
          <section className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-[url('assets/images/contact-us.jpg')] bg-no-repeat bg-cover bg-right rounded-[15px] shadow-md">
              <div className="p-5 h-full rounded-[15px] bg-[#134896d6]">
                <h3 className="text-[24px] font-[700] text-white mb-5">
                  Get in Touch
                </h3>
                <div className="flex gap-x-3 mb-5">
                  <MdEmail className="relative top-[3px] bg-white text-[#005AAB] p-[10px] min-w-[40px] w-[40px] h-[40px] rounded-full flex justify-center items-center" />
                  <div>
                    <p className="text-[16px] text-white font-[600]">Email</p>
                    <p className="text-[14px] text-white font-[300]">
                      {contactUsDetail?.email}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-3 mb-5">
                  <MdLocalPhone className="relative top-[3px] bg-white text-[#005AAB] p-[10px] min-w-[40px] w-[40px] h-[40px] rounded-full flex justify-center items-center" />
                  <div>
                    <p className="text-[16px] text-white font-[600]">Phone</p>
                    <p className="text-[14px] text-white font-[300]">
                      {contactUsDetail?.mobileNumber}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-3 mb-5">
                  <FaHome className="relative top-[3px] bg-white text-[#005AAB] p-[10px] min-w-[40px] w-[40px] h-[40px] rounded-full flex justify-center items-center" />
                  <div>
                    <p className="text-[16px] text-white font-[600]">
                      Address:
                    </p>
                    <p className="text-[14px] text-white font-[300]">
                      {contactUsDetail?.address}
                    </p>
                  </div>
                </div>
                <p className="text-[14px] text-white font-[300] mb-5">
                  We’re available 24/7 via email for any inquiries. Send us a
                  message anytime, and our team will respond promptly!
                </p>
                <div className="mb-5">
                  <h3 className="text-[18px] font-[700] text-white mb-2">
                    Follow Us
                  </h3>
                  <div className="flex gap-6">
                    <FaFacebook onClick={e => { window.open(contactUsDetail?.facebook_link) }} className="text-[22px] text-white hover:text-[#E1B524] cursor-pointer" />
                    <FaXTwitter onClick={e => { window.open(contactUsDetail?.twitter_link) }} className="text-[22px] text-white hover:text-[#E1B524] cursor-pointer" />
                    <FaInstagram onClick={e => { window.open(contactUsDetail?.instagram_link) }} className="text-[22px] text-white hover:text-[#E1B524] cursor-pointer" />
                    <FaLinkedin onClick={e => { window.open(contactUsDetail?.linkedin_link) }} className="text-[22px] text-white hover:text-[#E1B524] cursor-pointer" />
                  </div>
                </div>
                <div className="mb-5">
                  <h3 className="text-[18px] font-[700] text-white mb-2">
                    Connecting You Coast to Coast
                  </h3>
                  <p className="text-[14px] text-white font-[300] mb-5">
                    Leaflet Map with pin on Fort Lauderdale or if it cannot be
                    interactive please use a map image with a pin on Fort
                    Lauderdale for office location
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white p-5 rounded-[15px] shadow-md">
              <h3 className="text-[24px] font-[700] text-[#005AAB] mb-4">
                Send Us a Message
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <FormControl
                    type="text"
                    name="fullName"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    value={formData?.fullName}
                    onChange={(e) => setFormData({ ...formData, fullName: e })}
                    required
                  />
                </div>
                <div className="mb-4">
                  <FormControl
                    type="email"
                    name="email"
                    label="Email Address"
                    placeholder="Enter Email Address"
                    value={formData?.email}
                    onChange={(e) => setFormData({ ...formData, email: e })}
                    required
                  />
                </div>
                <div className="mb-4">
                  <FormControl
                    type="textarea"
                    name="message"
                    label="Your Message"
                    placeholder="Write your message here..."
                    value={formData?.message}
                    onChange={(e) => setFormData({ ...formData, message: e })}
                    required
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`bg-[#005AAB] text-white font-[500] px-5 py-3 rounded-lg hover:opacity-90 w-full ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
