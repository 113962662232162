
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { FaUserAlt } from 'react-icons/fa';
import { FaCircleUser } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { BiSolidUserCircle } from "react-icons/bi";
import { HiMiniKey } from "react-icons/hi2";

const GuestUserModal = ({ isOpen, setIsOpen, handleGuestUser }) => {
    const navigate = useNavigate()

    return (
        <>
            <Dialog open={isOpen?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen({ ...isOpen, modal: false })}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB] leading-[24px]">How Would You Like to Continue?​</DialogTitle>
                            </div>
                            <div className="my-4 mt-[2rem] flex items-center justify-center gap-5">
                                <Button type="button" onClick={() => handleGuestUser()} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500] w-full">
                                    <BiSolidUserCircle className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                    Continue as a guest​
                                </Button>
                                <div className='text-[#005AAB]'>OR</div>
                                <Button type="button" onClick={() => { navigate(`/auth`) }} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500] w-full">
                                    <HiMiniKey className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                    Sign In​
                                </Button>
                            </div>
                            <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1 mt-2">
                                Already have an account?{' '}
                                <span onClick={() => { navigate('/signin') }} className="text-[#005AAB] font-[700 cursor-pointer">Sign In</span>
                            </p>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default GuestUserModal