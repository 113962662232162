import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Logo from "../../assets/images/logo.png"
import lock from "../../assets/images/Icons/lock.svg"
import methodModel from '../../methods/methods';
import FormControl from '../../common/FormControl';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import ApiClient from '../../methods/api/apiClient';
import { useDispatch } from 'react-redux';
import { login_success } from '../../redux/actions/user';
import loader from '../../methods/loader';
import { Button, Dialog, DialogPanel } from '@headlessui/react'
import { socket } from '../../socket';

export const Signin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [form, setForm] = useState({ email: "", password: "" })
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const inValidPassword = methodModel.passwordValidation(form?.password);
    const [submitted, setSubmitted] = useState(false)
    const [eye, setEye] = useState(false)
    const [popUp, setPopUp] = useState({ modal: false, resend: "", message: "", image: "", buttonText: "", email: "" })
    const inValidPopUpEmail = methodModel.emailvalidation(popUp?.email);

    useEffect(() => {
        socket.connect()
        const role = methodModel.getPrams("role")
        const email = methodModel.getPrams("email")
        if (role) {
            setForm((prev) => ({ ...prev, role: role }))
        }
        if (email) {
            ApiClient.post(`user/auto/login`, { email: email }).then(res => {
                if (res.success) {
                    handleSocket()
                    dispatch(login_success(res?.data))
                    sessionStorage.setItem('token', res?.data?.access_token)
                    handleRoleRedirection(res?.data?.role)
                }
            })
        }
        if (sessionStorage.getItem("token")) {
            navigate("/");
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (!inValidEmail || !inValidPassword) return
        const payload = {
            email: form?.email,
            password: form?.password,
        }
        const apiUrl = "users/login"
        loader(true)
        ApiClient.post(apiUrl, payload, {}, "", true).then((res) => {
            if (res.success) {
                handleSocket(res?.data)
                dispatch(login_success(res?.data))
                sessionStorage.setItem('token', res?.data?.access_token)
                handleRoleRedirection(res?.data?.role)
            } else {
                if (res?.error?.message === "Please verify your email first.") {
                    setPopUp({ modal: true, resend: "no", message: "Your account isn’t verified yet. Didn’t get the email? Click here to resend.", image: "/assets/img/warning.png", buttonText: "", email: "" })
                } else {
                    setPopUp({ modal: true, resend: "", message: res?.error?.message, image: "/assets/img/warning.png", buttonText: "Ok", email: "" })
                }
            }
            loader(false)
        })
    }

    const handleRoleRedirection = (role) => {
        if (role === "venue") {
            navigate("/venue/profile")
        } else if (role === "host") {
            navigate("/host/profile")
        } else if (role === "member") {
            navigate("/Event")
        } else if (role === "influencer") {
            navigate("/influencer/profile")
        } else if (role === "crew") {
            navigate("/entertainmentCrew/profile")
        } else if (role === "crew_member") {
            navigate(`/crewMember/profile`)
        } else if (role === "vendor") {
            navigate("/vendor/profile")
        } else if (role === "food_truck_vendor") {
            navigate("/foodTruckVendor/profile")
        } else {
            navigate("/home")
        }
    }

    const handleRedirection = (url) => {
        if (url === "/auth" && form?.role === "venue") {
            return navigate("/providersignup")
        }
        if (form?.role) {
            navigate(`${url}?role=${form?.role}`)
        } else {
            navigate(url)
        }
    }

    const handleResendEmail = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!inValidPopUpEmail) return
        const payload = {
            oldEmail: form?.email,
            newEmail: popUp?.email,
            // role: form?.role,
        }
        loader(true)
        ApiClient.post(`users/resend/email`, payload).then(res => {
            if (res.success) {
                setPopUp({ modal: true, resend: "", message: "The email has been successfully resent. Please check your inbox to verify your account.", image: "/assets/img/email.png", buttonText: "Ok", email: "" })
            }
            loader(false)
        })
    }

    const handleSocket = (data) => {
        socket.emit('connection');
        socket.emit('user-online', { UserId: data?._id || data?.id });
    }

    return (
        <>
            <div className={`w-full bg-cover bg-[url('assets/images/sign_in.jpeg')] `}>
            <div className="bg-[#00000082] h-[100vh] flex justify-between md:flex-row flex-col">
                <div className="md:max-w-[624px] w-full pt-[15px] md:pt-[30px] px-5 md:px-10">
                    <Link to="/">
                        <img src={Logo} alt="" className='w-[120px] lg:w-[220px]' />
                    </Link>
                    <div className="flex flex-col gap-[20px] mt-[40px] md:mt-[100px]">
                        <h2 className="text-[28px] sm:text-[42px] font-[700] leading-[38px] sm:leading-[50px] text-white drop-shadow-[0px_2px_3px_#000]">Welcome Back to Marc</h2>
                        <p className="text-[18px] font-[400] leading-[27.54px] text-white drop-shadow-[0px_2px_3px_#000]">Access your account to explore events, connect with your audience,
                            and manage your experiences all in one place. Let’s make something amazing happen today!</p>
                    </div>
                </div>
                <div className="bg-white h-full w-full px-[50px] lg:px-[107px] pb-[30px] lg:pb-[118px] lg:max-w-[652px] rounded-tl-[113.9px] lg:rounded-tl-[204.9px] flex justify-center flex-col items-start mt-5 lg:mt-0">
                    <form onSubmit={handleSubmit} autoComplete="one-time-code" className="w-full pt-[60px] md:pt-[134.71px]">
                        <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full capitalize">Sign In {form?.role && `As ${form?.role}`}</h2>
                        <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                            <div className="">
                                <FormControl
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    autoComplete="one-time-code"
                                    value={form?.email}
                                    onChange={(e) => setForm({ ...form, email: e })}
                                    required
                                />
                                {form?.email && !inValidEmail && submitted && (
                                    <div className="d-block text-red-600">Please enter valid email</div>
                                )}
                            </div>
                            <div className="relative mt-2">
                                <FormControl
                                    type={eye ? "text" : "password"}
                                    name="password"
                                    placeholder="Enter Password"
                                    autoComplete="one-time-code"
                                    minlength={8}
                                    maxlength={16}
                                    value={form?.password}
                                    onChange={(e) => setForm({ ...form, password: e })}
                                    required
                                />
                                {!eye ?
                                    <IoEyeOffOutline size={20} onClick={e => setEye(true)} className="absolute top-3 right-3 cursor-pointer" />
                                    :
                                    <IoEyeOutline size={20} onClick={e => setEye(false)} className="absolute top-3 right-3 cursor-pointer" />
                                }
                                {form?.password && !inValidPassword && submitted && (
                                    <div className="text-red-600">{methodModel.passwordValidationMessages(form?.password)}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end mt-6 cursor-pointer" onClick={e => handleRedirection('/auth/forgot-password')}>
                            <img className="me-1" src={lock} alt="lock-icon" />
                            <p className="text-[11.86px] font-[400] text-[#6B6B6B]">Forgot Password</p>
                        </div>
                        <div className="flex flex-col pt-[20px] w-full gap-4 md:gap-[35px]">
                            <button type='submit' className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer hover:opacity-[90%]">
                                Sign In
                            </button>
                            {/* <div className="flex justify-center items-center gap-5">
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                                <span className="text-[14.4px] font-[500] text-[#005AAB]">Or Sign Up With </span>
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            </div>
                            <div>
                                <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                    <img className='cursor-pointer' src={google} onClick={() => thirdPartySignInClicked('google')} alt="google_icon" />
                                    <img className='cursor-pointer' src={facebook} onClick={() => thirdPartySignInClicked('google')} alt="facebook_icon" />
                                    <img className='cursor-pointer' src={linkedin} onClick={() => thirdPartySignInClicked('google')} alt="linkedin_icon" />
                                    <img className='cursor-pointer' src={instagram} onClick={() => thirdPartySignInClicked('google')} alt="instagram_icon" />
                                </div>
                            </div> */}
                            <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                                Don't have an account yet?{' '}
                                <span onClick={() => handleRedirection("/providersignup")} className="cursor-pointer font-medium text-[#3163b2] hover:underline dark:text-[#3163b2]">
                                    Create an account
                                </span>
                            </p>
                        </div>
                    </form>
                </div>
                {/* PopUp For Messages & Re-send Verification Email */}
                <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                            <DialogPanel transition className={`bg-[url('assets/images/colorful.png')] bg-cover bg-no-repeat w-full ${popUp?.resend === "no" ? "max-w-2xl" : "max-w-lg"} rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
                                <div className="bg-[#fff9] rounded-xl p-10 relative">
                                    <form className="bg-[#fffc]" onSubmit={handleResendEmail}>
                                        {popUp?.resend === "yes" ?
                                            <>
                                                <div className="">
                                                    <img src="/assets/img/resend_img.png" className="w-[80px] object-contain mx-auto my-5"></img>
                                                    <h2 className="text-[28px] text-[#005AAB] font-[500] mb-5 text-center leading-[34px]">Re-send Verification Email</h2>
                                                    <FormControl
                                                        type="text"
                                                        name="email"
                                                        label={"Email"}
                                                        placeholder={"Enter Email"}
                                                        autoComplete="one-time-code"
                                                        value={popUp?.email}
                                                        onChange={(e) => setPopUp((prev) => ({ ...prev, email: e }))}
                                                        required
                                                    />
                                                    {popUp?.email && !inValidPopUpEmail && submitted && (
                                                        <div className="d-block text-red-600">Please enter valid email</div>
                                                    )}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <img src={popUp?.image} className={`${popUp?.resend === "no" ? "w-[120px] h-[120px]" : "w-[100px] h-[100px]"} object-contain mx-auto my-5`} />
                                                <div className='text-center'>
                                                    <p className="text-center text-[#404040] max-w-[450px] mx-auto text-[15px]">
                                                        {popUp?.message || ""}
                                                    </p>
                                                </div>
                                            </>
                                        }
                                        <div className="mt-[2rem] flex flex-wrap sm:flex-nowrap justify-between gap-3">
                                            {popUp?.resend === "yes" ?
                                                <Button type="submit" className="px-6 py-3 bg-[#005AAB] w-[200px] mx-auto text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer">Resend</Button>
                                                :
                                                popUp?.resend === "no" ?
                                                    <span onClick={e => setPopUp((prev) => ({ ...prev, resend: "yes", email: form?.email }))} className="px-6 py-3 bg-[#005AAB] w-[250px] mx-auto text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer">Re-send Verification Email</span>
                                                    : null
                                            }
                                            {popUp?.resend !== "yes" && popUp?.buttonText ? <Button type="button" onClick={e => setPopUp((prev) => ({ ...prev, modal: false }))} className={`px-6 py-3 bg-[#005AAB] ${popUp?.resend === "no" ? "w-full" : "w-[200px] mx-auto"} text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer`}>{popUp?.buttonText}</Button> : null}
                                        </div>
                                    </form>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            </div>
            </div>
        </>
    );
};
