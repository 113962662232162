import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { IoIosArrowBack } from "react-icons/io";
import methodModel from "../../methods/methods";
import { SiGoogleforms } from "react-icons/si";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormControl from "../../common/FormControl";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";
import HirePostModal from "./HirePostModal";
import moment from "moment";
import CustomMap from "../EventDetail/CustomMap";

const Detail = () => {
    const [data, setData] = useState();
    const history = useNavigate();
    const { id } = useParams();
    const user = useSelector((state) => state.user)
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ type: "", rejectReason: "" })
    const [hireModal, setHireModal] = useState({ modal: false, jobEndDate: "", payment: "" })
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    useEffect(() => {
        if (id) {
            getDetail();
        }
    }, []);

    const getDetail = () => {
        loader(true);
        ApiClient.get(`host/post/detail`, { postId: id }).then((res) => {
            if (res.success) {
                setData(res?.data)
                if(res?.data?.addedBy?.role === "host" || res?.data?.addedBy?.role === "food_truck_vendor"){
                    getLocationAvailability(res?.data?.addedBy?._id || res?.data?.addedBy?.id)
                }
            }
            loader(false);
        });
    }

    const getLocationAvailability = (userId) => {
        ApiClient.get(`host/availability/listing?hostId=${userId}`, {}, "", true).then(res => {
            if (res.success) {
                let locations = []
                let mapview = {}
                res?.data?.location_availability?.map((item, index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel })
                    if (index === 0) {
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel }
                    }
                })
                setLocations(locations)
                setMapView(mapview)
            }
        })
    }

    const requestStatus = (requestType) => {
        setForm((prev) => ({ ...prev, type: requestType }))
        setIsOpen(true)
    }

    const handleRequest = (e) => {
        e.preventDefault()
        const payload = {
            applicationId: id,
            type: "reject",
            reason: form?.rejectReason
        }
        ApiClient.post(`host/application`, payload).then(res => {
            if (res.success) {
                getDetail()
                setIsOpen(false)
            }
        })
    }

    const handleHirePayment = (e, data, type) => {
        e.preventDefault()
        const amount = type === "hire" ? hireModal?.payment === "50%" ? Number(data?.salary / 2) : Number(data?.salary) : Number(data?.pendingAmount / 100)
        if (type === "hire" || amount !== 0) {
            let paymentPayload = {
                postId: data?.post_id?._id || data?.post_id?.id,
                productData: {
                    productName: data?.post_id?.title
                },
                paidBy: user?._id || user?.id,
                paidTo: data?.addedBy?._id || data?.addedBy?.id,
                email: user?.email,
                totalPrice: Number(data?.salary),
                applicationId: id,
                type: type,
                halfPrice: amount,
                pendingAmount: hireModal?.payment === "50%" ? Number(data?.salary / 2) : 0,
            }
            if (type === "hire") {
                paymentPayload = { ...paymentPayload, jobEndDate: hireModal?.jobEndDate }
            }
            if (data?.paymentRequestStatus !== "pending" && data?.jobPostStatus === "working") { // For payment request mail
                paymentPayload = { ...paymentPayload, jobPaymentRequest: "sent" }
            }
            loader(true)
            ApiClient.post(`job/post/payment`, paymentPayload).then(res => {
                loader(false)
                if (res.success) {
                    window.open(res?.data, "_self")
                }
            })
        } else {
            ApiClient.post(`job/mark/complete`, { appliedPostId: id }).then(res => {
                if (res.success) {
                    getDetail()
                }
            })
        }
    }

    const handleDocumentRedirection = (data) => {
        if (data) {
            window.open(methodModel.document(data), "_blank")
        } else {
            return
        }
    }

    const rejectPaymentRequest = () => {
        const payload = {
            appliedPostId: data?.id || data?._id,
            userId: data?.post_id?.addedFor
        }
        loader(true)
        ApiClient.post("job/reject/payment/request", payload).then(res => {
            if (res.success) {
                getDetail()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5">
                    <div className="wrapper_section w-full py-5">
                        <div className="mb-5">
                            <div className="flex flex-wrap justify-between gap-3">
                                <div className="flex items-center">
                                    <Tooltip placement="top" title="Back">
                                        <span onClick={() => history(-1)} className="!px-1 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all mr-3 bg-[#05388fed] text-white hover:text-black">
                                            <IoIosArrowBack size={20} />
                                        </span>
                                    </Tooltip>
                                    <h3 className="text-[15px] font-semibold text-[#111827]">Applied Post Request Details</h3>
                                </div>
                                <div>
                                    <div className="flex flex-wrap items-center gap-2">
                                        <div className="flex items-center gap-2">
                                            {data?.request_status === "pending" ?
                                                <div className="flex items-center gap-2">
                                                    <button type="button" onClick={e => setHireModal((prev) => ({ ...prev, modal: true, jobEndDate: "", payment: "", data: data }))} className="text-[12px] !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Hire</button>
                                                    <button type="button" onClick={e => requestStatus("reject")} className="text-[12px] !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#000] text-white">Reject</button>
                                                </div>
                                                : null}
                                        </div>
                                        {data?.request_status !== "pending" &&
                                            <div className="flex gap-2 items-center">
                                                <span className="text-[14px]"> Request Status: </span>
                                                <div className={`capitalize text-[14px] ${data?.request_status === "rejected" ? "text-red-600" : data?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>
                                                    {data?.request_status === "accepted" ? "Hired" : "Rejected"}
                                                </div>
                                            </div>
                                        }
                                        {data?.jobPostStatus === "working" &&
                                            <div className="flex items-center gap-2">
                                                <button type="button" onClick={e => handleHirePayment(e, "complete")} className="text-[12px] !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Mark As Complete</button>
                                            </div>
                                        }
                                        {data?.jobPostStatus === "completed" &&
                                            <div className="flex items-center gap-2">
                                                <button type="button" className="text-[12px] !px-3 py-1 cursor-default flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Job Completed</button>
                                            </div>
                                        }
                                    </div>
                                    {data?.jobPostStatus === "working" &&
                                        <p>Job Post End Date & Time: <span className="text-[#134896]">{moment(data?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></p>
                                    }
                                    {data?.paymentRequestStatus !== "pending" && data?.jobPostStatus === "working" ?
                                        <>
                                            <p className="flex gap-2 items-center">Payment Request:
                                                {data?.paymentRequestStatus === "sent" ?
                                                    <>
                                                        <button type="button" onClick={e => handleHirePayment(e, "complete")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                        <button type="button" onClick={e => rejectPaymentRequest()} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                    </>
                                                    :
                                                    <span className="text-red-600">Rejected</span>
                                                }
                                            </p>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {data?.request_status === "rejected" &&
                                <p className="text-[14px] mt-2">Reject Reason: <span className="text-[#0000009c]">{data?.reject_reason || "--"}</span></p>
                            }
                        </div>
                        <div>
                            {data?.addedBy?.image &&
                                <div className="flex flex-col">
                                    <div className="flex flex-wrap gap-3">
                                        <img src={methodModel.noImg(data?.addedBy?.image)} className="h-[250px] sm:h-[300px] md:h-[450px] rounded-[15px] w-full object-cover" />
                                    </div>
                                </div>
                            }
                            {data?.addedBy?.images?.length > 0 ?
                                <div className="mt-5">
                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                        {data?.addedBy?.images?.map((item, index) => {
                                            return <Tooltip position="top" title="Click to open">
                                                <div className="shadow rounded-[15px] bg-white">
                                                    <img src={methodModel.noImg(item)} onClick={e => { window.open(methodModel.noImg(item), "_blank") }} className="max-[499px]:h-[120px] h-[160px] sm:h-[200px] object-cover w-full rounded-[15px] border cursor-pointer" key={index} />
                                                </div>
                                            </Tooltip>
                                        })}
                                    </div>
                                </div>
                                : null}
                            <div className="mt-6">
                                <h2 className="text-[24px] sm:text-[32px] leading-[30px] sm:leading-[38px] text-[#0F64B0] font-[500] capitalize">{data?.addedBy?.fullName || `${data?.addedBy?.firstName || ""} ${data?.addedBy?.lastName || ""}`}</h2>
                                <div className="flex flex-wrap gap-x-1 mt-2">
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]"><FaLocationDot className="text-[20px]" /></p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.addedBy?.location},</p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.addedBy?.country},</p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.addedBy?.state},</p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.addedBy?.city},</p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.addedBy?.zipCode}</p>
                                </div>
                                <div className="flex flex-wrap gap-x-5 mt-2">
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]"> <MdEmail className="text-[20px]" /> {data?.addedBy?.email}</p>
                                    {data?.addedBy?.mobileNo && <p className="font-[300] flex items-center gap-x-1 text-[#134896]"> <IoMdCall className="text-[20px]" />+{data?.addedBy?.mobileNo}</p>}
                                </div>
                                <div className="h-[1px] w-[105px] bg-[#e7e7e7] mt-3"></div>
                                {data?.addedBy?.instagram_link || data?.addedBy?.facebook_link || data?.addedBy?.twitter_link || data?.addedBy?.linkedin_link || data?.addedBy?.youtube_link ?
                                    <div className="mt-3">
                                        <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize">Social Links :</h2>
                                        <div className="flex flex-wrap gap-y-2 gap-x-4">
                                            {data?.addedBy?.instagram_link && <img src="/assets/img/Instagram.webp" onClick={e => { window.open(data?.addedBy?.instagram_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md" />}
                                            {data?.addedBy?.facebook_link && <img src="/assets/img/Facebook.png" onClick={e => { window.open(data?.addedBy?.facebook_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md" />}
                                            {data?.addedBy?.twitter_link && <img src="/assets/img/twitter.webp" onClick={e => { window.open(data?.addedBy?.twitter_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md" />}
                                            {data?.addedBy?.linkedin_link && <img src="/assets/img/linkedin.webp" onClick={e => { window.open(data?.addedBy?.linkedin_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md" />}
                                            {data?.addedBy?.youtube_link && <img src="/assets/img/youtube.png" onClick={e => { window.open(data?.addedBy?.youtube_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md" />}
                                        </div>
                                    </div>
                                    : null}
                                {data?.addedBy?.short_description && <p className="font-[300] text-[#0000009c] text-[14px] mt-3" dangerouslySetInnerHTML={{ __html: data?.addedBy?.short_description }}></p>}
                                {data?.addedBy?.description && <p className="font-[300] text-[#0000009c] text-[14px] mt-3" dangerouslySetInnerHTML={{ __html: data?.addedBy?.description || "--" }}></p>}
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-10">
                                <div className="flex flex-col">
                                    <label className="text-[14px] text-[#134896] tracking-wider leading-[17px] mb-1">Apply Post Description</label>
                                    <p className="font-[300] text-[14px] flex items-center gap-x-1 text-[#0000009c]">{data?.description}</p>
                                </div>
                                {data?.addedBy?.skills?.length !== 0 &&
                                    <div className="flex flex-col">
                                        <label className="text-[14px] text-[#134896] tracking-wider leading-[17px] mb-1">Skills</label>
                                        <p className="text-[13px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                                            {data?.addedBy?.skills?.map((item, index) => {
                                                return <span className="bg-[#f7f7f7] p-2 px-3 rounded-lg text-[#0000009c]" key={index}>{item}</span>
                                            })}
                                        </p>
                                    </div>
                                }
                                <div className="flex flex-col">
                                    <label className="text-[14px] text-[#134896] tracking-wider leading-[17px] mb-1">Salary</label>
                                    <p className="font-[300] text-[14px] flex items-center gap-x-1 text-[#0000009c]">{data?.salary ? `$${data?.salary}` : "--"}</p>
                                </div>
                            </div>
                            {data?.addedBy?.resume &&
                                <div className="mt-10">
                                    <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize mb-2">Documents</h2>
                                    <div className="grid grid-cols-12 gap-5">
                                        {data?.addedBy?.resume &&
                                            <Tooltip position="top" title="Click To Open">
                                                <div className={`max-[499px]:col-span-12 col-span-6 md:col-span-3 2xl:col-span-2 shadow-md rounded-lg flex justify-center border p-5 ${data?.addedBy?.resume && "cursor-pointer"} hover:border-[#004d92]`} onClick={() => handleDocumentRedirection(data?.addedBy?.resume)}>
                                                    <div className="text-center">
                                                        <p className="text-[13px]">
                                                            {data?.addedBy?.resume ?
                                                                <SiGoogleforms size={40} className="cursor-pointer mx-auto text-[#004d92] mb-2" />
                                                                :
                                                                <span>Resume Not Uploaded</span>
                                                            }
                                                        </p>
                                                        <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Resume</label>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {data?.addedBy?.videos?.length > 0 ?
                            <div className="mt-10">
                                <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize mb-2">Videos</h2>
                                <div className="grid max-[499px]:grid-cols-1 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                    {data?.addedBy?.videos?.map((item, index) => {
                                        return <video className="max-[499px]:h-[225px] max-[639px]:h-[160px] sm:h-[200px] w-full rounded-[15px] border" key={index} controls>
                                            <source src={methodModel.video(item)} type="video/mp4" />
                                            <source src={methodModel.video(item)} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    })}
                                </div>
                            </div>
                            : null}
                        {locations?.length > 0 ?
                            <div className="mt-5">
                                <CustomMap mapView={mapView} locations={locations} type="availabilty" />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-black">Add Reason</DialogTitle>
                            </div>
                            <form onSubmit={handleRequest}>
                                <div>
                                    <FormControl
                                        type="textarea"
                                        name="rejectReason"
                                        label="Reason"
                                        placeholder="Enter Reason"
                                        autoComplete="one-time-code"
                                        value={form?.rejectReason}
                                        onChange={(e) => setForm({ ...form, rejectReason: e })}
                                        required
                                    />
                                </div>
                                <div className="my-4 mt-[4rem] flex flex-wrap justify-center gap-5">
                                    <Button type="button" onClick={e => setIsOpen(false)} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                    <Button type="submit" className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Reject</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <HirePostModal hireModal={hireModal} setHireModal={setHireModal} handleHirePayment={handleHirePayment} />
        </>
    );
};

export default Detail;