import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import DatePicker from "react-datepicker";
import SelectDropdown from '../../common/SelectDropdown';
import { useSelector } from 'react-redux';

const HirePostModal = ({ hireModal, setHireModal, handleHirePayment }) => {
    const user = useSelector((state) => state.user)
    const paymentOptions = [
        { id: "50%", name: "50%" },
        { id: "100%", name: "100%" }
    ]
    const stripeFeePercentage = (2.9 / 100) // 2.9%
    const stripeFeeFixed = (0.30) // $0.30

    const calculatePrice = (type) => {
        let value = 0
        if (type === "total") {
            const salary = hireModal?.payment === "50%" ? Number(hireModal?.data?.salary / 2) : hireModal?.data?.salary
            const stripeCharges = (salary * stripeFeePercentage) + stripeFeeFixed
            if(user?.activePlanId?.platformFee){
                const platformCharges = salary * (user?.activePlanId?.platformFee / 100)
                value = salary + stripeCharges + platformCharges
            }else{
                value = salary + stripeCharges
            }
        } else {
            value = 0
        }
        return parseFloat(value).toFixed(2)
        // return Math.round(value)
    }

    return (
        <Dialog open={hireModal?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setHireModal((prev) => ({ ...prev, modal: true }))}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                    <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                        <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                            <DialogTitle as="h3" className="text-[20px] font-medium text-black">Hire</DialogTitle>
                        </div>
                        <form onSubmit={e => handleHirePayment(e, hireModal?.data, "hire")}>
                            <div className='mb-3'>
                                <label className="text-sm mb-2 block">Job Post End Date & Time <span className="text-red-600">*</span></label>
                                <div>
                                    <DatePicker
                                        selected={hireModal?.jobEndDate}
                                        onChange={(date) => setHireModal((prev) => ({ ...prev, jobEndDate: date }))}
                                        className="relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] "
                                        minDate={new Date()}
                                        dateFormat="MM/dd/yyyy hh:mm a"
                                        placeholderText="Job Post End Date & Time"
                                        showTimeSelect
                                        required
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label className="text-sm mb-2 block">Select Payment <span className="text-red-600">*</span></label>
                                <div>
                                    <SelectDropdown
                                        id="statusDropdown"
                                        displayValue="name"
                                        className="mt-1"
                                        placeholder="Select Payment Type"
                                        theme="search"
                                        intialValue={hireModal?.payment}
                                        result={(e) => setHireModal((prev) => ({ ...prev, payment: e?.value }))}
                                        options={paymentOptions}
                                        isClearable={false}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='max-w-[200px] mx-auto mt-5'>
                                <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Salary :<span className='text-[#000]'>${hireModal?.data?.salary}</span></p>
                                {hireModal?.payment && <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Payment :<span className='text-[#000]'>{hireModal?.payment}</span></p>}
                                <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Stripe Fee :<span className='text-[#000]'>(2.9% + $0.30)</span></p>
                                <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Platform Fee :<span className='text-[#000]'>{user?.activePlanId?.platformFee || "0"}%</span></p>
                                <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] pt-3 border-t-[1px]'>Total :<span className='text-[#000]'>${calculatePrice("total")}</span></p>
                            </div>
                            <div className="mt-[2rem] flex flex-wrap justify-center gap-5">
                                <Button type="button" onClick={e => setHireModal((prev) => ({ ...prev, modal: false }))} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                <Button type="submit" className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Hire</Button>
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default HirePostModal