import { useNavigate } from "react-router-dom"
import methodModel from "../../methods/methods"
import Location from '../../assets/images/Icons/location.svg';

const VenuesListing = ({ users }) => {
    const token = sessionStorage.getItem("token")
    const navigate = useNavigate()

    const handleClaimVenue = (id) => {
        navigate(`/providersignup/${id}`)
    }

    return (
        <>
            <div className="upcoming pb-[40px]">
                <div className="">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                            All Venues
                        </h3>
                    </div>
                </div>
                <div className="grid gap-5 mt-5 md:mt-12 max-[480px]:grid-cols-1 grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                    {users.map((item, index) => (
                        <div key={index}>
                            <div className="claimbtn overflow-hidden bg-white rounded-lg shadow-md h-full">
                                <div className="relative max-[480px]:h-[190px] h-[150px] lg:h-[190px]">
                                    <img onClick={e => { navigate(`/venue/detail/${item?._id || item?.id}`) }} className="cursor-pointer object-cover w-full h-full" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                    {!token && item?.addedType !== "self" ?
                                        <div className="claimbtn_data">
                                            <div className="absolute top-0 left-0 w-full h-full bg-[#00000082] flex justify-center items-center cursor-pointer" onClick={() => handleClaimVenue(item?._id || item?.id)}>
                                                <button className="group flex w-fit h-fit text-[14px] text-white items-center gap-2 rounded-full py-1.5 px-8 bg-[#005AAB]">
                                                    Claim Venue
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div onClick={e => { navigate(`/venue/detail/${item?._id || item?.id}`) }} className="cursor-pointer p-4 hh">
                                    <h2 className="ellipises_heading font-[500] text-[16px] capitalize">
                                        {item?.venue_name}
                                    </h2>
                                    <p className="font-[400] text-[13px] my-3 flex flex-wrap gap-1">
                                        {item?.amenities?.slice(0, 2)?.map((itm, i) => {
                                            return <span className="px-3 py-[4px] bg-[#d9d9d9] rounded-full text-black text-[11px]" key={i}>{itm?.title}</span>
                                        })}
                                    </p>
                                    <p className="flex gap-2 font-[400] text-[13px]">
                                        <img src={Location} alt="location_icon" className="responive mt-[-2px]" />
                                        <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.locations?.[0]?.location || "--"}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default VenuesListing