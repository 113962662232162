import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import LocationIcon from "../../../assets/images/locationicon.png"
import RedLocationIcon from "../../../assets/images/redlocationicon.png"
import { IoLocationSharp } from "react-icons/io5";
import moment from 'moment';
import { TbCalendarClock } from "react-icons/tb";
import { MdTravelExplore } from "react-icons/md";

// Default icon setup for Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const containerStyle = {
  width: '100%',
  height: '600px',
};

function CustomMap({ locations, mapView, type = "" }) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  if (!mapView || !mapView.lat || !mapView.lng) {
    return <div>Location not found</div>
  }

  return (
    <div className='profile_map'>
    <MapContainer
      center={[mapView.lat, mapView.lng]} // Use an array for the center coordinates
      zoom={15}
      style={containerStyle}
      scrollWheelZoom={true} // Optional, disable scroll zoom
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
      />
      {locations && locations.map((location, index) => (
        <Marker
          key={index}
          position={[location.lat, location.lng]}
          icon={L.icon({
            iconUrl: location?.isBoostUsed ? RedLocationIcon : LocationIcon,
            iconSize: [60, 60], // Adjust the size of the icon if necessary
          })}
          eventHandlers={{
            click: () => {
              setSelectedLocation(location);
            },
          }}
        />
      ))}
      {selectedLocation && type === "availabilty" ? (
        <Popup
          position={[selectedLocation.lat, selectedLocation.lng]}
          onClose={() => setSelectedLocation(null)}
        >
          <div className=''>
            <div className='mt-2'>
              <div className='flex gap-x-2 mb-3'>
              <IoLocationSharp className='text-[#005AAB] bg-[#e6f3ff] w-[30px] min-w-[30px] h-[30px] p-[6px] rounded-full flex justify-center items-center' />
              <h2 className='text-[13px] font-bold'>Location:
                <span className='capitalize text-[12px] flex gap-1 !m-0 font-normal'> {selectedLocation.info}</span>
              </h2>
              </div>
              <div className='flex gap-x-2 mb-3'>
              <TbCalendarClock className='text-[#005AAB] bg-[#e6f3ff] w-[30px] min-w-[30px] h-[30px] p-[6px] rounded-full flex justify-center items-center' />
              <h2 className='text-[13px] font-bold'>Start Date & Time:
                <span className='capitalize text-[12px] flex gap-1 !m-0 font-normal'>{moment(selectedLocation?.date).format("DD MMM, YYYY")} {moment(selectedLocation?.startTime).format("hh:mm A")}</span>
              </h2>
              </div>
              <div className='flex gap-x-2 mb-3'>
              <TbCalendarClock className='text-[#005AAB] bg-[#e6f3ff] w-[30px] min-w-[30px] h-[30px] p-[6px] rounded-full flex justify-center items-center' />
              <h2 className='text-[13px] font-bold'>End Date & Time:
                <span className='capitalize text-[12px] flex gap-1 !m-0 font-normal'>{moment(selectedLocation?.date).format("DD MMM, YYYY")} {moment(selectedLocation?.endTime).format("hh:mm A")}</span>
              </h2>
              </div>
              <div className='flex gap-x-2 mb-3'>
              <MdTravelExplore className='text-[#005AAB] bg-[#e6f3ff] w-[30px] min-w-[30px] h-[30px] p-[6px] rounded-full flex justify-center items-center' />
              <h2 className='text-[13px] font-bold'>Free To Travel:
                <span className='capitalize text-[12px] flex gap-1 !m-0 font-normal'>{selectedLocation.freeToTravel}</span>
              </h2>
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
      {selectedLocation && type !== "availabilty" ? (
        <Popup
          position={[selectedLocation.lat, selectedLocation.lng]}
          onClose={() => setSelectedLocation(null)}
        >
          <div className=''>
            <img src={selectedLocation.icon} className="w-full h-[90px] rounded-lg object-cover" alt="Location" />
            <div className='mt-2'>
              {/* <h2 className='text-[13px]'>Location Info:</h2> */}
              <p className='capitalize text-[12px] flex gap-1 !m-0 '><IoLocationSharp className='text-[#005AAB] text-[15px]' /> {selectedLocation.info}</p>
            </div>
          </div>
        </Popup>
      ) : null}
    </MapContainer>
    </div>
  );
}

export default React.memo(CustomMap);
