import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Signup } from './components/Auth/signup';
import { Forgot } from './components/Auth/forgot';
import { Reset } from './components/Auth/reset';
import { Toaster } from 'sonner';
import { LandingPage } from './components/Landing';
import Home from './components/Home';
import ProviderSignIn from './components/Auth/providerSignin';
import ProviderSignUp from './components/Auth/providerSignup';
import Events from './components/Events';
import EventDetails from './components/EventDetail';
import FilterPage from './components/Filters';
import HostProfile from './components/HostProfile';
import { Signin } from './components/Auth/signin';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import loader from '../src/assets/images/loader.gif';
import ChangePassword from './components/Auth/ChangePassword';
import AllEvents from './components/Events/AllEvents';
import AllUpcomingEvents from './components/Events/AllUpcomingEvents';
import Plans from './components/Plans';
import VenueDetail from './components/Venues/VenueDetail';
import EventsListing from './components/VenueEvents';
import AddEdit from './components/VenueEvents/AddEdit';
import EventsView from './components/VenueEvents/View';
import PostDetail from './components/Posts/Detail';
import Listing from './components/Collaboration/Listing';
import Detail from './components/Collaboration/Detail';
import ReApply from './components/Auth/ReApply';
import ApplyPostListing from './components/ApplyPosts/Listing';
import ApplyPostDetail from './components/ApplyPosts/Detail';
import SetupSchedule from './components/ManageSchedule/SetupSchedule';
import Posts from './components/Posts';
import AddEditPost from './components/Posts/AddEdit';
import MyOrdersListing from "./components/MyOrders/Listing"
import MyOrdersDetail from "./components/MyOrders/Detail"
import ExperienceMarc from './components/ExperienceMarc';
import JoinMarc from './components/JoinMarc';
import GetHelp from './components/GetHelp';
import AboutUs from './components/AboutUs';
import Chat from './components/Chat';
import JobPosts from './components/JobPosts';
import Transaction from './components/Transaction';
import PlanTransaction from './components/Transaction/PlanTransaction';
import MemberTransactions from './components/Transaction/MemberTransactions';
import Notifications from './components/Notifications';
import Wishlist from './components/Wishlist';
import Follow from './components/Follow';
import Invites from './components/Events/Invites';
import ProfilePage from './components/Influencer/ProfilePage';
import EntertainmentCrewProfilePage from './components/EntertainmentCrew/ProfilePage';
import AddEditCrewMember from './components/EntertainmentCrew/CrewMember/AddEdit';
import CrewMember from './components/EntertainmentCrew/CrewMember';
import CrewProfilePage from './components/EntertainmentCrew/CrewMember/ProfilePage';
import Followers from './components/Followers';
import QrScanner from './components/QrScanner';
import Profile from './components/Profile';
import VendorProfilePage from './components/Vendor/ProfilePage';
import FoodTruckVendorProfilePage from './components/FoodTruckVendor/ProfilePage';
import PostListing from './components/Posts/PostListing';
import TermsOfService from './components/TermsOfService/inde';
import PrivacyPolicy from './components/PrivacyPolicy​';
import ContactUs from './components/ContactUs';
import Boosting from './components/Boosting';
import UsersListing from './components/UsersListing';


function App() {
    return (
        <>
            <Toaster position="top-center" />
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />}></Route>
                    <Route path="/signin" element={<Signin />} /> {/* Auth Routes */}
                    <Route path="/auth/*" element={<Signup />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/auth/reset-password" element={<Reset />} />
                    <Route path="/auth/forgot-password" element={<Forgot />} />
                    <Route path="/profile" element={<Profile />}></Route> {/* Common Profile Route */}
                    <Route path="/:role/profile" element={<Profile />}></Route> {/* Profile Route */}
                    <Route path="/joinmarc" element={<JoinMarc />} />
                    <Route path="/termsofservices" element={<TermsOfService />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/home" element={<Home />}></Route> {/* Dashboard Page */}
                    <Route path="/wishlist" element={<Wishlist />}></Route>
                    <Route path="/follow" element={<Follow />}></Route>
                    <Route path="/providersignin" element={<ProviderSignIn />}></Route>
                    <Route path="/providersignup" element={<ProviderSignUp />}></Route>
                    <Route path="/providersignup/:id/" element={<ProviderSignUp />}></Route> {/* For Claim Venue */}
                    <Route path="/reapply/:role/:userId" element={<ReApply />}></Route> {/* For Re Apply Venue */}
                    <Route path="/all/users" element={<UsersListing />}></Route>
                    <Route path="/all/upcoming/events" element={<AllUpcomingEvents />}></Route>
                    <Route path="/all/events" element={<AllEvents />}></Route>
                    <Route path="/Event" element={<Events />}></Route>
                    <Route path="/eventmap" element={<FilterPage />}></Route>
                    <Route path="/EventDetails/:id/:invitesId?" element={<EventDetails />}></Route>
                    <Route path="/hostProfile/:id" element={<HostProfile />}></Route>
                    <Route path="/influencerProfile/:id" element={<ProfilePage />}></Route>
                    <Route path="/entertainmentCrewProfile/:id" element={<EntertainmentCrewProfilePage />}></Route>
                    <Route path="/plans" element={<Plans />}></Route>
                    <Route path="/venue/detail/:id" element={<VenueDetail />}></Route>
                    <Route path="/events" element={<EventsListing />}></Route>
                    <Route path="/events/add" element={<AddEdit />}></Route>
                    <Route path="/events/edit/:id" element={<AddEdit />}></Route>
                    <Route path="/events/detail/:id" element={<EventsView />}></Route>
                    <Route path="/post/list" element={<PostListing />}></Route> {/* Posts */}
                    <Route path="/post" element={<Posts />}></Route> {/* Post */}
                    <Route path="/post/add" element={<AddEditPost />}></Route>
                    <Route path="/post/edit/:id" element={<AddEditPost />}></Route>
                    <Route path="/post/detail/:id" element={<PostDetail />}></Route>
                    <Route path="/collaborations" element={<Listing />}></Route> {/* Collaborate Venue */}
                    <Route path="/collaboration/detail/:id" element={<Detail />}></Route>
                    <Route path="/apply/posts" element={<ApplyPostListing />}></Route> {/* Apply Posts Venue */}
                    <Route path="/apply/post/:id" element={<ApplyPostDetail />}></Route>
                    <Route path="/manage/schedule" element={<SetupSchedule />}></Route> {/* Setup & Manage Schedule */}
                    <Route path="/myorder" element={<MyOrdersListing />}></Route> {/* My Orders */}
                    <Route path="/myorder/detail/:id" element={<MyOrdersDetail />}></Route>
                    <Route path="/experiencemarc" element={<ExperienceMarc />}></Route>
                    <Route path="/aboutus" element={<AboutUs />}></Route>
                    <Route path="/contactus" element={<ContactUs />}></Route>
                    <Route path="/gethelp" element={<GetHelp />}></Route>
                    <Route path="/message" element={<Chat />}></Route>
                    <Route path="/message/:id" element={<Chat />}></Route>
                    <Route path="/jobposts" element={<JobPosts />}></Route>
                    <Route path="/transaction" element={<Transaction />}></Route>
                    <Route path="/plan/transaction" element={<PlanTransaction />}></Route>
                    <Route path="/member/transaction" element={<MemberTransactions />}></Route>
                    <Route path="/notifications" element={<Notifications />}></Route>
                    <Route path="/invites" element={<Invites />}></Route>
                    <Route path="/crew/member" element={<CrewMember />}></Route> {/* Crew Member */}
                    <Route path="/crew/member/add" element={<AddEditCrewMember />}></Route>
                    <Route path="/crew/member/edit/:id" element={<AddEditCrewMember />}></Route>
                    <Route path="/crewProfile/:id" element={<CrewProfilePage />}></Route>
                    <Route path="/followers" element={<Followers />}></Route> {/* Followers */}
                    <Route path="/scanner" element={<QrScanner />}></Route> {/* QrScanner */}
                    <Route path="/vendorProfile/:id" element={<VendorProfilePage />}></Route> {/* Vendor */}
                    <Route path="/foodTruckVendorProfile/:id" element={<FoodTruckVendorProfilePage />}></Route> {/* Food Truck Vendor */}
                    <Route path="/boosting" element={<Boosting />}></Route>
                </Routes>
            </Router>
            <div id="loader" className="hidden fixed inset-0 flex items-center justify-center z-[99999] backdrop-blur-sm h-full w-full">
                <img src={loader} alt="logo" width="50px" />
            </div>
            <ToastContainer position="top-right" />
        </>
    );
}

export default App;
