import { useState, useEffect } from 'react';
import { NavigationBar } from "../Landing/NavigationBar";
import { Scanner } from "@yudiel/react-qr-scanner";

const QrScanner = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [cameraAvailable, setCameraAvailable] = useState(false); // Track camera availability

    useEffect(() => {
        const checkCameraAvailability = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter(device => device.kind === 'videoinput');
                if (cameras.length === 0) {
                    setCameraAvailable(false);
                    setErrorMessage("No camera found. Please connect a camera.");
                } else {
                    setCameraAvailable(true);
                    setErrorMessage("");
                }
            } catch (err) {
                setCameraAvailable(false);
                setErrorMessage("An error occurred while checking for camera devices.");
            }
        };
        checkCameraAvailability();
    }, []);

    const handleScan = (data) => {
        if (data) {
            let value = data[0]?.rawValue;
            window.open(value, "_self"); // To redirect on qr code response
        }
    };

    const handleError = (err) => {
        setCameraAvailable(false);
        if (err.name === 'NotFoundError') {
            setErrorMessage("Camera not found. Please connect a camera.");
        } else if (err.name === 'NotAllowedError') {
            setErrorMessage("Camera access denied. Please grant camera permissions.");
        } else {
            setErrorMessage("An unknown error occurred while accessing the camera.");
        }
    };

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="mb-8">
                    {cameraAvailable ? (
                        <div className="container mx-auto px-5">
                            {errorMessage ? (
                                <div className="h-[calc(100vh_-_61px)] flex justify-center items-center text-red-500 text-center leading-[20px]">{errorMessage}</div>
                            ) : (
                                <div className='h-[calc(100vh_-_61px)] mt-8'>
                            <div className='max-w-[400px] mx-auto h-fit'>
                                <Scanner
                                    delay={300}
                                    onError={handleError}
                                    onScan={handleScan}
                                    components={{ torch: true }}
                                    styles={{ width: "120px", height: "120px", margin: "2px" }}
                                />
                                </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="container mx-auto px-5">
                            <div className="h-[calc(100vh_-_61px)] flex justify-center items-center text-red-500 text-center leading-[20px]">{errorMessage}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default QrScanner;