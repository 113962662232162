import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "../../common/FormControl";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { login_success } from "../../redux/actions/user";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import { Tooltip } from "antd";

const MemberProfile = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [form, setForm] = useState({
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        mobileNo: user?.mobileNo || "",
        image: user?.image || ""
    })
    const [edit, setEdit] = useState(false)
    const inValidEmail = methodModel.emailvalidation(form?.email)
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!inValidEmail) return
        const payload = {
            ...form,
            id: user?._id || user?.id
        }
        loader(true)
        ApiClient.put(`users/edit/profile`, payload).then(res => {
            if (res.success) {
                dispatch(login_success({ ...user, ...form, fullName: `${form?.firstName} ${form?.lastName}` }))
                setEdit(false)
            }
            loader(false)
        })
    }

    const uploadImage = (e) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                setForm({ ...form, image: res?.fileName })
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="">
                <div className="bg-white">
                    <div className="flex justify-center items-center pb-[4rem]">
                        <div className="container mx-auto">
                            <div className="relative">
                                <img src="https://t3.ftcdn.net/jpg/09/33/47/78/360_F_933477838_OVC2RrIoqLHTl2jJF3RYErHAs0KWJv6Z.jpg" className="object-cover h-[200px] w-full rounded-[20px]" alt="bannerImage" />
                                <div className="text-[24px] text-white font-[600] absolute top-3 left-5">Profile</div>
                                <div className="flex gap-2 absolute right-5 top-3">
                                    <button type="button" onClick={e => setEdit(!edit)} className="text-[14px] px-5 py-2 rounded-full bg-[#005AAB] text-white hover:opacity-[90%]">{edit ? "Cancel" : "Edit"}</button>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-5 md:max-w-[85%] px-5 md:px-0 mx-auto relative top-[-50px]">
                                <div className="col-span-12 md:col-span-4">
                                    <div className="event_card relative bg-white rounded-xl shadow-2xl p-5">
                                        <img src={methodModel.userImg(form?.image)} className="h-[130px] w-[130px] mx-auto rounded-full object-cover" alt="userImage" />
                                        {edit &&
                                            <div className="hidden_card">
                                                <div className="flex gap-2 absolute left-0 right-0 top-5 bottom justify-center items-center h-[130px] w-[130px] mx-auto rounded-full bg-[#00000040]">
                                                    <label>
                                                        <input type="file" className="hidden" disabled={!edit} onChange={uploadImage} />
                                                        <Tooltip position="top" title="Edit">
                                                            <MdEdit className="w-[30px] h-[30px] p-[5px] bg-white text-[green] rounded-full border hover:border-white hover:opacity-[90%] cursor-pointer" />
                                                        </Tooltip>
                                                    </label>
                                                    {form?.image &&
                                                        <Tooltip position="top" title="Delete">
                                                            <MdDelete onClick={e => setForm({ ...form, image: "" })} className="w-[30px] h-[30px] p-[5px] bg-white text-[#bf0000] rounded-full border hover:border-white hover:opacity-[90%] cursor-pointer" />
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="mt-2">
                                            <h2 className="text-[16px] font-[500] text-center">{user?.fullName ? user?.fullName : `${user?.firstName} ${user?.lastName || ""}`}</h2>
                                            <p className="text-[12px] text-center text-[#525252]">{user?.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-8 w-full px-5 py-8 sm:p-8 rounded-2xl shadow-2xl bg-white">
                                    <form onSubmit={handleSubmit}>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="firstName"
                                                    label="First Name"
                                                    placeholder={`Enter First Name`}
                                                    autoComplete="one-time-code"
                                                    value={form?.firstName}
                                                    onChange={(e) => setForm({ ...form, firstName: e })}
                                                    className="disabled:cursor-not-allowed disabled:bg-[#ededed]"
                                                    disabled={!edit}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="lastName"
                                                    label="Last Name"
                                                    placeholder={`Enter Last Name`}
                                                    autoComplete="one-time-code"
                                                    value={form?.lastName}
                                                    onChange={(e) => setForm({ ...form, lastName: e })}
                                                    className="disabled:cursor-not-allowed disabled:bg-[#ededed]"
                                                    disabled={!edit}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="email"
                                                    label="Email"
                                                    placeholder={`Enter Email`}
                                                    autoComplete="one-time-code"
                                                    value={form?.email}
                                                    className="disabled:cursor-not-allowed disabled:bg-[#ededed]"
                                                    disabled
                                                    required
                                                />
                                                {form?.email && !inValidEmail && submitted && (
                                                    <div className="d-block text-red-600 mt-2">Please enter valid email</div>
                                                )}
                                            </div>
                                            <div className="">
                                                <FormControl
                                                    type="phone"
                                                    name="mobileNo"
                                                    label="Mobile Number"
                                                    placeholder={`Enter Mobile Number`}
                                                    className="disabled:bg-[#ededed]"
                                                    autoComplete="one-time-code"
                                                    value={form?.mobileNo}
                                                    onChange={(e) => setForm({ ...form, mobileNo: e })}
                                                    disabled={!edit}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        {edit &&
                                            <div className="flex justify-end mt-8">
                                                <button type="submit" className="text-sm font-[500] tracking-wide bg-[#005AAB] text-gray-100 px-5 py-2 hover:opacity-[90%] rounded-full focus:outline-none focus:shadow-outline">
                                                    Save
                                                </button>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MemberProfile