import { useNavigate } from 'react-router-dom';
import { SidebarItems } from './SidebarItems';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { NavigationBar } from '../../Landing/NavigationBar';
import methodModel from '../../../methods/methods';

export default function DesktopSidebar() {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)

    useEffect(() => {
        if (user?.docVerified === "N") {
            if (user?.role === "venue") {
                navigate('/venue/profile')
            } else if (user?.role === "host") {
                navigate('/host/profile')
            } else {
                return
            }
        }
    }, [])

    return (
        <>
            <NavigationBar />
            {methodModel.checkUserDetail(user) && user?.activePlan || user?.role === "crew_member" && user?.doc_status === "verified" ?
                <div className="sidebar_header bg-[#134896] lg:block lg:inset-y-0 lg:flex lg:flex-col py-5 mt-[1rem]">
                    <div className="flex grow flex-col px-6">
                        <nav className="flex flex-1 flex-col h-full text-[#005fa7]">
                            <ul role="list" className="flex flex-1 flex-col">
                                <li>
                                    {/* <ul role="list" className="-mx-2 space-y-1 gap-4 flex pl-[3rem]"> */}
                                    <ul role="list" className="">
                                        <SidebarItems />
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                : null
            }
        </>
    );
}