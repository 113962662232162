import { useSelector } from "react-redux"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import { useState } from "react"
import FollowingEvents from "./FollowingEvents"
import FollowingHosts from "./FollowingHosts"
import FollowingInfluencers from "./FollowingInfluencers"
import FollowingEntertainmentCrew from "./FollowingEntertainmentCrew"
import FollowingCrewMembers from "./FollowingCrewMembers"

const Follow = () => {
    const user = useSelector(state => state.user)
    const [tab, setTab] = useState()

    const handleTabChange = (e) => {
        setTab(e)
    }

    const handleRolesChecks = (roles) => {
        let value = true
        roles?.filter((item) => {
            if (item === user?.role) {
                value = false
            }
        })
        return value
    }

    return (
        <>
            <div className="">
                <div className="bg-[#D9D9D938]">
                    <div className="bg-white w-full h-screen">
                        <DesktopSidebar />
                        <div className="container mx-auto px-5 mt-6">
                            <TabGroup selectedIndex={tab} onChange={e => handleTabChange(e)}>
                                <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                                    <Tab key={"Events"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Events
                                    </Tab>
                                    {handleRolesChecks(["host"]) &&
                                        <Tab key={"Hosts"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Hosts
                                        </Tab>
                                    }
                                    {handleRolesChecks(["influencer"]) &&
                                        <Tab key={"Influencer"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Influencers
                                        </Tab>
                                    }
                                    {handleRolesChecks(["crew"]) &&
                                        <Tab key={"Crew"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Entertainment Crew
                                        </Tab>
                                    }
                                    {handleRolesChecks(["crew","crew_member"]) &&
                                        <Tab key={"Crew_Member"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Crew Members
                                        </Tab>
                                    }
                                </TabList>
                                <TabPanels className="mt-3">
                                    <TabPanel key={"Events"} className="rounded-xl bg-white/5">
                                        <FollowingEvents />
                                    </TabPanel>
                                    {handleRolesChecks(["host"]) &&
                                        <TabPanel key={"Hosts"} className="rounded-xl bg-white/5">
                                            <FollowingHosts />
                                        </TabPanel>
                                    }
                                    {handleRolesChecks(["influencer"]) &&
                                        <TabPanel key={"Influencer"} className="rounded-xl bg-white/5">
                                            <FollowingInfluencers />
                                        </TabPanel>
                                    }
                                    {handleRolesChecks(["crew"]) &&
                                        <TabPanel key={"Crew"} className="rounded-xl bg-white/5">
                                            <FollowingEntertainmentCrew />
                                        </TabPanel>
                                    }
                                    {handleRolesChecks(["crew","crew_member"]) &&
                                        <TabPanel key={"Crew_Member"} className="rounded-xl bg-white/5">
                                            <FollowingCrewMembers />
                                        </TabPanel>
                                    }
                                </TabPanels>
                            </TabGroup>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Follow