import { useEffect, useState } from "react";
import { MdClear, MdOutlinePaid } from "react-icons/md";
import Table from "../../common/Table";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import moment from "moment";
import { TbClockDollar } from "react-icons/tb";

const ReceiveAmountTransactions = () => {
    const user = useSelector((state) => state.user);
    const [filters, setFilter] = useState({ page: 1, count: 10, search: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [amountDetail, setAmountDetail] = useState("")

    useEffect(() => {
        getData()
        getAmountDetail()
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, userId: user?._id || user?.id }
        loader(true)
        let url = "account/transfer/listing"
        ApiClient.get(url, filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const getAmountDetail = () => {
        ApiClient.get(`account/user/scheduled/transfer/?userId=${user?._id || user?.id}`).then(res => {
            if (res.success) {
                setAmountDetail(res?.data)
            }
        })
    }

    const sortClass = (key) => {
        let cls = "fa-sort";
        if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
        else if (filters.key == key && filters.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    }
    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }
        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    }

    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        getData({ page: e });
    }
    const count = (e) => {
        setFilter({ ...filters, count: e });
        getData({ ...filters, count: e });
    }

    const columns = [
        {
            key: "transferredAt",
            name: "Date",
            render: (row) => {
                return <span>{moment(row?.transferredAt).format("DD MMM, YYYY")}</span>
            },
        },
        {
            key: "accountId?.bank_name",
            name: "Bank Name",
            render: (row) => {
                return <span>{row?.accountId?.bank_name || "--"}</span>
            },
        },
        {
            key: "accountId?.bankAccountNumber",
            name: "Account Number Last Digits",
            render: (row) => {
                return <span>{row?.accountId?.bankAccountNumber || "--"}</span>
            },
        },
        {
            key: "description",
            name: "Description",
            render: (row) => {
                return <span>{row?.description || "--"}</span>
            },
        },
        {
            key: "transferredAmount",
            name: "Total Amount",
            render: (row) => {
                return <span>${row?.transferredAmount ? (row?.transferredAmount / 100) : 0}</span>
            },
        },
    ]

    return (
        <>
            <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6">
                <div className="grid grid-cols-12 gap-6 mb-8">
                    <div className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg">
                        <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat">
                            <div>
                                <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total <br /> Paid Amount</h3>
                                <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">${amountDetail?.paidAmount ? parseFloat((amountDetail?.paidAmount / 100)).toFixed(2) : 0}</p>
                            </div>
                            <div>
                                <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                                    <MdOutlinePaid />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg">
                        <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat">
                            <div>
                                <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total <br /> Pending Amount</h3>
                                <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">${amountDetail?.dueAmount ? parseFloat((amountDetail?.dueAmount / 100)).toFixed(2) : 0}</p>
                            </div>
                            <div>
                                <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                                    <TbClockDollar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-[1px] rounded-[15px] p-5 gap-6">
                    <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">Receive Amount Transactions</h3>
                        </div>
                    </div>
                    <div className="flex py-4 items-center flex-wrap gap-2">
                        <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <input
                                    type="text"
                                    id="simple-search"
                                    value={filters?.search}
                                    onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                    autoComplete="one-time-code"
                                    className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                                    placeholder="Search"
                                />
                                {filters?.search && (
                                    <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                )}
                            </div>
                            <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </form>
                    </div>
                    <Table
                        className="mb-3"
                        firstColumnClass="width_row"
                        data={data}
                        columns={columns}
                        page={filters?.page}
                        count={filters?.count}
                        filters={filters}
                        total={total}
                        result={(e) => {
                            if (e.event == "page") pageChange(e.value);
                            if (e.event == "sort") {
                                sorting(e.value);
                                sortClass(e.value);
                            }
                            if (e.event == "count") count(e.value);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default ReceiveAmountTransactions