import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import ApiClient from '../../methods/api/apiClient';
import FormControl from '../../common/FormControl';
import { GrSubtractCircle } from 'react-icons/gr';
import { IoMdAddCircleOutline } from 'react-icons/io';
import shared from './shared';
import { useState } from 'react';
import SelectDropdown from '../../common/SelectDropdown';
import loader from '../../methods/loader';

const AddEditTickets = ({ isOpen, setIsOpen, form, setForm, getData }) => {
    const [disabled, setDisabled] = useState(false)

    const isOnlineOption = [
        { id: true, name: "Yes" },
        { id: false, name: "No" }
    ]
    const typeOption = [
        { id: "paid", name: "Paid" },
        { id: "free", name: "Free" }
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        let payload = {
            eventId: form?.eventId,
            ticketData: form?.data?.map((item) => {
                return ({ ...item, isOnline: item?.isOnline ? true : false, price_of_ticket: item?.type === "paid" ? item?.price_of_ticket : 0 })
            }),
        }
        if (form?.data?.[0]?.id) {
            ApiClient.put(shared?.editTicketApi, payload).then(res => {
                if (res.success) {
                    setIsOpen(false)
                    getData()
                }
                setDisabled(false)
            })
        } else {
            ApiClient.post(shared?.addTicketApi, payload).then(res => {
                if (res.success) {
                    getData()
                    setIsOpen(false)
                }
                setDisabled(false)
            })
        }
    }

    const handleAddMoreInput = (e, index, key) => {
        let value = form?.data
        value[index][key] = e
        setForm((prev) => ({ ...prev, data: value }))
    }
    const handleAddMore = () => {
        const value = [...form?.data, { ticketName: "", type: "", tickets: "", price_of_ticket: "", isOnline: true, tickets_per_order: "" }]
        setForm((prev) => ({ ...prev, data: value }))
    }
    const handleRemoveAddMore = (item, index) => {
        if (item?.id) {
            loader(true)
            ApiClient.delete(`${shared?.deleteTicketApi}?id=${item?.id}`).then(res => {
                if (res.success) {
                    const value = form?.data?.filter((item, i) => i !== index)
                    setForm((prev) => ({ ...prev, data: value }))
                }
                loader(false)
            })
        } else {
            const value = form?.data?.filter((item, i) => i !== index)
            setForm((prev) => ({ ...prev, data: value }))
        }
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel
                            transition
                            className="w-full max-w-4xl rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle as="h3" className="text-[20px] font-medium text-[#003b85] border-[#c1c1c1] border-b pb-3">
                                {form?.data?.[0]?.id ? "Edit" : "Add"} Ticket
                            </DialogTitle>
                            <form onSubmit={handleSubmit}>
                                <div className="py-3 max-h-[350px] overflow-auto">
                                    <div className="">
                                        {form?.data?.map((item, index) => {
                                            return <div className='border p-5 rounded-lg mb-3' key={index}>
                                                <div key={index} className="grid grid-cols-2 gap-3 mb-3">
                                                    <div className="mb-3">
                                                        <label>Name <span className="text-red-600">*</span></label>
                                                        <FormControl
                                                            type="text"
                                                            name="ticketName"
                                                            placeholder="Enter Name"
                                                            autoComplete="one-time-code"
                                                            value={item?.ticketName}
                                                            onChange={(e) => handleAddMoreInput(e, index, "ticketName")}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label>Type <span className="text-red-600">*</span></label>
                                                        <SelectDropdown
                                                            id="statusDropdown"
                                                            displayValue="name"
                                                            className="mt-1"
                                                            placeholder="Select Type"
                                                            theme="search"
                                                            intialValue={item?.type}
                                                            isClearable={false}
                                                            result={(e) => handleAddMoreInput(e?.value, index, "type")}
                                                            options={typeOption}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label>Tickets <span className="text-red-600">*</span></label>
                                                        <FormControl
                                                            type="number"
                                                            name="tickets"
                                                            placeholder="Enter Tickets"
                                                            autoComplete="one-time-code"
                                                            value={item?.tickets}
                                                            onChange={(e) => handleAddMoreInput(e, index, "tickets")}
                                                            required
                                                        />
                                                    </div>
                                                    {item?.type === "paid" &&
                                                        <div className="mb-3">
                                                            <label>Ticket Price ($) <span className="text-red-600">*</span></label>
                                                            <FormControl
                                                                type="number"
                                                                name="price_of_ticket"
                                                                placeholder="Ticket Price ($)"
                                                                autoComplete="one-time-code"
                                                                value={item?.price_of_ticket}
                                                                onChange={(e) => handleAddMoreInput(e, index, "price_of_ticket")}
                                                                required
                                                            />
                                                        </div>
                                                    }
                                                    <div className="mb-3">
                                                        <label>Tickets Per Order <span className="text-red-600">*</span></label>
                                                        <FormControl
                                                            type="number"
                                                            name="tickets_per_order"
                                                            placeholder="Enter Tickets Per Order"
                                                            autoComplete="one-time-code"
                                                            value={item?.tickets_per_order}
                                                            onChange={(e) => handleAddMoreInput(e, index, "tickets_per_order")}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label>Ticket Purchase Online (Yes/No) <span className="text-red-600">*</span></label>
                                                        <SelectDropdown
                                                            id="statusDropdown"
                                                            displayValue="name"
                                                            className="mt-1"
                                                            placeholder="Select Ticket Purchase Online"
                                                            theme="search"
                                                            intialValue={item?.isOnline}
                                                            isClearable={false}
                                                            result={(e) => handleAddMoreInput(e?.value, index, "isOnline")}
                                                            options={isOnlineOption}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {form?.data?.length > 1 || item?.id ?
                                                    <div className="bg-red-600 p-3 text-white rounded-lg cursor-pointer w-fit ml-auto" onClick={e => handleRemoveAddMore(item, index)}><GrSubtractCircle size={20} /></div>
                                                    : null
                                                }
                                            </div>
                                        })}
                                    </div>
                                    {!form?.data?.[0]?.id &&
                                        <div className="w-fit ml-auto mt-3 bg-[#003b85] p-3 text-white rounded-lg cursor-pointer" onClick={e => handleAddMore()}>
                                            <IoMdAddCircleOutline size={20} />
                                        </div>
                                    }
                                </div>
                                <div className=" border-[#c1c1c1] border-t flex justify-end pt-3 mt-3">
                                    <Button
                                        className="inline-flex mr-3 items-center gap-2 rounded-full bg-gray-700 py-1.5 px-5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                                        type='button'
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className={`disabled:cursor-not-allowed inline-flex items-center gap-2 rounded-full bg-[#003b85] py-1.5 px-5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700`}
                                        type='submit'
                                        disabled={disabled}
                                    >
                                        {form?.data?.[0]?.id ? "Update" : "Save"}
                                    </Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddEditTickets