import { persistCombineReducers } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; // sessionStorage
import user from "./reducers/generic/user"
import loader from './reducers/generic/loader';
import search from './reducers/generic/search';


const userPersistConfig = {
    key: 'admin-app',
    storage: storageSession,  // Use sessionStorage
    blacklist: ['loader'],
};

export default persistCombineReducers(userPersistConfig, {
    user,
    loader,
    search
});