import DesktopSidebar from './Sidebars/DesktopSidebar';
import Dashboard from '../Dashboard';

export default function Home() {
    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div id="mainPage" className={`w-full flex h-full flex-col justify-center py-5`}>
                    <div className="w-full flex flex-col h-full">
                        <Dashboard />
                    </div>
                </div>
            </div>
        </>
    );
}
