import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import EChartsComponent from "../../components/graphs/echarts";
import { IoHandRightOutline } from "react-icons/io5";
import SelectDropdown from "../../common/SelectDropdown";
import loader from "../../methods/loader";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FaHandshakeSimple } from "react-icons/fa6";
import { MdEventAvailable, MdPendingActions } from "react-icons/md";
import { RiFileCloseFill } from "react-icons/ri";
import { MdOutlineAddChart } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import BoostingLocationCharts from "./BoostingLocationCharts";
import BoostingPeakTimeCharts from "./BoostingPeakTimeCharts";
import BoostingChart from "./BoostingChart";
import environment from "../../environment";

const Dashboard = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [collabrationData, setCollabrationData] = useState({ total: 0, accepted: 0, pending: 0, rejected: 0 })
  const [totalEvents, setTotalEvents] = useState(0)
  const [ticketSalesData, setTicketSalesData] = useState([])
  const [ticketType, setTicketType] = useState("monthly")
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filter, setFilter] = useState({ categoryType: "", startDate: "", endDate: "", boostType: "daily" })

  const getCategoryOptions = () => {
    let options = []
    if (user?.role === "venue") {
      options.push(
        { id: "profile", name: "Profile" },
        { id: "event", name: "Event" },
        { id: "amenities", name: "Amenities" },
      )
    } else if (user?.role === "host") {
      options.push(
        { id: "profile", name: "Profile" },
        { id: "event", name: "Event" },
        { id: "location", name: "Location" },
      )
    } else if (user?.role === "crew") {
      options.push({ id: "profile", name: "Profile" })
    } else if (user?.role === "food_truck_vendor") {
      options.push(
        { id: "profile", name: "Profile" },
        { id: "location", name: "Location" },
      )
    } else options = []
    return options
  }

  const ticketTypeOptions = [
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" },
    { id: "yearly", name: "Yearly" },
  ]

  useEffect(() => {
    if (user?.role === "venue" || user?.role === "host") {
      getCollabrationData()
      getTotalEvents()
    }
  }, [])

  useEffect(() => {
    getTicketSalesData()
  }, [ticketType])

  const getCollabrationData = (filter = {}) => {
    loader(true)
    const payload = { userId: user?._id || user?.id, type: user?.role, ...filter }
    ApiClient.get(`host/total/collaboration`, payload).then(res => {
      if (res.success) {
        setCollabrationData(res?.data)
      }
      loader(false)
    })
  }
  const getTotalEvents = (filter = {}) => {
    const payload = { loginType: user?.role, userId: user?._id || user?.id, ...filter }
    loader(true)
    ApiClient.get(`analytic/total/event`, payload).then(res => {
      if (res.success) {
        setTotalEvents(res?.data?.totalEvents || 0)
      }
      loader(false)
    })
  }
  const getTicketSalesData = (filter = {}) => {
    let payload = {
      type: ticketType,
      loginType: user?.role,
      ...filter
    }
    if (user?.role === "member") {
      payload = { ...payload, purchasedBy: user?._id || user?.id }
    } else {
      payload = { ...payload, paidTo: user?._id || user?.id }
    }
    loader(true)
    ApiClient.get(`analytic/ticket`, payload).then(res => {
      if (res.success) {
        setTicketSalesData(res?.data?.map((item) => {
          return ({ ...item, totalSales: (item?.totalSales / 100) })
        }))
      }
      loader(false)
    })
  }
  const handleDateRangeFilter = (date) => {
    setDateRange(date)
    if (date?.[0] && date?.[1] || !date?.[0] && !date?.[1]) {
      const start = date?.[0] ? moment(date?.[0]).format("YYYY-MM-DD") : ""
      const end = date?.[1] ? moment(date?.[1]).format("YYYY-MM-DD") : ""
      setFilter((prev) => ({ ...prev, startDate: start, endDate: end }))
      getCollabrationData({ startDate: start, endDate: end })
      getTicketSalesData({ startDate: start, endDate: end })
      getTotalEvents({ startDate: start, endDate: end })
    }
  }

  return (
    <div className="dashboard bg-[#f5f5f5] py-8">
      <div className="container mx-auto px-5">
        <div className="pb-5">
          <h4 className="text-2xl font-bold mb-3 flex items-center capitalize gap-2">
            <IoHandRightOutline className="text-3xl slow-shake text-[#8DC641]" />
            <span className="">Hi,</span> {user?.role === "venue" ? user?.venue_name : user?.fullName || user?.firstName}
          </h4>
          {user?.role === "crew_member" ? null :
            <main className="">
              <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-end mb-5">
                {user?.role === "venue" || user?.role === "host" || user?.role === "member" || user?.role === "influencer" || user?.role === "crew" || user?.role === "food_truck_vendor" ?
                  <div className="date_picker relative">
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => handleDateRangeFilter(update)}
                      isClearable={true}
                      placeholderText="Click to select date range"
                    />
                    <SlCalender className="absolute top-[-1px] left-[-2px] p-[8px] text-gray-400 text-[35px]" />
                  </div>
                  : null}
                {user?.role === "venue" || user?.role === "host" || user?.role === "crew" || user?.role === "food_truck_vendor" ?
                  <>
                    <div className="ml-4 dropdown-custom">
                      <SelectDropdown
                        id="statusDropdown"
                        displayValue="name"
                        placeholder="All Boosting Category Type"
                        theme="search"
                        intialValue={filter.categoryType}
                        result={(e) => { setFilter((prev) => ({ ...prev, categoryType: e?.value })) }}
                        options={getCategoryOptions()}
                      />
                    </div>
                    <div className="ml-4 dropdown-custom">
                      <SelectDropdown
                        id="statusDropdown"
                        displayValue="name"
                        placeholder="Select Boost Type"
                        theme="search"
                        intialValue={filter.boostType}
                        isClearable={false}
                        result={(e) => { setFilter((prev) => ({ ...prev, boostType: e?.value })) }}
                        options={[
                          { id: "daily", name: "Daily" },
                          { id: "weekly", name: "Weekly" },
                        ]}
                      />
                    </div>
                  </>
                  : null}
              </div>
              {user?.role !== "member" ?
                <section className="grid grid-cols-12 gap-6 mb-8">
                  <div onClick={e => { navigate(`/collaborations`) }} className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg cursor-pointer">
                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat">
                      <div>
                        <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total <br /> Collaboration</h3>
                        <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{collabrationData?.total}</p>
                      </div>
                      <div>
                        <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                          <MdOutlineAddChart />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={e => { navigate(`/collaborations`) }} className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg cursor-pointer">
                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat cursor-pointer">
                      <div>
                        <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Accepted <br /> Collaboration</h3>
                        <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{collabrationData?.accepted}</p>
                      </div>
                      <div>
                        <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                          <FaHandshakeSimple />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={e => { navigate(`/collaborations`) }} className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg cursor-pointer">
                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat cursor-pointer">
                      <div>
                        <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Pending <br /> Collaboration</h3>
                        <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{collabrationData?.pending}</p>
                      </div>
                      <div>
                        <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                          <MdPendingActions />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={e => { navigate(`/collaborations`) }} className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg cursor-pointer">
                    <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat cursor-pointer">
                      <div>
                        <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Rejected <br /> Collaboration</h3>
                        <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{collabrationData?.rejected}</p>
                      </div>
                      <div>
                        <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                          <RiFileCloseFill />
                        </div>
                      </div>
                    </div>
                  </div>
                  {user?.role === "venue" || user?.role === "host" ?
                    <div onClick={e => { navigate(`/events`) }} className="max-[575px]:col-span-12 col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow-lg rounded-lg cursor-pointer">
                      <div className="rounded-lg bg-card flex justify-between items-center p-3 bg-[url('assets/images/dash_back.jpg')] bg-cover h-full bg-no-repeat">
                        <div>
                          <h3 className="text-[18px] font-[500] leading-[22px] text-[#004d92]">Total Events</h3>
                          <p className="text-[18px] font-[600] text-[#d39b1e] mt-2">{totalEvents}</p>
                        </div>
                        <div>
                          <div className="text-[20px] w-[45px] h-[45px] bg-[#005fa712] text-[#134896] rounded-full flex justify-center items-center">
                            <MdEventAvailable />
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                </section>
                : null
              }
              <section className="grid grid-cols-12 gap-6">
                {user?.role === "venue" || user?.role === "host" || user?.role === "member" ?
                  <div className="col-span-12 lg:col-span-6 bg-white shadow-lg rounded-lg">
                    <div className="flex gap-x-5 justify-between border-b border-gray-200 min-h-[53px] px-5 py-3 ">
                      <div className="font-semibold text-[#005fa7]">{user?.role === "member" ? "Purchased Tickets" : "Ticket Sales"}</div>
                      <div className="flex gap-2 flex-wrap justify-end">
                        <div className="">
                          <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            theme="search"
                            intialValue={ticketType}
                            isClearable={false}
                            result={(e) => setTicketType(e.value)}
                            options={ticketTypeOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="py-3 px-5 flex-grow">
                      <div className="chatr_ones">
                        <EChartsComponent data={ticketSalesData} period={ticketType} />
                      </div>
                    </div>
                  </div>
                  : null}
                {user?.role === "venue" || user?.role === "host" || user?.role === "crew" || user?.role === "food_truck_vendor" ?
                  <>
                    {environment.elitePlanIds?.some((item) => item === user?.activePlanId?._id) &&
                      <>
                        <div className="col-span-12 lg:col-span-6 bg-white shadow-lg rounded-lg">
                          <div className="flex gap-x-5 justify-between border-b border-gray-200 min-h-[53px] px-5 py-3">
                            <div className="font-semibold text-[#005fa7]">Boosting Peak Hour</div>
                          </div>
                          <div className="py-3 px-5 flex-grow">
                            <div className="chatr_ones">
                              <BoostingPeakTimeCharts filter={filter} />
                            </div>
                          </div>
                        </div>
                        <BoostingLocationCharts filter={filter} />
                      </>
                    }
                    {environment.proPlanIds?.some((item) => item === user?.activePlanId?._id) &&
                      <>
                        <div className="col-span-12 lg:col-span-6 bg-white shadow-lg rounded-lg">
                          <div className="flex gap-x-5 justify-between border-b border-gray-200 min-h-[53px] px-5 py-3">
                            <div className="font-semibold text-[#005fa7]">Boosting Chart</div>
                          </div>
                          <div className="py-3 px-5 flex-grow">
                            <div className="chatr_ones">
                              <BoostingChart filter={filter} />
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </>
                  : null}
              </section>
            </main>
          }
        </div>
      </div>
    </div>
  );
};
export default Dashboard;