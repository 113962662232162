const environment = {
  api: process.env.REACT_APP_API_URL,
  frontendUrl: process.env.REACT_APP_WEBSITE_URL, 
  map_api_key: process.env.REACT_APP_MAP_API_KEY,
  settingSlug: process.env.REACT_APP_SETTING_SLUG,
  starterPlanIds: process.env.REACT_APP_STARTER_PLAN_IDS ? process.env.REACT_APP_STARTER_PLAN_IDS.split(',') : [],
  proPlanIds: process.env.REACT_APP_PRO_PLAN_IDS ? process.env.REACT_APP_PRO_PLAN_IDS.split(',') : [],
  elitePlanIds: process.env.REACT_APP_ELITE_PLAN_IDS ? process.env.REACT_APP_ELITE_PLAN_IDS.split(',') : [],
};

export default environment;
