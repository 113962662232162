import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import Table from "../../common/Table";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { PiEyeLight } from "react-icons/pi";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PopUp from "../../common/PopUp";
import methodModel from "../../methods/methods";
import { NavigationBar } from "../Landing/NavigationBar";

const Listing = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate()
    const height = window.innerHeight - (window.innerWidth < 1024 ? 56 : 0) + 'px';
    const token = sessionStorage.getItem("token")

    const [filters, setFilter] = useState({ page: 1, count: 10, search: "", request_status: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [orderId, setOrderId] = useState("")
    const [isOpen, setIsOpen] = useState({ modal: false, message: "" })

    useEffect(() => {
        const modal = methodModel.getPrams("success")
        if (modal) {
            setIsOpen({ modal: true, buttonText: "Ok", image: "/assets/img/form.png", message: "Your order details and  order ID are waiting in your email! Ready to track it? Just pop the order ID into the Track Order page at Marclocal.com and you’re all set.", redirectUrl: "/myorder" })
        }
        if (token) {
            getData()
        }
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, userId: user?._id || user?.id }
        loader(true)
        ApiClient.get(`ticket/listing`, filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const sortClass = (key) => {
        let cls = "fa-sort";
        if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
        else if (filters.key == key && filters.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    }
    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }
        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    }

    const handleFilter = (e, key) => {
        setFilter({ ...filters, [key]: e });
        getData({ [key]: e });
    }
    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "", request_status: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        getData({ page: e });
    }
    const count = (e) => {
        setFilter({ ...filters, count: e });
        getData({ ...filters, count: e });
    }

    const columns = [
        {
            key: "event_id",
            name: "Event Name",
            sort: true,
            render: (row) => {
                return <span className="capitalize">{row?.event_id?.title}</span>
            },
        },
        {
            key: "event_id",
            name: "Event Start Date",
            render: (row) => {
                return <span>{moment(row?.event_id?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</span>
            },
        },
        {
            key: "event_id",
            name: "Event End Date",
            render: (row) => {
                return <span>{moment(row?.event_id?.eventEndDate).format("DD MMM, YYYY hh:mm A")}</span>
            },
        },
        {
            key: "quantity",
            name: "Tickets",
            render: (row) => {
                return <span>{row?.quantity}</span>
            },
        },
        // {
        //     key: "ticket_amount",
        //     name: "Ticket Price",
        //     render: (row) => {
        //         return <span>{row?.ticketDetails?.[0]?.type === "free" ? "Free" : `$${(row?.ticket_amount / 100) || 0}`}</span>
        //     },
        // },
        {
            key: "total_amount",
            name: "Paid Amount",
            render: (row) => {
                return <span>{row?.ticketDetails?.[0]?.type === "free" ? "Free" : `$${(row?.total_amount / 100) || 0}`}</span>
            },
        },
        {
            key: "action",
            name: "Action",
            render: (itm) => {
                return (
                    <>
                        <div className="flex items-center justify-start gap-1.5">
                            <Tooltip placement="top" title="View">
                                <p onClick={() => { navigate(`/myorder/detail/${itm?.trackingId}`) }} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                                    <PiEyeLight />
                                </p>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ]

    const trackOrder = (e) => {
        e.preventDefault()
        loader(true)
        setOrderId("")
        ApiClient.get(`ticket/detail`, { trackingId: orderId }, "", true).then((res) => {
            if (res.success) {
                navigate(`/myorder/detail/${orderId}`)
            } else {
                setIsOpen({ modal: true, buttonText: "Close", image: "/assets/img/warning.png", message: "Oops! It looks like the order ID you entered doesn’t match our records. Could you double-check and send the correct one our way? Thanks so much! (Try Again) for the button", redirectUrl: "/myorder" })
            }
            loader(false)
        });
    }

    return (
        <>
            <div className="bg-white w-full h-screen" style={{ maxHeight: height }}>
            <NavigationBar />
                {token ?
                    <div className="container mx-auto px-5 mt-6">
                        <div className="border-[1px] rounded-[15px] p-5 gap-6">
                            <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
                                <div>
                                    <h3 className="text-2xl font-semibold text-[#111827]">My Orders</h3>
                                </div>
                            </div>
                            <div className="flex py-4 items-center flex-wrap gap-2">
                                <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                                    <label htmlFor="simple-search" className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            id="simple-search"
                                            value={filters?.search}
                                            onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                            autoComplete="one-time-code"
                                            className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                                            placeholder="Search"
                                        />
                                        {filters?.search && (
                                            <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                        )}
                                    </div>
                                    <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <svg
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                        <span className="sr-only">Search</span>
                                    </button>
                                </form>
                            </div>
                            <Table
                                className="mb-3"
                                firstColumnClass="width_row"
                                data={data}
                                columns={columns}
                                page={filters?.page}
                                count={filters?.count}
                                filters={filters}
                                total={total}
                                result={(e) => {
                                    if (e.event == "page") pageChange(e.value);
                                    if (e.event == "sort") {
                                        sorting(e.value);
                                        sortClass(e.value);
                                    }
                                    if (e.event == "count") count(e.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className="bg-cover bg-[url('assets/images/track_bg.png')] bg-no-repeat h-screen">
                        <div className="min-h-[600px] xl:min-h-[800px] bg-[#0000008a] flex items-center h-screen">
                            <div className="container mx-auto px-5">
                                <div className="grid  grid-cols-1 lg:grid-cols-2 gap-5 items-center">
                                    <div>
                                        <h2 className="max-[499px]:w-[325px] max-w-[520px] max-[499px]:text-[34px] text-[50px] xl:text-[60px] text-white max-[499px]:leading-[45px] leading-[60px] xl:leading-[70px] font-[500]">Connect for your
                                            ticket enquiry.</h2>
                                        <div className="w-[90px] h-[2px] bg-[#FFC42B] mt-5 mb-5"></div>
                                        <p className="max-w-[450px] max-[499px]:text-[20px] text-[24px] xl:text-[28px] text-white leading-[32px] xl:leading-[35px]">Tracking the status of purchased tickets for concerts, shows, or events.</p>
                                    </div>
                                    <div className="">
                                        <h2 className="max-[499px]:text-[24px] text-[30px] xl:text-[36px] font-[500] text-white mb-3">Track Your Order</h2>
                                        <form onSubmit={trackOrder}>
                                            <div className="relative">
                                                <input type="text" value={orderId} onChange={e => setOrderId(e.target.value)} className="text-[12px] w-full rounded-lg h-[55px] px-5 pr-[6rem]" placeholder="Put your order ID here" required />
                                                <button type="submit" className="absolute right-[8px] top-[6px] bg-[#134896] text-white rounded-lg text-[12px] font-[300] px-6 py-3">Search</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <PopUp popUp={isOpen} setPopUp={setIsOpen} redirectUrl={isOpen?.redirectUrl} />
        </>
    )
}

export default Listing