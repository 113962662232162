import { FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { FaStar } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { NavigationBar } from "../Landing/NavigationBar";
import { useSelector } from "react-redux";
import FormControl from "../../common/FormControl";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

const ProfilePage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const token = sessionStorage.getItem("token")
    const [detail, setDetail] = useState("")
    const [crewMembers, setCrewMembers] = useState([])
    const backgroundImage = `url(${methodModel.noImg(detail?.backgroundImage || "")})`
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "" })

    useEffect(() => {
        if (id) {
            getDetails()
            getCrewMembers()
        }
    }, [id])

    const getDetails = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setDetail(res?.data)
                if (res?.data?.isBoostUsed && res?.data?.boostCategory === "profile") {
                    getTrackingDetails(res?.data)
                }
            }
            loader(false)
        })
    }

    const getTrackingDetails = (data) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        let payload = {
            userId: data?._id || data?.id,
            boostType: data?.boostType,
            boostCategory: data?.boostCategory,
            corrdinates: {
                type: "Point",
                coordinates: [userLat || "", userLong || ""]
            },
            deviceToken: sessionStorage.getItem("deviceToken") || ""
        }
        if (token) {
            payload = { ...payload, visitedUserId: user?._id || user?.id }
        }
        ApiClient.post(`boost/track/analytics`,payload)
    }

    const getCrewMembers = () => {
        loader(true)
        ApiClient.get(`crew/listing?addedBy=${id}`).then(res => {
            if (res.success) {
                setCrewMembers(res?.data)
            }
            loader(false)
        })
    }

    const handleLikeFollowEvent = (type) => {
        const payload = {
            id: detail?.id || detail?._id,
            type: type,
            userId: user?._id || user?.id,
            addedTo: "crew",
        }
        loader(true)
        ApiClient.post(`event/${type}`, payload).then(res => {
            if (res.success) {
                getDetails()
            }
            loader(false)
        })
    }

    const getCollaborationStatus = () => {
        const colabData = detail?.collaboraterDetail
        const userId = user?._id || user?.id
        let value = ""
        colabData?.filter((item) => {
            if (item?.addedBy?._id === userId) {
                value = item?.request_status
            }
        })
        return value
    }
    const handleColab = () => {
        if (getCollaborationStatus()) {
            return
        } else {
            setForm({ description: "" })
            setIsOpen(true)
        }
    }
    const handleCollabration = (e) => {
        e.preventDefault()
        const payload = {
            venueId: id,
            description: form?.description,
        }
        loader(true)
        ApiClient.post(`host/collaborate`, payload).then(res => {
            if (res.success) {
                getDetails()
                setIsOpen(false)
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="">
                <NavigationBar />
                <div className="">
                    <div className="relative bg-cover bg-no-repeat w-full" style={{ backgroundImage: backgroundImage }}>
                        <div className="bg-[#00000026]">
                            <div className="container mx-auto px-5">
                                <div className="relative h-[170px] sm:min-h-[300px]">
                                    {detail?.youtube_link || detail?.instagram_link || detail?.facebook_link || detail?.twitter_link || detail?.linkedin_link ?
                                        <div className="absolute bottom-[20px] right-[20px]">
                                            <div className='flex flex-wrap gap-3'>
                                                {detail?.youtube_link && <FaYoutube onClick={() => { window.open(detail?.youtube_link, "_blank") }} className="text-[#005AAB] cursor-pointer hover:text-[#005AAB] text-[20px] w-[25px] h-[25px] z-10 bg-white p-[3px] rounded-full hover:shadow-lg" />}
                                                {detail?.instagram_link && <PiInstagramLogoFill onClick={() => { window.open(detail?.instagram_link, "_blank") }} className="text-[#005AAB] cursor-pointer hover:text-[#005AAB] text-[20px] w-[25px] h-[25px] z-10 bg-white p-[3px] rounded-full hover:shadow-lg" />}
                                                {detail?.facebook_link && <FaFacebook onClick={() => { window.open(detail?.facebook_link, "_blank") }} className="text-[#005AAB] cursor-pointer hover:text-[#005AAB] text-[20px] w-[25px] h-[25px] z-10 bg-white p-[3px] rounded-full hover:shadow-lg" />}
                                                {detail?.twitter_link && <FaXTwitter onClick={() => { window.open(detail?.twitter_link, "_blank") }} className="text-[#005AAB] cursor-pointer hover:text-[#005AAB] text-[20px] w-[25px] h-[25px] z-10 bg-white p-[3px] rounded-full hover:shadow-lg" />}
                                                {detail?.linkedin_link && <FaLinkedin onClick={() => { window.open(detail?.linkedin_link, "_blank") }} className="text-[#005AAB] cursor-pointer hover:text-[#005AAB] text-[20px] w-[25px] h-[25px] z-10 bg-white p-[3px] rounded-full hover:shadow-lg" />}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-5">
                        <div className="container mx-auto px-5">
                            <div className="relative top-[-55px] sm:top-[-75px]">
                                <div className="max-[639px]:flex-wrap flex w-full pb-5 border-b">
                                    <img src={methodModel.userImg(detail?.image)} className="bg-white w-[120px] sm:w-[180px] h-[120px] sm:h-[180px] border-[4px] border-white object-cover rounded-full" />
                                    <div className="max-[639px]:w-full w-[calc(100%_-_210px)] max-[639px]:ml-0 mt-3 sm:mt-[5.5rem] flex max-[639px]:flex-wrap items-end justify-between gap-5 ml-8">
                                        <div className="">
                                            <h2 className="text-[20px] font-[600]">{detail?.fullName || `${detail?.firstName || ""} ${detail?.lastName || ""}`}</h2>
                                            <p className="mb-1">{detail?.email}</p>
                                            <p className="flex flex-wrap gap-x-2 mb-1">
                                                <span className="text-[12px] font-[300]">{detail?.location || "--"},</span>
                                                <span className="text-[12px] font-[300]">{detail?.country || "--"},</span>
                                                <span className="text-[12px] font-[300]">{detail?.state || "--"},</span>
                                                <span className="text-[12px] font-[300]">{detail?.city || "--"},</span>
                                                <span className="text-[12px] font-[300]">{detail?.zipCode || "--"}</span>
                                            </p>
                                            <p className="text-[12px] font-[300]">{detail?.mobileNo ? `+${detail?.mobileNo}` : "--"}</p>
                                        </div>
                                        <div className="flex gap-4">
                                            {user?.role === "venue" || user?.role === "host" || user?.role === "influencer" || user?.role === "crew_member" ?
                                                <div className="">
                                                    <button type="button" onClick={e => handleLikeFollowEvent("follow")} className="text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-2 px-5 text-center cursor-pointer hover:opacity-[90%]">
                                                        {detail?.follow_data?.some((item) => item?.addedBy === user?._id) ? "Unfollow" : "Follow"}
                                                    </button>
                                                </div>
                                                : null}
                                            {user?.role === "venue" &&
                                                <div>
                                                    <button type="button" onClick={e => handleColab()} className={`text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-[6px] px-5 text-center ${getCollaborationStatus() ? "cursor-not-allowed" : "cursor-pointer hover:opacity-[90%]"}`}>{getCollaborationStatus() ? <span className="capitalize">Collaboration Status: {getCollaborationStatus()}</span> : "Collaborate with Entertainment Crew"}</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <TabGroup>
                                        <TabList className="flex border-b bg-white overflow-auto scroll_hide mb-5">
                                            <Tab key="CREW" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                CREW
                                            </Tab>
                                            <Tab key="ABOUT" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                ABOUT
                                            </Tab>
                                            <Tab key="GALLERY" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                GALLERY
                                            </Tab>
                                            <Tab key="REVIEWS" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                REVIEWS
                                            </Tab>
                                        </TabList>
                                        <TabPanels className="mt-3">
                                            <TabPanel key="CREW" className="rounded-xl bg-white/5">
                                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                                    {crewMembers?.map((item, index) => {
                                                        return <div onClick={e => { navigate(`/crewProfile/${item?._id || item?.id}`) }} className="cursor-pointer flex p-3 shadow-lg" key={index}>
                                                            <img src={methodModel.userImg(item?.image)} className="w-[50px] h-[50px] object-cover rounded-full" />
                                                            <div className="ml-2">
                                                                <h2 className="text-[16px] font-[500] truncate ...">{item?.fullName || `${item?.firstName || ""} ${item?.lastName || ""}`}</h2>
                                                                <p className="p_text font-[300] text-[12px]" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </TabPanel>
                                            <TabPanel key="ABOUT" className="rounded-xl bg-white/5">
                                                <p className="text-[#6d6d6d] font-[300] mb-8" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                            </TabPanel>
                                            <TabPanel key="GALLERY" className="rounded-xl bg-white/5">
                                                {detail?.images?.length > 0 ?
                                                    <div className="mb-10">
                                                        <h2 className="text-[#242424] text-[18px] font-[600] mb-3">Images</h2>
                                                        <div className="grid max-[499px]:grid-cols-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-5 mb-5">
                                                            {detail?.images?.map((item, index) => {
                                                                return <img src={methodModel.noImg(item)} key={index} className="max-[499px]:h-[115px]  h-[125px] sm:h-[130px] md:h-[110px] lg:h-[150px] xl:h-[200px] 2xl:h-[230px] w-full object-cover rounded-xl"></img>
                                                            })}
                                                        </div>
                                                    </div>
                                                    : null}
                                                {detail?.videos?.length > 0 ?
                                                    <div className="mb-10">
                                                        <h2 className="text-[#242424] text-[18px] font-[600] mb-3">Videos</h2>
                                                        <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                                                            {detail?.videos?.map((item, index) => {
                                                                return <video key={index} className="max-[499px]:h-[190px] h-[160px] sm:h-[120px] md:h-[110px] lg:h-[140px] xl:h-[180px] 2xl:h-[220px] w-full bg-black rounded-xl" controls>
                                                                    <source src={methodModel.video(item)} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            })}
                                                        </div>
                                                    </div>
                                                    : null}
                                            </TabPanel>
                                            <TabPanel key="REVIEWS" className="rounded-xl bg-white/5">
                                                <div>
                                                    <div className="flex items-center gap-2 mb-3">
                                                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Reviews</h2>
                                                        <div className="mt-2 flex items-center gap-2 sm:mt-0">
                                                            <div className="flex items-center gap-0.5">
                                                                <FaStar className="text-[#e6b547] text-[15px]" />
                                                                <FaStar className="text-[#e6b547] text-[15px]" />
                                                                <FaStar className="text-[#e6b547] text-[15px]" />
                                                                <FaStar className="text-[#e6b547] text-[15px]" />
                                                                <FaStar className="text-[#e6b547] text-[15px]" />
                                                            </div>
                                                            <div className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">(4.8)</div>
                                                        </div>
                                                    </div>
                                                    <div className="grid col-grid-1 md:grid-col-2 lg:grid-cols-2 gap-5">
                                                        <div className="bg-white border p-5">
                                                            <div>
                                                                <div className="flex justify-between gap-3 mb-3">
                                                                    <div className="flex items-center gap-0.5">
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                    </div>
                                                                </div>
                                                                <div className="space-y-0.5">
                                                                    <div className="flex gap-2 items-center mb-2">
                                                                        <img src="https://pagedone.io/asset/uploads/1704349572.png" className="h-[40px] w-[40px] object-cover rounded-full" />
                                                                        <div className="">
                                                                            <p className="text-base font-semibold text-gray-900 dark:text-white">Micheal Gough</p>
                                                                            <p className="text-[12px] font-normal text-gray-500 dark:text-gray-400">November 18, 2024</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0"><p className="text-[14px] font-normal text-gray-500 dark:text-gray-400">Amazing host, highly recommend!</p></div>
                                                        </div>
                                                        <div className="bg-white border p-5">
                                                            <div>
                                                                <div className="flex justify-between gap-3 mb-3">
                                                                    <div className="flex items-center gap-0.5">
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                    </div>
                                                                </div>
                                                                <div className="space-y-0.5">
                                                                    <div className="flex gap-2 items-center mb-2">
                                                                        <img src="https://pagedone.io/asset/uploads/1704349572.png" className="h-[40px] w-[40px] object-cover rounded-full" />
                                                                        <div className="">
                                                                            <p className="text-base font-semibold text-gray-900 dark:text-white">Micheal Gough</p>
                                                                            <p className="text-[12px] font-normal text-gray-500 dark:text-gray-400">November 18, 2024</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0"><p className="text-[14px] font-normal text-gray-500 dark:text-gray-400">Amazing host, highly recommend!</p></div>
                                                        </div>
                                                        <div className="bg-white border p-5">
                                                            <div>
                                                                <div className="flex justify-between gap-3 mb-3">
                                                                    <div className="flex items-center gap-0.5">
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                    </div>
                                                                </div>
                                                                <div className="space-y-0.5">
                                                                    <div className="flex gap-2 items-center mb-2">
                                                                        <img src="https://pagedone.io/asset/uploads/1704349572.png" className="h-[40px] w-[40px] object-cover rounded-full" />
                                                                        <div className="">
                                                                            <p className="text-base font-semibold text-gray-900 dark:text-white">Micheal Gough</p>
                                                                            <p className="text-[12px] font-normal text-gray-500 dark:text-gray-400">November 18, 2024</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0"><p className="text-[14px] font-normal text-gray-500 dark:text-gray-400">Amazing host, highly recommend!</p></div>
                                                        </div>
                                                        <div className="bg-white border p-5">
                                                            <div>
                                                                <div className="flex justify-between gap-3 mb-3">
                                                                    <div className="flex items-center gap-0.5">
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                        <FaStar className="text-[#e6b547] text-[15px]" />
                                                                    </div>
                                                                </div>
                                                                <div className="space-y-0.5">
                                                                    <div className="flex gap-2 items-center mb-2">
                                                                        <img src="https://pagedone.io/asset/uploads/1704349572.png" className="h-[40px] w-[40px] object-cover rounded-full" />
                                                                        <div className="">
                                                                            <p className="text-base font-semibold text-gray-900 dark:text-white">Micheal Gough</p>
                                                                            <p className="text-[12px] font-normal text-gray-500 dark:text-gray-400">November 18, 2024</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0"><p className="text-[14px] font-normal text-gray-500 dark:text-gray-400">Amazing host, highly recommend!</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </TabPanels>
                                    </TabGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Collaborate with Entertainment Crew</DialogTitle>
                            </div>
                            <form onSubmit={handleCollabration}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ProfilePage