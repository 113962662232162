import { useState } from "react"
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import PlanTransaction from "./PlanTransaction"
import TicketTransaction from "./TicketTransaction"
import { useSelector } from "react-redux"
import JobPostTransaction from "./JobPostTransaction"
import CommissionTransaction from "./CommissionTransaction"
import BoostingTransaction from "./BoostingTransaction"
import ReceiveAmountTransactions from "./ReceiveAmountTransactions"

const Transaction = () => {
    const user = useSelector((state) => state.user)
    const [tab, setTab] = useState()

    const handleTabChange = (e) => {
        setTab(e)
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 pb-5">
                    <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6">
                        <TabGroup selectedIndex={tab} onChange={e => handleTabChange(e)}>
                            <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                                <Tab key={"Plans"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Plans
                                </Tab>
                                {user?.role === "venue" || user?.role === "host" || user?.role === "crew" || user?.role === "food_truck_vendor" ?
                                    <Tab key={"Boosting"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Boosting
                                    </Tab>
                                    :
                                    null
                                }
                                {user?.role === "venue" ?
                                    <>
                                        <Tab key={"Tickets"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Tickets
                                        </Tab>
                                        <Tab key={"Job_Post"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Job Post
                                        </Tab>
                                    </>
                                    : null}
                                {user?.role === "influencer" &&
                                    <Tab key={"Commissions"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Commissions
                                    </Tab>
                                }
                                {user?.role === "venue" || user?.role === "host" || user?.role === "influencer" || user?.role === "crew" || user?.role === "vendor" || user?.role === "food_truck_vendor" ?
                                    <Tab key={"Receive_Amount"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Receive Amount
                                    </Tab>
                                    : null
                                }
                            </TabList>
                            <TabPanels className="mt-3">
                                <TabPanel key={"Plans"} className="rounded-xl bg-white/5">
                                    <PlanTransaction />
                                </TabPanel>
                                {user?.role === "venue" || user?.role === "host" || user?.role === "crew" || user?.role === "food_truck_vendor" ?
                                    <TabPanel key={"Boosting"} className="rounded-xl bg-white/5">
                                        <BoostingTransaction />
                                    </TabPanel>
                                    :
                                    null
                                }
                                {user?.role === "venue" ?
                                    <>
                                        <TabPanel key={"Tickets"} className="rounded-xl bg-white/5">
                                            <TicketTransaction />
                                        </TabPanel>
                                        <TabPanel key={"Job_Post"} className="rounded-xl bg-white/5">
                                            <JobPostTransaction />
                                        </TabPanel>
                                    </>
                                    : null}
                                {user?.role === "influencer" &&
                                    <TabPanel key={"Commissions"} className="rounded-xl bg-white/5">
                                        <CommissionTransaction />
                                    </TabPanel>
                                }
                                {user?.role === "venue" || user?.role === "host" || user?.role === "influencer" || user?.role === "crew" || user?.role === "vendor" || user?.role === "food_truck_vendor" ?
                                    <TabPanel key={"Receive_Amount"} className="rounded-xl bg-white/5">
                                        <ReceiveAmountTransactions />
                                    </TabPanel>
                                    : null}
                            </TabPanels>
                        </TabGroup>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transaction