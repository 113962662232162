import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const PopUp = ({
    popUp,
    setPopUp,
    redirectUrl
}) => {
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()

    const handleSave = () => {
        if (redirectUrl) {
            setPopUp((prev) => ({ ...prev, modal: false }))
            navigate(redirectUrl)
        } else {
            setPopUp((prev) => ({ ...prev, modal: false }))
        }
    }

    return (
        <>
            <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#0000006b]">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel transition className="w-full bg-[url('assets/images/flat.png')] bg-cover bg-no-repeat max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className='bg-[#ffffffc4] rounded-xl p-10 relative'>
                                <div className=''>
                                    {popUp?.title &&
                                        <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                            <DialogTitle as="h3" className="text-[20px] font-medium text-black capitalize">{popUp?.title}</DialogTitle>
                                        </div>
                                    }
                                    {popUp?.image ?
                                        <img src={popUp?.image} className='w-[90px] h-[90px] object-contain mx-auto my-5' />
                                        :
                                        <img src={user?.doc_status !== "rejected" ? "/assets/img/verify.png" : "/assets/img/reject.png"} className='w-[60px] h-[60px] object-contain mx-auto my-5' />
                                    }
                                    <div className='text-center'>
                                        <p className="text-center text-[#404040] max-w-[370px] mx-auto text-[16px]">{popUp?.message || ""}</p>
                                    </div>
                                    <div className="mt-[2rem] flex flex-wrap justify-center gap-4">
                                        {popUp?.cancelButton && <Button type="button" onClick={() => setPopUp((prev) => ({ ...prev, modal: false }))} className="px-6 py-2 w-[150px] mx-auto border bg-[#005AAB] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] hover:opacity-[90%]">{popUp?.cancelButton || "Cancel"}</Button>}
                                        <Button type="button" onClick={e => handleSave()} className="px-6 py-2 w-[150px] mx-auto bg-[#005AAB] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] hover:opacity-[90%]">{popUp?.buttonText || "Save"}</Button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PopUp