import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useNavigate } from "react-router-dom";
import methodModel from "../../methods/methods";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import FormControl from "../../common/FormControl";
import SelectDropdown from "../../common/SelectDropdown";
import PopUp from "../../common/PopUp";


const Invites = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 10, search: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [requestModal, setRequestModal] = useState({ modal: false, reason: "", id: "" })
    const [modal, setModal] = useState({ modal: false, message: "" })
    const [tab, setTab] = useState()

    const statusOptions = [
        { id: "accepted", name: "Accepted" },
        { id: "rejected", name: "Rejected" },
        { id: "pending", name: "Pending" },
    ]

    useEffect(() => {
        if (user?.role === "influencer" || user?.role === "crew") {
            setTab(1)
            getData({ type: "invitation" })
        } else {
            setTab(0)
            getData({ type: "invited" })
        }
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, type: tab === 0 ? "invited" : "invitation", ...p, id: user?._id || user?.id }
        loader(true)
        ApiClient.get("invitation/listing", filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "", request_status: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }

    const handlePagination = (e) => {
        setFilter((prev) => ({ ...prev, page: e }))
        getData({ page: e })
    }

    const handleRequest = (e, id, type) => {
        e.preventDefault()
        if(type === "accepted" && user?.activePlanId?.acceptBookingsTypePerMonth === "count" && user?.acceptBookingsPerMonth >= user?.activePlanId?.acceptBookingsPerMonth){
            setModal({ modal: true, message: "Your current plan limit has been reached. To accept more invitations, please consider upgrading your plan.", image: "/assets/img/waiting.png", buttonText: "Ok" })
            return
        }
        let payload = {
            id: id,
            status: type
        }
        if (type === "rejected") {
            payload = { ...payload, reason: requestModal?.reason }
        }
        loader(true)
        ApiClient.post(`invitation/accept/reject`, payload).then(res => {
            if (res.success) {
                setRequestModal({ modal: false, reason: "", id: "" })
                getData()
            }
            loader(false)
        })
    }

    const handleFilter = (e, key) => {
        setFilter({ ...filters, [key]: e });
        getData({ [key]: e });
    }

    const handleTab = (e) => {
        if(user?.role === "influencer" || user?.role === "crew") return
        setTab(e)
        getData({ type: e === 0 ? "invited" : "invitation" })
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 pb-5">
                    <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6 border border-[#f1f1f1] shadow-[0px_0px_15px_-12px] p-[1.5rem]">
                        <TabGroup selectedIndex={tab} onChange={e => handleTab(e)}>
                            <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                                {user?.role === "influencer" || user?.role === "crew" ? null :
                                    <Tab key={"Invited"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Event Invites
                                    </Tab>
                                }
                                <Tab key={"Invitation"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Event Invitations
                                </Tab>
                            </TabList>
                            <TabPanels className="mt-3">
                                <div className="flex justify-between flex-wrap items-center gap-5 mb-5 pb-5 border-b border-[#f1f1f1]">
                                    <div className="w-full flex items-center flex-wrap justify-between flex-wrap gap-2">
                                        <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                                            <div className="relative w-full">
                                                <input
                                                    type="text"
                                                    id="simple-search"
                                                    value={filters?.search}
                                                    onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                                    autoComplete="one-time-code"
                                                    className="text-gray-900 text-sm rounded-lg  focus:border-[#063688] block w-full ps-5 p-2.5 pr-10 border border-[#d1d1d1]"
                                                    placeholder="Search"
                                                />
                                                {filters?.search && (
                                                    <MdClear className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                                )}
                                                <button type="submit" className="absolute right-[3px] top-[2px] p-2.5 text-sm font-medium text-white bg-[#063688] rounded-lg border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    <svg
                                                        className="w-4 h-4"
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                        />
                                                    </svg>
                                                    <span className="sr-only">Search</span>
                                                </button>
                                            </div>
                                        </form>
                                        <div className="flex gap-2 ml-auto flex-wrap justify-end">
                                            <SelectDropdown
                                                id="statusDropdown"
                                                displayValue="name"
                                                placeholder="All Status"
                                                theme="search"
                                                isClearable={false}
                                                intialValue={filters?.request_status}
                                                result={(e) => { handleFilter(e.value, 'request_status') }}
                                                options={statusOptions}
                                            />
                                            {filters?.request_status &&
                                                <button onClick={() => clear()} className="bg-[#063688] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">Reset</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {user?.role === "influencer" || user?.role === "crew" ? null :
                                    <TabPanel key={"Invited"} className="rounded-xl bg-white/5">
                                        <div className="">
                                            {data?.length === 0 ?
                                                <div className="text-center py-10">No Data Found</div>
                                                :
                                                <div className="grid grid-cols-1">
                                                    {data?.map((item, index) => {
                                                        return <div className="border-b py-8" key={index}>
                                                            <div className="flex flex-wrap justify-between gap-5">
                                                                <div className="w-full">
                                                                    <div className="flex justify-between items-center">
                                                                        <div className="flex items-center gap-3">
                                                                            <img src={methodModel.userImg(item?.event_id?.images?.[0])} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full" />
                                                                            <div className="capitalize">
                                                                                <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.venue_name || item?.addedBy?.fullName}</p>
                                                                                <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.event_id?.title}</h2>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center gap-2">
                                                                            <span className="text-[14px] capitalize ">Request Status: <span className="font-normal"> <span className={`${item?.request_status === "rejected" ? "text-red-600" : item?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{item?.request_status}</span></span></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-1 text-[14px]">Event Start Date: <span className="text-[#005AAB]">{moment(item?.event_id?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                                                    <div className="mb-2 text-[14px]">Event End Date: <span className="text-[#005AAB]">{moment(item?.event_id?.eventEndDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                                                </div>
                                                                {/* <div className="flex items-center gap-4">
                                                                {item?.request_status === "pending" && user?.role === "host" ?
                                                                    <>
                                                                        <button type="button" onClick={e => handleRequest(e, item?._id || item?.id, "accepted")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                        <button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: true, reason: "", id: item?._id || item?.id }))} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                    </>
                                                                    :
                                                                    <span className="text-[14px] capitalize font-semibold">Request Status: <span className="font-normal"> <span className={`${item?.request_status === "rejected" ? "text-red-600" : item?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{item?.request_status}</span></span>
                                                                    </span>
                                                                }
                                                            </div> */}
                                                            </div>
                                                            <div className="mt-3">
                                                                <div className={`text-[14px] text_3line w-full`} dangerouslySetInnerHTML={{ __html: item?.event_id?.description }}></div>
                                                                <div onClick={() => { navigate(`/EventDetails/${item?.event_id?._id || item?.event_id?.id}/${item?._id || item?.id}`) }} className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto">See More</div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </TabPanel>
                                }
                                <TabPanel key={"Invitation"} className="rounded-xl bg-white/5">
                                    <div className="">
                                        {data?.length === 0 ?
                                            <div className="text-center py-10">No Data Found</div>
                                            :
                                            <div className="grid grid-cols-1">
                                                {data?.map((item, index) => {
                                                    return <div className="border-b py-8" key={index}>
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <div className="w-full">
                                                                <div className="flex flex-wrap gap-y-3 gap-x-5 justify-between items-center">
                                                                    <div className="flex items-center gap-3">
                                                                        <img src={methodModel.userImg(item?.event_id?.images?.[0])} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full" />
                                                                        <div className="capitalize">
                                                                            <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.venue_name || item?.addedBy?.fullName}</p>
                                                                            <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.event_id?.title}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center gap-2">
                                                                        {item?.request_status === "pending" ?
                                                                            <>
                                                                                <button type="button" onClick={e => handleRequest(e, item?._id || item?.id, "accepted")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                                <button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: true, reason: "", id: item?._id || item?.id }))} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                            </>
                                                                            :
                                                                            <span className="text-[14px] capitalize ">Request Status: <span className="font-normal"> <span className={`${item?.request_status === "rejected" ? "text-red-600" : item?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{item?.request_status}</span></span>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {item?.invitedBy?.role === "venue" ?
                                                                    <div className="mt-2 mb-1 text-[14px]">Venue: <span className="text-[#005AAB]">{item?.invitedBy?.venue_name || "--"}</span></div>
                                                                    :
                                                                    <div className="mt-2 mb-1 text-[14px]">Host: <span className="text-[#005AAB]">{item?.invitedBy?.fullName || `${item?.invitedBy?.firstName} ${item?.invitedBy?.lastName}`}</span></div>
                                                                }
                                                                <div className="mt-1 text-[14px]">Event Start Date: <span className="text-[#005AAB]">{moment(item?.event_id?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                                                <div className="mb-2 text-[14px]">Event End Date: <span className="text-[#005AAB]">{moment(item?.event_id?.eventEndDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                                            </div>
                                                            {/* <div className="flex items-center gap-4">
                                                                {item?.request_status === "pending" && user?.role === "host" ?
                                                                    <>
                                                                        <button type="button" onClick={e => handleRequest(e, item?._id || item?.id, "accepted")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                                        <button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: true, reason: "", id: item?._id || item?.id }))} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                                                    </>
                                                                    :
                                                                    <span className="text-[14px] capitalize font-semibold">Request Status: <span className="font-normal"> <span className={`${item?.request_status === "rejected" ? "text-red-600" : item?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{item?.request_status}</span></span>
                                                                    </span>
                                                                }
                                                            </div> */}
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className={`text-[14px] text_3line w-full`} dangerouslySetInnerHTML={{ __html: item?.event_id?.description }}></div>
                                                            <div onClick={() => { navigate(`/EventDetails/${item?.event_id?._id || item?.event_id?.id}/${item?._id || item?.id}`) }} className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto">See More</div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        }
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                        {total > filters?.count ?
                            <div className="pagination_div w-full text-right">
                                <Pagination
                                    activePage={filters?.page}
                                    itemsCountPerPage={filters?.count}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                                    activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                                    onChange={e => handlePagination(e)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <Dialog open={requestModal?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setRequestModal((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-black">Add Reason</DialogTitle>
                            </div>
                            <form onSubmit={e => handleRequest(e, requestModal?.id, "rejected")}>
                                <div>
                                    <FormControl
                                        type="textarea"
                                        name="rejectReason"
                                        label="Reason"
                                        placeholder="Enter Reason"
                                        autoComplete="one-time-code"
                                        value={requestModal?.reason}
                                        onChange={(e) => setRequestModal((prev) => ({ ...prev, reason: e }))}
                                        required
                                    />
                                </div>
                                <div className="mb-3 mt-8 flex flex-wrap justify-center gap-5">
                                    <Button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: false }))} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                    <Button type="submit" className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Reject</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <PopUp popUp={modal} setPopUp={setModal} />
        </>
    )
}

export default Invites