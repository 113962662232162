import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";

const BoostingLocationCharts = ({ filter }) => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getData();
  }, [filter]);

  const getData = () => {
    ApiClient.get(`boost/analytics/listing?userId=${user?._id || user?.id}&boostCategory=${filter?.categoryType}&boostType=${filter?.boostType}&startDate=${filter?.startDate}&endDate=${filter?.endDate}`).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
    });
  };

  const formattedStateData = [
    ["State", "Hike"],
    ...data.map((item) => {
      const [state] = item._id.split(" ");
      return [state, item.totalUsers];
    }),
  ];

  const formattedCountryData = [
    ["Country", "Hike"],
    ...data.map((item) => {
      return [item.country, item.totalUsers];
    }),
  ];

  return (
    <>
      <div className="col-span-12 bg-white shadow-lg rounded-lg">
        <div className="flex gap-x-5 justify-between border-b border-gray-200 min-h-[53px] px-5 py-3 ">
          <div className="font-semibold text-[#005fa7]">Top Boosting States</div>
        </div>
        <div className="py-3 px-5 flex-grow">
          <div className="chatr_ones">
            <div>
              <Chart
                chartEvents={[
                  {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;
                    },
                  },
                ]}
                chartType="GeoChart"
                width="100%"
                height="600px"
                data={formattedStateData}
                options={{
                  region: "US",
                  displayMode: "regions",
                  resolution: "provinces",
                  colorAxis: { colors: ["#e0f7fa", "#004d92"] },
                  tooltip: { isHtml: true },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 bg-white shadow-lg rounded-lg">
        <div className="flex gap-x-5 justify-between border-b border-gray-200 min-h-[53px] px-5 py-3 ">
          <div className="font-semibold text-[#005fa7]">Top 10 Boosting Locations</div>
        </div>
        <div className="py-3 px-5 flex-grow">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="whitespace-nowrap bg-gray-100 rounded">
                <tr>
                  <th className="p-4 text-left text-sm font-semibold text-black">
                    Location
                  </th>
                  <th className="p-4 text-left text-sm font-semibold text-black">
                    Hike
                  </th>
                </tr>
              </thead>
              <tbody className="whitespace-nowrap">
                {data?.map((item, index) => {
                  return <tr className="hover:bg-gray-50" key={index}>
                    <td className="p-4 text-sm text-black">
                      <p className="text-xs text-gray-500 mt-1">{item?._id}</p>
                    </td>
                    <td className="p-4 text-sm text-black">
                      <p className="text-xs text-gray-500 mt-1">{item?.totalUsers}</p>
                    </td>
                  </tr>
                })}
                {data?.length === 0 &&
                  <tr className="hover:bg-gray-50">
                    <td colSpan={2} className="p-4 text-sm text-black">
                      <p className="text-xs text-gray-500 mt-1 text-center">Data Not Found</p>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostingLocationCharts;