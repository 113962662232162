import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../Footer";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { NavigationBar } from "../Landing/NavigationBar";

const GetHelp = () => {
    return (
        <>
            <div className="bg-white w-full h-screen">
                <NavigationBar />
                <div>
                    <section className="bg-[url('assets/images/faq_bg.png')] bg-fixed bg-cover bg-center bg-no-repeat w-full">
                        <div className="bg-[#ffffffe3] py-10">
                            <div className="container max-w-[1200px] mx-auto px-5">
                                {/* <div className="max-w-[1200px]w-full justify-start xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
                                <div className="">
                                    <div className="">
                                        <div className="mb-6 lg:mb-10">
                                            <h6 className="text-lg text-center font-medium text-[#005AAB] mb-2 lg:text-left">FAQS</h6>
                                            <h3 className="text-3xl sm:text-[52px] leading-[65px] font-extrabold tracking-tight text-gray-900 mb-2">
                                                Frequently <br />Asked <span className="text-[#005AAB]">Questions</span>
                                            </h3>
                                            <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                                has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                                                unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-5 rounded-xl">
                                            <Disclosure as="div" className="p-4 border rounded-lg transition-all">
                                                <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                    <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                                                        What is your refund policy?
                                                    </span>
                                                    <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                </DisclosureButton>
                                                <DisclosurePanel className="mt-1 text-sm/5 text-black/50 ease-in-out">
                                                    If you're unhappy with your purchase, we'll refund you in full.
                                                </DisclosurePanel>
                                            </Disclosure>
                                            <Disclosure as="div" className="p-4 border rounded-lg transition-all">
                                                <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                    <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                                                        Do you offer technical support?
                                                    </span>
                                                    <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                </DisclosureButton>
                                                <DisclosurePanel className="mt-1 text-sm/5 text-black/50 ease-in-out">No.</DisclosurePanel>
                                            </Disclosure>
                                            <Disclosure as="div" className="p-4 border rounded-lg transition-all">
                                                <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                    <span className="text-sm/6 font-medium text-black group-data-[hover]:text-black/80">
                                                        What is your refund policy?
                                                    </span>
                                                    <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                </DisclosureButton>
                                                <DisclosurePanel className="mt-1 text-sm/5 text-black/50 ease-in-out">
                                                    If you're unhappy with your purchase, we'll refund you in full.
                                                </DisclosurePanel>
                                            </Disclosure>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:justify-start justify-center items-start flex">
                                    <div className="w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                                        <img className="sm:mt-5 sm:ml-5 w-full h-full rounded-3xl object-cover" src="/assets/img/faq.jpg" alt="about Us image" />
                                    </div>
                                </div>
                            </div> */}

                                <h3 className="text-[35px] sm:text-[42px] leading-[48px] sm:leading-[55px] font-extrabold tracking-tight text-gray-900 mb-[2rem]">
                                    Frequently <br />Asked <span className="text-[#005AAB]">Questions</span>
                                </h3>

                                <div className="">
                                    <div className="grid grid-cols-12 gap-5">
                                        <div className="col-span-12">
                                            <TabGroup>
                                                <TabList className="scroll_class flex overflow-auto gap-2 pb-2">
                                                    <Tab
                                                        key={"tab_1"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Entertainment Crew"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_2"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Ticketing-Specific"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_3"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Food Truck Vendor"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_4"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Host"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_5"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Influencer"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_6"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Vendor"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_7"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Venue"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_8"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Booking and Cancellations"}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_9"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Tickets & Access "}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_10"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Ticketing Platform "}
                                                    </Tab>
                                                    <Tab
                                                        key={"tab_11"}
                                                        className="text-[13px] font-[500] border border-[#005AAB] text-[#005AAB] rounded-md bg-white w-fit px-3 py-1 cursor-pointer focus:outline-none data-[selected]:bg-[#005AAB] data-[selected]:text-white data-[hover]:bg-[#005AAB] data-[selected]:data-[hover]:bg-[#005AAB] hover:text-white whitespace-nowrap"
                                                    >
                                                        {"Boost Your Engagement "}
                                                    </Tab>
                                                </TabList>
                                                <TabPanels className="mt-5">
                                                    <TabPanel key={"tab_1"} className="">
                                                        <div className="">
                                                            <div className="mb-3">
                                                                <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Entertainment Crew FAQ</h2>
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Entertainment Crew Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">A tiered plan designed for managing bookings, showcasing talent, and collaborating with venues and other performers.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>


                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the main differences between the plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter: Basic tools for smaller crews.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro: Advanced features for growing teams.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite: Unlimited tools and premium access for professionals.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <span className="">
                                                                            <h3 className="font-[600] mb-2"><span className="">3.</span>How is revenue distributed?</h3>
                                                                        </span>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Marc collects revenue, deducts applicable fees, and distributes the net amount to:
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">The crew leader’s account.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Individual members via the Marc dashboard.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>What are the main differences between the plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, subscription changes apply to the next billing cycle.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Are there extra fees for ticket processing?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, Stripe charges the following processing fees:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">2.9% of the total transaction amount.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">$0.30 per ticket sold.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_2"} className="">
                                                        <div className="">
                                                            <div className="mb-3">
                                                                <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Ticketing-Specific FAQ</h2>
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>How can entertainment crews sell tickets for performances?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Entertainment crews can sell tickets directly through Marc, with tickets marketed via:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">The crew’s public-facing profile.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Linked events on venue pages, allowing attendees to find and purchase tickets easily.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>


                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>How is ticket revenue collected and distributed?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Gross ticket revenue is collected by Marc.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">After deducting applicable fees, net revenue is distributed to:
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">The crew leader’s account.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Individual members, if revenue-splitting is set up in Marc’s dashboard.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>What are the fees deducted from ticket revenue?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Marc deducts both platform fees and Stripe processing fees:</p>
                                                                        <h3 className="font-[600] mb-2">Platform Fees by Plan:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter Plan: 4% platform fee.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro Plan: 2% platform fee.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite Plan: No platform fee (Stripe fees still apply).</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Stripe Fees:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">2.9% of the total transaction amount.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">$0.30 per ticket sold.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Can you provide an example of revenue distribution?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <h3 className="font-[600] mb-2">Example:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Event: Live Jazz Performance.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Tickets Sold: 100 tickets @ $20 each = $2,000 gross revenue.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Fees (Starter Plan):</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fee: 4% of $2,000 = $80.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Stripe Fees: 2.9% + $0.30 per ticket = $87.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Net Revenue:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">$2,000 - $80 (platform fee) - $87 (Stripe fees) = $1,833 net revenue.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">The crew can split the $1,833 among members using Marc’s dashboard.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>How do crews manage revenue splits?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Revenue-splitting is managed via Marc’s dashboard:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Assign specific percentages or amounts to individual members.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Automate the distribution of earnings for each event.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>



                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel key={"tab_3"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Food Truck Vendor FAQ</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Food Truck Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">A tiered subscription model providing tools for food trucks to enhance visibility, schedule locations, and analyze performance.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the differences between the Basic, Pro, and Elite Plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Basic:</b> Daily schedule updates and essential visibility tools.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro:</b> Weekly/monthly scheduling and collaboration with venues.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite:</b> Advanced analytics, geo-targeted notifications, and premium visibility.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How does Boosted Visibility work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Boosted Visibility ensures your food truck appears at the top of search results and in curated sections like “Top Trucks This Week.”</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>What analytics are available to food trucks?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Basic:</b> Profile views, follows, and tagged location interactions.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro:</b> Expanded scheduling and audience engagement insights.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite:</b> Advanced trends analysis, peak hours, and most popular menu items.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Can I sell tickets through Marc?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes. You can sell tickets for events or pre-sold meal packages. Platform fees and Stripe fees apply, depending on your subscription plan.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>Are there additional fees for boosting or promotions?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Basic:</b> Boosted visibility is $25 per day</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro:</b> Boosted visibility is $15 per day.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite:</b> Includes 5 boosts per month, with additional boosts at $10 each.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>How are food truck bookings managed?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Flat Fee Bookings:</b> Food trucks set a flat rate, and payment is processed upon confirmation.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Commission-Based Bookings:</b> Food trucks and venues agree on a percentage commission, with revenue tracked and distributed by Marc.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>
                                                    </TabPanel>

                                                    <TabPanel key={"tab_4"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Host FAQ</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Host Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">A tiered plan providing tools for bookings, collaborations, and visibility. Includes analytics,
                                                                                messaging, and revenue distribution tailored to hosts' needs.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the differences between Starter, Pro, and Elite Plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter: For new hosts with limited gigs and messaging capabilities.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro: Offers more gigs, enhanced visibility, and analytics.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite: Unlimited gigs, advanced analytics, and premium collaboration tools.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How does revenue distribution work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <h3 className="font-[600] mb-2">Ticket Sales Process:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Hosts create events with pricing, ticket types, and venue tags on Marc’s platform.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Explorers purchase tickets directly on the event page via Marc’s payment gateway.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Revenue Collection:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Marc collects ticket revenue as the intermediary.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Fee Deductions:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fees (based on plan):
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Starter: 4%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Pro: 2%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Elite: None (up to ticket cap)</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Payment Processing Fees: 2.9% + $0.30 per transaction.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Revenue Distribution:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Hosts receive the remaining revenue directly via Marc wallet or bank account.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Venues receive their share if applicable.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Are there examples of how fees and profits are calculated?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Example for the Starter Plan:</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Ticket Sales: 100 tickets @ $20 = $2,000.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fee: $80 (4% of $2,000).</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Processing Fees: ~$74.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Net Revenue: $1,846.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Example for Fixed Venue Rental Fees:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Venue Rental Fee: $1,000.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Ticket Sales Revenue: $2,500.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fees:
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venue: $20 (2%).</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Host: $100 (4%).</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Processing Fees: ~$84.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Host’s Net Profit: $1,316.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Are there additional fees for payment processing?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Yes, Stripe charges 2.9% + $0.30 per transaction.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>Can I upgrade or downgrade my subscription?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Yes, changes can be made at any time and will apply in the next billing cycle.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>What tools do hosts receive?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Booking tools, collaboration features, analytics, and visibility boosts.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>How does collaboration work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro and Elite hosts can post opportunities or invite collaborators directly via the dashboard.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">9.</span>Can hosts rent venues through Marc?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Yes, hosts can book venues directly, and fees are processed seamlessly.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel key={"tab_5"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Influencer FAQ</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Influencer Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">What is the Influencer Subscription Plan?</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>How does Marc calculate follower payments?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Influencers are paid a fixed amount per new follower gained through the platform, capped based on the plan tier.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How is revenue distributed?What are the ticket commission rates?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Commissions vary by plan:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter: 5%.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro: 8%.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite: 10%.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Can I upgrade or downgrade my subscription?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, subscription changes apply to the next billing cycle.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Are there fees for ticketing?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter: 4% platform fee after 100 tickets.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro: 2% platform fee after 300 tickets.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite: No platform fees; ticketing fees absorbed by Marc.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>What tools do I receive as an influencer?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Access to ticket tracking, follower growth insights, analytics, and collaboration tools to grow your presence and monetize your events.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>

                                                    <TabPanel key={"tab_6"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Vendor FAQ</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Vendor Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">A tiered subscription plan providing tools for vendors to showcase services, manage bookings, and connect with venues hosts, and more.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the differences between the Starter, Pro, and Elite Plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Starter:</b> Basic tools for vendors with limited bookings and messaging, and visibility.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro:</b> Unlimited service listings, enhanced visibility, and targeted promotions.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite:</b> Premium placement, advanced analytics, and top-tier visibility.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How does Premium Placement work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Vendors with Premium Placement appear in curated searches and are prioritized in relevant categories, boosting exposure and bookings.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>What analytics are available to vendors?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Flat Fee Bookings</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors set a flat fee for their services (e.g., $500 per event).</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Payments are processed through Marc’s platform once the booking is confirmed.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Commission-Based Bookings (Pro and Elite Plans):</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors and venues agree on commission terms (e.g., 10%) for vendor on-site sales.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">These terms are determined by the vendor and agreed upon by the venue during the booking process.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Payments are calculated based on tracked sales, with Marc deducting applicable platform and Stripe fees.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Disbursement:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors retain the majority of their on-site sales revenue.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venues are paid their agreed commission, minus platform and Stripe processing fees.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Net payments are disbursed to both vendors and venues via Marc’s dashboard.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>How do commission arrangements work in the Pro and Elite Plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Set Commission Terms:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors define the percentage commission (e.g., 10%) they are willing to pay venues for hosting their on-site sales.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">These terms are outlined during the booking process and must be agreed upon by the venue.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Revenue Tracking:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Marc tracks sales generated from the event (e.g., on-site sales from a vendor's product or service).</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors report sales via the dashboard or use automated tracking if available.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Payment Distribution:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors retain their agreed portion of the revenue.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venue commission is calculated based on total tracked sales.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Marc deducts platform and Stripe fees before disbursing payments to both parties.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Payment Distribution:</b></li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Scenario: A swimsuit vendor operates at a day club event and agrees to pay the venue a 10% commission on their on-site sales.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Total Sales:</b>$1,000.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Venue Commission:</b> 10%×$1,000=$100</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Platform Fee (Pro Plan):</b>7.5%×$100= $7.50</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Stripe Fees:</b>2.9%×$1,000+(50×0.30)=$29+$15=$44</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Revenue Distribution:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Vendor Retains:</b>$1,000−$100−$44= $856</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Venue Earns (Net):</b>100−$7.50= $92.50</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>What analytics are available to vendors?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Starter:</b> View total bookings and revenue.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro:</b> Insights into booking trends and audience engagement.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite:</b> Exportable data, top venues, and booking performance metrics.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>Can vendors upgrade or downgrade their plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, subscription changes apply to the next billing cycle.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>Are there additional fees for promotions or bookings?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"> Vendors can create targeted promotions for an additional cost.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Standard platform fees (2.9% + $0.30 Stripe processing) apply to bookings.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Platform Fees for Vendor Bookings:</h3>
                                                                        <h3 className="font-[600] mb-2">What are the platform fees for vendor bookings?</h3>
                                                                        <p className="text-[#343434] text-[14px] mb-2">Platform fees vary depending on the vendor's subscription plan and are applied to both flat fee and commission-based bookings.</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Starter Plan:</b> 10% platform fee on total booking revenue.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro Plan:</b> 7.5% platform fee on total booking revenue.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite Plan:</b> 5% platform fee on total booking revenue.</li>
                                                                        </ul>
                                                                        <h3 className="font-[600] mb-2">Stripe Fees:</h3>
                                                                        <p className="text-[#343434] text-[14px] mb-2">In addition to platform fees, Stripe charges a standard processing fee:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>2.9% </b> of the total transaction amount.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>$0.30</b> per transaction.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_7"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Venue FAQ</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is the Venue Subscription Plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">The Venue Subscription Plan is a tiered membership model that provides venues with tools to manage events, attract explorers, and increase bookings.
                                                                            It offers features like analytics, visibility boosts, and messaging tools tailored to the needs of venues.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the key differences between the Starter, Pro, and Elite Plans?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter Plan: Best for smaller venues managing up to 3 active events. Offers basic tools like limited messaging, event listings, and analytics.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro Plan: Ideal for mid-sized venues with features such as unlimited event listings, enhanced visibility, and advanced analytics.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite Plan: Designed for large venues needing maximum exposure, premium placements, and advanced collaboration tools.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How is revenue distributed?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter Plan: 4% platform fee. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro Plan: 2% platform fee. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite Plan: No platform fee for the first 1,000 tickets per month. Tickets beyond this threshold are subject to a sliding fee structure. </li>
                                                                        </ul>

                                                                        <h3 className="font-[600] mb-2">Example for Elite Plan:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">First 1,000 tickets: No platform fees.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Next 1,000 tickets: 2% fee.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Additional tickets: 3% fee.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Are there additional fees for payment processing?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, payment gateway Stripe charges processing fees of 2.9% + $0.30 per transaction. These fees are separate from Marc’s platform fees.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Can I upgrade or downgrade my subscription plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, you can adjust your subscription tier at any time. Changes will apply in the next billing cycle.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>How does Marc handle ticket revenue distribution?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Marc collects revenue from ticket sales, deducts applicable platform and processing fees, and distributes the remaining amount.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Both venues and hosts receive detailed breakdowns through their dashboards.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>What additional tools does the platform provide?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Calendar Integration: Streamlined scheduling.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Messaging System: Collaborate with hosts, vendors, and entertainment crews.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Analytics: Insights into bookings, demographics, and event performance.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Visibility Boosts: Inclusion in top venue lists and priority search placement.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>What is the booking process for venues?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Venues on Marc can accept bookings from hosts, entertainment crews, and vendors. The subscription plan determines the booking limits:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Starter Plan: 5 bookings/month.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Pro Plan: 10 bookings/month.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Elite Plan: Unlimited bookings. Venues manage bookings through an integrated dashboard with notifications, calendar views, and approval workflows.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">9.</span>How does revenue sharing work for ticketed events?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Revenue sharing is based on host-venue agreements, which specify a percentage of ticket sales that goes to the venue. Marc facilitates seamless fund transfers, showing detailed breakdowns on dashboards for transparency.</p>
                                                                        <h3 className="font-[600] mb-2">Example:</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Ticket Price: $25.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Total Tickets Sold: 200.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Revenue Share (Venue): 10%.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Venue Share: $500.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">10.</span>Can venues request a flat minimum guarantee or tiered revenue sharing?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, venues have flexible revenue-sharing options:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Flat Minimum Guarantee + Revenue Share: Venues can request a guaranteed fee in addition to a percentage of ticket sales.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Example: $250 flat fee + 5% of ticket sales.</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Tiered Revenue Sharing: Venues earn higher percentages for events exceeding a ticket sales threshold.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Example: 10% for sales under $10,000, 15% for sales over $10,000.</li>
                                                                                </ul>
                                                                            </li>
                                                                            <p className="text-[#343434] text-[14px] mb-2">These options provide flexibility and allow venues to maximize their earnings based on event size and performance.</p>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">11.</span>Can venues charge a flat rental fee?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, venues can list fixed rental fees for events. Hosts can book directly through Marc, and payments are processed seamlessly via the platform. Example:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Rental Fee: $500.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fee: $20 (4% for Starter Plan).</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Total Paid by Host: $520.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">12.</span>How can venues work with entertainers or vendors?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Venues can book entertainers or vendors for events through Marc:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Booking Flow: Specify details (e.g., event date, duration, requirements), and Marc handles payment processing.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Example: A DJ charges $1,500. Venue pays $1,560 (4% fee included), and the DJ receives $1,500.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">13.</span>Are non-ticketed events supported?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Yes, venues can host free events to attract patrons for other revenue streams (e.g., food and beverages). Venues still benefit from streamlined booking, messaging tools, and analytics.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">14.</span>Can venues feature specific events and amenities (Pro Plan)?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Venues can book entertainers or vendors for events through Marc:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Yes, venues using the Pro Plan or higher can highlight featured events and unique amenities to boost visibility and attract more explorers.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Featured Events:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Events appear at the top of the venue profile with a "Featured" badge.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Enhanced visuals like banners or larger thumbnails to catch explorer interest.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Example: A rooftop bar promotes its "Saturday Night DJ Series" with a custom banner.</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Venue Amenities:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Highlight amenities such as outdoor seating, VIP areas, audio-visual capabilities, and catering.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Amenities are tagged and searchable to align with explorer preferences.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Example: A brewery emphasizes its pet-friendly patio and live music stage.</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Integration with Pro Plan:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Includes up to 3 featured events per month at no extra cost.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Additional slots can be purchased for $25/event/month.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Featured events receive priority placement in explorer search results.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_8"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Booking and Cancellations FAQs </h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>How do I book an event on Marc?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Booking an event is simple! Browse events on Marc and select the one you’re interested
                                                                            in. Choose your ticket type, complete the purchase, and your tickets will be available instantly in the "My Tickets" section
                                                                            of your Marc account. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>Can I cancel my booking?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Tickets are non-refundable unless otherwise specified by the event organizer. Be sure
                                                                            to review the event’s cancellation policy before completing your purchase.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>What happens if an event is canceled?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">If an event is canceled, you’ll receive a notification from Marc, and the refund
                                                                            process will begin automatically. Refunds will typically be issued to the original payment method, but details may vary
                                                                            depending on the event organizer. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Can I transfer my ticket to someone else?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes! All events on Marc allow ticket transfers. To transfer your ticket, go
                                                                            to "My Tickets" in your Marc account, select the ticket, and enter the recipient’s email address. The ticket will
                                                                            be sent to them instantly. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-5">


                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>Can I reschedule my booking for another date? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Rescheduling is dependent on the event organizer. If an event offers
                                                                            multiple dates and allows changes, you’ll see the option in the "My Tickets" section of your Marc account.
                                                                            Select the ticket and choose a new date if available.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>What if I can’t attend an event but don’t want to lose my ticket?  </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">If you can’t attend, you have the option to transfer your ticket to someone else or
                                                                            reach out to the event organizer for other solutions, such as event credit, if applicable.   </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>What if I experience issues during booking? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">If you encounter any problems while booking an event, please reach out to Marc
                                                                            support through the "Help" section of your account. Our team is here to assist you and ensure your booking is completed successfully. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>Can I buy tickets for someone else? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes! When purchasing tickets, simply provide the recipient’s email address during checkout.
                                                                            The tickets will be sent directly to their email, and you’ll still have a receipt in your Marc account.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">9.</span>Are ticket prices final, or can fees change? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Ticket prices are set by the event organizer, and any additional service or processing fees
                                                                            will be displayed at checkout. Be sure to review the total price before completing your purchase.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">10.</span>How do I know if my ticket purchase is confirmed? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">After completing your purchase, you’ll receive a confirmation email with all event details.
                                                                            Your tickets will also appear in the "My Tickets" section of your Marc account.   </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">11.</span> What if I lose my tickets? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Don’t worry—your tickets are always accessible in the "My Tickets" section of your Marc account.
                                                                            If you have trouble accessing them, contact Marc support for help.   </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">12.</span> Can I cancel a ticket transfer once it’s completed? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">No, once a ticket transfer is completed, it cannot be reversed. Be sure to confirm the recipient’s
                                                                            information before transferring your ticket.   </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_9"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">FAQ: Tickets & Access on Marc</h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What are the ticketing fees for subscribers?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Ticketing fees include a platform fee and a payment processing fee: </p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Platform Fee:</b> The percentage charged varies depending on your subscription plan and user type. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Payment Processing Fee:</b> A standard rate of 2.9% + $0.30 per transaction is applied by third-party
                                                                                payment processors.  </li>
                                                                        </ul>
                                                                        <p className="text-[#343434] text-[14px] mb-2">Ticketing fees include a platform fee and a payment processing fee: </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>How do explorers purchase tickets? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Explorers can purchase tickets without creating an account. For signed-up members, Marc simplifies the process with
                                                                            options to buy multiple tickets in one transaction. Tickets are sent via QR codes to their email. Members can also purchase tickets and email them to others,
                                                                            allowing individuals to access their own tickets.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>Do I need to create an account to purchase tickets?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">No, you can purchase tickets without creating an account. However, members benefit from additional features
                                                                            like streamlined transactions and the ability to distribute tickets to others.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>Do I need to print my ticket?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">No, Marc supports digital tickets. Simply present the QR code on your smartphone at the event. If you
                                                                            prefer, you can print your ticket. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>What is Marc’s refund policy?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Refunds depend on the event organizer’s policy. If refunds are allowed, you can request one through
                                                                            your Marc account. Organizers typically process refunds for cancellations or under specific conditions mentioned in the event details.
                                                                            Refunds are not guaranteed for non-refundable events.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>What is Marc’s cancellation policy?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">If an event is canceled, ticket holders will be notified promptly, and refunds will be issued
                                                                            automatically unless the event organizer specifies otherwise. For rescheduled events, your ticket will remain valid for the new date.
                                                                            If you cannot attend the rescheduled event, reach out to our support team to explore refund options.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>Can I transfer my ticket to someone else?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes! All events on Marc allow ticket transfers. Simply log into your Marc account, go to
                                                                            "My Tickets," select the ticket you’d like to transfer, and enter the recipient’s email address. The ticket will be sent to
                                                                            them instantly, making it easy to share tickets with friends or family.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>What happens if I lose access to my ticket? </h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">

                                                                        <p className="text-[#343434] text-[14px] mb-2">Tickets are stored securely in your Marc account. If you lose access, log in to
                                                                            "My Tickets" to retrieve them. For additional help, contact our support team through the "Get Help" section. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_10"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Ticketing Platform Fee Options for Marc Subscribers</h2>
                                                            <p className="text-[#343434] text-[14px] mb-2">Marc provides flexibility in how platform fees are handled for ticketed events. Whether you’re a Host, Venue, Vendor, Food Truck, Entertainment Crew, or Influencer,
                                                                this FAQ will explain how platform fees work and your options for managing them.</p>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What are platform fees?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Platform fees are a percentage of the ticket price charged by Marc for providing services such as booking management, visibility tools,
                                                                            and analytics. These fees vary by subscription plan:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Starter Plan: </b> Highest platform fees, designed for those starting out.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro Plan:</b> Reduced platform fees for more advanced users.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite Plan:</b> No platform fees on most transactions (limits may apply).</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>What are the options for handling platform fees?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Subscribers have two options when creating ticketed events on Marc:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">
                                                                                1. <b>Built-In Fees</b> (default for all tiers):
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Platform fees are <b>absorbed</b> by the subscriber and deducted from the ticket revenue during payout.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customers see a single ticket price with no additional platform fees added.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">
                                                                                2. <b>Add-On Fees:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Platform fees are <b>listed separately</b> at checkout and paid by the customer.</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customers see a breakdown of the ticket price, platform fees, and any applicable taxes.</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>

                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How does each option impact pricing and payouts?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <h3 className="font-[600] mb-2">Built-In Fees Example (Starter Plan):</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Ticket Price:</b>$100</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Platform Fee:</b>10% ($10)</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Stripe Fees: </b>2.9% + $0.30 ($3.20)</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Net Revenue:</b>$86.80</li>
                                                                        </ul>

                                                                        <h3 className="font-[600] mb-2">Add-On Fees Example (Starter Plan):</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Ticket Price:</b>$100</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Platform Fee:</b>$10 (paid by the customer)</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Stripe Fees: </b>2.9% + $0.30 ($3.48 on $110 total)</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Net Revenue:</b>$96.52</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>When should I use Built-In Fees?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Simplified Pricing:</b>Customers see one flat ticket price, which can reduce sticker shock.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Lower-Priced Events:</b>Helps ensure affordable and seamless pricing for smaller-ticket items.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Transparent Budgeting: </b>Makes it easier for subscribers to predict their net revenue.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>When should I use Add-On Fees?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Maximizing Revenue:</b>Subscribers keep the full ticket price, as platform fees are passed to the customer.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>High-Demand Events:</b>Customers are more likely to pay an added platform fee for sought-after events.</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Transparent Fee Breakdown:</b>Add-On fees can build trust by showing exactly what the customer is paying for.</li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>How do Stripe fees work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2"><b>Stripe Fees</b>re applied to the total amount paid by the customer, including:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Ticket Price</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Platform Fees (if Add-On)</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Taxes (if applicable)</li>
                                                                        </ul>
                                                                        <p className="text-[#343434] text-[14px] mb-2"><b>Example:</b></p>
                                                                        <p className="text-[#343434] text-[14px] mb-2">If a customer pays $110 (ticket + platform fees), Stripe deducts 2.9% + $0.30 from $110.</p>

                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>Can I change the platform fee option after ticket creation?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">No. Once a ticket is created, the platform fee handling method is locked. Choose the option that best aligns with your event strategy during ticket setup.</p>

                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>What are the platform fees for each subscription plan?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Platform fees depend on your plan:</p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Starter Plan:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Hosts: 4%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venues: 2.5%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors: 10%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Food Trucks: 4%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Entertainment Crews: 3%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Influencers: 4% after the ticket cap (100 tickets).</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Pro Plan:</b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Hosts: 2%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venues: 2%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Vendors: 7.5%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Food Trucks: 2%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Entertainment Crews: 2%</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Influencers: 2% after the ticket cap (300 tickets).</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Elite Plan:</b> No platform fees on most transactions, with reduced fees for large-scale ticketing beyond thresholds.
                                                                            </li>
                                                                        </ul>

                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">9.</span>Examples of Fee Handling by Subscriber Type</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <h3 className="font-[600] mb-2">Host Example (Starter Plan)</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Ticket Price:</b>$50</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Built-In Fees:</b>Platform fees (4%) and Stripe fees are deducted.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $50</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Host Receives: ~$46.25 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Add-On Fees:</b>Platform fee (4%) added separately at checkout.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $52</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Host Receives: ~$49.23 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>

                                                                        <h3 className="font-[600] mb-2">Venue Example (Pro Plan)</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Ticket Price:</b>$75</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Built-In Fees:</b>Platform fees (2%) and Stripe fees are deducted.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $75</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venue Receives: ~$71.20 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Add-On Fees:</b>Platform fee (2%) added separately at checkout.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $76.50</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Venue Receives: ~$74.18 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>

                                                                        <h3 className="font-[600] mb-2">Food Truck Example (Starter Plan)</h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Ticket Price:</b> $20</li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Built-In Fees:</b>Platform fees (4%) and Stripe fees are deducted.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $20</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Food Truck Receives: ~$18.47 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Add-On Fees:</b>Platform fee (4%) added separately at checkout.
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Customer Pays: $20.80</li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Food Truck Receives: ~$19.79 (after deductions).</li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">10.</span>Can Elite Plan subscribers use Add-On Fees?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Yes, Elite Plan subscribers can choose <b>Add-On Fees</b> for transparency, even though they generally pay no platform fees.
                                                                            For high-volume ticketing beyond included caps (e.g., 1,000 tickets/month), reduced platform fees apply, and these can be Add-On.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">11.</span>Need Help?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">For more information or assistance, visit the <b>Marc Help Center</b> or contact our <b>Support</b> Team at support@marc.com.</p>
                                                                        <p className="text-[#343434] text-[14px] mb-2">This FAQ ensures all subscribers understand and effectively manage platform fees for ticketed events on Marc.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                    <TabPanel key={"tab_11"} className="">
                                                        <div className="">
                                                            <h2 className="text-[25px] font-[600] text-[#005AAB] mb-3">Boost Your Engagement FAQ </h2>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">1.</span>What is boosting, and how does it work?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Boosting increases your visibility on Marc by prioritizing your profile,
                                                                            events, or services in searches and recommendations. Boosted content appears more prominently, making it easier for potential
                                                                            collaborators or explorers to discover you. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">2.</span>Who can use boosting features?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Boosting is available to subscription-based users on Pro and higher plans, including
                                                                            venues, hosts, entertainment crews, and food trucks. Starter subscription plans do not include access to boosting features. The
                                                                            features and costs associated with boosting vary based on your user type and subscription plan. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">3.</span>How much does boosting cost?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">The cost of boosting depends on your subscription plan and the type of boost
                                                                            (e.g., single-day or weekly). Refer to your plan details or the Boost FAQ for specific pricing information. Additionally,
                                                                            Stripe processing fees of 2.9% + $0.30 per transaction apply to all paid boosts. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">4.</span>What can I boost on Marc?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Users can boost a variety of content, including: </p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Event listings to attract more explorers. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Profiles to increase visibility in search results. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Amenities (venues only) to highlight unique features and attract more explorers. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Daily or weekly location pins (food trucks only) to increase visibility and drive more traffic to specific locations. </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">5.</span>How do I access boosting features?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Boosting features can be accessed through your Marc dashboard. Simply navigate to the marketing section, select the
                                                                            content you want to promote, and customize your campaign based on your goals.  </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">6.</span>How long does a boost last?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <h3 className="font-[600] mb-2">Boosting durations can vary based on your selection: </h3>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Daily Boosts: Count as 1 boost per day from your free or paid allotment. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Weekly Boosts: Count as 1 boost for a 7-day duration. Elite plans include 1 free weekly boost per month; additional weekly
                                                                                boosts are available at discounted rates. </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">7.</span>Will boosting guarantee results?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">While boosting increases visibility, results depend on factors such as the relevance of your content, audience interest, and the timing
                                                                            of the campaign. Marc provides analytics to help you track the performance of your boosts and refine your strategy. </p>

                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">8.</span>Are there any limits to boosting?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">The availability and extent of boosting features depend on your subscription plan: </p>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Free boosts cannot be rolled over to the next month. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Free weekly boosts must be scheduled in advance. </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Boosts are limited to promoting profiles, events, or relevant hosted content
                                                                                based on your plan tier.  </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">9.</span> How do I track the effectiveness of my boost?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">Marc’s analytics tools provide insights into the performance of your boosted content, such as clicks and engagement metrics. Use
                                                                            these insights to optimize your future campaigns.</p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">10.</span>Can I cancel or modify a boost after launching it?</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <p className="text-[#343434] text-[14px] mb-2">No, boosts cannot be canceled or modified once launched. Please ensure all details
                                                                            are accurate before confirming your campaign. </p>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">11.</span>Boost Duration Options</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Daily Boosts: Count as 1 boost per day from your free or paid allotment.  </li>
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2">Weekly Boosts: Count as 1 boost for a 7-day duration. </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                            <div className="mb-3">
                                                                <Disclosure as="div" className="p-4 border border-[#005fa7] rounded-lg transition-all">
                                                                    <DisclosureButton className="group flex w-full items-center justify-between ease-in-out text-left">
                                                                        <h3 className="font-[600] mb-2"><span className="">12.</span>General Notes</h3>
                                                                        <IoIosArrowDown className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                                                                    </DisclosureButton>
                                                                    <DisclosurePanel className="">
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Free boosts: </b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Cannot be rolled over to the next month. </li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Must be scheduled in advance. </li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Are limited to promoting profiles or directly hosted events. </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Add-Ons:  </b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Users can purchase additional weekly or single-day boosts. </li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Stripe processing fees of 2.9% + $0.30 per transaction are applied to all paid boosts.  </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ml-5 sm:ml-10">
                                                                            <li className="text-[#343434] text-[14px] list-disc mb-2"><b>Performance Analytics:  </b>
                                                                                <ul className="ml-5 sm:ml-10">
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Elite plans include advanced insights (e.g., geo-targeting, peak times). </li>
                                                                                    <li className="text-[#343434] text-[14px] list-disc mb-2">Pro plans include basic metrics (e.g., reach, clicks). </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </DisclosurePanel>
                                                                </Disclosure>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                </TabPanels>
                                            </TabGroup>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default GetHelp