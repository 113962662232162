import { useNavigate, useParams } from "react-router-dom"
import { NavigationBar } from "../Landing/NavigationBar"
import { useEffect, useState } from "react"
import ApiClient from "../../methods/api/apiClient"
import methodModel from "../../methods/methods"
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormControl from "../../common/FormControl"
import { useSelector } from "react-redux"
import { Tooltip } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment"
import loader from "../../methods/loader"

const PostDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [detail, setDetail] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "", salary: "" })
    const [otherPosts, setOtherPosts] = useState([])

    useEffect(() => {
        if (id) getPostDetails()
    }, [id])

    const getPostDetails = () => {
        loader(true)
        ApiClient.get(`venue/get-venue-post-detail?id=${id}`).then((res) => {
            if (res.success) {
                setDetail({ ...res?.payload, url: res?.payload?.link }) // link key not working in html so we change into url
                getOtherPosts(res?.payload)
            } else {
                loader(false)
            }
        })
    }

    const getOtherPosts = (data) => {
        const payload = { postType: user?.role === "host" || user?.role === "food_truck_vendor" ? "openOpportunities" : "promote,talent,collaborateConnect", status: "active", addedBy: data?.addedBy?._id || data?.addedBy?.id }
        ApiClient.get(`venue/get-all-venue-post`, payload).then(res => {
            if (res.success) {
                let filterData = res.data?.filter((item) => item?._id !== data?._id)
                setOtherPosts(filterData)
            }
            loader(false)
        })
    }

    const handleAppyPost = () => {
        if (getApplyPostStatus()) {
            return
        } else {
            setForm({ description: "", salary: "", type: "applyPost" })
            setIsOpen(true)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            postId: id,
            description: form?.description,
            salary: form?.salary
        }
        let apiUrl = "host/post/apply"
        if (form?.type === "collabration") {
            payload = {
                userId: detail?.addedBy?._id,
                description: form?.description
            }
            apiUrl = "host/collaborate/user"
        }
        ApiClient.post(apiUrl, payload).then(res => {
            if (res.success) {
                getPostDetails()
                setIsOpen(false)
            }
        })
    }

    const getApplyPostStatus = () => {
        const applyPostData = detail?.apply_post_details
        const hostId = user?._id || user?.id
        let value = ""
        applyPostData?.filter((item) => {
            if (item?.addedBy?._id === hostId) {
                value = item?.request_status
            }
        })
        return value
    }

    const paymentRequest = () => {
        const payload = {
            appliedPostId: detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?._id,
            userId: detail?.addedFor?._id
        }
        loader(true)
        ApiClient.post("job/payment/request", payload).then(res => {
            if (res.success) {
                getPostDetails()
            }
            loader(false)
        })
    }

    const handleProfileRedirection = () => {
        let url = ``
        const userId = detail?.addedBy?._id || detail?.addedBy?.id
        if (detail?.addedBy?.role === "venue") url = `/venue/detail/${userId}`
        else if (detail?.addedBy?.role === "host") url = `/hostProfile/${userId}`
        else if (detail?.addedBy?.role === "influencer") url = `/influencerProfile/${userId}`
        else if (detail?.addedBy?.role === "crew") url = `/entertainmentCrewProfile/${userId}`
        else if (detail?.addedBy?.role === "crew_member") url = `/crewProfile/${userId}`
        else if (detail?.addedBy?.role === "vendor") url = `/vendorProfile/${userId}`
        else if (detail?.addedBy?.role === "food_truck_vendor") url = `/foodTruckVendorProfile/${userId}`
        else url = ``
        if (url) navigate(url)
    }

    return (
        <>
            <NavigationBar />
            <div className="px-4 sm:px-8 py-5">
                <div className="container mx-auto">
                    <div className="">
                        <div className="flex items-center mb-5">
                            <Tooltip placement="top" title="Back">
                                <span onClick={() => { navigate(-1) }} className="!px-1 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all mr-3 bg-[#05388fed] text-white hover:text-black">
                                    <IoIosArrowBack size={20} />
                                </span>
                            </Tooltip>
                            <div className="w-full flex justify-between gap-x-5 items-center">
                                <h3 className="text-[15px] font-semibold text-[#111827]">
                                    Post Detail
                                </h3>
                                <button type="button" onClick={() => handleProfileRedirection()} className="bg-[#134896] px-5 py-2 text-white text-[14px] rounded-lg h-fit capitalize">Visit Profile</button>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="flex flex-wrap justify-between gap-5">
                                <div>
                                    <div className="flex items-center gap-3 capitalize">
                                        <img src={methodModel.userImg(detail?.addedBy?.image)} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full"></img>
                                        <div className="">
                                            <p className="text-[14px] sm:text-[16px]">{detail?.addedBy?.venue_name || detail?.addedBy?.fullName}</p>
                                            <h2 className="text-[16px] sm:text-[18px] font-[500] leading-[18px]">{detail?.title}</h2>
                                        </div>
                                    </div>

                                    {detail?.postType === "openOpportunities" ?
                                        <p className="text-[#7b7b7b] flex flex-wrap gap-x-3 mt-5 max-[499px]:!text-[12px] !text-[14px] !font-[500]">
                                            <span className="">Salary: ${detail?.startingSalary || 0} - ${detail?.maximumSalary || 0} </span>
                                        </p>
                                        : null}
                                    {getApplyPostStatus() &&
                                        <div className="max-[499px]:text-[12px] text-[14px] my-1">Job Post End Date & Time: <span className="text-[#134896]">{moment(detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                    }
                                </div>
                                <div className="flex flex-wrap gap-2 ">
                                {/* For Apply Post */}
                                {(user?.role === "host" || user?.role === "food_truck_vendor") && detail?.postType === "openOpportunities" && user?._id !== detail?.addedFor?._id ?
                                    <div className="">
                                        {getApplyPostStatus() ?
                                            <>
                                                <div className="flex gap-2 items-center">
                                                    <span className="text-[14px]">Applied Post Status: </span>
                                                    <div className={`capitalize text-[14px] h-fit ${getApplyPostStatus() === "rejected" ? "text-red-600" : getApplyPostStatus() === "accepted" ? "text-green-600" : "text-yellow-600"}`}> {getApplyPostStatus()}</div>
                                                </div>
                                                {new Date(detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?.jobEndDate) < new Date() && detail?.apply_post_details?.find((itm) => itm?.addedBy?.id === user?._id)?.jobPostStatus === "working" && detail?.apply_post_details?.find((itm) => itm?.addedBy?.id === user?._id)?.pendingAmount !== 0 ?
                                                    <>
                                                        {detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?.paymentRequestStatus === "pending" ?
                                                            <button type="button" onClick={e => paymentRequest()} className="bg-[#134896] px-5 py-2 text-white max-[499px]:text-[12px] text-[14px] rounded-lg h-fit">Payment Request</button>
                                                            :
                                                            <div className="max-[499px]:text-[12px] text-[14px]">Payment Request: <span className="capitalize text-[#134896] max-[499px]:text-[12px] text-[14px] h-fit">{detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?.paymentRequestStatus}</span></div>
                                                        }
                                                    </>
                                                    : null
                                                }
                                            </>
                                            :
                                            <button onClick={() => handleAppyPost()} className={`bg-[#134896] px-5 py-2 text-white text-[14px] rounded-lg h-fit capitalize`}>Apply Post</button>
                                        }
                                        {detail?.apply_post_details?.find((itm) => itm?.addedBy?._id === user?._id)?.jobPostStatus === "completed" &&
                                            <p>Job Post: <span className="text-green-600">Completed</span></p>
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                            </div>
                            <div className="mt-5">
                                <p className="text-[14px] text-[#0B0A0A] font-[300] sm:text-[16px] leading-[28px] mt-5" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                            </div>
                            {detail?.skills?.length !== 0 && detail?.skills ?
                                <div className='mt-8'>
                                    <h2 className="text-[18px] font-[500] mb-3">Skills</h2>
                                    <div className="flex flex-wrap gap-y-1 gap-x-2 text-[14px] font-[500] capitalize">
                                        {detail?.skills?.map((item, index) => {
                                            return <div className="flex items-center bg-[#f1f1f1] text-[#919191] px-4 py-[3px] rounded-[30px] gap-2" key={index}>{item}</div>
                                        })}
                                    </div>
                                </div>
                                : null
                            }
                            {detail?.image?.length > 1 || detail?.video?.length > 1 ?
                                <div className="mt-8">
                                    <h2 className="text-[22px] font-[500]">Gallery</h2>
                                    {detail?.image?.length > 1 &&
                                        <div className="mt-4">
                                            <h2 className="text-[16px] font-[500]">Photos</h2>
                                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mt-4">
                                                {detail?.image?.map((item, index) => {
                                                    if (index > 0) {
                                                        return <img src={methodModel.noImg(item)} key={index} className="max-[499px]:h-[135px] h-[180px] md:h-[170px] xl:h-[210px] 2xl:h-[250px] w-full object-cover rounded-lg"></img>
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {detail?.video?.length > 1 &&
                                        <div className="mt-8">
                                            <h2 className="text-[16px] font-[500]">Videos</h2>
                                            <div className="grid max-[499px]:grid-cols-1 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5 mt-4">
                                                {detail?.video?.map((item, index) => {
                                                    return <div key={index} className="">
                                                        <video className="max-[499px]:h-[225px] h-[180px] md:h-[170px] xl:h-[210px] 2xl:h-[250px] w-full object-contain rounded-lg bg-[#000]" controls>
                                                            <source src={methodModel.video(item)} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                                : null}
                            <div className="mt-8">
                                <h2 className="text-[18px] font-[500] mb-3">About the client</h2>
                                <div className="">
                                    <p className="text-[14px] font-[300]">Name: <span>{detail?.addedBy?.role === "venue" ? detail?.addedBy?.venue_name : detail?.addedBy?.fullName || detail?.addedBy?.firstName}</span></p>
                                    {detail?.addedBy?.role === "venue" ?
                                        <>
                                            {detail?.addedBy?.locations?.map((item, index) => {
                                                return <p className="text-[14px] font-[300]">Location {index + 1}: <span>{item?.location || "--"}</span></p>
                                            })}
                                        </>
                                        :
                                        <>
                                            {detail?.addedBy?.location ?
                                                <p className="text-[14px] font-[300]">Location: <span>{detail?.addedBy?.location || "--"}</span></p>
                                                : null}
                                        </>
                                    }
                                </div>
                            </div>
                            {otherPosts?.length > 0 ?
                                <div className="mt-8">
                                    <h2 className="text-[22px] font-[500] mb-3">Other Posts</h2>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                        {otherPosts?.map((item, index) => {
                                            return <div onClick={e => { navigate(`/post/detail/${item?._id || item?.id}`) }} className="border cursor-pointer rounded-lg p-4" key={index}>
                                                <h2 className="text-[#d9a11f] font-[500] leading-[20px] mb-3">{item?.title || "--"}</h2>
                                                <p className="text-[14px] text-[#05388fed] front-[300]">Posted {moment(item?.createdAt).fromNow()}</p>
                                                <p className="text-[14px] text-[#737373] front-[300] text_3line w-full" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                {item?.skills?.length > 0 &&
                                                    <div className="flex flex-wrap gap-y-1 gap-x-1 text-[14px] font-[500] capitalize mt-5">
                                                        {item?.skills?.map((itm, i) => {
                                                            return <div key={i} className="flex items-center bg-[#f1f1f1] text-[#919191] px-4 py-[3px] rounded-[30px] gap-2">{itm}</div>
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">{form?.type === "applyPost" ? "Apply For Post" : "Collabration"}</DialogTitle>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    {form?.type === "applyPost" &&
                                        <FormControl
                                            type="number"
                                            name="salary"
                                            label="Salary ($)"
                                            placeholder="Enter Salary ($)"
                                            value={form?.salary}
                                            onChange={(e) => setForm({ ...form, salary: e })}
                                            maxlength={7}
                                            required
                                        />
                                    }
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PostDetail