import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Html from "./html";
import { useNavigate } from "react-router-dom";
import shared from "./shared";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const EventsListing = () => {
  const searchState = { data: "" };
  const user = useSelector((state) => state.user)
  const [filters, setFilter] = useState({ page: 1, count: 10, search: "" });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState([])
  const [collabrationListOptions, setCollabrationListOptions] = useState([])
  const history = useNavigate();
  const [subCategoryOptions, setSubCategoryOptions] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  const [form, setForm] = useState({ eventId: "", data: [{ ticketName: "", type: "", tickets: "", price_of_ticket: "", isOnline: true, tickets_per_order: "" }] })
  const [modal, setModal] = useState({ modal: false, message: "" })

  const typeOptions = [
    { id: "paid", name: "Paid" },
    { id: "free", name: "Free" },
  ]

  useEffect(() => {
    getAllCategories()
    getAllSubCategories()
    getCollabrationList()
    setFilter({ ...filters, search: searchState.data });
    getData({ search: searchState.data, page: 1 });
  }, []);

  const getAllCategories = () => {
    ApiClient.get('category/listing?sortBy=name asc').then(res => {
      if (res.success) {
        setCategoryOptions(res?.data?.map((item) => {
          return ({ id: item?.id || item?._id, name: item?.name })
        }))
      }
    })
  }
  const getAllSubCategories = (id = "") => {
    ApiClient.get(`sub/listing?status=active&sortBy=name asc&categoryId=${id || ""}`).then(res => {
      if (res.success) {
        setSubCategoryOptions(res?.data?.map((item) => {
          return ({ id: item?.id || item?._id, name: item?.name })
        }))
      }
    })
  }
  const getCollabrationList = () => {
    ApiClient.get(`users/collaborated/listing?id=${user?._id || user?.id}&type=${user?.role}`).then(res => {
      if (res.success) {
        setCollabrationListOptions(res?.data?.map((item) => {
          if (item?.venue_id?._id === user?._id) {
            return ({ ...item, id: item?.host_id?._id || item?.host_id?.id, name: item?.host_id?.fullName || `${item?.host_id?.firstName || ""} ${item?.host_id?.lastName || ""}` })
          } else {
            return ({ ...item, id: item?.venue_id?._id || item?.venue_id?.id, name: item?.venue_id?.venue_name })
          }
        }))
      }
    })
  }

  const sortClass = (key) => {
    let cls = "fa-sort";
    if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
    else if (filters.key == key && filters.sorder == "desc")
      cls = "fa-sort-down";
    return "fa " + cls;
  };

  const sorting = (key) => {
    let sorder = "asc";
    if (filters.key == key) {
      if (filters.sorder == "asc") {
        sorder = "desc";
      } else {
        sorder = "asc";
      }
    }
    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder });
    getData({ sortBy, key, sorder });
  };

  const getData = (p = {}) => {
    let filter = { ...filters, ...p, [user?.role]: user?._id || user?.id };
    loader(true)
    ApiClient.get(shared.listApi, filter).then((res) => {
      if (res.success) {
        setData(res.data.map((itm) => {
          itm.id = itm._id;
          return itm;
        })
        );
        setTotal(res.total);
      }
      loader(false)
    });
  };

  const clear = () => {
    let f = {
      search: "",
      status: "",
      category: "",
      subCategory: "",
      venue: "",
      host: "",
      type: "",
    };
    setFilter({ ...filters, ...f });
    getData({ ...f });
  };

  const filter = (p = {}) => {
    let f = { page: 1, ...p }
    setFilter({ ...filters, ...f });
    getData({ ...f });
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this ${shared?.addTitle}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#063688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(shared.deleteApi, { id: id }).then((res) => {
          if (res.success) {
            clear();
          }
          loader(false);
        });
      }
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };
  const count = (e) => {
    setFilter({ ...filters, count: e });
    getData({ ...filters, count: e });
  };
  const handleFilter = (e, key) => {
    if (key === "category") {
      getAllSubCategories(e)
      setFilter({ ...filters, [key]: e, subCategory: "" });
      getData({ [key]: e, subCategory: "" });
    } else {
      setFilter({ ...filters, [key]: e });
      getData({ [key]: e });
    }
  };

  const statusChange = (itm) => {
    let status = "active";
    if (itm.status == "active") status = "deactive";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${status == "active" ? "Activate" : "Inactivate"
        } this ${shared?.addTitle}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#063688",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put(shared.statusApi, { id: itm.id }).then((res) => {
          if (res.success) {
            getData();
          }
          loader(false);
        });
      }
    });
  };

  const edit = (id) => {
    history(`/${shared.url}/edit/${id}`);
  };

  const view = (id) => {
    let url = `/${shared.url}/detail/${id}`;
    history(url);
  };

  const handleTickets = (item) => {
    let data = []
    item?.ticket_details?.map((itm) => {
      data.push({
        id: itm?._id,
        ticketName: itm?.ticket_name,
        type: itm?.type,
        tickets: itm?.ticket_count,
        price_of_ticket: itm?.ticket_price,
        isOnline: itm?.isOnline ? true : false,
        tickets_per_order: itm?.tickets_per_order,
      })
    })
    if (data?.length === 0) {
      data.push({ ticketName: "", type: "", tickets: "", price_of_ticket: "", isOnline: true, tickets_per_order: "" })
    }
    setForm((prev) => ({ ...prev, eventId: item?.id || item?._id, data: data }))
    setIsOpen(true)
  }

  const handleAddEvent = () => {
    if (user?.activePlanId?.manageEventsTypePerMonth === "count" && user?.manageEventsPerMonth >= user?.activePlanId?.manageEventsPerMonth) {
      setModal({ modal: true, message: "Your current plan limit has been reached. To add more events, please consider upgrading your plan.", image: "/assets/img/waiting.png", buttonText: "Ok" })
      return
    }
    if (collabrationListOptions?.length === 0 && user?.role === "host") {
      setModal({ modal: true, message: "Collaborate with the venue to coordinate and incorporate events into the schedule.", image: "/assets/img/waiting.png", buttonText: "Ok" })
    } else {
      history(`/${shared.url}/add`)
    }
  }

  return (
    <>
      <Html
        edit={edit}
        view={view}
        clear={clear}
        sortClass={sortClass}
        sorting={sorting}
        count={count}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        setFilter={setFilter}
        filter={filter}
        data={data}
        total={total}
        statusChange={statusChange}
        handleFilter={handleFilter}
        categoryOptions={categoryOptions}
        typeOptions={typeOptions}
        handleTickets={handleTickets}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        form={form}
        setForm={setForm}
        getData={getData}
        subCategoryOptions={subCategoryOptions}
        user={user}
        modal={modal}
        setModal={setModal}
        handleAddEvent={handleAddEvent}
      />
    </>
  );
};

export default EventsListing;
