const shared = {
  title: "Events",
  addTitle: "Event",
  url: "events",
  addApi: "event/add-events",
  editApi: "event/update-events",
  detailApi: "event/get-events-detail",
  listApi: "event/get-all-events",
  statusApi: "event/change-event-status",
  deleteApi: "event/delete-events",
  addTicketApi: "ticket/add",
  editTicketApi: "ticket/edit",
  deleteTicketApi: "ticket/delete"
};

export default shared;
