import { useEffect, useState } from "react"
import { NavigationBar } from "../Landing/NavigationBar"
import ApiClient from "../../methods/api/apiClient"
import { useDispatch, useSelector } from "react-redux"
import loader from "../../methods/loader"
import SigninModal from "../Auth/SigninModal"
import moment from "moment"
import { Dialog, DialogPanel } from '@headlessui/react'
import { IoMdClose } from "react-icons/io";
import { login_success } from "../../redux/actions/user"
import Footer from "../Footer";

const Plans = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [plans, setPlans] = useState([])
  const [tabs, setTabs] = useState("month")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activePlan, setActivePlan] = useState("")
  const [upgradePlanPopUp, setUpgradePlanPopUp] = useState(false)
  const [data, setData] = useState("")

  useEffect(() => {
    getPlans(user?.role || "")
    if (sessionStorage.getItem("token")) {
      getActivePlan()
    }
  }, [])

  const getActivePlan = () => {
    loader(true)
    ApiClient.get(`subscription/active/subscription?user_id=${user?._id || user?.id}`, {}, "", true).then(res => {
      if (res.success) {
        setActivePlan(res?.data)
      } else {
        setActivePlan("")
      }
      if (plans?.length !== 0) {
        loader(false)
      }
    })
  }

  const getPlans = (role = "") => {
    loader(true)
    ApiClient.get(`plan/listing?status=active&role=${role}`).then(res => {
      if (res.success) {
        setPlans(res?.data)
      }
      loader(false)
    })
  }

  const buyPlans = (item) => {
    if (!sessionStorage.getItem("token")) {
      setIsModalOpen(true)
      return
    }
    if (buttonText(item) === "Cancel Plan") {
      loader(true)
      ApiClient.post(`subscription/cancel?plan_id=${item?._id || item?.id}&user_id=${user?._id || user?.id}`).then(res => {
        if (res.success) {
          getProfileData()
          getPlans(user?.role || "")
          getActivePlan()
        }
        loader(false)
      })
    } else if (buttonText(item) === "Buy Now") {
      return
    } else if (buttonText(item) === "Upgrade Plan" && upgradePlanPopUp === false) {
      setData(item)
      setUpgradePlanPopUp(true)
    } else {
      let payload = {
        plan_id: item?._id || item?.id,
        amount: tabs === "month" ? (item?.monthlyPrice?.usd || 0) : (item?.yearlyPrice?.usd || 0),
        interval_count: tabs === "month" ? 1 : 3,
        interval: tabs,
        type: buttonText(item) === "Start your trial" ? "buy" : "update"
      }
      if (buttonText(item) !== "Start your trial") {
        payload = { ...payload, subscriptionId: activePlan?.stripe_subscription_id }
      }
      loader(true)
      ApiClient.post(`subscription/payNowOnStripe`, payload).then(res => {
        if (res.success) {
          if (res?.data?.url) {
            loader(false)
            window.open(res?.data?.url, "_self")
          } else {
            setTimeout(() => {
              setUpgradePlanPopUp(false)
              getActivePlan()
            }, 3000);
          }
        }
      })
    }
  }

  const closeModal = (role = "") => {
    setIsModalOpen(false)
    getPlans(role)
  }

  const buttonText = (item) => {
    if (!activePlan) return "Start your trial"
    let text = "Start your trial"
    const activePlanPrice = Number(activePlan?.interval_count === 3 ? activePlan?.subscription_plan_id?.yearlyPrice?.usd : activePlan?.subscription_plan_id?.monthlyPrice?.usd)
    const planPrice = tabs === "month" ? Number(item?.monthlyPrice?.usd || 0) : Number(item?.yearlyPrice?.usd || 0)
    if (item?._id === activePlan?.subscription_plan_id?._id && activePlanPrice === planPrice) {
      text = "Cancel Plan"
    } else if (item?._id === activePlan?.subscription_plan_id?._id) {
      if (activePlanPrice >= planPrice) {
        text = "Buy Now"
      } else {
        text = "Upgrade Plan"
      }
    } else {
      if (activePlanPrice >= planPrice) {
        text = "Buy Now"
      } else {
        text = "Upgrade Plan"
      }
    }
    return text
  }

  // Hitting this api to update data in redux persist
  const getProfileData = () => {
    ApiClient.get(`user/detail?id=${user?.id || user?._id}`).then(res => {
      if (res.success) {
        dispatch(login_success({ ...user, ...res?.data }))
      }
    })
  }

  return (
    <>
      <NavigationBar />
      <div>
        <div className="min-h-screen pb-28 sm:pb-0">
          <div className="bg-gray-900">
            <div className="max-w-7xl min-h-screen mx-auto p-4 sm:p-10 lg:py-20">
              <div className="max-w-5xl mx-auto text-center text-white tracking-widest pb-10">
                <p className="pb-4 text-[28px] font-[600] text-[#005AAB]">PRICING</p>
                <h1 className="text-[28px] font-black">The right price for you, whoeveryou are</h1>
                <p className="text-[18px] font-light text-gray-200 px-10 pt-4 pb-2">Ready to make your mark with Marc? Check out the options below and join the movement!</p>
                <p className="text-[18px] font-light text-gray-200 px-10 py-2">Choosing a yearly subscription unlocks 15% savings, making it the perfect option for those ready to commit and save. With a yearly plan, you'll enjoy all the benefits of your chosen subscription level while keeping more money in your pocket.</p>
                <p className="text-[18px] font-light text-gray-200 px-10 py-2">Starting in May 2025, platform fees will increase to 5% for Starter, 3% for Pro, and 2% for Elite subscriptions. Additionally, the free trial will no longer be available. These changes are designed to support ongoing improvements to the platform and the introduction of enhanced features for our subscribers.</p>
              </div>
              <div className="w-fit mx-auto pb-10">
                <button type="button" onClick={() => setTabs("month")} className={`${tabs === "month" ? "text-white bg-[#005AAB]" : "bg-[#fff] text-black"} text-[14px] font-[500] w-[100px] px-3 py-1 rounded-l-full hover:opacity-[90%]`}>Monthly</button>
                <button type="button" onClick={() => setTabs("year")} className={`${tabs === "year" ? "text-white bg-[#005AAB]" : "bg-[#fff]"} text-[14px] font-[500] w-[100px] px-3 py-1 rounded-r-full hover:opacity-[90%]`}>Yearly</button>
              </div>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 justify-center items-center  gap-8 lg:gap-0 lg:mt-4">
                {plans?.map((item, index) => {
                  return <div className={`flex-1 w-full bg-white rounded-xl shadow-xl lg:scale-95 ${buttonText(item) === "Cancel Plan" || item?.mostPopular ? "relative border-2 border-[#005AAB]" : ""}`} key={index}>
                    <div className="text-center p-10">
                      <p className="text-3xl lg:text-2xl xl:text-3xl pb-4 capitalize">{item?.name}</p>
                      <div className="flex justify justify-center items-center">
                        <span className="font-extrabold text-5xl lg:text-4xl xl:text-6xl align-text-middle px-3">${tabs === "month" ? (item?.monthlyPrice?.usd || 0) : (item?.yearlyPrice?.usd || 0)}</span>
                        <span className="font-normal text-xl text-gray-500 inline-block align-text-middle">/{tabs}</span>
                      </div>
                    </div>
                    <div className="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-7">
                      <ul className="space-y-4 max-h-[216px] min-h-[216px] overflow-auto">
                        {item?.feature?.map((itm, i) => {
                          return <li className="flex" key={i}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 min-w-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="text-gray-500 capitalize">{itm?.name}</span>
                          </li>
                        })}
                      </ul>
                      {item?.description &&
                        <div className="text-gray-600 p-4 border bg-white rounded-lg mt-3 mb-2">
                          <div className="max-h-[96px] overflow-auto min-h-[96px]" dangerouslySetInnerHTML={{ __html: item?.description }}></div></div>}
                      {/* activePlan?.trial_Status === "trialing" getting from strip on behalf of this status we are showing the trail period date and valid plan date */}
                      {buttonText(item) === "Cancel Plan" && <div className="text-center">Valid Upto: <span className="text-[#005AAB]">{activePlan?.trial_Status === "trialing" ? moment(activePlan?.trial_period_end_date).format("DD MMM, YYYY") : moment(activePlan?.valid_upto).format("DD MMM, YYYY")}</span></div>}
                      <button type="button" onClick={e => buyPlans(item)} className={`w-full text-center text-lg font-[600] ${buttonText(item) === "Cancel Plan" || item?.mostPopular && buttonText(item) !== "Buy Now" ? `${buttonText(item) === "Cancel Plan" ? "mt-2" : "mt-7"} bg-[#005AAB] text-white` : buttonText(item) === "Buy Now" ? "cursor-not-allowed mt-7" : "bg-white text-[#005AAB] hover:bg-[#005AAB] hover:text-white mt-7"} p-3 rounded-full shadow-xl transition`}>{buttonText(item)}</button>
                      {item?.mostPopular && <div className="absolute rounded-full w-40 bg-[#005AAB] font-[600] text-white text-center text-sm tracking-wider px-4 py-1 -top-3 inset-x-0 mx-auto">MOST POPULAR</div>}
                    </div>
                  </div>
                })}
                <SigninModal isModalOpen={isModalOpen} closeModal={closeModal} />
                {/* Upgrade Plan PopUp */}
                <Dialog open={upgradePlanPopUp} as="div" className="relative z-10 focus:outline-none" onClose={() => setUpgradePlanPopUp(true)}>
                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                      <DialogPanel transition className="bg-[url('assets/images/bg_elements.png')] bg-cover bg-no-repeat w-full max-w-2xl rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                        <div className="bg-[#fff9] rounded-xl p-10 relative">
                          <IoMdClose onClick={() => setUpgradePlanPopUp(false)} className="text-[24px] absolute top-5 right-5 text-[#7a7a7a] cursor-pointer hover:text-black" />
                          <div className="">
                            <img src="/assets/img/UPgrade.png" className="w-[100px] h-[100px] object-contain mx-auto mb-4"></img>
                            <div className="flex justify-center pb-3">
                              <div className="text-[28px] text-center font-medium text-[#005AAB]">Upgrade your plan</div>
                            </div>
                            <p className="text-center text-[#404040] max-w-[500px] mx-auto text-[14px]">If you upgrade your subscription, you will be charged automatically.
                              The remaining amount from your current plan will be deducted from the<br /> price of the new plan.</p>
                          </div>
                          <div className="flex justify-center mt-10">
                            <button type="button" onClick={() => buyPlans(data)} className="px-6 py-3 bg-[#005AAB] w-[200px] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] hover:opacity-[90%]">Upgrade</button>
                          </div>
                        </div>
                      </DialogPanel>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Plans