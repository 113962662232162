import { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';

const BoostingChart = ({ filter }) => {
    const [data, setData] = useState([]);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        getData();
    }, [filter]);

    // Function to convert month number to month name
    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1];
    };

    const getData = () => {
        ApiClient.get(`boost/analytics/listing/by/month?userId=${user?._id || user?.id}&boostCategory=${filter?.categoryType}&boostType=${filter?.boostType}&startDate=${filter?.startDate}&endDate=${filter?.endDate}`).then((res) => {
            if (res.success) {
                let chartData = [
                    ["Month", "Hike", { role: "style" }],
                    ...res.data.map(item => [
                        getMonthName(item.month),
                        item?.totalUsers || item?.totalBoosts,
                        "color: #004d92",
                    ])
                ];
                if (res.data?.length === 0) {
                    chartData = [
                        ["Month", "Hike", { role: "style" }],
                        ["January", 0, "color: #004d92",]
                    ];
                }
                console.log(chartData, '==chartData');
                setData(chartData);
            }
        })
    };

    return (
        <>
            <div className="col-md-12 mb-3">
                <div className="border_box">
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                    />
                </div>
            </div>
        </>
    );
};

export default BoostingChart;