import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import loader from "../../methods/loader";
import { useNavigate } from "react-router-dom";
import Location from '../../assets/images/Icons/location.svg';
import methodModel from "../../methods/methods";
import { MdOutlineClear } from "react-icons/md";
import { useSelector } from "react-redux";


const FollowingCrewMembers = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [data, setData] = useState([])
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "", status: "active" })
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getData()
    }, [])

    const getData = (f = {}) => {
        const filter = { ...filters, ...f, value: "follow", userId: user?._id || user?.id, addedTo: "crew_member" }
        loader(true)
        ApiClient.get(`event/listingByUserId`, filter).then(res => {
            if (res.success) {
                setData(res?.data)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const handlePagination = (e) => {
        setFilters((prev) => ({ ...prev, page: e }))
        getData({ page: e })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        getData(filters)
    }
    const searchInput = (e) => {
        if (e?.length !== 0) {
            setFilters({ ...filters, search: e })
        } else {
            setFilters({ ...filters, search: "" })
            getData({ search: "" })
        }
    }
    const clearSearch = () => {
        setFilters({ ...filters, search: "" })
        getData({ search: "" })
    }

    const unFollow = (eventId) => {
        const payload = {
            id: eventId,
            type: "follow",
            userId: user?._id || user?.id,
            addedTo: "crew_member"
        }
        loader(true)
        ApiClient.post(`event/follow`, payload).then(res => {
            if (res.success) {
                getData()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="border-[1px] rounded-[15px] p-5 gap-6">
                <div className="">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">Following Crew Member</h3>
                    </div>
                    <div className="w-full flex flex-row justify-between items-center my-3">
                        <form onSubmit={handleSearch} className="flex items-center gap-4">
                            <div className="relative">
                                <input type="text" value={filters?.search} onChange={e => searchInput(e.target.value)} placeholder="Search ..." className="bg-white w-full rounded-full h-10 overflow-hidden px-3 border border-[#00000036] text-[12px]" />
                                {filters?.search?.length > 0 && <MdOutlineClear size={20} onClick={() => clearSearch()} className="cursor-pointer absolute top-2.5 right-4" />}
                            </div>
                            <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">Search</button>
                        </form>
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-5 md:mt-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                        {data.map((item, index) => (
                            <div key={index}>
                                <div className="overflow-hidden bg-white rounded-lg shadow-md">
                                    <div className="relative">
                                        <img className="object-cover w-full h-[146px] cursor-pointer" onClick={e => { navigate(`/crewProfile/${item?._id || item?.id}`) }} src={methodModel.noImg(item?.image)} alt="Article" />
                                        <span size={25} onClick={e => unFollow(item?.id || item?._id)} className="absolute bottom-1 right-3 hover:opacity-[90%] flex items-center justify-center cursor-pointer text-[10px] text-nowrap font-semibold py-1 px-3 text-white border border-[#005AAB] bg-[#005AAB] rounded-lg md:inline-flex">Unfollow</span>
                                    </div>
                                    <div className="p-4 min-h-[115px] cursor-pointer" onClick={() => { navigate(`/crewProfile/${item?._id || item?.id}`) }}>
                                        <h2 className="ellipises_heading font-[500] text-[16px] capitalize">
                                            {item?.fullName || `${item?.firstName || ""} ${item?.lastName || ""}`}
                                        </h2>
                                        <p className="font-[400] text-[13px]">
                                            {item?.email}
                                        </p>
                                        <p className="flex items-center gap-2 font-[400] text-[13px] mt-1">
                                            <span className='ellipsis_class text-[#585858] leading-[17px]' dangerouslySetInnerHTML={{ __html: item?.description }}></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data?.length === 0 ?
                        <div className='text-center'>No Records Found</div>
                        : null}
                    {total > filters?.count ?
                        <div className="text-right pagination_div">
                            <Pagination
                                activePage={filters?.page}
                                itemsCountPerPage={filters?.count}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideFirstLastPages
                                prevPageText="<"
                                nextPageText=">"
                                onChange={e => handlePagination(e)}
                            />
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}

export default FollowingCrewMembers