import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { NavigationBar } from "../Landing/NavigationBar";

const AboutUs = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="bg-white w-full h-screen">
                <NavigationBar />
                <div className="">
                    <section className="relative xl:mr-0 lg:mr-5 mr-0">
                        <div className="bg-[url('assets/images/about_bg.jpeg')] bg-no-repeat bg-cover bg-top">
                            <div className="bg-[#13489626]">
                                <div className="container mx-auto px-5">
                                    <div className="flex items-center min-h-[600px] md:min-h-[800px] 2xl:min-h-[900px] w-full">
                                        <div className="w-full">
                                            <h2 className="text-[32px] sm:text-[50px] font-[800] text-[#134896] text-center leading-[38px] sm:leading-[55px]">Discover the <br /> Experiences You Love</h2>
                                            <div className="bg-[#134896] p-5 max-w-[550px] text-white rounded-[20px] mx-auto mt-8">
                                                <p className="text-[20px] font-[500] text-center">Marc is your go-to platform for finding and sharing unforgettable events. </p>
                                                <p className="text-[18px] text-[#efefef] text-center">From lively music nights and comedy shows to city-wide festivals, Marc makes it easy to
                                                    discover experiences that spark joy, connection, and community. </p>
                                            </div>
                                            <div className="text-center">
                                                <button type="button" onClick={e => { navigate("/joinmarc") }} className="bg-[#134896] text-[20px] font-[500] text-white px-5 py-2 w-[180px] rounded-[30px] hover:opacity-[90%] mt-5">Join Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="mb-12">
                        <div className="container mx-auto px-5">
                            <section className="mt-[8rem]">
                                <div className="grid max-[499px]:grid-cols-1 grid-cols-2 md:grid-cols-3 gap-x-5 lg:gap-x-12 gap-y-5">
                                    <div className="">
                                        <img src="/assets/img/about_img2.jpg" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                        <div className="mt-5">
                                            <h2 className="p_text font-[600]">Discover Events Tailored to You </h2>
                                            <p className="text_3line text-[14px] font-[300] mt-2">Sign up, set your preferences, and start exploring events near you. Marc brings personalized recommendations based on
                                                your interests and location, so you never miss out on what’s happening around you. </p>
                                            <button type="button" onClick={e => { navigate("/Event") }} className="bg-[#134896] text-[14px] text-white font-[500] px-5 py-2 w-[200px] hover:opacity-[90%] mt-3"> Find events near you</button>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/about_img1.png" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                        <div className="mt-5">
                                            <h2 className="p_text font-[600]">Share Your Talent and Reach New Audiences  </h2>
                                            <p className="text_3line text-[14px] font-[300] mt-2">Create a profile, set your schedule, and let Marc connect you with venues and guests looking for unforgettable
                                                experiences. Our platform makes it easy to promote your performances and reach the right crowd.  </p>
                                            <button type="button" onClick={e => { navigate("/providersignup?role=host") }} className="bg-[#134896] text-[14px] text-white font-[500] px-5 py-2 w-[200px] hover:opacity-[90%] mt-3"> Host with Marc </button>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/about_img3.jpg" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                        <div className="mt-5">
                                            <h2 className="p_text font-[600]">Maximize Your Venue’s Potential  </h2>
                                            <p className="text_3line text-[14px] font-[300] mt-2">Showcase your venue, attract top talent, and expand your reach. Marc makes it easy to promote your events and manage bookings seamlessly,
                                                bringing in guests who are looking for the perfect spot to enjoy their next experience.  </p>
                                            <button type="button" onClick={e => { navigate("/providersignup?role=venue") }} className="bg-[#134896] text-[14px] text-white font-[500] px-5 py-2 w-[200px] hover:opacity-[90%] mt-3">  Feature your venue</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mt-[10rem]">
                                <div>
                                    <h2 className="text-[38px] sm:text-[50px] font-[800] text-[#134896] text-center leading-[48px] sm:leading-[55px] mb-[5rem]">Start Exploring with Marc... </h2>
                                    <div className="grid max-[499px]:grid-cols-1 grid-cols-2 md:grid-cols-3 gap-x-5 lg:gap-x-12 gap-y-5">
                                        <div className="">
                                            <img src="/assets/img/about_img5.jpg" className="w-[200px] h-[200px] object-cover mx-auto rounded-full" />
                                            <div className="mt-5">
                                                <h2 className="p_text font-[600] text-[#134896] text-center">Launching with 1,000+ Hosts and Venues </h2>
                                                <p className="text_3line text-[14px] font-[300] text-center mt-2"> Marc will connect you with a dynamic range of hosts and venues, offering diverse experiences nationwide. </p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <img src="/assets/img/about_img6.jpeg" className="w-[200px] h-[200px] object-cover mx-auto rounded-full" />
                                            <div className="mt-5">
                                                <h2 className="p_text font-[600] text-[#134896] text-center">Thousands of Events Ready to Explore  </h2>
                                                <p className="text_3line text-[14px] font-[300] text-center mt-2"> From intimate gatherings to large-scale events, Marc brings a world of experiences waiting for you to discover.  </p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <img src="/assets/img/about_img4.jpeg" className="w-[200px] h-[200px] object-cover mx-auto rounded-full" />
                                            <div className="mt-5">
                                                <h2 className="p_text font-[600] text-[#134896] text-center">Endless Adventures Tailored to You  </h2>
                                                <p className="text_3line text-[14px] font-[300] text-center mt-2"> Find, book, and enjoy events that match your interests and location, with new options added all the time.  </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-8 text-center">
                                        <button type="button" onClick={e => { navigate("/Event") }} className="bg-[#134896] text-[14px] text-white font-[500] px-5 py-2 w-[200px] hover:opacity-[90%] mt-3">
                                            Find events near you
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AboutUs