import { useEffect, useState } from "react";
import Footer from "../Footer";
import { NavigationBar } from "../Landing/NavigationBar";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { IoCloseOutline, IoDocumentTextOutline, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import loader from "../../methods/loader";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { FaBullhorn } from "react-icons/fa";
import { BsFillBuildingsFill } from "react-icons/bs";
import { IoIosArrowBack, IoIosPeople } from "react-icons/io";
import { GiFoodTruck } from "react-icons/gi";
import { FaStore } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import { GiMicrophone } from "react-icons/gi";
import { IoMdStar } from "react-icons/io";
import { Tooltip } from "antd";
import { IoMdClose } from "react-icons/io";
import Logo from "../../assets/images/logo.png"
import OtpInput from 'react-otp-input';

const ProviderSignUp = () => {
    const { id } = useParams()
    const [form, setform] = useState({
        venue_name: "",
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobileNo: "",
        role: id ? "venue" : "",
        company_ein: "",
        ein_image: "",
    })
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const inValidEmail = methodModel.emailvalidation(form?.email)
    const inValidPassword = methodModel.passwordValidation(form?.password);
    const [eye, setEye] = useState({ password: false, confirmPassword: false })
    const [isOpen, setIsOpen] = useState(false)
    const [popUp, setPopUp] = useState({ modal: false, resend: "", message: "", image: "", buttonText: "", email: "" })
    const inValidPopUpEmail = methodModel.emailvalidation(popUp?.email)
    const [verificationModal, setVerificationModal] = useState({ modal: false, step: 1, type: "", otp: "", loader: false, error: "" })

    useEffect(() => {
        const role = methodModel.getPrams("role")
        if (role) {
            setform({ ...form, role: role })
        }
        if (!id && !role) {
            setIsOpen(true)
        }
        const status = methodModel.getPrams("status")
        if (status === "completed") {
            setPopUp({ modal: true, resend: "no", message: "", image: "/assets/img/location_check.png", buttonText: "Done", email: "" })
            setIsOpen(false)
        }
        const formDiv = document.getElementById('formDiv');
        if (formDiv) {
            formDiv.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor") { // Just for now until persona not setup completely
            if (!form?.ein_image || !inValidEmail || !inValidPassword && !id) return
        } else {
            if (!inValidEmail || !inValidPassword && !id) return
        }
        if (id) {
            if (!form?.mobileNo) return
            setVerificationModal((prev) => ({ ...prev, modal: true, step: 1, type: "", otp: "" }))
            return
        }
        let method = "post";
        let url = "users/register";
        let value = { ...form }
        if (value?.role === "venue") {
            delete value.firstName
            delete value.lastName
        } else if (value?.role === "host" || value?.role === "influencer" || value?.role === "crew" || value?.role === "vendor" || value?.role === "food_truck_vendor") {
            delete value.name
            delete value.venue_name
        } else {
            // For futher roles
        }
        loader(true)
        ApiClient.allApi(url, value, method).then((res) => {
            if (res.success) {
                if (res?.payload?.url) {
                    window.open(res?.payload?.url, "_self")
                } else {
                    setPopUp({ modal: true, resend: "no", message: "", image: "/assets/img/location_check.png", buttonText: "Done", email: "" })
                }
            }
            loader(false)
        });
    }

    const handleClaimVenue = () => {
        let payload = {
            ...form,
            claimId: id
        }
        delete payload.password
        loader(true)
        ApiClient.post(`users/claim/request`, payload).then(res => {
            if (res.success) {
                setPopUp({ modal: true, resend: "", message: "You have successfully claimed the venue. Please wait for the administrator to verify your request.", image: "/assets/img/waiting.png", buttonText: "Ok", email: "" })
            }
            loader(false)
        })
    }

    const handleDrop = (e) => {
        e.preventDefault();
        uploadEIN(e);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const uploadEIN = (e) => {
        let files = e?.target?.files || e?.dataTransfer?.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only doc, pdf, jpeg and png are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    const handlePopUp = (role) => {
        setform({ ...form, role: role })
        setIsOpen(false)
    }

    const handleResendEmail = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!inValidPopUpEmail) return
        const payload = {
            oldEmail: form?.email,
            newEmail: popUp?.email,
            role: form?.role,
        }
        loader(true)
        ApiClient.post(`users/resend/email`, payload).then(res => {
            if (res.success) {
                setPopUp({ modal: true, resend: "", message: "The email has been successfully resent. Please check your inbox to verify your account.", image: "/assets/img/email.png", buttonText: "Ok", email: "" })
            }
            loader(false)
        })
    }

    const handleDocumentField = (value) => {
        if (form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor") {
            setform({ ...form, company_ein: value })
        } else {
            const regex = /^[A-Za-z0-9*]{1,15}$/;
            if (value) {
                if (regex.test(value)) {
                    setform({ ...form, company_ein: value })
                }
            } else {
                setform({ ...form, company_ein: "" })
            }
        }
    }

    const handleSendOTP = (type) => {
        const payload = {
            type: type,
            number: form?.mobileNo
        }
        loader(true)
        ApiClient.post(`otp/send`, payload).then(res => {
            if (res.success) {
                setVerificationModal((prev) => ({ ...prev, type: type, step: 2, otp: "" }))
            }
            loader(false)
        })
    }
    const resendCode = () => {
        const payload = {
            type: verificationModal?.type,
            number: form?.mobileNo
        }
        loader(true)
        ApiClient.post(`otp/re/send`, payload).then(res => {
            loader(false)
            if (res.success) {
                toast.success("Verification Code Sent Successfully.")
            }
        })
    }
    const verifyOTP = () => {
        setVerificationModal((prev) => ({ ...prev, loader: true }))
        ApiClient.post(`otp/verify`, { otp: verificationModal?.otp }, {}, "", true).then(res => {
            if (res.success) {
                handleClaimVenue()
                setVerificationModal((prev) => ({ ...prev, loader: false, modal: false }))
            } else {
                setVerificationModal((prev) => ({ ...prev, loader: false, error: res?.error?.message }))
                setTimeout(() => {
                    setVerificationModal((prev) => ({ ...prev, error: "" }))
                }, 4000);
            }
        })
    }

    return (
        <>
            <section className="relative pb-20 pt-20 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/providersignup.jpeg')] min-h-[300px] sm:min-h-[400px] md:min-h-[553px] flex flex-col items-center justify-center">
                <div className="w-full absolute top-0">
                    <NavigationBar />
                </div>
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-[22px] sm:text-[28px] md:text-4xl">
                        <span className="text-white text-[28px] leading-[35px]">Elevate Your Business and Expand Your Reach​</span>
                        {/* {!id ? "Sign Up As A Service Provider Today" : "Claim Your Venue Today"} */}
                        <p className="text-[18px] max-w-[520px] leading-[27px] mx-auto"> {!id ? "Join Marc and connect with a nationwide audience. Unlock the potential to expand your reach, showcase your talents, and discover opportunities that help your business thrive. Sign up today to take the first step toward limitless possibilities!​" : "Claim Your Venue Today"} </p>
                    </h1>
                </div>
            </section>
            <div className="bg-[#f9f9f9] p-5">
                <div className="container mx-auto" id="formDiv">
                    <form onSubmit={handleSubmit} autoComplete="one-time-code" className="">
                        <div>
                            <div className="shadow-md bg-white rounded-md border-[#ededed] max-w-[700px] mx-auto p-8 mt-4 mb-4">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    {form?.role === "venue" ?
                                        <>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    label="Contact Person Name"
                                                    placeholder="Enter Contact Person Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.name}
                                                    onChange={(e) => setform({ ...form, name: e })}
                                                    required
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="firstName"
                                                    label="First Name"
                                                    placeholder="Enter First Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.firstName}
                                                    onChange={(e) => setform({ ...form, firstName: e })}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <FormControl
                                                    type="text"
                                                    name="lastName"
                                                    label="Last Name"
                                                    placeholder="Enter Last Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.lastName}
                                                    onChange={(e) => setform({ ...form, lastName: e })}
                                                    required
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className="">
                                        <FormControl
                                            type="text"
                                            name="email"
                                            label={"Email"}
                                            placeholder={"Enter Email"}
                                            autoComplete="one-time-code"
                                            value={form.email}
                                            onChange={(e) => setform({ ...form, email: e })}
                                            required
                                        />
                                        {form?.email && !inValidEmail && submitted && (
                                            <div className="d-block text-red-600">Please enter valid email</div>
                                        )}
                                    </div>
                                    {id ? null :
                                        <div>
                                            <div className="relative">
                                                <FormControl
                                                    type={eye?.password ? "text" : "password"}
                                                    name="password"
                                                    label="Password"
                                                    placeholder="Enter Password"
                                                    autoComplete="one-time-code"
                                                    minlength={8}
                                                    maxlength={16}
                                                    value={form?.password}
                                                    onChange={(e) => setform({ ...form, password: e })}
                                                    required
                                                />
                                                {!eye?.password ?
                                                    <IoEyeOffOutline size={20} onClick={e => setEye({ ...eye, password: true })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                                    :
                                                    <IoEyeOutline size={20} onClick={e => setEye({ ...eye, password: false })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                                }
                                            </div>
                                            {form?.password && !inValidPassword && submitted && (
                                                <div className="text-red-600">{methodModel.passwordValidationMessages(form?.password)}</div>
                                            )}
                                        </div>
                                    }
                                    <div className="mobile_number">
                                        <FormControl
                                            type="phone"
                                            name="mobileNo"
                                            autoComplete="one-time-code"
                                            label="Mobile Number"
                                            value={form?.mobileNo}
                                            onChange={(e) => setform({ ...form, mobileNo: e })}
                                            required={id}
                                        />
                                        {submitted && !form?.mobileNo ?
                                            <div className="text-red-600">Mobile Number is required</div>
                                            : null}
                                    </div>
                                    {form?.role === "venue" &&
                                        <div>
                                            <FormControl
                                                type="text"
                                                name="venue_name"
                                                label="Company Name"
                                                placeholder="Enter Company Name"
                                                autoComplete="one-time-code"
                                                value={form?.venue_name}
                                                onChange={(e) => setform({ ...form, venue_name: e })}
                                                required
                                            />
                                        </div>
                                    }
                                    <div className="">
                                        <FormControl
                                            type="text"
                                            name="company_ein"
                                            label={form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? "Company EIN" : "Driving Licence/State Id"}
                                            placeholder={`Enter ${form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? "Company EIN" : "Driving Licence/State Id"}`}
                                            autoComplete="one-time-code"
                                            value={form?.company_ein}
                                            onChange={(e) => handleDocumentField(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? // Just for now until persona not setup completely
                                    <div className="mt-4">
                                        <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                            Upload {form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? "Company EIN" : "Driving Licence/State Id"} <span className="text-red-600">*</span>
                                        </label>
                                        {form?.ein_image ?
                                            <div className="relative mt-3">
                                                <IoDocumentTextOutline size={20} className="bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />
                                            </div>
                                            :
                                            <label onDrop={handleDrop} onDragOver={handleDragOver} className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer">
                                                <div className="text-center">
                                                    <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                        <FiPlus />
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            className="hidden"
                                                            multiple
                                                            onChange={e => uploadEIN(e)}
                                                        />
                                                        {form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? "Upload Or Drag & Drop EIN" : "Upload Or Drag & Drop Driving Licence/State Id"}
                                                    </div>
                                                </div>
                                            </label>
                                        }
                                        {!form?.ein_image && submitted &&
                                            <span className="text-red-600">{form?.role === "venue" || form?.role === "vendor" || form?.role === "food_truck_vendor" ? "Company EIN" : "Driving Licence/State Id"} is required</span>
                                        }
                                    </div>
                                    : null}
                                <div className="w-[250px] mx-auto mt-4">
                                    <button type="submit" className="text-white w-full bg-[#005AAB] font-[500] rounded-full px-5 py-2.5 text-center hover:opacity-[90%]">
                                        {id ? "Claim Venue" : "Register"}
                                    </button>
                                    {!id &&
                                        <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1 mt-2">
                                            Already have an account?{' '}
                                            <span onClick={() => { history('/signin') }} className="text-[#005AAB] font-[700 cursor-pointer">Sign In</span>
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Select Role Modal */}
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-2xl rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Join the Marc Community​</DialogTitle>
                            </div>
                            <div className="my-4 mt-[2rem] grid grid-cols-2 sm:grid-cols-3 gap-5">
                                <Tooltip position="top" title="Sign up to explore events, grab tickets, and unlock perks. No account? No problem—you can still purchase tickets as a guest!">
                                    <Button type="button" onClick={() => { history(`/auth`) }} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <IoTicketOutline className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Member
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Promote events at your spot, team up with hosts, entertainment crews, vendors, and more to create unforgettable experiences.">
                                    <Button type="button" onClick={() => handlePopUp("venue")} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <BsFillBuildingsFill className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Venue
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Calling all entertainers! Use Marc to promote events and collaborate with venues and other hosts nationwide.">
                                    <Button type="button" onClick={() => handlePopUp("host")} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <GiMicrophone className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Host
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Match up with members who vibe with your activities, earn commissions on tickets and sign-ups, and collaborate with venues and hosts.">
                                    <Button type="button" onClick={() => handlePopUp("influencer")} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <FaBullhorn className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Influencer
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Showcase your group’s talents and collaborate with venues, hosts, and other creators to take your performances to the next level.">
                                    <Button type="button" onClick={() => handlePopUp("crew")} className="relative px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <IoIosPeople className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        <IoMdStar className="absolute top-[2px] left-[0px] right-[0px] mx-auto text-[38px] text-[#E1B524]" />
                                        Entertainment Crew
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Sell your products or services at events and connect with venues to grow your reach.">
                                    <Button type="button" onClick={() => handlePopUp("vendor")} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <FaStore className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Vendor
                                    </Button>
                                </Tooltip>
                                <Tooltip position="top" title="Pin your truck on the map, share specials, connect with venues, and attract hungry event-goers looking for unique eats.">
                                    <Button type="button" onClick={() => handlePopUp("food_truck_vendor")} className="px-3 py-2 bg-white hover:bg-[#005fa70d] min-h-[120px] text-[#005AAB] text-center rounded-md shadow-md block text-[13px] sm:text-[15px] font-[500]">
                                        <GiFoodTruck className="mx-auto text-[38px] text-[#E1B524] mb-2" />
                                        Food Truck Vendor
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="mt-8">
                                <div className="flex justify-center"></div>
                                <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1 mt-2">
                                    Already have an account?
                                    <span onClick={() => { history('/signin') }} className="text-[#005AAB] font-[700 cursor-pointer"> Sign In</span>
                                </p>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <Footer />
            {/* PopUp For Messages */}
            <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className={`bg-[url('assets/images/colorful.png')] bg-cover bg-no-repeat w-full ${popUp?.resend === "no" ? "max-w-2xl" : "max-w-lg"} rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
                            <div className="bg-[#fff9] rounded-xl p-10 relative">
                                <form className="bg-[#fffc]" onSubmit={handleResendEmail}>
                                    {popUp?.resend === "yes" ?
                                        <>
                                            <div className="">
                                                <img src="/assets/img/resend_img.png" className="w-[80px] object-contain mx-auto my-5"></img>
                                                <h2 className="text-[28px] text-[#005AAB] font-[500] mb-5 text-center leading-[34px]">Re-send Verification Email</h2>
                                                <FormControl
                                                    type="text"
                                                    name="email"
                                                    label={"Email"}
                                                    placeholder={"Enter Email"}
                                                    autoComplete="one-time-code"
                                                    value={popUp?.email}
                                                    onChange={(e) => setPopUp((prev) => ({ ...prev, email: e }))}
                                                    required
                                                />
                                                {popUp?.email && !inValidPopUpEmail && submitted && (
                                                    <div className="d-block text-red-600">Please enter valid email</div>
                                                )}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <img src={popUp?.image} className={`${popUp?.resend === "no" ? "w-[120px] h-[120px]" : "w-[100px] h-[100px]"} object-contain mx-auto my-5`} />
                                            {popUp?.resend === "no" ?
                                                <div className='text-center'>
                                                    <h2 className="text-[28px] text-[#005AAB] font-[500] mb-5 leading-[34px]">Verify your email address</h2>
                                                    <p className="text-center text-[#404040] max-w-[500px] mx-auto text-[14px]">
                                                        Please check your email to verify your account and <br />get started with Marc.
                                                        {/* A verification email has been sent to your email <span className="text-[#005AAB]">{form?.email} </span> Please check your email and click the link provided in the email to complete your account registration. */}
                                                    </p>
                                                    {/* <p className="text-center text-[#404040] max-w-[500px] mx-auto text-[13px] mt-7">If you do not receive the email within the next 5 minutes, use the button below to resend verification email.</p> */}
                                                </div>
                                                :
                                                <div className='text-center'>
                                                    <p className="text-center text-[#404040] max-w-[450px] mx-auto text-[15px]">
                                                        {popUp?.message || ""}
                                                    </p>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="mt-[2rem] flex flex-wrap sm:flex-nowrap justify-between gap-3">
                                        {popUp?.resend === "yes" ?
                                            <Button type="submit" className="px-6 py-3 bg-[#005AAB] w-[200px] mx-auto text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer">Resend</Button>
                                            :
                                            // popUp?.resend === "no" ?
                                            //     <span onClick={e => setPopUp((prev) => ({ ...prev, resend: "yes", email: form?.email }))} className="px-6 py-3 bg-[#005AAB] w-full text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer">Re-send Verification Email</span>
                                            //     : 
                                            null
                                        }
                                        {popUp?.resend !== "yes" && <Button type="button" onClick={e => { history("/signin") }} className={`px-6 py-3 bg-[#005AAB] w-[200px] mx-auto text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer`}>{popUp?.buttonText || "Ok"}</Button>}
                                    </div>
                                </form>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            {/* Claim Venue Verificatin Modal */}
            <Dialog open={verificationModal?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setVerificationModal((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className={`bg-[url('')] bg-white bg-cover bg-no-repeat w-full relative ${verificationModal?.resend === "no" ? "max-w-2xl" : "max-w-[500px]"} rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
                            {verificationModal?.step === 2 &&
                                <Tooltip title="Back">
                                    <IoIosArrowBack onClick={e => setVerificationModal((prev) => ({ ...prev, step: 1 }))} className='absolute top-[15px] left-[15px] cursor-pointer' />
                                </Tooltip>
                            }
                            <Tooltip title="Close">
                                <IoMdClose onClick={e => setVerificationModal((prev) => ({ ...prev, modal: false }))} className='absolute top-[15px] right-[15px] bg-[#E7E7E7] rounded-[30px] w-[20px] h-[20px] p-[3px] cursor-pointer hover:bg-[#dde4eb]' />
                            </Tooltip>
                            <div className='px-5 sm:px-10 py-10'>
                                <div>
                                    <img src={Logo} className='w-[150px] object-contain mx-auto mb-4' />
                                    <h2 className='text-[18px] uppercase font-[500] text-center text-[#828282] mb-1'>VERIFICATION CODE</h2>
                                    {verificationModal?.step === 1 ?
                                        <>
                                            <p className='text-[14px] text-center text-[#000] leading-[18px] max-w-[335px] mx-auto'>You can receive your verification code via text message (SMS) or phone call.</p>
                                            <div className='grid grid-cols-1 gap-3 mt-8'>
                                                <button type="button" onClick={e => handleSendOTP("sms")} className='border-0 bg-[#005AAB] rounded-[8px] w-full text-[16px] font-[400] h-10 flex items-center justify-center text-white hover:opacity-[90%]'>Send SMS</button>
                                                <button type="button" onClick={e => handleSendOTP("call")} className='border border-[#b5b5b5] bg-white rounded-[8px] w-full text-[16px] font-[400] h-10 flex items-center justify-center text-[#232323] hover:bg-[#f6f6f6]'>Call me</button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <p className='text-[14px] text-center text-[#000] leading-[18px] max-w-[335px] mx-auto'>A verification code has been sent to your mobile number.</p>
                                            <div className='flex justify-center gap-3 mt-6'>
                                                <OtpInput
                                                    value={verificationModal?.otp}
                                                    onChange={e => setVerificationModal((prev) => ({ ...prev, otp: e }))}
                                                    numInputs={4}
                                                    renderSeparator={<span className="mx-2"></span>}
                                                    renderInput={(props) => (
                                                        <input {...props} className="w-[40px] min-w-[40px] h-[40px] bg-[#E7E7E7] rounded-[5px] border-0 text-center" />
                                                    )}
                                                />
                                            </div>
                                            <div className='grid grid-cols-1 gap-3 mt-8'>
                                                <button type="button" onClick={e => verifyOTP()} disabled={verificationModal?.loader || !verificationModal?.otp} className='border-0 bg-[#005AAB] disabled:cursor-not-allowed rounded-[8px] w-full text-[16px] font-[400] h-10 flex items-center justify-center text-white hover:opacity-[90%]'>{verificationModal?.loader ? "Verifying..." : "Verify"}</button>
                                            </div>
                                            {verificationModal?.error && <p className="text-[12.5px] text-red-600 my-2">{verificationModal?.error}</p>}
                                            <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1] mt-3">Don't receive a code ? <span onClick={e => resendCode()} className="cursor-pointer font-medium text-[#3163b2] hover:underline dark:text-[#3163b2]">Resend</span></p>
                                        </>}

                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ProviderSignUp;