import Logo from "../../assets/images/logo.png"
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FormControl from '../../common/FormControl';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Button, Dialog, DialogPanel } from '@headlessui/react'

export const Signup = () => {
    const [form, setForm] = useState({ firstName: "", lastName: "", email: "", password: "" })
    const navigate = useNavigate()
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const [eye, setEye] = useState({ password: false, confirmPassword: false })
    const [popUp, setPopUp] = useState({ modal: false, message: "", image: "", buttonText: "", email: "" })
    const [submitted, setSubmitted] = useState(false)
    const inValidPassword = methodModel.passwordValidation(form?.password);
    const formValidation = [
        { key: "firstName", required: true },
        { key: "lastName", required: true },
        { key: "email", required: true },
        { key: "password", required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form);
        if (invalid || !inValidEmail || !inValidPassword) return
        const payload = {
            ...form,
            role: "member"
        }
        ApiClient.post(`users/register`, payload).then((res => {
            if (res.success) {
                setPopUp({ modal: true, message: "", image: "/assets/img/location_check.png", buttonText: "Done", email: "" })
            }
        }))
    }

    return (
        <div className="light">
            <div className="w-full bg-cover bg-[url('assets/images/signup.jpeg')]">
            <div className="bg-[#00000082] h-[100vh] flex justify-between md:flex-row flex-col">
                <div className="md:max-w-[624px] w-full pt-[15px] md:pt-[30px] px-5 md:px-10">
                    <Link to="/">
                        <img src={Logo} alt="" className="w-[120px] lg:w-[220px]" />
                    </Link>
                    <div className="flex flex-col gap-[20px] mt-[40px] md:mt-[100px]">
                        <h2 className="text-[28px] sm:text-[42px] font-[700] leading-[38px] sm:leading-[50px] text-white drop-shadow-[0px_2px_3px_#000]">Join Marc and discover local events</h2>
                        <p className="text-[18px] font-[400] leading-[27.54px] text-white drop-shadow-[0px_2px_3px_#000]">Explore and experience the best your city—or
                            any city nationwide—has to offer with Marc. From unique venues to unforgettable events, Marc helps you discover what's happening near
                            you or in the places you plan to visit. Sign up today and start your journey!</p>
                    </div>
                </div>
                <div className="bg-white h-full w-full px-[50px] lg:px-[107px] pb-[30px] lg:pb-[118px] lg:max-w-[652px] rounded-tl-[113.9px] lg:rounded-tl-[204.9px] flex justify-center flex-col items-start mt-5 lg:mt-0">
                    <form onSubmit={handleSubmit} className="w-full pt-[60px] md:pt-[134.71px]">
                        <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full">Sign Up As A Member</h2>
                        <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                            <div className="">
                                <label>First Name <span className="text-red-600">*</span></label>
                                <FormControl
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter First Name"
                                    autoComplete="one-time-code"
                                    value={form?.firstName}
                                    onChange={(e) => setForm({ ...form, firstName: e })}
                                    required
                                />
                            </div>
                            <div className="">
                                <label>Last Name <span className="text-red-600">*</span></label>
                                <FormControl
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter Last Name"
                                    autoComplete="one-time-code"
                                    value={form?.lastName}
                                    onChange={(e) => setForm({ ...form, lastName: e })}
                                    required
                                />
                            </div>
                            <div className="">
                                <label>Email <span className="text-red-600">*</span></label>
                                <FormControl
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    autoComplete="one-time-code"
                                    value={form?.email}
                                    onChange={(e) => setForm({ ...form, email: e })}
                                    required
                                />
                                {form?.email && !inValidEmail && submitted && (
                                    <div className="d-block text-red-600 mt-2">Please enter valid email</div>
                                )}
                            </div>
                            <div>
                                <div className="relative">
                                    <label>Password <span className="text-red-600">*</span></label>
                                    <FormControl
                                        type={eye?.password ? "text" : "password"}
                                        name="password"
                                        placeholder="Enter Password"
                                        autoComplete="one-time-code"
                                        minlength={8}
                                        maxlength={16}
                                        value={form?.password}
                                        onChange={(e) => setForm({ ...form, password: e })}
                                        required
                                    />
                                    {!eye?.password ?
                                        <IoEyeOffOutline size={20} onClick={e => setEye({ ...eye, password: true })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                        :
                                        <IoEyeOutline size={20} onClick={e => setEye({ ...eye, password: false })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                    }
                                </div>
                                {form?.password && !inValidPassword && submitted && (
                                    <div className="text-red-600">{methodModel.passwordValidationMessages(form?.password)}</div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col pt-[30px] w-full gap-4 md:gap-[35px]">
                            <button type="submit" className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 hover:opacity-[90%]">
                                Sign Up
                            </button>
                            <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                                Already have an account?{' '}
                                <span onClick={() => { navigate(`/signin`) }} className="text-[#005AAB] font-[700 cursor-pointer">Sign In</span>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className={`bg-[url('assets/images/colorful.png')] bg-cover bg-no-repeat w-full max-w-lg rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
                            <div className="bg-[#fff9] rounded-xl p-10 relative">
                                <img src={popUp?.image} className={`w-[100px] h-[100px] object-contain mx-auto my-5`} />
                                <div className='text-center'>
                                    <h2 className="text-[28px] text-[#005AAB] font-[500] mb-5 leading-[34px]">Verify your email address</h2>
                                    <p className="text-center text-[#404040] max-w-[500px] mx-auto text-[14px]">
                                        {/* Please check your email to verify your account and <br />get started with Marc. */}
                                        Let’s get started! Check your email to verify your address and explore everything Marc has waiting for you.
                                    </p>
                                </div>
                                <div className="mt-[2rem] flex flex-wrap sm:flex-nowrap justify-between gap-3">
                                    <Button type="button" onClick={e => { navigate("/signin") }} className={`px-6 py-3 bg-[#005AAB] w-[200px] mx-auto text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500] cursor-pointer`}>{popUp?.buttonText || "Ok"}</Button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
