import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import loader from "../../methods/loader";
import { Tooltip } from "antd";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { IoIosArrowBack } from "react-icons/io";
import methodModel from "../../methods/methods";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormControl from "../../common/FormControl";
import { useSelector } from "react-redux";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import { SiGoogleforms } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import CustomMap from "../EventDetail/CustomMap";

const Detail = () => {
    const user = useSelector((state) => state.user);
    const [data, setData] = useState();
    const history = useNavigate();
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ type: "", eventId: [], rejectReason: "" })
    const [events, setEvents] = useState([])
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    useEffect(() => {
        if (id) {
            getDetail();
        }
        if (user?._id || user?.id) {
            getEvents();
        }
    }, []);

    const getDetail = () => {
        loader(true);
        ApiClient.get(`host/collaboration/detail`, { collaborationId: id }).then((res) => {
            if (res.success) {
                setData(res?.data)
                const findKey = user?.role === data?.addedBy?.role ? "addedFor" : "addedBy"
                if(res?.data?.[findKey]?.role === "host" || res?.data?.[findKey]?.role === "food_truck_vendor"){
                    getLocationAvailability(res?.data?.[findKey]?._id || res?.data?.[findKey]?.id)
                }
            }
            loader(false);
        });
    };

    const getLocationAvailability = (userId) => {
        ApiClient.get(`host/availability/listing?hostId=${userId}`, {}, "", true).then(res => {
            if (res.success) {
                let locations = []
                let mapview = {}
                res?.data?.location_availability?.map((item, index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel })
                    if (index === 0) {
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel }
                    }
                })
                setLocations(locations)
                setMapView(mapview)
            }
        })
    }

    const getEvents = () => {
        ApiClient.get(`event/get-all-events?status=active&${user?.role}=${user?._id || user?.id}`).then(res => {
            if (res.success) {
                setEvents(res.data?.map((item) => {
                    return ({ id: item?._id, name: item?.title })
                }))
            }
        })
    }

    const requestStatus = (e, requestType) => {
        if (requestType === "verify") {
            setForm((prev) => ({ ...prev, type: requestType }))
            handleRequest(e)
        } else {
            setForm((prev) => ({ ...prev, type: requestType }))
            setIsOpen(true)
        }
    }

    const handleRequest = (e) => {
        e.preventDefault()
        let payload = {
            collaborationId: id,
            type: form?.type || "verify"
        }
        if (form?.type === "reject") {
            payload = { ...payload, reason: form?.reason }
        }
        ApiClient.post(`host/collaboration/application`, payload).then(res => {
            if (res.success) {
                getDetail()
                setIsOpen(false)
            }
        })
    }

    const findKey = () => {
        let key = "addedBy"
        if (user?.role === data?.addedBy?.role) {
            key = "addedFor"
        }
        return key
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5">
                    <div className="wrapper_section w-full py-7">
                        <div className="mb-5">
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <Tooltip placement="top" title="Back">
                                        <span onClick={() => history(-1)} className="!px-1 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all mr-3 bg-[#05388fed] text-white hover:text-black">
                                            <IoIosArrowBack size={20} />
                                        </span>
                                    </Tooltip>
                                    <h3 className="text-[15px] font-semibold text-[#111827]">
                                        Collaboration Request Details
                                    </h3>
                                </div>
                                {(user?.role === "venue" || user?.role === "vendor" || user?.role === "food_truck_vendor") &&
                                    <div className="flex items-center gap-2">
                                        <span className="text-[14px]">Request Status: </span>
                                        <div className={`capitalize text-[14px] ${data?.request_status === "rejected" ? "text-red-600" : data?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{data?.request_status}</div>
                                        {data?.request_status === "pending" ?
                                            <div className="flex items-center gap-2">
                                                <button onClick={e => requestStatus(e, "reject")} className="text-[12px] !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#000] text-white">Reject</button>
                                                <button onClick={e => requestStatus(e, "verify")} className="text-[12px] !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#05388fed] text-white">Accept</button>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="">
                            {data?.[findKey()]?.image &&
                                <div className="flex flex-col">
                                    <div className="flex flex-wrap gap-3">
                                        <img src={methodModel.noImg(data?.[findKey()]?.image)} className={`w-full object-cover h-[400px] rounded-[15px]`} />
                                    </div>
                                </div>
                            }
                            <div className="mt-5">
                                {data?.[findKey()]?.images?.length > 0 ?
                                    <div className="grid grid-cols-4 gap-4">
                                        {data?.[findKey()]?.images?.map((item, index) => {
                                            return <Tooltip position="top" title="Click to open">
                                                <div className="shadow rounded-[15px] bg-white">
                                                    <img src={methodModel.noImg(item)} onClick={e => { window.open(methodModel.noImg(item), "_blank") }} className="h-[200px] object-cover w-full rounded-[15px] border cursor-pointer" key={index} />
                                                </div>
                                            </Tooltip>
                                        })}
                                    </div>
                                    : null}
                            </div>
                            <div className="mt-5">
                                <h2 className="text-[32px] text-[#0F64B0] font-[500] capitalize"> {user?.role !== "venue" ? data?.[findKey()]?.venue_name : data?.[findKey()]?.fullName || `${data?.[findKey()]?.firstName || ""} ${data?.[findKey()]?.lastName || ""}`}</h2>
                                {user?.role !== "venue" ?
                                    <>
                                        {data?.[findKey()]?.locations?.map((item, index) => {
                                            return <div className="flex flex-wrap gap-x-1 mt-3" key={index}>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]"><FaLocationDot className="text-[20px]" /></p>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{item?.location},</p>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{item?.country},</p>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{item?.state},</p>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{item?.city},</p>
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{item?.zipCode}</p>
                                            </div>
                                        })}
                                    </>
                                    :
                                    <div className="flex flex-wrap gap-x-1 mt-3">
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]"><FaLocationDot className="text-[20px]" /></p>
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.location},</p>
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.country},</p>
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.state},</p>
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.city},</p>
                                        <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.zipCode}</p>
                                    </div>
                                }
                                <div className="flex flex-wrap gap-x-5 mt-2">
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]"> <MdEmail className="text-[20px]" />{data?.[findKey()]?.email}</p>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]"> <IoMdCall className="text-[20px]" />{data?.[findKey()]?.mobileNo ? `+${data?.[findKey()]?.mobileNo}` : "--"}</p>
                                </div>
                                <div className="h-[1px] w-[105px] bg-[#e7e7e7] mt-3"></div>
                                {data?.[findKey()]?.instagram_link || data?.[findKey()]?.facebook_link || data?.[findKey()]?.twitter_link || data?.[findKey()]?.linkedin_link || data?.[findKey()]?.youtube_link ?
                                    <div className="mt-3">
                                        <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize">Social Links :</h2>
                                        <div className="flex flex-wrap gap-y-2 gap-x-4">
                                            {data?.[findKey()]?.instagram_link && <img src="/assets/img/Instagram.webp" onClick={e => { window.open(data?.[findKey()]?.instagram_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                            {data?.[findKey()]?.facebook_link && <img src="/assets/img/Facebook.png" onClick={e => { window.open(data?.[findKey()]?.facebook_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                            {data?.[findKey()]?.twitter_link && <img src="/assets/img/twitter.webp" onClick={e => { window.open(data?.[findKey()]?.twitter_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                            {data?.[findKey()]?.linkedin_link && <img src="/assets/img/linkedin.webp" onClick={e => { window.open(data?.[findKey()]?.linkedin_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                            {data?.[findKey()]?.youtube_link && <img src="/assets/img/youtube.png" onClick={e => { window.open(data?.[findKey()]?.youtube_link, "_blank") }} className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                        </div>
                                    </div>
                                    : null}
                                {user?.role === "host" && <p className="font-[300] text-[#0000009c] text-[14px] mt-3" dangerouslySetInnerHTML={{ __html: data?.[findKey()]?.short_description || "--" }}></p>}
                                <p className="font-[300] text-[#0000009c] text-[14px] mt-3" dangerouslySetInnerHTML={{ __html: data?.[findKey()]?.description || "--" }}></p>
                            </div>
                            <div className="grid grid-cols-1 gap-5 mt-10">
                                <div className="flex flex-col">
                                    <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Collaboration Description:</label>
                                    <p className="font-[300] flex items-center gap-x-1 text-[#134896]">{data?.description}</p>
                                </div>
                                {/* <div className="flex flex-col">
                                    <label className="text-[14px] text-[#0000009c] tracking-wider leading-[17px] mb-1">Driving Licence/State Id</label>
                                    <p className="font-[300] text-[14px] flex items-center gap-x-1 text-[#134896]">{data?.[findKey()]?.company_ein || "--"}</p>
                                </div>*/}
                                {data?.[findKey()]?.skills?.length > 0 &&
                                    <div className="flex flex-col">
                                        <label className="text-[14px] text-[#0000009c] tracking-wider leading-[17px] mb-1">Skills</label>
                                        <p className="text-[14px] flex flex-wrap gap-2 text-black min-h-[35px] rounded-md items-center capitalize leading-[18px]">
                                            {data?.[findKey()]?.skills?.map((item, index) => {
                                                return <span className="bg-[#f7f7f7] p-2 px-3 rounded-lg text-[#0000009c]" key={index}>{item}</span>
                                            })}
                                        </p>
                                    </div>
                                }
                            </div>
                            {data?.[findKey()]?.resume &&
                                <div className="mt-10">
                                    <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize mb-2">Documents</h2>
                                    <div className="grid grid-cols-12 gap-5">
                                        {/* <Tooltip position="top" title="Click To Open">
                                            <div className="col-span-6 md:col-span-3 2xl:col-span-2 shadow-md rounded-lg flex justify-center border p-5 cursor-pointer hover:border-[#004d92]" onClick={() => { window.open(methodModel.document(data?.[findKey()]?.ein_image), "_blank") }}>
                                                <div className="text-center">
                                                    <p className="text-[13px]">
                                                        {data?.[findKey()]?.ein_image ?
                                                            <SiGoogleforms size={40} className="cursor-pointer mx-auto text-[#004d92] mb-2" />
                                                            :
                                                            <span>Document Not Uploaded</span>
                                                        }
                                                    </p>
                                                    <label className="text-[14px] text-[#0000009c] tracking-wider leading-[17px] mb-1">Driving Licence/State <br /> Id Document</label>
                                                </div>
                                            </div>
                                        </Tooltip> */}
                                        {data?.[findKey()]?.resume &&
                                            <Tooltip position="top" title="Click To Open">
                                                <div className="col-span-6 md:col-span-3 2xl:col-span-2 shadow-md rounded-lg flex justify-center border p-5 cursor-pointer hover:border-[#004d92]" onClick={() => { window.open(methodModel.document(data?.[findKey()]?.resume), "_blank") }}>
                                                    <div className="text-center">
                                                        <p className="text-[13px]">
                                                            {data?.[findKey()]?.resume ?
                                                                <SiGoogleforms size={40} className="cursor-pointer mx-auto text-[#004d92] mb-2" />
                                                                :
                                                                <span>Resume Not Uploaded</span>
                                                            }
                                                        </p>
                                                        <label className="text-[14px] text-[#0000009c] tracking-wider mb-1">Resume</label>
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mt-10">
                            <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize mb-2">Videos</h2>
                            <div className="grid max-[480px]:grid-cols-1 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                <video className="max-[480px]:h-[190px] max-[576px]:h-[140px] h-[170px] sm:h-[200px] w-full rounded-[15px] border bg-[#000]" controls>
                                    <source
                                        src="https://docs.material-tailwind.com/demo.mp4"
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            {data?.[findKey()]?.videos?.length > 0 ?
                                <div className="grid grid-cols-3 xl:grid-cols-4 gap-4">
                                    {data?.[findKey()]?.videos?.map((item, index) => {
                                        return <video className="h-[170px] sm:h-[200px] w-full rounded-[15px] border" key={index} controls>
                                            <source src={methodModel.video(item)} type="video/mp4" />
                                            <source src={methodModel.video(item)} type="video/ogg" />
                                            Your browser does not support the video tag.
                                        </video>
                                    })}
                                </div>
                                : null}
                        </div>
                        {locations?.length > 0 ?
                            <div className="mt-5">
                                <CustomMap mapView={mapView} locations={locations} type="availabilty" />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-black">{form?.type === "reject" ? "Add Reason" : "Select Events"}</DialogTitle>
                            </div>
                            <form onSubmit={handleRequest}>
                                <div>
                                    {form?.type === "reject" ?
                                        <FormControl
                                            type="textarea"
                                            name="rejectReason"
                                            label="Reason"
                                            placeholder="Enter Reason"
                                            autoComplete="one-time-code"
                                            value={form?.rejectReason}
                                            onChange={(e) => setForm({ ...form, rejectReason: e })}
                                            required
                                        />
                                        :
                                        <MultiSelectDropdown
                                            id="statusDropdown"
                                            placeholder="Select Events"
                                            intialValue={form?.eventId}
                                            className="mt-1 capitalize"
                                            result={(e) => setForm({ ...form, eventId: e?.value })}
                                            options={events}
                                            required
                                        />
                                    }
                                </div>
                                <div className="my-4 mt-[4rem] flex flex-wrap justify-center gap-5">
                                    <Button type="button" onClick={e => setIsOpen(false)} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                    <Button type="submit" className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">{form?.type === "reject" ? "Reject" : "Accept"}</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default Detail;