import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import Ticket from '../../assets/images/ticket.png';
import location from '../../assets/images/Icons/location.svg';
import CustomMap from './CustomMap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import moment from 'moment';
import methodModel from '../../methods/methods';
import { useSelector } from 'react-redux';
import { CiLocationOn, CiSearch } from 'react-icons/ci';
import loader from '../../methods/loader';
import { FaFilter } from "react-icons/fa6";

const FilterPage = () => {
    const [data, setData] = useState([])
    const search = useSelector((state) => state.search.data)
    const [filters, setFilters] = useState({ search: "", location: "", date: "", category: [], type: "", status: "active" })
    const [locations, setLocations] = useState([{ lat: "", lng: "", icon: "", info: "" }])
    const [mapView, setMapView] = useState([])
    const [categories, setCategories] = useState([])
    const [filterOptions, setFilterOptions] = useState({ category: false })
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        getAllCategories()
    }, [])

    useEffect(() => {
        setFilters({ ...filters, search: search?.name || "", location: search?.location || "" })
        getData({ search: search?.name || "", location: search?.location || "" })
    }, [search])

    const getData = (f = {}) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        const filter = { ...filters, category: filters?.category ? String(filters?.category) : "", ...f, value: "upcoming", lat: userLat, long: userLong }
        loader(true)
        ApiClient.get(`event/listing`, filter).then(res => {
            if (res.success) {
                setData(res?.data)
                let locations = []
                let mapview = {}
                res?.data?.map((item, index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(item?.images?.[0]), info: item?.title })
                    if (index === 0) {
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(item?.images?.[0]), info: item?.title }
                    }
                })
                setLocations(locations)
                setMapView(mapview)
            }
            loader(false)
        })
    }
    const getAllCategories = () => {
        ApiClient.get(`category/listing?status=active&sortBy=name asc`).then(res => {
            if (res.success) {
                setCategories(res?.data)
            }
        })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        getData({ search: filters?.search })
    }
    const clearFilter = () => {
        setFilters({ search: "", location: "", date: "", status: "active" })
        getData({ search: "", location: "", date: "", status: "active" })
    }
    const clearSidebarFilters = () => {
        setFilters({ ...filters, category: [], date: "", type: "" })
        getData({ category: [], date: "", type: "" })
    }
    const handleFilters = (value, key) => {
        if (key === "category") {
            let data = filters?.category
            if (data.includes(value)) {
                data = data.filter(item => item !== value)
            } else {
                data.push(value)
            }
            setFilters({ ...filters, [key]: data })
            getData({ [key]: String(data) })
        } else if (key === "date") {
            let date = "";
            if (value === "today") {
                date = moment();
            } else if (value === "tomorrow") {
                date = moment().add(1, 'days');
            }
            const formattedDate = date.format("MM/DD/YYYY");
            setFilters({ ...filters, [key]: value })
            getData({ [key]: formattedDate });
        }
        else {
            setFilters({ ...filters, [key]: value })
            getData({ [key]: value })
        }
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    }

    return (
        <>
            <NavigationBar />
            <div className="bg-[#D9D9D938]">
                <div className="grid grid-cols-12 relative">
                    <div className={`sideit lg:large-hide  ${isChecked ? 'sidefilter z-[99999] p-5 bg-white sideHide absolute left-[-385px] top-[55px] border-r border-[#6F6F6F4D] shadow md:shadow-none block lg:hidden duration-1000 translate-x-[24rem] w-[250px]' : ''}`}>
                        <div className='text-right'>
                            {filters?.category?.length > 0 || filters?.date || filters?.type ?
                                <button onClick={e => clearSidebarFilters()} className='font-[300] text-[12px] text-right text-[#005AAB]'>Clear Filters</button>
                                : null}
                        </div>
                        <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">Category</h3>
                            <div className='max-h-[144px] overflow-auto'>
                                {categories?.slice(0, filterOptions?.category ? 100 : 6)?.map((itm, i) => {
                                    return <div className="flex items-center gap-[10px]" key={i}>
                                        <input type="checkbox" className='cursor-pointer focus:ring-0 focus:shadow-none' id={`checkbox${i}`} onClick={e => handleFilters(itm?._id, "category")} checked={filters?.category?.includes(itm?._id)} />
                                        <label htmlFor={`checkbox${i}`} className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans capitalize cursor-pointer">{itm?.name}</label>
                                    </div>
                                })}
                            </div>
                            <div className="flex items-center gap-[10px] ml-auto">
                                <div onClick={e => setFilterOptions({ ...filterOptions, category: !filterOptions?.category })} className="font-normal text-xs leading-[24px] text-left text-[#005AAB] cursor-pointer font-open-sans">{filterOptions?.category ? "Less" : "More"}</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">Date</h3>
                            <div>
                                <div className="flex items-center gap-[10px]">
                                    <input type="radio" checked={filters?.date === "today"} onClick={e => handleFilters("today", "date")} id="today" name="date" className='cursor-pointer' />
                                    <label htmlFor="today" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">
                                        Today
                                    </label>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <input type="radio" checked={filters?.date === "tomorrow"} onClick={e => handleFilters("tomorrow", "date")} id="tomorrow" name="date" className='cursor-pointer' />
                                    <label htmlFor="tomorrow" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">
                                        Tomorrow
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">Price</h3>
                            <div>
                                <div className="flex items-center gap-[10px]">
                                    <input type="radio" onClick={e => handleFilters("free", "type")} checked={filters?.type === "free"} id="free" name="price" className='cursor-pointer' />
                                    <label htmlFor="free" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">Free</label>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <input type="radio" onClick={e => handleFilters("paid", "type")} checked={filters?.type === "paid"} id="paid" name="price" className='cursor-pointer' />
                                    <label htmlFor="paid" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">Paid</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2 hidden lg:block relative'>
                        <div className="px-2 w-full flex flex-col gap-3 pt-3 pb-6 border-r border-[#6F6F6F4D] bg-white">
                            <div className='lg:h-[calc(100vh_-_124px)] overflow-auto p-3'>
                                <div>
                                    <h2 className="font-semibold text-xl leading-[30px] text-left text-[#134896] font-montserrat border-b">Filters</h2>
                                    <div className='text-right'>
                                        {filters?.category?.length > 0 || filters?.date || filters?.type ?
                                            <button onClick={e => clearSidebarFilters()} className='font-[300] text-[12px] text-right text-[#005AAB]'>Clear Filters</button>
                                            : null}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                                    <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">
                                        Category
                                    </h3>
                                    <div className='max-h-[144px] overflow-auto'>
                                        {categories?.slice(0, filterOptions?.category ? 100 : 6)?.map((itm, i) => {
                                            return <div className="flex items-center gap-[10px]" key={i}>
                                                <input type="checkbox" className='cursor-pointer focus:ring-0 focus:shadow-none' id={`checkbox${i}`} onClick={e => handleFilters(itm?._id, "category")} checked={filters?.category?.includes(itm?._id)} />
                                                <label htmlFor={`checkbox${i}`} className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans capitalize cursor-pointer">{itm?.name}</label>
                                            </div>
                                        })}
                                    </div>
                                    <div className="flex items-center gap-[10px] ml-auto">
                                        <div onClick={e => setFilterOptions({ ...filterOptions, category: !filterOptions?.category })} className="font-normal text-xs leading-[24px] text-left text-[#005AAB] cursor-pointer font-open-sans">{filterOptions?.category ? "Less" : "More"}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                                    <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">Date</h3>
                                    <div>
                                        <div className="flex items-center gap-[10px]">
                                            <input type="radio" checked={filters?.date === "today"} onClick={e => handleFilters("today", "date")} id="today" name="date" className='cursor-pointer' />
                                            <label htmlFor="today" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">
                                                Today
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-[10px]">
                                            <input type="radio" checked={filters?.date === "tomorrow"} onClick={e => handleFilters("tomorrow", "date")} id="tomorrow" name="date" className='cursor-pointer' />
                                            <label htmlFor="tomorrow" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">
                                                Tomorrow
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 pb-[20px] border-b border-[#6F6F6F4D]">
                                    <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans">Price</h3>
                                    <div>
                                        <div className="flex items-center gap-[10px]">
                                            <input type="radio" onClick={e => handleFilters("free", "type")} checked={filters?.type === "free"} id="free" name="price" className='cursor-pointer' />
                                            <label htmlFor="free" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">Free</label>
                                        </div>
                                        <div className="flex items-center gap-[10px]">
                                            <input type="radio" onClick={e => handleFilters("paid", "type")} checked={filters?.type === "paid"} id="paid" name="price" className='cursor-pointer' />
                                            <label htmlFor="paid" className="font-normal cursor-pointer text-xs leading-[24px] text-left text-[#2B293D] font-open-sans">Paid</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-6'>
                        <h2 className="p-3 font-semibold text-xl leading-[30px] text-left text-[#134896] font-montserrat border-b block lg:hidden">
                            <input type="checkbox" id="toggle-hidden" checked={isChecked} onChange={handleCheckboxChange} className="toggle-input hidden" />
                            <label className='cursor-pointer flex items-center' htmlFor="toggle-hidden"><FaFilter className="pr-1" /> Filters</label>
                        </h2>
                        <div className="flex-1 py-12 pb-4 flex flex-col px-4">
                            <div className='font-semibold text-[18px] leading-[23.4px] tracking-[0.03em] text-left font-open-sans text-black mb-3'>What's happening near you?</div>
                            <form onSubmit={handleSearch} className='relative'>
                                <div className='flex flex-wrap lg:flex-nowrap max-md:justify-center gap-2'>
                                    <div className='relative max-md:w-[49%] max-[480px]:w-full'>
                                        <input type='text' value={filters?.search} onChange={e => setFilters({ ...filters, search: e.target.value })} className='w-full text-[14px] h-[38px] rounded-[30px] px-3 border-[1px] pl-8 border-[#dfdfdf]' placeholder='Search...' />
                                        <CiSearch className='absolute top-[10px] left-3 text-[18px] text-[#606060]' />
                                    </div>
                                    <div className='relative max-md:w-[49%] max-[480px]:w-full'>
                                        <input type='text' value={filters?.location} onChange={e => setFilters({ ...filters, location: e.target.value })} className='w-full text-[14px] h-[38px] rounded-[30px] px-3 border-[1px] pl-8 border-[#dfdfdf]' placeholder='Loaction...' />
                                        <CiLocationOn className='absolute top-[10px] left-3 text-[18px] text-[#606060]' />
                                    </div>
                                    <div className='flex gap-2'>
                                        {filters?.search || filters?.location ? <button type='button' onClick={() => clearFilter()} className="flex items-center justify-center h-[38px] text-[14px] text-nowrap font-[500] py-2 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">Clear</button> : null}
                                        <button type='submit' className="flex items-center justify-center h-[38px] text-[14px] text-nowrap font-[500] py-1 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">Search</button>
                                    </div>
                                </div>
                            </form>
                            <div className='mt-8'>
                                <div className="flex flex-col gap-3 lg:h-[calc(100vh_-_257px)] overflow-auto pb-3">
                                    {data && data?.map((item, index) => {
                                        return <Link to={`/EventDetails/${item?._id || item?.id}`} key={index}>
                                            <div className="flex gap-[15px] items-center flex-wrap p-4 shadow-md bg-white rounded-lg">
                                                <img className="max-w-[150px] h-[100px] w-full" src={methodModel.noImg(item?.images?.[0])} alt='event_image' />
                                                <div className="flex flex-col gap-1">
                                                    <h4 className="font-semibold text-[16px] tracking-[0.03em] text-left font-open-sans text-black capitalize">{item?.title}</h4>
                                                    <p className="font-normal text-xs tracking-[0.03em] text-left font-open-sans text-black">{moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</p>
                                                    <p className="flex gap-1 items-center text-[#2B293D] font-normal text-xs tracking-[0.03em] text-left font-open-sans">
                                                        <img className="w-[18px] h-[18px]" src={location} alt="location_icon" /> {item?.location}
                                                    </p>
                                                    <p className="flex gap-1 items-center text-[#2B293D] font-normal text-xs tracking-[0.03em] text-left font-open-sans capitalize">
                                                        {item?.type === "paid" ?
                                                            <>
                                                                {item?.ticket_details?.map((itm, i) => {
                                                                    return <>
                                                                        <img className="w-[18px] h-[18px]" src={Ticket} alt="ticket_icon" />{itm?.ticket_name} (${itm?.ticket_price})
                                                                    </>
                                                                })}
                                                            </>
                                                            :
                                                            <>
                                                                <img className="w-[20px] h-[20px]" src={Ticket} alt="ticket_icon" />Free
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    })}
                                    {data?.length === 0 &&
                                        <div className='text-center'>No Records Found</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4 p-4">
                        <div className='eventmap_div'>
                            <CustomMap locations={locations} mapView={mapView} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FilterPage;