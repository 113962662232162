import { NavigationBar } from "../Landing/NavigationBar"
import Footer from "../Footer";

const PrivacyPolicy = () => {
    return (
        <>
          <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="max-w-[1100px] mx-auto px-5">
                <div className="bg-white p-5 mb-5">
                    <div className="relative py-6">
                <h2 className="text-[28px] text-center font-[600] text-[#005AAB] mb-1">Marc Local Privacy Policy  </h2>
               <p className="text-[#3a3a3a] text-center text-[14px] mb-1"> At Marc Local, your privacy is important to us. This Privacy
                 Policy outlines how we collect, use, and protect the information shared by venues, hosts, entertainment crews, influencers, vendors, food
                  truck vendors, and guests through the Marc Local platform.  </p>
                
                <div className="mt-8">
                    
                    <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">1.</span>Information We Collect  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">When you interact with the Marc Local platform, we may collect the following types of data: </p>
                <h3 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1">1.1 Paid Subscriber Information </h3>
                <p className="text-[#4a4949] text-[14px] mb-4">Includes venues, hosts, entertainment crews, influencers, vendors, and food truck vendors. </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Contact Information</b>: Business name, address, phone number, email, and point-of-contact details.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Business Information</b>: Event details, listings, bookings, pricing, availability, and promotional information. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Payment Information</b>: Data necessary for processing subscription fees, payouts, and other financial transactions.</li>
                </ul>

                <h3 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1">1.2 Guest Information </h3>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Contact Information</b>: Names, email addresses, and phone numbers.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Account Details</b>: Usernames, passwords, preferences, and ticketing history. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Payment Details</b>: Information necessary for processing ticket purchases.</li>
                </ul>

                <h3 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1">1.3 Automatically Collected Information </h3>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Device Information</b>: Such as IP addresses, browser types, and operating systems. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>Usage Data</b>: Information about time spent on the platform, pages visited, event interactions, and booking trends.  </li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">2.</span> How We Use Collected Information </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">We use the information collected to provide, maintain, and improve the Marc Local platform, ensuring a seamless experience for all users. Specifically, we may use your data for the following purposes: </p>
                    <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Venues and Vendors </b>: To manage event listings, bookings, and promotional activities. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Hosts, Entertainment Crews, and Influencers </b>: To promote their profiles, facilitate bookings, and enhance visibility. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Food Truck Vendors </b>: To manage listings, visibility, and customer interactions. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Guests </b>: To process ticket sales, manage bookings, and deliver event-related updates.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Payment Processing </b>: To securely handle payments and issue payouts for paid subscribers. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Communication </b>: To send notifications, updates, and promotional content (with your consent). </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b>For Platform Improvement </b>: To analyze trends, feedback, and usage data to improve the user experience. </li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">3.</span> Data Sharing and Disclosure </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">Marc Local does not sell, rent, or share personal data with third parties for marketing purposes. We may, however, share your information under the following circumstances: </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Service Providers </b>: With trusted partners who support the platform, such as payment processors, hosting providers, and marketing agencies.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Legal Requirements </b>: To comply with legal obligations or respond to lawful requests from public authorities.  </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Business Transfers </b>: If Marc Local undergoes a merger, acquisition, or asset sale, your data may be transferred to the acquiring entity. </li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">4.</span> Data Security </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">Marc Local employs industry-standard security measures to protect your data from unauthorized access, alteration, or misuse. While we strive to safeguard your information,
                         no online or electronic storage method is 100% secure.  </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">5.</span> Data Retention </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">We retain personal data only as long as necessary to fulfill the purposes outlined in this policy or as required by law.  </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">6.</span> Your Rights and Choices </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">Depending on your location, you may have the following rights regarding your personal data:  </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Access </b>: Request a copy of the data we hold about you. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Correction </b>: Request corrections to inaccurate or incomplete information.  </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Deletion </b>: Request the deletion of your personal data, where applicable. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Opt-Out </b>: Opt-out of receiving promotional communications. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"><b> Data Portability </b>: Request the transfer of your data to another service provider. </li>
                </ul>

                <p className="text-[#4a4949] text-[14px]">To exercise any of these rights, please contact us using the information provided below. </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">7.</span> Cookies and Tracking Technologies </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">Marc Local may use cookies and similar technologies to enhance your experience, analyze platform performance, and deliver personalized content. You can manage cookies through your browser settings, but disabling
                         them may limit certain platform functionalities.   </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">8.</span> Changes to This Privacy Policy </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">We may update this policy periodically to reflect changes in our practices or legal requirements. Updates will be indicated by the “Last Updated” date at the top of this page.   </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">9.</span> Contact Us </h2>
                    <p className="text-[#4a4949] text-[14px] mb-4">If you have any questions about this Privacy Policy or wish to exercise your data rights, please contact us at:   </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1"> Citybird LLC (Marc Local)  </h2>
                    <h2 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1"> Info@MarcLocal.com  </h2>
                    <h2 className="flex font-[600] text-[16px] text-[#3a3a3a] gap-2 mb-1"> 239-251-2826  </h2>
                </div>

                </div>
                </div>
                </div>
                </div>
                <Footer />
            </div>
          
        </>
    )
}

export default PrivacyPolicy