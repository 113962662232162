import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useState } from "react"
import { useSelector } from "react-redux"
import WishListEvents from "./WishListEvents"
import WishListHosts from "./WishListHosts"

const Wishlist = () => {
    const user = useSelector(state => state.user)
    const [tab, setTab] = useState()

    const handleTabChange = (e) => {
        setTab(e)
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <div className="bg-white w-full h-screen">
                    <DesktopSidebar />
                    <div className="container mx-auto px-5 mt-6">
                        {user?.role === "host" ?
                            <WishListEvents />
                            :
                            <TabGroup selectedIndex={tab} onChange={e => handleTabChange(e)}>
                                <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                                    <Tab key={"Events"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Events
                                    </Tab>
                                    <Tab key={"Hosts"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Hosts
                                    </Tab>
                                </TabList>
                                <TabPanels className="mt-3">
                                    <TabPanel key={"Events"} className="rounded-xl bg-white/5">
                                        <WishListEvents />
                                    </TabPanel>
                                    <TabPanel key={"Hosts"} className="rounded-xl bg-white/5">
                                        <WishListHosts />
                                    </TabPanel>
                                </TabPanels>
                            </TabGroup>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wishlist