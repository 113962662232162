import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import { NavigationBar } from "../Landing/NavigationBar"
import ApiClient from "../../methods/api/apiClient";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import VenuesListing from "./VenuesListing";
import HostsListing from "./HostsListing";
import InfluencerListing from "./InfluencerListing";
import FoodTruckVendorListing from "./FoodTruckVendorListing";
import VendorListing from "./VendorListing";
import EntertainmentCrewListing from "./EntertainmentCrewListing";

const UsersListing = () => {
    const [users, setUsers] = useState([])
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "", status: "active" })
    const [total, setTotal] = useState(0)
    const [tab, setTab] = useState(0)

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = (f = {}) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        const filter = { ...filters, role: findRole(tab), ...f, lat: userLat, long: userLong }
        loader(true)
        ApiClient.get("users/listing", filter).then(res => {
            if (res.success) {
                setUsers(res?.data)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const clearSearch = () => {
        const filter = { search: "", status: "active" }
        setFilters(filter)
        getAllUsers(filter)
    }
    const handleSearch = () => {
        getAllUsers(filters)
    }

    const findRole = (tab) => {
        let role = ""
        if (tab === 0) role = "venue"
        else if (tab === 1) role = "host"
        else if (tab === 2) role = "influencer"
        else if (tab === 3) role = "crew"
        else if (tab === 4) role = "vendor"
        else if (tab === 5) role = "food_truck_vendor"
        else role = ""
        return role
    }

    const handleTabChange = (e) => {
        setTab(e)
        getAllUsers({ role: findRole(e) })
    }

    const handlePagination = (e) => {
        setFilters((prev) => ({ ...prev, page: e }))
        getAllUsers({ page: e })
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="w-[90%] mx-auto flex flex-col items-center justify-center py-[80px]">
                    <h2 className="font-[700] text-[32px] sm:text-[45px] text-[#E1B524] text-center leading-[38px] sm:leading-[48px]">
                        Exclusive users, priceless moments
                    </h2>
                    <div className="w-full flex md:flex-row flex-col justify-center items-center gap-2 pt-[60px]">
                        <div className="search_all max-w-[605px] w-full flex justify-between rounded-[30px] border border-[1px] !border-[#6a737f] bg-white px-3 py-1">
                            <input
                                type="text"
                                value={filters?.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="max-[499px]:w-[50px] bg-transparent flex-1 text-[14px]] outline-none border-0 focus:outline-none focus:ring-0"
                                placeholder="Search by name and location"
                            />
                        </div>
                        <div className="flex gap-2">
                        {filters?.search
                            ?
                            <button type='button' onClick={e => clearSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                                <IoMdClose className='text-[18px] mr-1' /> Clear
                            </button>
                            : null
                        }
                        <button type='button' onClick={e => handleSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                            <IoIosSearch className='text-[18px] mr-1' /> Search
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div className="upcoming py-[40px]">
            <div className='container mx-auto px-5'>
                <TabGroup selectedIndex={tab} onChange={e => handleTabChange(e)} className="">
                    <TabList className="flex gap-4 border-b-[1px] border-black/10 overflow-auto mb-4">
                        <Tab key={"Venues"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Venues
                        </Tab>
                        <Tab key={"Hosts"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Hosts
                        </Tab>
                        <Tab key={"Influencer"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Influencer
                        </Tab>
                        <Tab key={"Crew"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Entertainment Cew
                        </Tab>
                        <Tab key={"Vendor"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Vendor
                        </Tab>
                        <Tab key={"food_truck"} className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                            Food Truck Vendor
                        </Tab>
                    </TabList>
                    <TabPanels className="mt-3">
                        <TabPanel key={"Venues"} className="rounded-xl bg-white/5">
                            <VenuesListing users={users} />
                        </TabPanel>
                        <TabPanel key={"Hosts"} className="rounded-xl bg-white/5">
                            <HostsListing users={users} />
                        </TabPanel>
                        <TabPanel key={"Influencer"} className="rounded-xl bg-white/5">
                            <InfluencerListing users={users} />
                        </TabPanel>
                        <TabPanel key={"Crew"} className="rounded-xl bg-white/5">
                            <EntertainmentCrewListing users={users} />
                        </TabPanel>
                        <TabPanel key={"Vendor"} className="rounded-xl bg-white/5">
                            <VendorListing users={users} />
                        </TabPanel>
                        <TabPanel key={"food_truck"} className="rounded-xl bg-white/5">
                            <FoodTruckVendorListing users={users} />
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
                {users?.length === 0 ?
                    <div className='text-center'>No Records Found</div>
                    : null}
                {total > filters?.count ?
                    <div className="pagination_div w-[85%] text-right mx-auto">
                        <Pagination
                            activePage={filters?.page}
                            itemsCountPerPage={filters?.count}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            hideFirstLastPages
                            prevPageText="Previous"
                            nextPageText="Next"
                            itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                            activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                            onChange={e => handlePagination(e)}
                        />
                    </div>
                    : null}
                    </div>
            </div>

        </>
    )
}

export default UsersListing