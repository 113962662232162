import Select from "react-select";

const Html = ({ options, selectedValues, handleChange, displayValue, id, placeholder, className, required, closeMenuOnSelect, disabled, maxSelect }) => {
  let _options = options?.map((itm) => {
    return { value: itm.id, label: itm[displayValue] };
  });

  if (_options?.length > 1 && options?.length - selectedValues?.length > 1) {
    _options = [
      {
        value: "all",
        label: "Select All",
      },
    ].concat(_options);
  }

  const onChangeHandler = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (maxSelect) {
        selectedOptions = _options.slice(1, 1 + maxSelect);
      } else {
        selectedOptions = _options.slice(1);
      }
    }
    if (maxSelect && selectedOptions.length > maxSelect) {
      selectedOptions = selectedOptions.slice(0, maxSelect);
    }
    handleChange(selectedOptions);
  };

  return (
    <>
      <div className="selectDropdown">
        <Select
          defaultValue={displayValue}
          isMulti
          value={selectedValues || []}
          options={_options}
          className={`basic-multi-select ${className}`}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          classNamePrefix="select"
          isDisabled={disabled}
          onChange={onChangeHandler}
          required={required}
        />
      </div>
    </>
  );
};

export default Html;