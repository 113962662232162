import { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "antd";
import shared from "./shared";
import { IoCloseOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import DesktopSidebar from "../../Home/Sidebars/DesktopSidebar";
import FormControl from "../../../common/FormControl";
import { IoIosArrowBack } from "react-icons/io";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { CiRead } from "react-icons/ci";
import { MdAddCircleOutline, MdDeleteForever } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";


const AddEditCrewMember = () => {
  const { id } = useParams();
  const [form, setform] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    description: "",
    image: "",
    youtube_link: "",
    instagram_link: "",
    facebook_link: "",
    twitter_link: "",
    linkedin_link: "",
    company_ein: "",
    ein_image: "",
    permission: {
      readPost: false,
      addPost: false,
      editPost: false,
      deletePost: false,
    },
  });
  const history = useNavigate();
  const [tab, setTab] = useState(0)

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(shared.detailApi, { id }).then((res) => {
        if (res.success) {
          setform({
            firstName: res?.data?.firstName || "",
            lastName: res?.data?.lastName || "",
            email: res?.data?.email || "",
            mobileNo: res?.data?.mobileNo || "",
            description: res?.data?.description || "",
            image: res?.data?.image || "",
            youtube_link: res?.data?.youtube_link || "",
            instagram_link: res?.data?.instagram_link || "",
            facebook_link: res?.data?.facebook_link || "",
            twitter_link: res?.data?.twitter_link || "",
            linkedin_link: res?.data?.linkedin_link || "",
            permission: res?.data?.permission || "",
            id: res?.data?.id || res?.data?._id
          })
        }
        loader(false);
      });
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tab !== 3) {
      setTab(tab + 1)
      return
    }
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      role: shared.role
    }
    if (id) {
      method = "put";
      url = shared.editApi;
      delete value.role
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        history(`/${shared.url}`);
      }
      loader(false);
    });
  }

  const ImageUpload = (e, key) => {
    let files = e.target.files
    let file = files.item(0)
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
    if (!allowedTypes.includes(file.type)) {
      toast.error("Only JPG and PNG images are allowed.");
      return;
    }
    loader(true)
    ApiClient.postFormData('upload/image', { file: file }).then(res => {
      if (res.success) {
        setform({ ...form, [key]: res?.fileName })
      }
      loader(false)
    })
  }

  return (
    <>
      <div className="">
        <div className="bg-white w-full h-screen">
          <DesktopSidebar />
          <form onSubmit={handleSubmit} className="add_page w-full p-5">
            <div className="">
              <div className="flex items-center mb-4">
                <Tooltip placement="top" title="Back">
                  <Link to={`/${shared.url}`} className="!px-2 py-2 flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all bg-white mr-3">
                    <IoIosArrowBack />
                  </Link>
                </Tooltip>
                <div>
                  <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                    {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                  </h3>
                </div>
              </div>
              <div className="border-[1px] rounded-[15px] p-5 gap-6">
                <div className="flex w-full justify-center">
                  <div className="w-full">
                    <TabGroup selectedIndex={tab} onChange={e => setTab(e)}>
                      <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                        <Tab key="Info" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          Info
                        </Tab>
                        <Tab key="About" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          About
                        </Tab>
                        <Tab key="Social Media" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          Social Media
                        </Tab>
                        <Tab key="Permissions" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          Permissions
                        </Tab>
                      </TabList>
                      <TabPanels className="mt-3">
                        <TabPanel key="Info">
                          <ul>
                            <li key="Info" className="relative rounded-md text-sm/6 transition">
                              <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <FormControl
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    value={form?.firstName}
                                    onChange={(e) => setform({ ...form, firstName: e })}
                                    placeholder="Enter First Name"
                                    required
                                  />
                                </div>
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <FormControl
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    value={form?.lastName}
                                    onChange={(e) => setform({ ...form, lastName: e })}
                                    placeholder="Enter Last Name"
                                    required
                                  />
                                </div>
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <FormControl
                                    type="text"
                                    name="email"
                                    label="Email"
                                    value={form?.email}
                                    onChange={(e) => setform({ ...form, email: e })}
                                    placeholder="Enter Email"
                                    disabled={id}
                                    required
                                  />
                                </div>
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <FormControl
                                    type="phone"
                                    name="mobileNo"
                                    label="Mobile Number"
                                    value={form?.mobileNo}
                                    onChange={(e) => setform({ ...form, mobileNo: e })}
                                  />
                                </div>
                                <div className="col-span-12 mb-3">
                                  <label>Image (JPG/PNG)</label>
                                  <div className={`flex flex-col rounded-lg gap-6 max-sm:mx-auto cursor-pointer`}>
                                    {form?.image ? (
                                      <>
                                        <div className="flex flex-wrap gap-3 mt-3">
                                          <div className="relative">
                                            <img src={methodModel.userImg(form?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                            <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, image: "" })} size={25} />
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                        <FiPlus />
                                        <input
                                          id="dropzone-file"
                                          type="file"
                                          className="hidden"
                                          value={""}
                                          onChange={e => ImageUpload(e, "image")}
                                        />
                                        Upload Image
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="About">
                          <ul>
                            <li key="About" className="relative rounded-md text-sm/6 transition">
                              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-3">
                                <div>
                                  <FormControl
                                    type="editor"
                                    name="description"
                                    label="Description"
                                    value={form?.description}
                                    onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="Social Media">
                          <ul>
                            <li key="Social Media" className="relative rounded-md text-sm/6 transition">
                              <div className="">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                  <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="youtube_link"
                                      label="YouTube Link"
                                      placeholder="Enter YouTube Link"
                                      autoComplete="one-time-code"
                                      value={form?.youtube_link}
                                      onChange={(e) => setform({ ...form, youtube_link: e })}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="instagram_link"
                                      label="Instagram Link"
                                      placeholder="Enter Instagram Link"
                                      autoComplete="one-time-code"
                                      value={form?.instagram_link}
                                      onChange={(e) => setform({ ...form, instagram_link: e })}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="facebook_link"
                                      label="FaceBook Link"
                                      placeholder="Enter FaceBook Link"
                                      autoComplete="one-time-code"
                                      value={form?.facebook_link}
                                      onChange={(e) => setform({ ...form, facebook_link: e })}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="twitter_link"
                                      label="Twitter Link"
                                      placeholder="Enter Twitter Link"
                                      autoComplete="one-time-code"
                                      value={form?.twitter_link}
                                      onChange={(e) => setform({ ...form, twitter_link: e })}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="linkedin_link"
                                      label="LinkedIn Link"
                                      placeholder="Enter LinkedIn Link"
                                      autoComplete="one-time-code"
                                      value={form?.linkedin_link}
                                      onChange={(e) => setform({ ...form, linkedin_link: e })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="Permissions">
                          <ul>
                            <li key="Permissions" className="relative rounded-md text-sm/6 transition">
                              <div className="flex items-center gap-3 flex-wrap">
                                <label className={`relative p-3 ${form?.permission?.readPost ? "bg-white border-[2px] border-[#005AAB]" : "bg-white border-[2px] border-white"} shadow-[0px_0px_15px_-1px_#c1c1c1] rounded-lg text-gray-600 capitalize cursor-pointer`}>
                                  <input
                                    type="checkbox"
                                    checked={form?.permission?.readPost}
                                    onChange={(e) => setform((prev) => ({ ...prev, permission: { ...prev?.permission, readPost: e.target.checked } }))}
                                    className={`absolute hidden cursor-pointer`}
                                  />
                                  <span className="cursor-pointer">
                                    <div className="flex items-center justify-center"><CiRead size={30} /></div>
                                    <div className="flex items-center min-h-[36px]">
                                      <span className={`p_text mr-0 mt-2  ${form?.permission?.readPost ? "text-[#005AAB]" : "text-black"} capitalize flex justify-center leading-[14px] text-center w-[100px] text-[12px]`}>
                                        Read Post
                                      </span>
                                    </div>
                                  </span>
                                </label>
                                <label className={`relative p-3 ${form?.permission?.addPost ? "bg-white border-[2px] border-[#005AAB]" : "bg-white border-[2px] border-white"} shadow-[0px_0px_15px_-1px_#c1c1c1] rounded-lg text-gray-600 capitalize cursor-pointer`}>
                                  <input
                                    type="checkbox"
                                    checked={form?.permission?.addPost}
                                    onChange={(e) => setform((prev) => ({ ...prev, permission: { ...prev?.permission, addPost: e.target.checked } }))}
                                    className={`absolute hidden cursor-pointer`}
                                  />
                                  <span className="cursor-pointer">
                                    <div className="flex items-center justify-center"><MdAddCircleOutline size={30} /></div>
                                    <div className="flex items-center min-h-[36px]">
                                      <span className={`p_text mr-0 mt-2  ${form?.permission?.addPost ? "text-[#005AAB]" : "text-black"} capitalize flex justify-center leading-[14px] text-center w-[100px] text-[12px]`}>
                                        Add Post
                                      </span>
                                    </div>
                                  </span>
                                </label>
                                <label className={`relative p-3 ${form?.permission?.editPost ? "bg-white border-[2px] border-[#005AAB]" : "bg-white border-[2px] border-white"} shadow-[0px_0px_15px_-1px_#c1c1c1] rounded-lg text-gray-600 capitalize cursor-pointer`}>
                                  <input
                                    type="checkbox"
                                    checked={form?.permission?.editPost}
                                    onChange={(e) => setform((prev) => ({ ...prev, permission: { ...prev?.permission, editPost: e.target.checked } }))}
                                    className={`absolute hidden cursor-pointer`}
                                  />
                                  <span className="cursor-pointer">
                                    <div className="flex items-center justify-center"><AiOutlineEdit size={30} /></div>
                                    <div className="flex items-center min-h-[36px]">
                                      <span className={`p_text mr-0 mt-2  ${form?.permission?.editPost ? "text-[#005AAB]" : "text-black"} capitalize flex justify-center leading-[14px] text-center w-[100px] text-[12px]`}>
                                        Edit Post
                                      </span>
                                    </div>
                                  </span>
                                </label>
                                <label className={`relative p-3 ${form?.permission?.deletePost ? "bg-white border-[2px] border-[#005AAB]" : "bg-white border-[2px] border-white"} shadow-[0px_0px_15px_-1px_#c1c1c1] rounded-lg text-gray-600 capitalize cursor-pointer`}>
                                  <input
                                    type="checkbox"
                                    checked={form?.permission?.deletePost}
                                    onChange={(e) => setform((prev) => ({ ...prev, permission: { ...prev?.permission, deletePost: e.target.checked } }))}
                                    className={`absolute hidden cursor-pointer`}
                                  />
                                  <span className="cursor-pointer">
                                    <div className="flex items-center justify-center"><MdDeleteForever size={30} /></div>
                                    <div className="flex items-center min-h-[36px]">
                                      <span className={`p_text mr-0 mt-2  ${form?.permission?.deletePost ? "text-[#005AAB]" : "text-black"} capitalize flex justify-center leading-[14px] text-center w-[100px] text-[12px]`}>
                                        Delete Post
                                      </span>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </div>
                <div className="text-right">
                  {tab !== 0 &&
                    <button type="button" onClick={e => setTab(tab - 1)} className="text-white bg-[#063688] mr-3 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                      Back
                    </button>
                  }
                  <button type="submit" className="text-white bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                    {tab !== 3 ? "Next" : form?.id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEditCrewMember;
