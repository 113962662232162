import Logo from "../../assets/images/logo.png"
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import { useEffect, useState } from "react";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const token = sessionStorage.getItem("token")
  const navigate = useNavigate()
  const [socialLinks, setSocialLinks] = useState({})

  useEffect(() => {
    getSocialMediaLinks()
  }, [])

  const getSocialMediaLinks = () => {
    ApiClient.get(`platform/fee/ticket/detail?slug=${environment.settingSlug}`).then(res => {
      if (res.success) {
        setSocialLinks(res?.data)
      }
    })
  }

  return (
    <>
      <footer className="bg-[#2B2B2B]">
        <div className="container px-5 pt-12 pb-5 mx-auto">
          <div className="grid max-[480px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-5 mb-5">
            <div className="">
              <img src={Logo} onClick={e => { navigate(`/`) }} className="max-w-[150px] cursor-pointer" alt="logo" />
            </div>
            <div className="">
              <h2 className="text-[16px] font-[500] text-white mb-2">Your Account</h2>
              <div className="">
                {!token &&
                  <>
                    <p onClick={e => { navigate(`/joinmarc`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Sign up</p>
                    <p onClick={e => { navigate(`/signin`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Log in</p>
                  </>}
                <p onClick={e => { navigate(`/gethelp`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Help</p>
              </div>
            </div>
            <div className="">
              <h2 className="text-[16px] font-[500] text-white mb-2">Explore</h2>
              <div className="">
                <p onClick={e => { navigate(`/Event`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Find Events</p>
                <p onClick={e => { navigate(`/eventmap`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Events Map</p>
                <p onClick={e => { navigate(`/all/users`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">The Community</p>
              </div>
            </div>
            <div className="">
              <h2 className="text-[16px] font-[500] text-white mb-2"> Experience Marc</h2>
              <div className="">
                <p onClick={e => { navigate(`/aboutus`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">About</p>
                <p onClick={e => { navigate(`/experiencemarc`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">How It Works</p>
                {/* <p className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Success Stories</p> */}
              </div>
            </div>
            <div className="">
              <h2 className="text-[16px] font-[500] text-white mb-2">Support:</h2>
              <div className="">
                <p onClick={e => { navigate(`/gethelp`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Help Center</p>
                <p onClick={e => { navigate(`/gethelp`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">FAQs</p>
                <p onClick={e => { navigate(`/contactus`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Contact Us</p>
              </div>
            </div>
            <div className="">
              <h2 className="text-[16px] font-[500] text-white mb-2">Legal:</h2>
              <div className="">
                <p onClick={e => { navigate(`/privacypolicy`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Privacy Policy</p>
                <p onClick={e => { navigate(`/termsofservices`) }} className="text-[#cdcdcd] text-[14px] front-[400] cursor-pointer hover:text-[#E1B524] mb-2 w-fit">Terms of Service</p>
              </div>
            </div>
          </div>
          <div className="">
            <h2 className="text-[16px] font-[500] text-white mb-2">Follow us</h2>
            <div className="flex flex-wrap gap-5 justify-between items-center">
              <div className="flex gap-6">
                <FaFacebook onClick={e => { window.open(socialLinks?.facebook_link) }} className="text-[#cdcdcd] hover:text-[#E1B524] cursor-pointer" />
                <FaXTwitter onClick={e => { window.open(socialLinks?.twitter_link) }} className="text-[#cdcdcd] hover:text-[#E1B524] cursor-pointer" />
                <FaInstagram onClick={e => { window.open(socialLinks?.instagram_link) }} className="text-[#cdcdcd] hover:text-[#E1B524] cursor-pointer" />
                <FaLinkedin onClick={e => { window.open(socialLinks?.linkedin_link) }} className="text-[#cdcdcd] hover:text-[#E1B524] cursor-pointer" />
              </div>
              <div className="flex gap-5">
                <img src="/assets/img/google.png" className="w-[90px] cursor-pointer" />
                <img src="/assets/img/app.png" className="w-[90px] cursor-pointer" />
              </div>
            </div>
          </div>
          <p className="text-center text-[13px] text-[#cdcdcd] mt-5">Marc is a trademark of Citybird LLC. All rights reserved. The Marc platform is operated by Citybird LLC.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;