import { FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { CiLocationOn } from "react-icons/ci";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { NavigationBar } from "../Landing/NavigationBar";
import moment from "moment";
import FormControl from "../../common/FormControl";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

const ProfilePage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [detail, setDetail] = useState("")
    const [events, setEvents] = useState([])
    const backgroundImage = `url(${methodModel.noImg(detail?.backgroundImage || "")})`
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "" })

    useEffect(() => {
        if (id) {
            getDetails()
            getInfluencerEvents()
        }
    }, [id])

    const getDetails = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setDetail(res?.data)
            }
            loader(false)
        })
    }

    const getInfluencerEvents = () => {
        ApiClient.get(`event/influencer/event/listing?userId=${id}`).then(res => {
            if (res.success) {
                setEvents(res?.data)
            }
        })
    }

    const handleLikeFollowEvent = (type) => {
        const payload = {
            id: detail?.id || detail?._id,
            type: type,
            userId: user?._id || user?.id,
            addedTo: "influencer",
        }
        loader(true)
        ApiClient.post(`event/${type}`, payload).then(res => {
            if (res.success) {
                getDetails()
            }
            loader(false)
        })
    }

    const handleEventRedirection = (eventDetail) => {
        const url = `/EventDetails/${eventDetail?._id || eventDetail?.id}${eventDetail?.type === "paid" ? `?influencerId=${id}` : ""}`
        navigate(url)
    }

    const getCollaborationStatus = () => {
        const colabData = detail?.collaboraterDetail
        const userId = user?._id || user?.id
        let value = ""
        colabData?.filter((item) => {
            if (item?.addedBy?._id === userId) {
                value = item?.request_status
            }
        })
        return value
    }
    const handleColab = () => {
        if (getCollaborationStatus()) {
            return
        } else {
            setForm({ description: "" })
            setIsOpen(true)
        }
    }
    const handleCollabration = (e) => {
        e.preventDefault()
        const payload = {
            venueId: id,
            description: form?.description,
        }
        loader(true)
        ApiClient.post(`host/collaborate`, payload).then(res => {
            if (res.success) {
                getDetails()
                setIsOpen(false)
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="">
                <NavigationBar />
                <div className="">
                    <div className={`bg-cover bg-no-repeat w-full`} style={{ backgroundImage: backgroundImage }}>
                        <div className="bg-[#0000009c]">
                            <div className="container mx-auto px-5 relative">
                                <div className="max-[639px]:flex-wrap flex items-center pt-5 pb-10 min-h-[400px]">
                                    <img src={methodModel.userImg(detail?.image)} className="bg-white w-[150px] sm:w-[200px] h-[200px] sm:h-[250px] object-cover rounded-[5px]"></img>
                                    <div className="max-[639px]:w-full max-[639px]:ml-0 ml-8">
                                        <h2 className="text-white text-[22px] sm:text-[28px] font-[600]">{detail?.fullName || `${detail?.firstName || ""} ${detail?.lastName || ""}`}</h2>
                                        <p className="text-white mb-1">{detail?.email}</p>
                                        <p className="flex flex-wrap gap-x-2 mb-1">
                                            <span className="text-white text-[12px] font-[300]">{detail?.location || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.country || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.state || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.city || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.zipCode || "--"}</span>
                                        </p>
                                        <p className="text-white text-[12px] font-[300] mb-1">{detail?.mobileNo ? `+${detail?.mobileNo}` : "--"}</p>
                                        <p className="text-white text-[12px] font-[500]">Followers ( {detail?.follow_count || 0} )</p>
                                        {user?.role === "venue" || user?.role === "host" || user?.role === "crew" || user?.role === "crew_member" ?
                                            <div className="flex gap-3 mt-3 mb-3">
                                                <button type="button" onClick={e => handleLikeFollowEvent("follow")} className="text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-[6px] px-5 text-center cursor-pointer hover:opacity-[90%]">
                                                    {detail?.follow_data?.some((item) => item?.addedBy === user?._id) ? "Unfollow" : "Follow"}
                                                </button>
                                            </div>
                                            :
                                            null
                                        }
                                        {user?.role === "venue" &&
                                            <div>
                                                <button type="button" onClick={e => handleColab()} className={`text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-[6px] px-5 text-center ${getCollaborationStatus() ? "cursor-not-allowed" : "cursor-pointer hover:opacity-[90%]"}`}>{getCollaborationStatus() ? <span className="capitalize">Collaboration Status: {getCollaborationStatus()}</span> : "Collaborate with Influencer"}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {detail?.youtube_link || detail?.instagram_link || detail?.facebook_link || detail?.twitter_link || detail?.linkedin_link ?
                                    <div className="absolute bottom-[20px] right-[20px]">
                                        <div className='flex flex-wrap gap-5'>
                                            {detail?.youtube_link && <FaYoutube onClick={() => { window.open(detail?.youtube_link, "_blank") }} className="text-white cursor-pointer hover:text-[#005AAB] text-[20px]" />}
                                            {detail?.instagram_link && <PiInstagramLogoFill onClick={() => { window.open(detail?.instagram_link, "_blank") }} className="text-white cursor-pointer hover:text-[#005AAB] text-[20px]" />}
                                            {detail?.facebook_link && <FaFacebook onClick={() => { window.open(detail?.facebook_link, "_blank") }} className="text-white cursor-pointer hover:text-[#005AAB] text-[20px]" />}
                                            {detail?.twitter_link && <FaXTwitter onClick={() => { window.open(detail?.twitter_link, "_blank") }} className="text-white cursor-pointer hover:text-[#005AAB] text-[20px]" />}
                                            {detail?.linkedin_link && <FaLinkedin onClick={() => { window.open(detail?.linkedin_link, "_blank") }} className="text-white cursor-pointer hover:text-[#005AAB] text-[20px]" />}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="pb-5">
                        <div className="container mx-auto px-5">
                            <div className="">
                                <div className="w-full">
                                    <TabGroup>
                                        <TabList className="flex border-b bg-white overflow-auto scroll_hide mb-5">
                                            <Tab key="ABOUT" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                ABOUT
                                            </Tab>
                                            <Tab key="GALLERY" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                GALLERY
                                            </Tab>
                                            <Tab key="Events" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                Events
                                            </Tab>
                                        </TabList>
                                        <TabPanels className="mt-3">
                                            <TabPanel key="ABOUT" className="rounded-xl bg-white/5">
                                                <p className="text-[#6d6d6d] font-[300] mb-8" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                            </TabPanel>
                                            <TabPanel key="GALLERY" className="rounded-xl bg-white/5">
                                                {detail?.images?.length > 0 ?
                                                    <div className="mb-10">
                                                        <h2 className="text-[#242424] text-[18px] font-[600] mb-3">Images</h2>
                                                        <div className="grid max-[499px]:grid-cols-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-5 mb-5">
                                                            {detail?.images?.map((item, index) => {
                                                                return <img src={methodModel.noImg(item)} key={index} className="max-[499px]:h-[115px] h-[125px] sm:h-[130px] md:h-[110px] lg:h-[150px] xl:h-[200px] 2xl:h-[230px] w-full object-cover rounded-xl"></img>
                                                            })}
                                                        </div>
                                                    </div>
                                                    : null}
                                                {detail?.videos?.length > 0 ?
                                                    <div className="mb-10">
                                                        <h2 className="text-[#242424] text-[18px] font-[600] mb-3">Videos</h2>
                                                        <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                                                            {detail?.videos?.map((item, index) => {
                                                                return <video key={index} className="max-[499px]:h-[190px] h-[160px] sm:h-[120px] md:h-[110px] lg:h-[140px] xl:h-[180px] 2xl:h-[220px] w-full bg-black rounded-xl" controls>
                                                                    <source src={methodModel.video(item)} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            })}
                                                        </div>
                                                    </div>
                                                    : null}
                                            </TabPanel>
                                            <TabPanel key="Events" className="rounded-xl bg-white/5">
                                                {events?.length === 0 ?
                                                    <p className="text-center">No Events Found</p>
                                                    :
                                                    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                                                        {events?.map((item, index) => {
                                                            return <div onClick={e => handleEventRedirection(item?.event_id)} className="claimbtn2 overflow-hidden bg-white" key={index}>
                                                                <div className="event_card relative cursor-pointer rounded-md overflow-hidden">
                                                                    <img src={methodModel.noImg(item?.event_id?.images?.[0])} className="object-cover w-full rounded-md max-[499px]:h-[180px] max-[639px]:h-[250px] h-[160px] 2xl:h-[220px] transition-all duration-300 hover:scale-110" />
                                                                    {item?.event_id?.type === "paid" &&
                                                                        <div className="claimbtn_data2">
                                                                            <div className="absolute top-0 left-0 w-full h-full bg-[#00000082] flex justify-center items-center cursor-pointer">
                                                                                <button className="group flex w-fit h-fit text-[14px] text-white items-center gap-2 rounded-full py-1.5 px-8 bg-[#005AAB]">Buy Tickets</button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="cursor-pointer p-3 min-h-[115px]">
                                                                    <h2 className="p_text font-[500] text-[16px] leading-[18px] mb-2 capitalize">{item?.event_id?.title || "--"}</h2>
                                                                    <p className="font-[400] text-[13px]">{moment(item?.event_id?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</p>
                                                                    <p className="flex items-center gap-1 font-[400] text-[13px] mt-1">
                                                                        <CiLocationOn className="text-[18px]" />
                                                                        <span className="ellipsis_class text-[#585858] leading-[17px]">{item?.event_id?.location}</span>
                                                                    </p>
                                                                    <p className="ellipsis_class text-[#585858] text-[13px] leading-[16px] mt-1" dangerouslySetInnerHTML={{ __html: item?.event_id?.description }}></p>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            </TabPanel>
                                        </TabPanels>
                                    </TabGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Collaborate with Influencer</DialogTitle>
                            </div>
                            <form onSubmit={handleCollabration}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ProfilePage