import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import { NavigationBar } from "../Landing/NavigationBar"
import DatePicker from 'react-datepicker';
import ApiClient from "../../methods/api/apiClient";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import moment from "moment";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import Location from '../../assets/images/Icons/location.svg';
import Pagination from "react-js-pagination";

const AllEvents = () => {
    const [events, setEvents] = useState([])
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "", date: "", status: "active" })
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getAllEvents()
    }, [])

    const getAllEvents = (f = {}) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        const filter = { ...filters, ...f, lat: userLat, long: userLong }
        loader(true)
        let url = "event/listing"
        ApiClient.get(url, filter).then(res => {
            if (res.success) {
                setEvents(res?.data)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const clearSearch = () => {
        const filter = { search: "", date: "", status: "active" }
        setFilters(filter)
        getAllEvents(filter)
    }
    const handleSearch = () => {
        let eventDate = filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : ""
        getAllEvents({ ...filters, date: String(eventDate) })
    }

    const handlePagination = (e) => {
        setFilters((prev)=>({ ...prev, page: e }))
        getAllEvents({ page: e })
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="w-[90%] mx-auto flex flex-col items-center justify-center py-[80px]">
                    <h2 className="font-[700] text-[32px] sm:text-[45px] text-[#E1B524] text-center leading-[38px] sm:leading-[48px]">
                        Exclusive events, priceless moments
                    </h2>
                    <div className="w-full flex md:flex-row flex-col justify-center items-center gap-2 pt-[60px]">
                        <div className="search_all max-w-[605px] w-full flex justify-between rounded-[30px] border border-[1px] !border-[#6a737f] bg-white px-3 py-1">
                            <input
                                type="text"
                                value={filters?.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="max-[499px]:w-[50px] bg-transparent flex-1 text-[14px]] outline-none border-0 focus:outline-none focus:ring-0"
                                placeholder="Search by events, name, location and more"
                            />
                            <DatePicker
                                selected={filters?.date}
                                onChange={(date) => setFilters({ ...filters, date: date })}
                                className="bg-transparent border-0 text-[#AEAEAE] text-[14px] outline-none focus:outline-none focus:ring-0 w-[180px]"
                                placeholderText='mm/dd/yyyy'
                                minDate={new Date()}
                            />
                        </div>
                        <div className="flex gap-2">
                        {filters?.search || filters?.date
                            ?
                            <button type='button' onClick={e => clearSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                                <IoMdClose className='text-[18px] mr-1' /> Clear
                            </button>
                            : null
                        }
                        <button type='button' onClick={e => handleSearch()} className="flex items-center justify-center text-[14px] text-nowrap font-[400] py-3 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:opacity-[80%] rounded-full md:inline-flex">
                            <IoIosSearch className='text-[18px] mr-1' /> Search
                        </button>
                        </div>
                    </div>
                </div>
                <div className="upcoming py-[40px]">
                <div className='container mx-auto px-5'>
                    <div className="">
                        <div className="w-full flex flex-row justify-between items-center">
                            <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                                All Events
                            </h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-5 md:mt-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                        {events.map((item, index) => (
                            <Link to={`/EventDetails/${item?._id || item?.id}`} key={index}>
                                <div className=" overflow-hidden bg-white rounded-lg shadow-md">
                                    <img className="object-cover w-full h-[146px]" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                    <div className="p-4 min-h-[115px]">
                                        <h2 className="ellipises_heading font-[500] text-[16px] capitalize">
                                            {item?.title}
                                        </h2>
                                        <p className="font-[400] text-[13px]">
                                            {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                        </p>
                                        <p className="flex items-center gap-2 font-[400] text-[13px] mt-1">
                                            <img src={Location} alt="location_icon" />
                                            <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    {events?.length === 0 ?
                        <div className='text-center'>No Records Found</div>
                        : null}
                    {total > filters?.count ?
                        <div className="w-[85%] pagination_div text-right mx-auto">
                            <Pagination
                                activePage={filters?.page}
                                itemsCountPerPage={filters?.count}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideFirstLastPages
                                prevPageText="<"
                                nextPageText=">"
                                onChange={e => handlePagination(e)}
                            />
                        </div>
                        : null}
                        </div>
                </div>
            </div>
        </>
    )
}

export default AllEvents