import { useState, useEffect } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Footer';
import InstaIcon from '../../assets/images/Icons/insta.png';
import FBIcon from '../../assets/images/Icons/face.png';
import LinkIcon from '../../assets/images/Icons/link.png';
import TwtIcon from '../../assets/images/Icons/twit.png';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import { FaCheck, FaHeart, FaRegHeart, FaYoutube } from 'react-icons/fa';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import SigninModal from '../Auth/SigninModal';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import moment from 'moment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomMap from '../EventDetail/CustomMap';

const HostProfile = ({ setIsSignup, thirdPartySignInClicked }) => {
    const { id } = useParams()
    const user = useSelector((state) => state.user)
    const token = sessionStorage.getItem("token")
    const navigate = useNavigate()
    const [detail, setDetail] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [hostPosts, setHostPosts] = useState([])
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        if (id) {
            getHostDetails()
            getHostsPosts()
            getLocationAvailability()
        }
    }, [])

    const getHostDetails = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setDetail(res?.data)
                if (res?.data?.isBoostUsed && res?.data?.boostCategory === "profile" || res?.data?.isBoostUsed && res?.data?.boostCategory === "location") {
                    getTrackingDetails(res?.data)
                }
            }
            loader(false)
        })
    }

    const getTrackingDetails = (data) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        let payload = {
            userId: data?._id || data?.id,
            boostType: data?.boostType,
            boostCategory: data?.boostCategory,
            corrdinates: {
                type: "Point",
                coordinates: [userLat || "", userLong || ""]
            },
            deviceToken: sessionStorage.getItem("deviceToken") || ""
        }
        if (token) {
            payload = { ...payload, visitedUserId: user?._id || user?.id }
        }
        ApiClient.post(`boost/track/analytics`,payload)
    }

    const getLocationAvailability = () => {
        ApiClient.get(`host/availability/listing?hostId=${id}`, {}, "", true).then(res => {
            if (res.success) {
                let locations = []
                let mapview = {}
                res?.data?.location_availability?.map((item, index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel })
                    if (index === 0) {
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], isBoostUsed: item?.isBoostUsed || false, info: item?.location, date: item?.date, startTime: item?.start_time, endTime: item?.end_time, freeToTravel: item?.free_to_travel }
                    }
                })
                setLocations(locations)
                setMapView(mapview)
            }
        })
    }

    const getHostsPosts = () => {
        loader(true)
        ApiClient.get(`venue/get-all-venue-post?addedFor=${id}&postType=${user?.role === "venue" || user?.role === "host" ? "openOpportunities,collaborateConnect" : "promote"}`).then(res => {
            if (res.success) {
                setHostPosts(res?.data)
            }
            loader(false)
        })
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = (role = "") => {
        setIsModalOpen(false);
    }

    const handleLikeFollowEvent = (type) => {
        if (user?._id || user?.id) {
            const payload = {
                id: detail?.id || detail?._id,
                type: type,
                userId: user?._id || user?.id,
                addedTo: "host",
            }
            loader(true)
            ApiClient.post(`event/${type}`, payload).then(res => {
                if (res.success) {
                    getHostDetails()
                }
                loader(false)
            })
        } else {
            openModal()
        }
    }

    return (
        <>
            <NavigationBar />
            <div className="venue-profile bg-[#D9D9D938] pb-[40px] pt-5">
                <div className="container mx-auto px-5 gap-9">
                    <div className='bg-white'>
                        <div className=''>
                            <img src={methodModel.noImg(detail?.backgroundImage)} className='w-full max-[499px]:h-[180px] h-[250px] lg:h-[350px] object-cover bg-no-repeat rounded-xl' alt="profileBackgroundImage" />
                        </div>
                        <div className='flex justify-between flex-wrap gap-5 items-end relative top-[-40px] px-5'>
                            <h2 className="flex gap-5 text-left items-end text-black font-montserrat">
                                <img className="w-[100px] h-[100px] object-cover border-[4px] border-white rounded-full bg-white shadow" src={methodModel.userImg(detail?.image)} alt="host_image" />
                                <div>
                                    <h2 className='max-[639px]:text-[18px] text-[24px] font-[600] leading-[26px] capitalize'>{detail?.fullName || `${detail?.firstName || ""} ${detail?.lastName || ""}`}</h2>
                                    <p className='text-[#7e7e7e] max-[639px]:text-[12px] text-[14px]'>{detail?.email}</p>
                                </div>
                            </h2>
                            {user?.role === "member" || user?.role === "host" ? null :
                                <>
                                    {user?._id !== id &&
                                        <div className="flex gap-3">
                                            <div onClick={e => handleLikeFollowEvent("follow")} className="h-fit flex items-center justify-center cursor-pointer text-[16px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                                {detail?.follow_data?.some((item) => item?.addedBy === user?._id) ? "Unfollow" : "Follow"}
                                            </div>
                                            <div onClick={e => handleLikeFollowEvent("like")} className={`h-fit flex cursor-pointer items-center text-[16px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-full md:inline-flex bg-white`}>
                                                {detail?.like_data?.some((item) => item?.addedBy === user?._id) ?
                                                    <>
                                                        <FaHeart className={`mr-2 text-red-600`} /> Unlike
                                                    </>
                                                    :
                                                    <>
                                                        <FaRegHeart className={`mr-2`} /> Like
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="">
                            <div>
                                <div className="flex w-full justify-center">
                                    <div className="w-full">
                                        <TabGroup>
                                            <TabList className="flex border bg-white overflow-auto scroll_hide">
                                                <Tab key="TIMELINE" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600]">
                                                    TIMELINE
                                                </Tab>
                                                <Tab key="ABOUT" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600]">
                                                    ABOUT
                                                </Tab>
                                                {detail?.images?.length !== 0 && detail?.videos?.length !== 0 ?
                                                    <Tab key="GALLERY" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600]">
                                                        GALLERY
                                                    </Tab>
                                                    : null}
                                                {locations?.length > 0 ?
                                                    <Tab key="MAP" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600]">
                                                        MAP
                                                    </Tab>
                                                    : null}
                                            </TabList>
                                            <TabPanels className="mt-3">
                                                <TabPanel key="TIMELINE" className="rounded-xl bg-white/5">
                                                    <div className=' grid grid-cols-1 md:grid-cols-2 gap-5 bg-[#f9fcff] border p-5'>
                                                        <div>
                                                            <p className="text-[14px] font-normal text-gray-500 dark:text-gray-400 flex gap-2 mb-2">
                                                                <CiLocationOn className='text-[18px] text-[#005AAB] relative top-[2px]' />
                                                                {detail?.location}
                                                            </p>
                                                            <p className='text-[14px] font-normal text-gray-500 dark:text-gray-400 flex gap-2 mb-2'><IoCallOutline className='text-[18px] text-[#005AAB] relative top-[2px]' /> {detail?.mobileNo && `+${detail?.mobileNo}`}</p>
                                                            <p className="text-[14px] font-normal text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: detail?.short_description }}></p>
                                                        </div>
                                                        <div className='md:border-l md:pl-5'>
                                                            {detail?.skills?.length !== 0 &&
                                                                <div className='mb-3'>
                                                                    <h2 className="text-base font-semibold text-gray-900 dark:text-white mb-1">
                                                                        Skills
                                                                    </h2>
                                                                    <div className="flex flex-wrap gap-y-1 gap-x-5 capitalize">
                                                                        {detail?.skills?.map((item, index) => {
                                                                            return <div className="font-open-sans text-[14px] font-[400] leading-6 tracking-tight text-left text-gray-700 flex gap-2" key={index}><FaCheck className='text-[16px] text-green-600 relative top-[4px]' /> {item}</div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {!detail?.youtube_link && !detail?.instagram_link && !detail?.facebook_link && !detail?.twitter_link && !detail?.linkedin_link ? null :
                                                                <div className="">
                                                                    <p className="text-base font-semibold text-gray-900 dark:text-white">
                                                                        Social Media
                                                                    </p>
                                                                    <div className='flex flex-wrap gap-2'>
                                                                        {detail?.youtube_link && <FaYoutube onClick={() => { window.open(detail?.youtube_link, '_blank') }} className="w-[30px] h-[40px] bg-white rounded-full cursor-pointer text-red-600" />}
                                                                        {detail?.instagram_link && <img onClick={() => { window.open(detail?.instagram_link, '_blank') }} className="w-[40px] h-[40px] cursor-pointer" src={InstaIcon} alt="instagram_icon" />}
                                                                        {detail?.facebook_link && <img onClick={() => { window.open(detail?.facebook_link, '_blank') }} className="w-[40px] h-[40px] cursor-pointer" src={FBIcon} alt="facebook_icon" />}
                                                                        {detail?.twitter_link && <img onClick={() => { window.open(detail?.twitter_link, '_blank') }} className="w-[40px] h-[40px] cursor-pointer" src={TwtIcon} alt="twitter_icon" />}
                                                                        {detail?.linkedin_link && <img onClick={() => { window.open(detail?.linkedin_link, '_blank') }} className="w-[40px] h-[40px] cursor-pointer" src={LinkIcon} alt="linkdin_icon" />}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {user?.role === "host" || user?.role === "venue" ?
                                                                <>
                                                                    {detail?.expectedMinSalary && detail?.expectedMaxSalary ?
                                                                        <div className="">
                                                                            <h2 className="text-base font-semibold text-gray-900 dark:text-white mb-1">
                                                                                Expected Salary
                                                                            </h2>
                                                                            <div className='flex flex-wrap gap-2'>
                                                                                <div className='text-[14px] font-normal text-gray-500 dark:text-gray-400 flex gap-2'>
                                                                                    ${detail?.expectedMinSalary}
                                                                                    <span>to</span>
                                                                                    ${detail?.expectedMaxSalary}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className='bg-white mt-3'>
                                                        <div className='columns-1 md:columns-2 gap-0'>
                                                            {hostPosts?.map((item, index) => {
                                                                return <div className='break-inside-avoid border p-5 pb-8' key={index}>
                                                                    <div className='flex gap-2 mb-2 cursor-pointer' onClick={e => { navigate(`/post/detail/${item?._id || item?.id}`) }} >
                                                                        <img src={methodModel.noImg(detail?.image)} className='h-[40px] w-[40px] object-cover rounded-full'></img>
                                                                        <div className=''>
                                                                            <p className="text-base font-semibold text-gray-900 dark:text-white">{item?.title}</p>
                                                                            <p className="text-[12px] font-normal text-gray-500 dark:text-gray-400">{moment(item?.createdAt).format("DD MMM, YYYY")}</p>
                                                                        </div>
                                                                    </div>
                                                                    <p className='text-[14px] font-normal text-gray-500 dark:text-gray-400' dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                                    <Slider {...settings}>
                                                                        {item?.image?.map((itm, i) => {
                                                                            return <img src={methodModel.noImg(itm)} className='w-full h-[225px] object-contain mt-3' alt='post_image' key={i} />
                                                                        })}
                                                                        {item?.video?.map((itm, i) => {
                                                                            return <video key={i} className="h-[200px] w-full rounded-[15px] border" controls>
                                                                                <source src={methodModel.video(itm)} type="video/mp4" />
                                                                            </video>
                                                                        })}
                                                                    </Slider>
                                                                    {/* <div className='flex gap-5 justify-between mt-3'>
                                                                        <p className='text-[12px] text-[#005AAB]'><span className=''>Like</span> • <span className=''>Comment</span></p>
                                                                        <div className='flex gap-3'>
                                                                            <p className='flex gap-1 text-[12px]'><AiOutlineLike className='text-[16px] text-[#005AAB] cursor-pointer' /> 122</p>
                                                                            <p className='flex gap-1 text-[12px]'><LiaComment className='text-[16px] text-[#005AAB] cursor-pointer' /> 33 </p>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel key="ABOUT" className="rounded-xl bg-white/5">
                                                    <div className="bg-white border p-5">
                                                        <h3 className="text-lg capitalize font-semibold text-gray-900 dark:text-white mb-3">About {detail?.fullName || `${detail?.firstName || ""} ${detail?.lastName || ""}`}</h3>
                                                        <p className="font-open-sans text-base font-normal leading-7 tracking-tight text-left text-black" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                                    </div>
                                                </TabPanel>
                                                {detail?.images?.length !== 0 && detail?.videos?.length !== 0 ?
                                                    <TabPanel key="GALLERY" className="rounded-xl bg-white/5">
                                                        {detail?.images?.length !== 0 &&
                                                            <div className='mb-4'>
                                                                <h2 className='text-lg font-semibold text-gray-900 dark:text-white mb-2'>Photos</h2>
                                                                <div className='bg-white border p-5'>
                                                                    <div className="grid max-[499px]:grid-cols-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-5 mb-5">
                                                                        {detail?.images?.map((item, index) => {
                                                                            return <img src={methodModel.noImg(item)} className='max-[499px]:h-[115px]  h-[125px] sm:h-[130px] md:h-[110px] lg:h-[150px] xl:h-[200px] 2xl:h-[230px] w-full object-cover rounded-xl' alt="media_images" key={index} />
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {detail?.videos?.length !== 0 &&
                                                            <div className=''>
                                                                <h2 className='text-lg font-semibold text-gray-900 dark:text-white mb-2'>Videos</h2>
                                                                <div className='bg-white border p-5'>
                                                                    <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
                                                                        {detail?.videos?.map((item, index) => {
                                                                            return <video width="320" height="240" className="max-[499px]:h-[190px] h-[160px] sm:h-[120px] md:h-[110px] lg:h-[140px] xl:h-[180px] 2xl:h-[220px] w-full bg-black rounded-xl" key={index} controls>
                                                                                <source src={methodModel.video(item)} type="video/mp4" />
                                                                                <source src={methodModel.video(item)} type="video/ogg" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </TabPanel>
                                                    : null}
                                                {locations?.length > 0 ?
                                                    <TabPanel key="MAP" className="rounded-xl bg-white/5">
                                                        <CustomMap mapView={mapView} locations={locations} type="availabilty" />
                                                    </TabPanel>
                                                    : null}
                                            </TabPanels>
                                        </TabGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SigninModal isModalOpen={isModalOpen} closeModal={closeModal} setIsSignup={setIsSignup} thirdPartySignInClicked={thirdPartySignInClicked} />
        </>
    );
};

export default HostProfile;
