import { useEffect, useState } from "react";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { MdClear } from "react-icons/md";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useNavigate } from "react-router-dom";
import methodModel from "../../methods/methods";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormControl from "../../common/FormControl"
import { FaCheck } from "react-icons/fa";
import moment from "moment";

const JobPosts = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 10, search: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [seeMore, setSeeMore] = useState({ less: true, index: "" })
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "", id: "", salary: "" })

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, postType: "openOpportunities", status: "active" }
        loader(true)
        ApiClient.get("venue/get-all-venue-post", filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "", transaction_status: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }

    const handlePagination = (e) => {
        setFilter((prev) => ({ ...prev, page: e }))
        getData({ page: e })
    }

    const getApplyPostStatus = (item) => {
        const applyPostData = item?.apply_post_details
        const hostId = user?._id || user?.id
        let value = ""
        applyPostData?.filter((item) => {
            if (item?.addedBy === hostId) {
                value = item?.request_status
            }
        })
        return value
    }

    const handleAppyPost = (postId) => {
        if (getApplyPostStatus()) {
            return
        } else {
            setForm({ description: "", id: postId, salary: "" })
            setIsOpen(true)
        }
    }
    const applyPost = (e) => {
        e.preventDefault()
        const payload = {
            postId: form?.id,
            description: form?.description,
            salary: Number(form?.salary)
        }
        ApiClient.post(`host/post/apply`, payload).then(res => {
            if (res.success) {
                getData()
                setIsOpen(false)
            }
        })
    }

    const paymentRequest = (item) => {
        const payload = {
            appliedPostId: item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?._id,
            userId: item?.addedFor?._id
        }
        loader(true)
        ApiClient.post("job/payment/request", payload).then(res => {
            if (res.success) {
                getData()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 pb-5">
                    <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6 border border-[#f1f1f1] shadow-[0px_0px_15px_-12px] p-[1.5rem]">
                        <div className="flex justify-between flex-wrap items-center gap-5 mb-5 pb-5 border-b border-[#f1f1f1]">
                            <div className="">
                                <h3 className="text-2xl font-semibold text-[#134896]">Job Posts</h3>
                            </div>
                            <div className="flex items-center flex-wrap gap-2">
                                <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                                    {/* <label htmlFor="simple-search" className="sr-only">Search</label> */}
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            id="simple-search"
                                            value={filters?.search}
                                            onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                            autoComplete="one-time-code"
                                            className="text-gray-900 text-sm rounded-lg  focus:border-[#063688] block w-full ps-5 p-2.5 pr-10 border border-[#d1d1d1]"
                                            placeholder="Search"
                                        />
                                        {filters?.search && (
                                            <MdClear className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                        )}
                                        <button type="submit" className="absolute right-[3px] top-[2px] p-2.5 text-sm font-medium text-white bg-[#063688] rounded-lg border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            <svg
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                />
                                            </svg>
                                            <span className="sr-only">Search</span>
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="">
                            {data?.length === 0 ?
                                <div className="text-center py-10">No Data Found</div>
                                :
                                <div className="grid grid-cols-1">
                                    {data?.map((item, index) => {
                                        return <div className="border-b py-8" key={index}>
                                            <div className="flex flex-wrap justify-between gap-5">
                                                <div>
                                                    <div className="flex items-center gap-3">
                                                        <img src={methodModel.userImg(item?.addedBy?.image)} className="bg-[#D9D9D9] w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] object-cover rounded-full"></img>
                                                        <div className="capitalize">
                                                            <p className="max-[499px]:text-[12px] text-[14px]">{item?.addedBy?.venue_name || item?.addedBy?.fullName}</p>
                                                            <h2 className="max-[499px]:text-[14px] text-[16px] font-[600] leading-[18px]">{item?.title}</h2>
                                                        </div>
                                                    </div>
                                                   
                                                    <p className="text-[#7b7b7b] flex flex-wrap gap-x-3 mt-3 max-[499px]:!text-[12px] !text-[14px] !font-[500]">
                                                        <span className="">Salary: ${item?.startingSalary || 0} - ${item?.maximumSalary || 0} </span>
                                                    </p>
                                                    {getApplyPostStatus(item) &&
                                                        <div className=" max-[499px]:text-[12px] text-[14px] my-1">Job Post End Date & Time: <span className="text-[#134896]">{moment(item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.jobEndDate).format("DD MMM, YYYY hh:mm A")}</span></div>
                                                    }
                                                </div>
                                                {user?._id !== item?.addedFor?._id &&
                                                    <div className="">
                                                        {getApplyPostStatus(item) ?
                                                            <>
                                                                <div className="flex justify-end gap-2 items-center mt-2 mb-2">
                                                                    <span className="max-[499px]:text-[12px] text-[14px]">Applied Post Status: </span>
                                                                    <div className={`capitalize max-[499px]:text-[12px] text-[14px] h-fit ${getApplyPostStatus(item) === "rejected" ? "text-red-600" : getApplyPostStatus(item) === "accepted" ? "text-green-600" : "text-yellow-600"}`}> {getApplyPostStatus(item)}</div>
                                                                </div>
                                                                {new Date(item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.jobEndDate) < new Date() && item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.jobPostStatus === "working" && item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.pendingAmount !== 0 ?
                                                                    <>
                                                                        {item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.paymentRequestStatus === "pending" ?
                                                                            <button type="button" onClick={e => paymentRequest(item)} className="bg-[#134896] px-5 py-2 text-white max-[499px]:text-[12px] text-[14px] rounded-lg h-fit">Payment Request</button>
                                                                            :
                                                                            <div className="max-[499px]:text-[12px] text-[14px]">Payment Request: <span className="capitalize text-[#134896] max-[499px]:text-[12px] text-[14px] h-fit">{item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.paymentRequestStatus}</span></div>
                                                                        }
                                                                    </>
                                                                    : null
                                                                }
                                                            </>
                                                            :
                                                            <button type="button" onClick={() => handleAppyPost(item?._id || item?.id)} className="bg-[#134896] px-5 py-2 text-white max-[499px]:text-[12px] text-[14px] rounded-lg h-fit">Apply Post</button>
                                                        }
                                                        {item?.apply_post_details?.find((itm) => itm?.addedBy === user?._id)?.jobPostStatus === "completed" &&
                                                            <p className="max-[499px]:text-[12px] text-[14px] w-fit">Job Post: <span className="text-green-600">Completed</span></p>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <div className={`text-[14px] ${!seeMore?.less && seeMore?.index === index ? "" : "text_3line w-full"}`} dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                {item?.skills?.length > 0 &&
                                                    <p className="flex flex-wrap gap-x-2 mt-3 max-[499px]:!text-[12px] !text-[12px] !font-[500]">
                                                        {item?.skills?.map((itm, i) => {
                                                            return <span className="flex items-center bg-[#f1f1f1] text-[#919191] px-3 py-[2px] rounded-[30px] gap-2" key={i}>{itm}</span>
                                                        })}
                                                    </p>
                                                }
                                                <div onClick={() => { navigate(`/post/detail/${item?._id || item?.id}`) }} className="text-[#134896] max-[499px]:text-[12px] text-[14px] hover:underline cursor-pointer text-right w-fit ml-auto">See More</div>
                                            </div>

                                        </div>
                                    })}
                                </div>
                            }
                        </div>
                        {total > filters?.count ?
                            <div className="pagination_div w-full text-right">
                                <Pagination
                                    activePage={filters?.page}
                                    itemsCountPerPage={filters?.count}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                                    activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                                    onChange={e => handlePagination(e)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Apply For Post</DialogTitle>
                            </div>
                            <form onSubmit={applyPost}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    <FormControl
                                        type="number"
                                        name="salary"
                                        label="Salary ($)"
                                        placeholder="Enter Salary ($)"
                                        autoComplete="one-time-code"
                                        value={form?.salary}
                                        onChange={(e) => setForm({ ...form, salary: e })}
                                        maxlength={7}
                                        required
                                    />
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default JobPosts