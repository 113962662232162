import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "antd";
import shared from "./shared";
import { IoCloseOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import FormControl from "../../common/FormControl";
import { IoIosArrowBack, IoMdAddCircleOutline } from "react-icons/io";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useSelector } from "react-redux";
import SelectDropdown from "../../common/SelectDropdown";
import { GrSubtractCircle } from "react-icons/gr";
import { Dialog, DialogPanel } from '@headlessui/react'
import environment from "../../environment";
import { RxCross2 } from "react-icons/rx";

const AddEditPost = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user)
  const [form, setform] = useState({
    title: "",
    description: "",
    image: [],
    video: [],
    startingSalary: "",
    maximumSalary: "",
    skills: [""],
    link: "",
    addedFor: user?._id || user?.id,
    role: user?.role,
    postType: user?.role === "host" ? "advertise" : "",
  });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const formValidation = [
    { key: "title", required: true },
    { key: "description", required: true },
  ]
  const [tab, setTab] = useState(0)
  const [selectEvent, setSelectEvent] = useState({ modal: false, id: "" })
  const [eventListing, setEventListing] = useState([])

  const postTypeInfo = [
    { title: "Promote Event/Offer", description: "For posting events, specials, or updates to the guest side. Ideal for venues advertising upcoming shows, happy hours, or special events.", role: ["venue", "host", "influencer", "crew", "crew_member","food_truck_vendor"] },
    { title: "Talent Announcement", description: "Hosts use this to announce their availability, new tour locations, or upcoming gigs to other venues/hosts or the guest side.", role: ["host"] },
    { title: "Open Opportunities", description: "Venues can share job listings, gig opportunities, or open mic nights, inviting talent to apply or participate.", role: ["venue", "host","vendor"] },
    { title: "Collaborate & Connect", description: "Hosts and venues can use this to seek collaborations, partnerships, or shared events. Posts appear on the venue/host side.", role: ["venue", "influencer", "crew", "crew_member","vendor","food_truck_vendor"] },
  ]

  useEffect(() => {
    if (user?.role === "influencer") getEventListing()
    if (id) {
      loader(true);
      ApiClient.get(shared.detailApi, { id }).then((res) => {
        if (res.success) {
          setform({
            title: res?.payload?.title || "",
            description: res?.payload?.description || "",
            image: res?.payload?.image || "",
            video: res?.payload?.video || "",
            startingSalary: res?.payload?.startingSalary || "",
            maximumSalary: res?.payload?.maximumSalary || "",
            skills: res?.payload?.skills?.length > 0 ? res?.payload?.skills : [""],
            link: res?.payload?.link || "",
            role: res?.payload?.role,
            postType: res?.payload?.postType,
            addedFor: res?.payload?.addedFor,
            id: res?.payload?.id || res?.payload?._id
          })
        }
        loader(false);
      });
    }
  }, [])

  const getEventListing = () => {
    ApiClient.get(`event/influencer/event/listing?userId=${user?._id || user?.id}`).then(res => {
      if (res.success) {
        const data = res?.data?.filter((item)=> item?.event_id?.type === "paid")
        setEventListing(data?.map((item) => {
          return ({ id: item?.event_id?._id || item?.event_id?.id, name: item?.event_id?.title })
        }))
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    if (tab !== 1) {
      setTab(tab + 1)
      return
    }
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      skills: form?.skills?.filter((item) => item?.length > 1)
    }
    if (id) {
      method = "put";
      url = shared.editApi;
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        history(`/${shared.url}`);
      }
      loader(false);
    });
  }

  const ImageUpload = (e) => {
    let files = Array.from(e.target.files);
    const acceptedTypes = ['image/jpeg', 'image/png']; // Add more image types if needed
    const sizeLimits = { min: 0, max: 3 }; // Adjust size limits as necessary (in MB)
    const maxImages = 10 - Number(form?.image?.length || 0)

    const { filteredFiles, invalidFiles, errorMsg } = methodModel.multipleImageValidation(files, acceptedTypes, sizeLimits, maxImages);
    if (errorMsg) {
      toast.error(errorMsg);
    }
    if (filteredFiles.length === 0) {
      return;
    }

    loader(true)
    let images = form?.image || []
    ApiClient.multiImageUpload('upload/multiple-images', filteredFiles, {}, "files").then(res => {
      if (res.success) {
        const data = res?.files?.map((item) => item?.fileName)
        images.push(...data)
        setform({ ...form, image: images })
      }
      loader(false)
    })
  }

  const videoUpload = (e) => {
    const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg']; // Add more video types if needed
    const sizeLimits = { min: 1, max: 30 }; // Adjust size limits as necessary (in MB)
    let files = Array.from(e.target.files);
    const maxVideos = 5 - Number(form?.video?.length || 0)
    const videoDuration = 60

    const fileArray = [];
    const metadataPromises = files.map(file => {
      return new Promise((resolve) => {
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata'; // Load only metadata to get duration
        videoElement.onloadedmetadata = () => {
          const durationInSeconds = videoElement.duration;
          if (durationInSeconds > videoDuration) {
            toast.error(`Videos exceeding a duration of ${videoDuration} seconds will be disregarded during upload. Please ensure that all videos are ${videoDuration} seconds or shorter`);
            resolve(null); // Resolve with null for invalid video
          } else {
            fileArray.push(file);
            resolve(file); // Resolve with the file if valid
          }
        };
        const url = URL.createObjectURL(file);
        videoElement.src = url;
      });
    });

    Promise.all(metadataPromises).then(() => {
      const { filteredFiles, invalidFiles, errorMsg } = methodModel.multipleVideoValidation(fileArray, acceptedVideoTypes, sizeLimits, maxVideos);
      if (errorMsg) toast.error(errorMsg)
      if (filteredFiles.length === 0) return
      let videos = form?.video || []
      loader(true)
      ApiClient.multiImageUpload('upload/multiple-videos', filteredFiles, {}, "files").then(res => {
        if (res.success) {
          const data = res?.files?.map((item) => item?.fileName)
          videos.push(...data)
          setform({ ...form, video: videos })
        }
        loader(false)
      })
    })
  }

  const handleDelete = (index, key) => {
    let data = [...form?.[key]]
    data.splice(index, 1)
    setform({ ...form, [key]: data })
  }

  const handleAddMore = () => {
    const data = [...form?.skills, [""]]
    setform({ ...form, skills: data })
  }
  const handleRemoveAddMore = (index) => {
    const data = form?.skills?.filter((item, i) => i !== index)
    setform((prev) => ({ ...prev, skills: data }))
  }
  const handleAddMoreInput = (value, index) => {
    let data = [...form?.skills]
    data[index] = value
    setform({ ...form, skills: data })
  }

  const getPostTypeOptions = () => {
    if (user?.role === "venue") return shared.venuePostTypeOptions
    else if (user?.role === "host") return shared.hostPostTypeOptions
    else if (user?.role === "influencer") return shared.influencerPostTypeOptions
    else if (user?.role === "crew" || user?.role === "crew_member" || user?.role === "food_truck_vendor") return shared.entertainmentCrewPostTypeOptions
    else if (user?.role === "vendor") return shared.vendorPostTypeOptions
    else return shared.postTypeOptions
  }

  const handleModal = () => {
    if (selectEvent?.id) {
      setSelectEvent((prev) => ({ ...prev, modal: false, id: "" }))
      setform((prev) => ({ ...prev, link: "" }))
    } else {
      setSelectEvent((prev) => ({ ...prev, modal: true, id: "" }))
    }
  }
  const handleEventSelect = (e) => {
    e.preventDefault()
    setform((prev) => ({ ...prev, link: `${environment?.frontendUrl}EventDetails/${selectEvent?.id}?influencerId=${user?._id || user?.id}` }))
    setSelectEvent((prev) => ({ ...prev, modal: false }))
  }

  return (
    <>
      <div className="">
        <div className="bg-white w-full h-screen">
          <DesktopSidebar />
          <form onSubmit={handleSubmit} className="add_page w-full p-5">
            <div className="">
              <div className="flex items-center mb-4">
                <Tooltip placement="top" title="Back">
                  <Link to={`/${shared.url}`} className="!px-2 py-2 flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all bg-white mr-3">
                    <IoIosArrowBack />
                  </Link>
                </Tooltip>
                <div>
                  <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                    {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                  </h3>
                </div>
              </div>
              <div className="border-[1px] rounded-[15px] p-5 gap-6">
                <div className="flex w-full justify-center ">
                  <div className="w-full">
                    <TabGroup selectedIndex={tab} onChange={e => setTab(e)}>
                      <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                        <Tab key="Info" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          Info
                        </Tab>
                        <Tab key="Gallery" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600]">
                          Gallery
                        </Tab>
                      </TabList>
                      <TabPanels className="mt-3">
                        <TabPanel key="Info">
                          <ul>
                            <li key="Info" className="relative rounded-md text-sm/6 transition">
                              <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <FormControl
                                    type="text"
                                    name="title"
                                    label="Title"
                                    value={form?.title}
                                    onChange={(e) => setform({ ...form, title: e })}
                                    placeholder="Enter Title"
                                    required
                                  />
                                  {submitted && !form.title && (
                                    <div className="d-block text-red-600">Title is required</div>
                                  )}
                                </div>
                                <div className="col-span-12 lg:col-span-6 mb-3">
                                  <label className="flex items-center gap-2">Post Type
                                    <span className="text-red-600">*</span>
                                  </label>
                                  <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    className="mt-1 capitalize"
                                    placeholder="Select Post Type"
                                    theme="search"
                                    intialValue={form?.postType}
                                    result={(e) => setform({ ...form, postType: e?.value, startingSalary: "", maximumSalary: "", link: "" })}
                                    options={getPostTypeOptions()}
                                    isClearable={false}
                                    required
                                  />
                                </div>
                                {form?.postType === "openOpportunities" ?
                                  <>
                                    <div className="mb-3">
                                      <FormControl
                                        type="number"
                                        name="startingSalary"
                                        label="Starting Salary ($)"
                                        placeholder="Enter Starting Salary"
                                        value={form?.startingSalary}
                                        onChange={(e) => setform((prev) => ({ ...prev, startingSalary: e }))}
                                        maxLength={10}
                                        required
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <FormControl
                                        type="number"
                                        name="maximumSalary"
                                        label="Maximum Salary ($)"
                                        placeholder="Enter Maximum Salary"
                                        value={form?.maximumSalary}
                                        onChange={(e) => setform((prev) => ({ ...prev, maximumSalary: e }))}
                                        maxLength={10}
                                        required
                                      />
                                    </div>
                                  </>
                                  :
                                  <div className="col-span-12 lg:col-span-6 mb-3 flex gap-2 items-end">
                                    <div className="w-full">
                                      <FormControl
                                        type="text"
                                        name="link"
                                        label="Link"
                                        value={form?.link}
                                        onChange={(e) => setform({ ...form, link: e })}
                                        disabled={selectEvent?.id}
                                        className="disabled:cursor-not-allowed"
                                        placeholder="Enter Link"
                                      />
                                    </div>
                                    {eventListing?.length > 0 && form?.postType === "promote" ?
                                      <Tooltip title={`${selectEvent?.id && "Remove Buy Ticket Link"}`}>
                                        <div onClick={e => handleModal()} className={`${selectEvent?.id ? "p-2" : "px-2 py-2.5"} text-white whitespace-nowrap cursor-pointer bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm`}>
                                          {selectEvent?.id ?
                                            <RxCross2 size={25} />
                                            :
                                            "Add Buy Ticket Link"
                                          }
                                        </div>
                                      </Tooltip>
                                      : null
                                    }
                                  </div>
                                }
                              </div>
                              {form?.postType === "openOpportunities" &&
                                <div className="mb-3">
                                  <div>Skills</div>
                                  <div className="grid grid-cols-12 gap-4">
                                    {form?.skills?.map((item, index) => {
                                      return <div className="col-span-12 md:col-span-6 lg:col-span-4 flex gap-4 mb-3">
                                        <FormControl
                                          type="text"
                                          name="skills"
                                          placeholder="Enter Skills"
                                          autoComplete="one-time-code"
                                          value={item}
                                          onChange={(e) => handleAddMoreInput(e, index)}
                                        />
                                        {form?.skills?.length > 1 &&
                                          <div className="bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index)}><GrSubtractCircle size={20} /></div>
                                        }
                                      </div>
                                    })}
                                  </div>
                                  <div className="col-span-12 flex justify-end">
                                    <div className="bg-[#0063a3] p-3 text-white rounded-lg cursor-pointer w-fit" onClick={e => handleAddMore()}>
                                      <IoMdAddCircleOutline size={20} />
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="col-span-2 mb-3">
                                <FormControl
                                  type="editor"
                                  name="description"
                                  label="Description"
                                  value={form?.description}
                                  onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                  required
                                />
                                {submitted && !form.description && (
                                  <div className="d-block text-red-600">Description is required</div>
                                )}
                              </div>
                              <div className="mb-3 mt-2 pt-3">
                                <label className="text-[20px] mb-3 d-block font-semibold underline">Information
                                </label>
                                <div className="mt-3">
                                  {postTypeInfo?.map((item, index) => {
                                    if (item?.role?.length === 0 || item?.role?.some((itm) => itm === user?.role)) {
                                      return <>
                                        <p className="text-[16px] font-semibold">* {item?.title} – </p>
                                        <p className="text-[15px] mb-2 pl-5">{item?.description}</p>
                                      </>
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="Gallery">
                          <ul>
                            <li key="Gallery" className="relative rounded-md text-sm/6 transition">
                              <div className="mb-3">
                                <label>Images (JPG/PNG)</label>
                                <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                  {form?.image?.length !== 10 &&
                                    <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                      <FiPlus />
                                      <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        multiple
                                        onChange={ImageUpload}
                                      />
                                      Upload Images
                                    </label>
                                  }
                                  {form?.image?.length > 0 ?
                                    <span className="flex flex-wrap gap-3">
                                      {form?.image?.map((item, index) => {
                                        return <div className="relative" key={index}>
                                          <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                          <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleDelete(index, "image")} size={25} />
                                        </div>
                                      })}
                                    </span> : null
                                  }
                                </div>
                              </div>
                              <div className="mb-3">
                                <label>Videos</label>
                                <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                  {form?.video?.length !== 5 &&
                                    <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                      <FiPlus />
                                      <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        accept="video/*"
                                        multiple
                                        onChange={videoUpload}
                                      />
                                      Upload Videos
                                    </label>
                                  }
                                  {form?.video?.length > 0 ?
                                    <span className="flex flex-wrap gap-3">
                                      {form?.video?.map((item, index) => {
                                        return <div className="relative mt-2 w-[200px] rounded-lg" key={index}>
                                          <video width="320" height="240" controls>
                                            <source src={methodModel.video(item)} type="video/mp4" />
                                            <source src={methodModel.video(item)} type="video/ogg" />
                                            Your browser does not support the video tag.
                                          </video>
                                          <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleDelete(index, "video")} size={25} />
                                        </div>
                                      })}
                                    </span>
                                    : null}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </div>
                <div className="text-right">
                  {tab !== 0 &&
                    <button type="button" onClick={e => setTab(tab - 1)} className="text-white bg-[#063688] mr-3 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                      Back
                    </button>
                  }
                  <button type="submit" className="text-white bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                    {tab !== 1 ? "Next" : form?.id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog open={selectEvent?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setSelectEvent((prev) => ({ ...prev, modal: true }))}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
            <DialogPanel transition className={`bg-[url('assets/images/colorful.png')] bg-cover bg-no-repeat w-full max-w-lg rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
              <div className="bg-[#fff9] rounded-xl p-10 relative">
                <form onSubmit={handleEventSelect}>
                  <div className="mb-3">
                    <label>Select Event</label>
                    <SelectDropdown
                      id="statusDropdown"
                      displayValue="name"
                      className="mt-1 capitalize"
                      placeholder="Select Event"
                      theme="search"
                      intialValue={selectEvent?.id}
                      result={(e) => setSelectEvent({ ...selectEvent, id: e?.value })}
                      options={eventListing}
                      isClearable={false}
                      required
                    />
                  </div>
                  <div className="flex justify-end gap-3">
                    <button type="button" onClick={e => setSelectEvent((prev) => ({ ...prev, modal: false, id: "" }))} className="border border-[#063688] text-[#063688] font-medium rounded-full text-sm px-5 py-2.5 mt-3">Back</button>
                    <button type="submit" className="text-white bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 mt-3">Select</button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddEditPost;
