import { useState, useEffect } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { setRedirectURL } from '../../constants';
import Footer from '../Footer';
import CustomMap from './CustomMap';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import EDuration from '../../assets/images/e-duration.png';
import EAtten from '../../assets/images/e-atten.png';
import Ecall from '../../assets/images/e-call.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css';
import Location from '../../assets/images/Icons/location.svg';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import moment from 'moment';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { IoMdClose } from 'react-icons/io';
import { MdOutlineEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { RiContactsLine } from "react-icons/ri";
import { FiMinus } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import FormControl from '../../common/FormControl';
import GuestUserModal from '../Auth/GuestUserModal';
import SigninModal from '../Auth/SigninModal';
import SelectDropdown from '../../common/SelectDropdown';
import { CiCircleChevLeft } from "react-icons/ci";
import { CiCircleChevRight } from "react-icons/ci";
import environment from '../../environment';


const EventDetails = () => {
    const { id, invitesId } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const token = sessionStorage.getItem("token")
    const [detail, setDetail] = useState({})
    const [categoryEvents, setCategoryEvents] = useState([])
    const [params] = useSearchParams()
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})
    const [isOpen, setIsOpen] = useState({ modal: false })
    const [ticket, setTicket] = useState({ modal: false, data: [], ticketPurchased: "" })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [inviteDetail, setInviteDetail] = useState("")
    const [requestModal, setRequestModal] = useState({ modal: false, reason: "", id: "" })
    const [platformFee, setPlatformFee] = useState("")
    const stripeFeePercentage = (2.9 / 100) // 2.9%
    const stripeFeeFixed = (0.30) // $0.30

    const ticketPurchasedOption = [
        { id: "single", name: "One QR Code For All" },
        { id: "multiple", name: "Send Each Ticket By Email" }
    ]

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        getPlatformFee()
        if (id) {
            getEventDetail()
        }
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
        if (invitesId) {
            getInviteDetail()
        }
    }, [id])

    const getEventDetail = () => {
        loader(true)
        ApiClient.get(`event/detail?id=${id}`).then(res => {
            if (res.success) {
                const data = res?.payload
                setDetail(data)
                getCategoryEvents(data?.category?._id || data?.category?.id)
                setLocations([{ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title }])
                setMapView({ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title })
                setTicket({
                    modal: false, ticketPurchased: "", data: data?.ticket_details?.map(item => {
                        return ({
                            ticket_name: item?.ticket_name,
                            isOnline: item?.isOnline,
                            type: item?.type,
                            tickets_per_order: item?.tickets_per_order,
                            pending_tickets: item?.pending_tickets,
                            ticket_price: item?.ticket_price,
                            id: item?._id || item?.id,
                            quantity: 0,
                        })
                    })
                })
                if (data?.isBoostUsed || data?.venue?.isBoostUsed && data?.venue?.boostCategory === "amenities") {
                    getTrackingDetails(data)
                }
            }
            loader(false)
        })
    }

    const getTrackingDetails = (data) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        let payload = {
            userId: data?._id || data?.id,
            boostType: data?.boostType || data?.venue?.boostType,
            boostCategory: "event",
            corrdinates: {
                type: "Point",
                coordinates: [userLat || "", userLong || ""]
            },
            deviceToken: sessionStorage.getItem("deviceToken") || ""
        }
        if (token) {
            payload = { ...payload, visitedUserId: user?._id || user?.id }
        }
        ApiClient.post(`boost/track/analytics`, payload)
    }

    const getCategoryEvents = (categoryId) => {
        if (categoryId) {
            ApiClient.get(`event/listing?category=${categoryId}`).then(res => {
                if (res.success) {
                    setCategoryEvents(res?.data?.filter((item) => item?._id !== id))
                }
            })
        }
    }

    const getInviteDetail = () => {
        ApiClient.get(`invitation/detail?id=${invitesId}`).then(res => {
            if (res.success) {
                setInviteDetail(res?.data)
            }
        })
    }

    const getPlatformFee = () => {
        ApiClient.get(`platform/fee/ticket/detail?slug=${environment.settingSlug}`).then(res => {
            if (res.success) {
                setPlatformFee(res?.data?.ticketPlatformFee)
            }
        })
    }

    const openModal = (type) => {
        if (!token) {
            setIsOpen({
                modal: true,
            });
            setTicket((prev) => ({ ...prev, type: type }))
        } else {
            handleIndividualFields()
            setTicket((prev) => ({
                ...prev,
                modal: true,
                type: type,
                firstName: user?.firstName || "",
                lastName: user?.lastName || "",
                email: user?.email || "",
                mobileNo: user?.mobileNo || ""
            }))
        }
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const calculateDuration = (key) => {
        // Convert the input strings to Date objects
        const startDate = new Date(detail?.eventStartDate);
        const endDate = new Date(detail?.eventEndDate);
        // Calculate the difference in milliseconds
        const diffInMs = endDate.getTime() - startDate.getTime();
        // Calculate the difference in days, hours, and minutes
        const days = Math.floor(diffInMs / (1000 * 3600 * 24));
        const hours = Math.floor((diffInMs % (1000 * 3600 * 24)) / (1000 * 3600));
        const minutes = Math.floor((diffInMs % (1000 * 3600)) / (1000 * 60));
        // Return the results as an object
        if (key === "days") return (days || 0)
        else if (key === "hours") return (hours || 0)
        else if (key === "minutes") return (minutes || 0)
        else return ""
    }

    const handleLikeFollowEvent = (type) => {
        if (user?._id || user?.id) {
            const payload = {
                id: detail?.id || detail?._id,
                type: type,
                userId: user?._id || user?.id,
                addedTo: "event",
            }
            loader(true)
            ApiClient.post(`event/${type}`, payload).then(res => {
                if (res.success) {
                    getEventDetail()
                }
                loader(false)
            })
        } else {
            setIsModalOpen(true)
        }
    }

    const handleTicketsQuantity = (type, index, quantity) => {
        let ticketData = ticket?.data
        if (type === "add") {
            ticketData[index]["quantity"] = Number(quantity) + 1
        } else {
            ticketData[index]["quantity"] = Number(quantity) - 1
        }
        setTicket((prev) => ({ ...prev, data: ticketData }))
    }

    const purchasedTickets = (e) => {
        e.preventDefault()
        setSubmitted(true)
        const influencerId = methodModel.getPrams("influencerId")
        let apiUrl = ""
        let payload = {
            eventId: detail?._id || detail?.id,
            productData: {
                productName: `${detail?.title} Ticket`,
            },
            role: user?.role || "explorer",
            paidTo: detail?.addedBy?.role === "admin" ? detail?.venue?._id : detail?.addedBy?._id,
            ticket_data: ticket?.data?.filter((item) => item?.quantity > 0),
        }
        if (influencerId) {
            payload = { ...payload, influencerId: influencerId }
        }
        if (ticket?.ticketPurchased === "single") {
            let ticketIds = []
            let ticketPrice = 0
            let quantity = 0
            ticket?.data?.map((item) => {
                if (item?.quantity !== 0) {
                    ticketPrice += item?.ticket_price * item?.quantity
                    quantity += item?.quantity
                    ticketIds.push({ id: item?.id, quantity: item?.quantity })
                }
            })
            apiUrl = "ticket/buy"
            payload = {
                ...payload,
                ticketId: ticketIds,
                ticketPrice: ticketPrice,
                quantity: quantity,
                firstName: ticket?.firstName,
                lastName: ticket?.lastName,
                email: ticket?.email,
                mobileNo: ticket?.mobileNo,
            }
        } else {
            apiUrl = "ticket/buy"
            payload = {
                ...payload,
                email: token ? user?.email : ticket?.tickets?.[0]?.email,
                individualTickets: ticket?.tickets?.map((item) => {
                    return ({
                        ticketId: item?.id,
                        ticketPrice: item?.ticket_price || 0,
                        quantity: item?.quantity,
                        firstName: item?.firstName,
                        lastName: item?.lastName,
                        email: item?.email,
                        mobileNo: item?.mobileNo
                    })
                })
            }
        }
        if (user?._id || user?.id) {
            payload = {
                ...payload, purchasedBy: user?._id || user?.id
            }
        }
        if (ticket?.type === "free") {
            apiUrl = "ticket/free"
        }
        loader(true)
        ApiClient.post(apiUrl, payload).then(res => {
            if (res.success) {
                if (ticket?.type === "free") {
                    navigate(`/myorder?success=true`)
                } else {
                    window.open(res?.data, "_self")
                }
            }
            loader(false)
        })
    }

    const calTotalAvailableTickets = (item) => {
        let total = 0
        item?.map((item) => {
            total += item?.pending_tickets
        })
        return total
    }

    const handleGuestUser = () => {
        setIsOpen({ ...isOpen, modal: false })
        handleIndividualFields()
    }

    const handleIndividualFields = () => {
        let ticketData = ticket?.data;
        ticketData = ticketData?.filter((item) => item?.quantity !== 0); // Filter the ticket behalf of quantity
        // In this we expand the array according to the quantity of tickets for the multiple tickets
        const expandedTicketData = ticketData.flatMap((item) =>
            Array.from({ length: item.quantity }, () => ({ ...item, quantity: 1 }))
        );
        setTicket((prev) => ({
            ...prev,
            modal: true,
            ticketPurchased: expandedTicketData?.length === 1 ? "single" : "",
            tickets: expandedTicketData // Take tickets key for input field so in event detail page tickets data will not change
        }))
    }

    const calculatePrice = (type) => {
        let value = 0
        if (type === "ticket") {
            ticket?.data?.map((item) => {
                value += item?.ticket_price * item?.quantity
            })
        } else if (type === "total") {
            ticket?.data?.map((item) => {
                value += item?.ticket_price * item?.quantity
            })
            const ticketAmount = value
            const stripeCharges = (ticketAmount * stripeFeePercentage) + stripeFeeFixed
            if (detail?.platformFeeType === "additional") {
                const platformCharges = ticketAmount * (platformFee / 100)
                value = ticketAmount + stripeCharges + platformCharges
            } else {
                value = ticketAmount + stripeCharges
            }
        } else {
            value = 0
        }
        return parseFloat(value).toFixed(2)
        // return Math.round(value)
    }

    const closeTicketModal = () => {
        setTicket({
            modal: false, ticketPurchased: "", data: detail?.ticket_details?.map(item => {
                return ({
                    ticket_name: item?.ticket_name,
                    isOnline: item?.isOnline,
                    type: item?.type,
                    tickets_per_order: item?.tickets_per_order,
                    pending_tickets: item?.pending_tickets,
                    ticket_price: item?.ticket_price,
                    id: item?._id || item?.id,
                    quantity: 0,
                })
            })
        })
    }

    const handleTicketFields = (value, index, key) => {
        let ticketData = ticket?.tickets
        ticketData[index][key] = value
        setTicket((prev) => ({ ...prev, tickets: ticketData }))
    }

    const handleRequest = (e, id, type) => {
        e.preventDefault()
        let payload = {
            id: id,
            status: type
        }
        if (type === "rejected") {
            payload = { ...payload, reason: requestModal?.reason }
        }
        loader(true)
        ApiClient.post(`invitation/accept/reject`, payload).then(res => {
            if (res.success) {
                setRequestModal({ modal: false, reason: "", id: "" })
                getEventDetail()
                getInviteDetail()
            }
            loader(false)
        })
    }

    return (
        <>
            <NavigationBar />
            <div className="event-detail bg-[#D9D9D938] py-[2rem]">
                <div className="container mx-auto px-5">
                    <div className="flex-1">
                        <img src={methodModel.noImg(detail?.images?.[0])} className='w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[450px] object-cover rounded-[15px]' alt="event_image" />
                        <div className="flex flex-wrap lg:flex-nowrap gap-4 pt-5 pb-10">
                            <div className="w-full lg:max-w-[calc(100%_-_400px)]">
                                <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat capitalize mb-1">
                                    {detail?.title}
                                </h2>
                                <p className="flex items-center font-[400] text-[14px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans break-all mb-1">
                                    <img className="mr-2 w-[18px] h-[18px]" src={location} alt="location_icon" />
                                    {detail?.location}
                                </p>
                                <p className="flex items-center font-[400] text-[14px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans break-all mb-1">
                                    <img className="mr-2 w-[18px] h-[18px]" src={calender} alt="date_icon" />
                                    {moment(detail?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                </p>
                                <p className="w-full font-normal text-[14px] tracking-[0.03em] text-left text-black font-open-sans mt-4" dangerouslySetInnerHTML={{ __html: detail?.short_description }}></p>
                                {user?.role === "member" || detail?.venue?._id === user?._id ? null :
                                    <div className="flex gap-3 pt-[25px]">
                                        <div onClick={e => handleLikeFollowEvent("follow")} className="flex items-center justify-center cursor-pointer text-[15px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                            {detail?.follow_data?.some((item) => item?.addedBy === user?._id) ? "Unfollow" : "Follow"}
                                        </div>
                                        <div onClick={e => handleLikeFollowEvent("like")} className={`flex cursor-pointer items-center text-[15px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-full md:inline-flex bg-white`}>
                                            {detail?.like_data?.some((item) => item?.addedBy === user?._id) ?
                                                <>
                                                    <FaHeart className={`mr-2 text-red-600`} /> Unlike
                                                </>
                                                :
                                                <>
                                                    <FaRegHeart className={`mr-2`} /> Like
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className='mt-10'>
                                    <h2 className='font-montserrat text-xl font-semibold leading-7 text-left text-gray-900 mb-3'>Venue Detail</h2>
                                    <div className='flex flex-wrap gap-y-5 gap-x-10 cursor-pointer' onClick={() => { navigate(`/venue/detail/${detail?.venue?._id || detail?.venue?.id}`) }}>
                                        <div className='flex items-center gap-3'>
                                            <BiUser className='text-[28px] text-[#e5b23f]' />
                                            <div>
                                                <h2 className='text-[14px]'>Venue</h2>
                                                <p className='text-[#0000009c] capitalize text-[14px]'>{detail?.venue?.venue_name}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <RiContactsLine className='text-[28px] text-[#e5b23f]' />
                                            <div className=''>
                                                <h2 className='text-[14px]'>Contact Person</h2>
                                                <p className='text-[#0000009c] capitalize text-[14px]'>{detail?.venue?.name}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <MdOutlineEmail className='text-[28px] text-[#e5b23f]' />
                                            <div className=''>
                                                <h2 className='text-[14px]'>Email</h2>
                                                <p className='text-[#0000009c] text-[14px] break-all'>{detail?.venue?.email}</p>
                                            </div>
                                        </div>
                                        {detail?.venue?.mobileNo &&
                                            <div className='flex items-center gap-3'>
                                                <MdPhone className='text-[28px] text-[#e5b23f]' />
                                                <div className=''>
                                                    <h2 className='text-[14px]'>Mobile Number</h2>
                                                    <p className='text-[#0000009c] text-[14px]'>+{detail?.venue?.mobileNo}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='mt-10'>
                                        <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                            Venue Amenities
                                        </h2>
                                        <div className="">
                                            <span className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-2 xl:grid-cols-3'>
                                                {detail?.venue?.boostedAmenities?.map((itm, i) => {
                                                    return <div className='flex gap-3 items-center rounded-md border border-[#83c5ff] shadow-lg p-1' key={i}>
                                                        <img src={methodModel.noImg(itm?.image)} className='w-[50px] h-[50px] object-contain rounded-full' alt="venue_image" />
                                                        <span className='font-open-sans text-[14px] font-[400] leading-6 tracking-tight text-left text-[#0F64B0] break-all'>{itm?.title || '--'}</span>
                                                    </div>
                                                })}
                                                {detail?.venue?.subAmenities?.map((itm, i) => {
                                                    const isBoosted = detail?.venue?.boostedAmenities?.some((boostedItem) => boostedItem?._id === itm?._id);
                                                    if (!isBoosted) {
                                                        return <div className='flex gap-3 items-center rounded-md' key={i}>
                                                            <img src={methodModel.noImg(itm?.image)} className='w-[50px] h-[50px] object-contain rounded-full' alt="venue_image" />
                                                            <span className='font-open-sans text-[14px] font-[400] leading-6 tracking-tight text-left text-gray-700 break-all'>{itm?.title || '--'}</span>
                                                        </div>
                                                    }
                                                    return null
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-10'>
                                        <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                            Event Information
                                        </h2>
                                        <div className="grid grid-cols-1 gap-y-5 gap-x-3 mt-5 md:grid-cols-2 xl:grid-cols-3">
                                            <div className="flex gap-3 rounded-md">
                                                <img className="w-[45px] h-[45px] object-contain" src={EDuration} alt="duration_icon" />
                                                <div className="flex flex-col">
                                                    <p className="font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700">
                                                        Duration
                                                    </p>
                                                    <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left text-gray-600">
                                                        {calculateDuration("days") !== 0 ? `${calculateDuration("days")} days ` : null}
                                                        {calculateDuration("hours") !== 0 || calculateDuration("minutes") !== 0 ? `${calculateDuration("hours")} hours ` : null}
                                                        {calculateDuration("minutes") !== 0 ? `${calculateDuration("minutes")} minutes` : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 rounded-md">
                                                <img className="w-[45px] h-[45px] object-contain" src={EAtten} alt="audience_icon" />
                                                <div className="flex flex-col">
                                                    <p className="font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700">
                                                        Audience
                                                    </p>
                                                    <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left text-gray-600">
                                                        {detail?.audience || '--'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 rounded-md">
                                                <img className="w-[45px] h-[45px] object-contain" src={Ecall} alt="attention_icon" />
                                                <div className="flex flex-col">
                                                    <p className="font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700">
                                                        Attention
                                                    </p>
                                                    <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left text-gray-600 break-words">
                                                        {detail?.attention || '--'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">Description</h3>
                                        <p className="font-open-sans text-[14px] font-normal leading-7 tracking-tight text-left text-black" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full lg:w-[400px]'>
                                {/* For Free Tickets */}
                                {detail?.type === "paid" && user?.role === "member" || detail?.type === "paid" && !token ?
                                    <>
                                        {ticket?.data?.some((item) => item?.type === "free") &&
                                            <div className="mb-4">
                                                <div className='shadow-[0px_0px_14px_-8px_#000] rounded-[20px] bg-white py-5 px-4'>
                                                    <label className="text-[#005AAB] font-[600]">Claim Your Spot</label>
                                                    <div className='max-h-[305px] overflow-auto'>
                                                        {ticket?.data?.map((item, index) => {
                                                            if (item?.type === "free") {
                                                                return <div className='p-3 bg-[#f3f8ff] rounded-lg mt-3' key={index}>
                                                                    <div className='flex justify-between gap-5' >
                                                                        <div className='flex gap-2'>
                                                                            <img src="/assets/img/ticket_img.png" className='w-[25px] h-[25px] object-contain'></img>
                                                                            <div>
                                                                                <p className='text-[#005AAB] text-[16px] font-[500]'>{item?.ticket_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {item?.isOnline && item?.pending_tickets !== 0 ?
                                                                                <div className="flex items-center justify-end gap-2">
                                                                                    <button type='button' onClick={() => handleTicketsQuantity("subtract", index, item?.quantity)} disabled={item?.quantity === 0} className="bg-white w-[30px] h-[30px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border block"><FiMinus /></button>
                                                                                    <p>{item?.quantity}</p>
                                                                                    <button type='button' onClick={() => handleTicketsQuantity("add", index, item?.quantity)} disabled={item?.quantity === item?.tickets_per_order || item?.quantity === item?.pending_tickets} className="bg-white w-[30px] h-[30px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border block"><LuPlus /></button>
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <p className='text-[12px] text-[#212121] flex items-center gap-2'>Free</p>
                                                                    {item?.pending_tickets === 0 ?
                                                                        <div className='text-[13px] text-[#134896]'>Tickets Sold Out</div>
                                                                        :
                                                                        <>
                                                                            {item?.isOnline ?
                                                                                null
                                                                                :
                                                                                <div className='text-[13px] text-[#134896]'>Buy Tickets Offline</div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                    {ticket?.data?.some((item) => item?.quantity !== 0 && item?.type === "free") &&
                                                        <div className='w-full flex justify-center mt-5'>
                                                            <button onClick={() => openModal("free")} className="w-[100px] bg-[#FFC42B] font-poppins font-[600] text-[12px] disabled:cursor-not-allowed text-[#000] py-[5px] px-[16px] gap-10 rounded-md hover:opacity-[80%]">
                                                                Get It
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                    : null
                                }
                                {/* For Paid Tickets */}
                                {detail?.type === "paid" && user?.role === "member" || detail?.type === "paid" && !token ?
                                    <>
                                        {ticket?.data?.some((item) => item?.type === "paid") &&
                                            <div className="mb-4">
                                                <div className='shadow-[0px_0px_14px_-8px_#000] rounded-[20px] bg-white py-5 px-4'>
                                                    <label className="text-[#005AAB] font-[600]">Claim Your Spot</label>
                                                    <div className='max-h-[305px] overflow-auto'>
                                                        {ticket?.data?.map((item, index) => {
                                                            if (item?.type === "paid") {
                                                                return <div className='p-3 bg-[#f3f8ff] rounded-lg mt-3' key={index}>
                                                                    <div className='flex justify-between gap-5' >
                                                                        <div className='flex gap-2'>
                                                                            <img src="/assets/img/ticket_img.png" className='w-[25px] h-[25px] object-contain'></img>
                                                                            <div>
                                                                                <p className='text-[#005AAB] text-[16px] font-[500]'>{item?.ticket_name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {item?.isOnline && item?.pending_tickets !== 0 ?
                                                                                <div className="flex items-center justify-end gap-2">
                                                                                    <button type='button' onClick={() => handleTicketsQuantity("subtract", index, item?.quantity)} disabled={item?.quantity === 0} className="bg-white w-[30px] h-[30px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border block"><FiMinus /></button>
                                                                                    <p>{item?.quantity}</p>
                                                                                    <button type='button' onClick={() => handleTicketsQuantity("add", index, item?.quantity)} disabled={item?.quantity === item?.tickets_per_order || item?.quantity === item?.pending_tickets} className="bg-white w-[30px] h-[30px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border block"><LuPlus /></button>
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <p className='text-[12px] text-[#212121] flex items-center gap-2'>
                                                                        {item?.type === "paid" ?
                                                                            <>
                                                                                Price <span className='text-[18px] text-[#000] font-[600]'>${parseFloat(item?.ticket_price).toFixed(2)}</span>
                                                                            </>
                                                                            :
                                                                            <>Free</>
                                                                        }
                                                                    </p>
                                                                    {item?.pending_tickets === 0 ?
                                                                        <div className='text-[13px] text-[#134896]'>Tickets Sold Out</div>
                                                                        :
                                                                        <>
                                                                            {item?.isOnline ?
                                                                                null
                                                                                :
                                                                                <div className='text-[13px] text-[#134896]'>Buy Tickets Offline</div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                    {ticket?.data?.some((item) => item?.quantity !== 0 && item?.type === "paid") &&
                                                        <div className='w-full flex justify-between gap-5 mt-5'>
                                                            <p className='text-[12px] text-[#212121] flex items-center gap-2'>Price <span className='text-[18px] text-[#000] font-[600]'>${calculatePrice("ticket")}</span></p>
                                                            <button onClick={() => openModal("paid")} className="w-[100px] bg-[#FFC42B] font-poppins font-[600] text-[12px] disabled:cursor-not-allowed text-[#000] py-[5px] px-[16px] gap-10 rounded-md hover:opacity-[80%]">
                                                                Get It
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                    : null
                                }
                                {invitesId ?
                                    <div className='h-fit shadow-[0px_0px_14px_-8px_#000] p-4 bg-[#fff] rounded-[20px] mb-4'>
                                        <div className="flex justify-center gap-4 my-3">
                                            <span className="text-[16px] capitalize">Invite Request Status: <span className={`${inviteDetail?.request_status === "rejected" ? "text-red-600" : inviteDetail?.request_status === "accepted" ? "text-green-600" : "text-yellow-600"}`}>{inviteDetail?.request_status}</span></span>
                                        </div>
                                        {inviteDetail?.request_status === "pending" && user?.role !== inviteDetail?.invitedBy?.role &&
                                            <div className="flex justify-center gap-3 my-2">
                                                <button type="button" onClick={e => handleRequest(e, inviteDetail?._id || inviteDetail?.id, "accepted")} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Accept</button>
                                                <button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: true, reason: "", id: inviteDetail?._id || inviteDetail?.id }))} className="text-[12px] curs !px-3 py-1 cursor-pointer flex items-center justify-center rounded-lg shadow-btn border transition-all bg-[#063688] text-white">Reject</button>
                                            </div>
                                        }
                                    </div>
                                    : null}
                                {detail?.host?.length !== 0 &&
                                    <div className='h-fit shadow-[0px_0px_14px_-8px_#000] p-4 bg-[#fff] rounded-[20px] mb-4'>
                                        <h2 className='bg-white border-b-[1px] text-[18px] rounded-t-[20px] pb-3'>Hosts</h2>
                                        <div className='max-h-[395px]'>
                                            <div className='max-h-[389px] overflow-auto'>
                                                {detail?.host?.map((item, index) => {
                                                    return <Link to={`/hostProfile/${item?._id || item?.id}`} className="flex gap-3 border-b-[1px] border-[#efefef] rounded-lg hover:bg-[#f3f8ff] bg-white cursor-pointer py-4 px-3" key={index}>
                                                        <img src={methodModel.noImg(item?.image)} className="w-[45px] h-[45px] rounded-full bg-gray-600 object-cover" alt="host_image" />
                                                        <div className="flex flex-col">
                                                            <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left capitalize">{item?.fullName || `${item?.firstName} ${item?.lastName}`}</span>
                                                            <span className="font-open-sans text-[12px] font-[300] leading-2 tracking-tight text-left text-gray-600">{item?.email}</span>
                                                        </div>
                                                    </Link>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {detail?.coHost && detail?.coHost?.length !== 0 ?
                                    <div className='h-fit shadow-[0px_0px_14px_-8px_#000] p-4 bg-[#fff] rounded-[20px] mb-4'>
                                        <h2 className='bg-white border-b-[1px] text-[18px] rounded-t-[20px] pb-3'>Co-hosts</h2>
                                        <div className='max-h-[395px]'>
                                            <div className='max-h-[389px] overflow-auto'>
                                                {detail?.coHost?.map((item, index) => {
                                                    return <Link to={item?.role === "crew" ? `/entertainmentCrewProfile/${item?._id || item?.id}` : `/influencerProfile/${item?._id || item?.id}`} className="flex gap-3 border-b-[1px] border-[#efefef] rounded-lg hover:bg-[#f3f8ff] bg-white cursor-pointer py-4 px-3" key={index}>
                                                        <img src={methodModel.noImg(item?.image)} className="w-[45px] h-[45px] rounded-full bg-gray-600 object-cover" alt="host_image" />
                                                        <div className="flex flex-col">
                                                            <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left capitalize">{item?.fullName || `${item?.firstName} ${item?.lastName}`}</span>
                                                            <span className="font-open-sans text-[12px] font-[300] leading-2 tracking-tight text-left capitalize">{item?.role === "crew" ? "Entertainment Crew" : item?.role}</span>
                                                            <span className="font-open-sans text-[12px] font-[300] leading-2 tracking-tight text-left text-gray-600">{item?.email}</span>
                                                        </div>
                                                    </Link>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {detail?.vendors && detail?.vendors?.length !== 0 ?
                                    <div className='h-fit shadow-[0px_0px_14px_-8px_#000] p-4 bg-[#fff] rounded-[20px] mb-4'>
                                        <h2 className='bg-white border-b-[1px] text-[18px] rounded-t-[20px] pb-3'>Vendor/Food Truck Vendor</h2>
                                        <div className='max-h-[395px]'>
                                            <div className='max-h-[389px] overflow-auto'>
                                                {detail?.vendors?.map((item, index) => {
                                                    return <Link to={item?.role === "vendor" ? `/vendorProfile/${item?._id || item?.id}` : `/foodTruckVendorProfile/${item?._id || item?.id}`} className="flex gap-3 border-b-[1px] border-[#efefef] rounded-lg hover:bg-[#f3f8ff] bg-white cursor-pointer py-4 px-3" key={index}>
                                                        <img src={methodModel.noImg(item?.image)} className="w-[45px] h-[45px] rounded-full bg-gray-600 object-cover" alt="host_image" />
                                                        <div className="flex flex-col">
                                                            <span className="font-open-sans text-[14px] font-normal leading-6 tracking-tight text-left capitalize">{item?.fullName || `${item?.firstName} ${item?.lastName}`}</span>
                                                            <span className="font-open-sans text-[12px] font-[300] leading-2 tracking-tight text-left capitalize">{item?.role === "crew" ? "Entertainment Crew" : item?.role}</span>
                                                            <span className="font-open-sans text-[12px] font-[300] leading-2 tracking-tight text-left text-gray-600">{item?.email}</span>
                                                        </div>
                                                    </Link>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div className="w-full mx-auto mt-8">
                            <div className="map_div">
                                <CustomMap mapView={mapView} locations={locations} />
                            </div>
                        </div>
                        {detail?.extraFood === "no" ? null :
                            <div className='my-10'>
                                <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                    Event Menu
                                </h2>
                                {detail?.menu_item_format === "manual" ?
                                    <>
                                        {detail?.foods?.length > 0 ?
                                            <div className="mt-2 mb-8">
                                                <label className='font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700'>Foods</label>
                                                <span className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-3'>
                                                    {detail?.foods?.map((itm, i) => {
                                                        return <div className='flex gap-3 items-center  rounded-md' key={i}>
                                                            <img src={methodModel.noImg(itm?.image)} className='w-[50px] h-[50px] object-cover rounded-full' alt="food_image" />
                                                            <span className='font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700'>{`${itm?.item} ($${itm?.price})`}</span>
                                                        </div>
                                                    })}
                                                </span>
                                            </div>
                                            : null}
                                        {detail?.drinks?.length > 0 ?
                                            <div className="mt-2 mb-8">
                                                <label className='font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700'>Drinks</label>
                                                <span className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-3'>
                                                    {detail?.drinks?.map((itm, i) => {
                                                        return <div className='flex gap-3 items-center  rounded-md' key={i}>
                                                            <img src={methodModel.noImg(itm?.image)} className='w-[50px] h-[50px] object-cover rounded-full' alt="drinks_image" />
                                                            <span className='font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700'>{`${itm?.item} ($${itm?.price})`}</span>
                                                        </div>
                                                    })}
                                                </span>
                                            </div>
                                            : null}
                                    </>
                                    :
                                    <>
                                        {detail?.foodImages?.length > 0 ?
                                            <div className="mt-2 mb-8">
                                                <label className='font-open-sans text-lg font-[400] leading-6 tracking-tight text-left text-gray-700'>Food Images</label>
                                                <span className='grid grid-cols-2 gap-5 mt-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                                                    {detail?.foodImages?.map((itm, i) => {
                                                        return <div className='flex gap-3 items-center  rounded-md' key={i}>
                                                            <img src={methodModel.noImg(itm)} onClick={e => { window.open(methodModel.noImg(itm)) }} className='cursor-pointer w-full h-[180px] rounded-xl object-cover' alt='foodImages' />
                                                        </div>
                                                    })}
                                                </span>
                                            </div>
                                            : null}
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {categoryEvents?.length !== 0 &&
                        <div className="mt-10">
                            <h2 className="font-montserrat text-2xl font-semibold leading-9 text-left text-[#2D2C3C] mb-5">
                                Other events you may like
                            </h2>
                            <div className="w-full">
                                <Swiper
                                    className="mySwiper"
                                    loop={true}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        1280: {
                                            slidesPerView: 3,
                                            spaceBetween: 30
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 20
                                        },
                                        767: {
                                            slidesPerView: 2,
                                            spaceBetween: 20
                                        },
                                        575: {
                                            slidesPerView: 2,
                                            spaceBetween: 20
                                        }
                                    }}
                                >
                                    {categoryEvents && categoryEvents?.map((item, index) => {
                                        return <SwiperSlide key={index}>
                                            <Link to={`/EventDetails/${item?._id || item?.id}`}>
                                                <div className="overflow-hidden bg-white rounded-lg shadow-md mb-2">
                                                    <img className="object-cover w-full h-[200px]" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                                    <div className="p-4">
                                                        <h2 className="font-[500] text-[18px] capitalize">
                                                            {item?.title}
                                                        </h2>
                                                        <p className="font-[400] text-[14px]">
                                                            {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                                        </p>
                                                        <p className="flex gap-2 font-[400] text-[14px] mt-1">
                                                            <img src={Location} alt="location_icon" />
                                                            <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    })}
                                    <div className="swiper-button-prev w-[40px] h-[40px] after:hidden">
                                        <CiCircleChevLeft alt="Previous" className='bg-[#0000003b] text-white rounded-full' />
                                    </div>
                                    <div className="swiper-button-next w-[40px] h-[40px] after:hidden">
                                        <CiCircleChevRight alt="Next" className='bg-[#0000003b] text-white rounded-full' />
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Footer />
            <SigninModal isModalOpen={isModalOpen} closeModal={closeModal} />
            <GuestUserModal isOpen={isOpen} setIsOpen={setIsOpen} handleGuestUser={handleGuestUser} />
            {/* Purchased Ticket Modal */}
            <Dialog open={ticket?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setTicket((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="bg-cover bg-[url('assets/images/bg-ticket.png')] relative w-full max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <form onSubmit={purchasedTickets}>
                                <div className='bg-[#ffffffa8] rounded-xl p-6'>
                                    <div className="flex justify-center border-[#e9e9e9] pb-3 mb-3">
                                        <DialogTitle as="h3" className="text-[22px] text-[#005AAB] font-[600]">{!ticket?.ticketPurchased ? "Grab Your Ticket" : ticket?.ticketPurchased === "multiple" ? "Confirm Your Spot" : "Buy Ticket"}</DialogTitle>
                                        <IoMdClose onClick={() => closeTicketModal()} className="text-[24px] absolute top-5 right-5 text-[#7a7a7a] cursor-pointer hover:text-black" />
                                    </div>
                                    {!ticket?.ticketPurchased &&
                                        <div className="mb-3">
                                            <SelectDropdown
                                                id="statusDropdown"
                                                displayValue="name"
                                                className="max-w-[300px] mt-3 mx-auto"
                                                placeholder="Pick Your Ticket Option"
                                                theme="search"
                                                intialValue={ticket?.ticketPurchased}
                                                isClearable={false}
                                                result={(e) => setTicket((prev) => ({ ...prev, ticketPurchased: e?.value }))}
                                                options={ticketPurchasedOption}
                                                required
                                            />
                                        </div>
                                    }
                                    {ticket?.ticketPurchased === "single" &&
                                        <div className='grid max-[499px]:grid-cols-1 grid-cols-2 gap-x-3 mt-5'>
                                            <div className='mb-3'>
                                                <FormControl
                                                    type="text"
                                                    name="firstName"
                                                    label="First Name"
                                                    placeholder={`Enter First Name`}
                                                    autoComplete="one-time-code"
                                                    value={ticket?.firstName}
                                                    onChange={(e) => setTicket({ ...ticket, firstName: e })}
                                                    required
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <FormControl
                                                    type="text"
                                                    name="lastName"
                                                    label="Last Name"
                                                    placeholder={`Enter Last Name`}
                                                    autoComplete="one-time-code"
                                                    value={ticket?.lastName}
                                                    onChange={(e) => setTicket({ ...ticket, lastName: e })}
                                                    required
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <FormControl
                                                    type="text"
                                                    name="email"
                                                    label="Email"
                                                    placeholder={`Enter Email`}
                                                    autoComplete="one-time-code"
                                                    value={ticket?.email}
                                                    onChange={(e) => setTicket({ ...ticket, email: e })}
                                                    required
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <FormControl
                                                    type="phone"
                                                    name="mobileNo"
                                                    label="Mobile Number"
                                                    placeholder={`Enter Mobile Number`}
                                                    autoComplete="one-time-code"
                                                    value={ticket?.mobileNo}
                                                    onChange={(e) => setTicket({ ...ticket, mobileNo: e })}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className='max-h-[500px] overflow-auto'>
                                        {ticket?.ticketPurchased === "multiple" &&
                                            <>
                                                {ticket?.tickets?.map((item, index) => {
                                                    return <div className='px-1' key={index}>
                                                        <h2 className='text-[17px] font-[500] text-[#005AAB] mb-1'>Ticket {index + 1}: {item?.ticket_name}</h2>
                                                        <div className='grid max-[499px]:grid-cols-1 grid-cols-2 gap-x-3 mb-5 shadow-[0px_0px_12px_-8px_#000] p-5 bg-white rounded-lg'>
                                                            <div className='mb-3'>
                                                                <FormControl
                                                                    type="text"
                                                                    name="firstName"
                                                                    label="First Name"
                                                                    placeholder={`Enter First Name`}
                                                                    autoComplete="one-time-code"
                                                                    value={item?.firstName}
                                                                    onChange={(e) => handleTicketFields(e, index, "firstName")}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <FormControl
                                                                    type="text"
                                                                    name="lastName"
                                                                    label="Last Name"
                                                                    placeholder={`Enter Last Name`}
                                                                    autoComplete="one-time-code"
                                                                    value={item?.lastName}
                                                                    onChange={(e) => handleTicketFields(e, index, "lastName")}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <FormControl
                                                                    type="text"
                                                                    name="email"
                                                                    label="Email"
                                                                    placeholder={`Enter Email`}
                                                                    autoComplete="one-time-code"
                                                                    value={item?.email}
                                                                    onChange={(e) => handleTicketFields(e, index, "email")}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <FormControl
                                                                    type="phone"
                                                                    name="mobileNo"
                                                                    label="Mobile Number"
                                                                    placeholder={`Enter Mobile Number`}
                                                                    autoComplete="one-time-code"
                                                                    value={item?.mobileNo}
                                                                    onChange={(e) => handleTicketFields(e, index, "mobileNo")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </>
                                        }
                                    </div>
                                    {ticket?.ticketPurchased &&
                                        <>
                                            {ticket?.type === "paid" &&
                                                <div className='max-w-[200px] mx-auto mt-5'>
                                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Tickets :<span className='text-[#000]'>${calculatePrice("ticket")}</span></p>
                                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Stripe Fee :<span className='text-[#000]'>(2.9% + $0.30)</span></p>
                                                    {detail?.platformFeeType === "additional" ?
                                                        <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Platform Fee :<span className='text-[#000]'>{platformFee}%</span></p>
                                                        : null}
                                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] pt-3 border-t-[1px]'>Total :<span className='text-[#000]'>${calculatePrice("total")}</span></p>
                                                </div>
                                            }
                                            <div className="flex justify-center mt-5">
                                                <button type='submit' className="bg-[#005AAB] w-[150px] p-2 disabled:cursor-not-allowed text-white rounded-full shadow-md block">{ticket?.type === "paid" ? ticket?.ticketPurchased === "multiple" ? "Let's Go!" : "Check Out" : "Reserve"}</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            {/* Invites Request Modal */}
            <Dialog open={requestModal?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setRequestModal((prev) => ({ ...prev, modal: true }))}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-black">Add Reason</DialogTitle>
                            </div>
                            <form onSubmit={e => handleRequest(e, requestModal?.id, "rejected")}>
                                <div>
                                    <FormControl
                                        type="textarea"
                                        name="rejectReason"
                                        label="Reason"
                                        placeholder="Enter Reason"
                                        autoComplete="one-time-code"
                                        value={requestModal?.reason}
                                        onChange={(e) => setRequestModal((prev) => ({ ...prev, reason: e }))}
                                        required
                                    />
                                </div>
                                <div className="mb-3 mt-8 flex flex-wrap justify-center gap-5">
                                    <Button type="button" onClick={e => setRequestModal((prev) => ({ ...prev, modal: false }))} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                    <Button type="submit" className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Reject</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
export default EventDetails;
