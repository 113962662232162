import { NavigationBar } from "../Landing/NavigationBar"
import { FaLocationDot } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import React, { useEffect, useState } from "react";
import { BsChevronCompactRight } from "react-icons/bs";
import { BsChevronCompactLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import moment from "moment";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import FormControl from "../../common/FormControl";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import CustomMap from "../Filters/CustomMap";
import { CiClock2 } from "react-icons/ci";

const VenueDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const token = sessionStorage.getItem("token")
    const [detail, setDetail] = useState("")
    const [posts, setPosts] = useState([])
    const [events, setEvents] = useState([])
    const postsRef = React.useRef(null)
    const eventsRef = React.useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "" })
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    useEffect(() => {
        if (id) {
            getVenueDetails()
            getVenuePosts()
            getVenueEvents()
        }
    }, [])

    const getVenueDetails = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setDetail(res?.data)
                let locations = []
                let mapview = {}
                res?.data?.locations?.map((item, index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(res?.data?.images?.[0]), info: item?.location })
                    if (index === 0) {
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(res?.data?.images?.[0]), info: item?.location }
                    }
                })
                if (res?.data?.isBoostUsed && res?.data?.boostCategory === "profile" || res?.data?.isBoostUsed && res?.data?.boostCategory === "amenities") {
                    getTrackingDetails(res?.data)
                }
                setLocations(locations)
                setMapView(mapview)
            }
            loader(false)
        })
    }

    const getTrackingDetails = (data) => {
        const userLat = sessionStorage.getItem("userLat")
        const userLong = sessionStorage.getItem("userLong")
        let payload = {
            userId: data?._id || data?.id,
            boostType: data?.boostType,
            boostCategory: data?.boostCategory,
            corrdinates: {
                type: "Point",
                coordinates: [userLat || "", userLong || ""]
            },
            deviceToken: sessionStorage.getItem("deviceToken") || ""
        }
        if (token) {
            payload = { ...payload, visitedUserId: user?._id || user?.id }
        }
        ApiClient.post(`boost/track/analytics`, payload)
    }

    const getVenuePosts = () => {
        const filter = { addedFor: id, postType: user?.role === "venue" || user?.role === "host" ? "openOpportunities" : "promote" }
        ApiClient.get(`users/post/listing`, filter).then(res => {
            if (res.success) {
                setPosts(res?.data)
            }
        })
    }

    const getVenueEvents = () => {
        ApiClient.get(`users/event/listing?venueId=${id}`).then(res => {
            if (res.success) {
                setEvents(res?.data)
            }
        })
    }

    const handlePostsPrev = () => {
        if (postsRef.current && postsRef.current.swiper) {
            postsRef.current.swiper.slidePrev();
        }
    }
    const handlePostsNext = () => {
        if (postsRef.current && postsRef.current.swiper) {
            postsRef.current.swiper.slideNext();
        }
    }
    const handleEventsPrev = () => {
        if (eventsRef.current && eventsRef.current.swiper) {
            eventsRef.current.swiper.slidePrev();
        }
    }
    const handleEventsNext = () => {
        if (eventsRef.current && eventsRef.current.swiper) {
            eventsRef.current.swiper.slideNext();
        }
    }

    const handleColab = () => {
        if (getCollaborationStatus()) {
            return
        } else {
            setForm({ description: "" })
            setIsOpen(true)
        }
    }
    const handleCollabration = (e) => {
        e.preventDefault()
        const payload = {
            venueId: id,
            description: form?.description,
            // type: user?.role
        }
        loader(true)
        ApiClient.post(`host/collaborate`, payload).then(res => {
            if (res.success) {
                getVenueDetails()
                setIsOpen(false)
            }
            loader(false)
        })
    }

    const getCollaborationStatus = () => {
        const colabData = detail?.collaboraterDetail
        const userId = user?._id || user?.id
        let value = ""
        colabData?.filter((item) => {
            if (item?.addedBy?._id === userId) {
                value = item?.request_status
            }
        })
        return value
    }

    return (
        <>
            <NavigationBar />
            <div>
                <div className="container mx-auto px-5">
                    <div className="relative banner_button pt-[1rem] pb-[2rem]">
                        <div className="venue_detail">
                            <img src={methodModel.noImg(detail?.backgroundImage)} className="h-[250px] sm:h-[300px] md:h-[400px] rounded-lg w-full object-cover"></img>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container mx-auto px-5 pb-5">
                        <div className="mb-5">
                            {detail?.images?.length !== 0 || detail?.videos?.length !== 0 ?
                                <div className="mb-5">
                                    <div className="grid max-[499px]:grid-cols-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                        {detail?.images?.map((item, index) => {
                                            return <>
                                                <div className="shadow rounded-[15px] bg-white" key={index}>
                                                    <img src={methodModel.noImg(item)} className="max-[390px]:h-[120px] max-[499px]:h-[140px] max-[639px]:h-[160px] sm:h-[130px] md:h-[160px] xl:h-[190px] 2xl:h-[220px] w-full rounded-[15px] border object-cover"></img>
                                                </div>
                                            </>
                                        })}
                                        {detail?.videos?.map((item, index) => {
                                            return <div key={index}>
                                                <video className="max-[390px]:h-[120px] max-[499px]:h-[140px] max-[639px]:h-[160px] sm:h-[130px] md:h-[160px] xl:h-[190px] 2xl:h-[220px] w-full rounded-[15px] border bg-[#000]" controls>
                                                    <source src={methodModel.video(item)} type="video/mp4" />
                                                </video>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null
                            }
                            <div className="mb-8">
                                <div className="flex flex-wrap md:flex-unset justify-between gap-5 mt-5">
                                    <div>
                                        <h2 className="text-[32px] text-[#0F64B0] font-[500] capitalize">{detail?.venue_name}</h2>
                                        <div className="flex flex-wrap gap-y-2 gap-x-5 mt-3">
                                            {detail?.locations?.map((item, index) => {
                                                return <p className="font-[300] flex items-center gap-x-1 text-[#134896]" key={index}><FaLocationDot className="text-[20px]" />{item?.location}</p>
                                            })}
                                            <p className="font-[300] flex items-center gap-x-1 text-[#134896]"><MdEmail className="text-[20px]" />
                                                {detail?.email || ""}
                                            </p>
                                            {detail?.mobileNo &&
                                                <p className="font-[300] flex items-center gap-x-1 text-[#134896]"><IoMdCall className="text-[20px]" />+{detail?.mobileNo}</p>
                                            }
                                        </div>
                                        <div className="h-[1px] w-[105px] bg-[#e7e7e7] mt-3"></div>
                                        <div className="flex flex-wrap gap-x-4 mt-3">
                                            <h2 className="text-[18px] text-[#0F64B0] font-[500] capitalize">Social Links :</h2>
                                            <div className="flex flex-wrap gap-y-2 gap-x-4">
                                                {detail?.website_link && <img onClick={() => { window.open(detail?.website_link, "_blank") }} src="/assets/img/websitelink.png" className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                                {detail?.instagram_link && <img onClick={() => { window.open(detail?.instagram_link, "_blank") }} src="/assets/img/Instagram.webp" className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                                {detail?.facebook_link && <img onClick={() => { window.open(detail?.facebook_link, "_blank") }} src="/assets/img/Facebook.png" className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                                {detail?.tiktok_link && <img onClick={() => { window.open(detail?.tiktok_link, "_blank") }} src="/assets/img/tiktok.webp" className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                                {detail?.youtube_link && <img onClick={() => { window.open(detail?.youtube_link, "_blank") }} src="/assets/img/youtube.png" className="w-[30px] h-[30px] rounded-full cursor-pointer bg-white hover:border-[2px] border-[2px] border-white hover:shadow-md"></img>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end gap-3">
                                        {!token && detail?.addedType !== "self" ?
                                            <button type="button" onClick={e => { navigate(`/providersignup/${detail?._id}`) }} className="flex items-center justify-center text-[16px] text-nowrap font-semibold py-2 px-6 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex h-fit">Claim Venue</button>
                                            :
                                            null
                                        }
                                        {user?.role === "host" || user?.role === "influencer" ? <button type="button" onClick={() => handleColab()} className={`flex items-center justify-center text-[16px] text-nowrap font-semibold py-2 px-6 text-white ${getCollaborationStatus() ? "cursor-not-allowed" : "hover:text-[#005AAB] hover:bg-transparent"} border border-[#005AAB] bg-[#005AAB] rounded-full md:inline-flex h-fit`}>{getCollaborationStatus() ? <span className="capitalize">Collaboration Status: {getCollaborationStatus()}</span> : "Collaborate with Venue"}</button> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-[5rem]" dangerouslySetInnerHTML={{ __html: detail?.description }}></div>
                            <div className="h-[1px] bg-[#d1d1d1] my-[3rem]"></div>
                            <div className="mt-[5rem]">
                                <div className="mb-5">
                                    <h2 className="text-[24px] text-[#0F64B0] font-[500]">Amenities</h2>
                                </div>
                                {detail?.time_schedule?.length !== 0 &&
                                    <div className="mt-[2rem] mb-[2rem]">
                                        <div className="">
                                            <h2 className="font-montserrat flex items-center gap-2 text-[14px] font-[500] leading-7 text-left text-[#0F64B0] mb-1"> <p className="text-xl"><CiClock2 />
                                            </p> Hours of operation
                                            </h2>
                                        </div>
                                        <div className="grid max-[410px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6  gap-4 pr-4">
                                            {detail?.time_schedule?.map((item, index) => {
                                                return <div className=" flex gap-2  items-center flex-wrap max-[410px]:border-0 border-r last:border-none border-gray-300 w-full" key={index}>
                                                    <div className="w-full">
                                                        <div className="inline-block mb-1">
                                                            <p className="text-[#0F64B0] text-[10px] uppercase">{item?.day}</p>
                                                        </div>
                                                        {item?.open ?
                                                            <>
                                                                <div className="leading-[20px] text-[15px] flex items-center gap-2">
                                                                    <span className="">{item?.start_time ? moment(item?.start_time).format("hh:mm A") : ""}</span>
                                                                    -
                                                                    <span className="">{item?.end_time ? moment(item?.end_time).format("hh:mm A") : ""}</span>
                                                                </div>
                                                                <p className="text-[12px] text-gray-600">Best Time To Visit: {item?.best_time_to_visit ? moment(item?.best_time_to_visit).format("hh:mm A") : ""}</p>
                                                            </>
                                                            :
                                                            <div className="leading-[20px] text-center flex items-center justify-center text-gray-600">Closed</div>
                                                        }
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className="grid grid-cols-1 sm:grid-cols-1 mt-5 md:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-8">
                                    {detail?.boostedAmenities?.map((item, index) => {
                                        return <div className="flex gap-3 items-center rounded-2xl border border-[#83c5ff] shadow-lg p-1" key={index}>
                                            <img src={methodModel.noImg(item?.image)} className="w-[50px] h-[50px] object-contain rounded-full" />
                                            <span className="text-[14px] text-[#0F64B0] capitalize">{item?.title || ""}</span>
                                        </div>
                                    })}
                                    {detail?.subAmenities?.map((item, index) => {
                                        const isBoosted = detail?.boostedAmenities?.some((boostedItem) => boostedItem._id === item._id);
                                        if (!isBoosted) {
                                            return <div className="flex gap-3 items-center rounded-md" key={index}>
                                                <img src={methodModel.noImg(item?.image)} className="w-[50px] h-[50px] object-contain rounded-full" />
                                                <span className="text-[14px] capitalize">{item?.title || ""}</span>
                                            </div>
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                            {detail?.menu_item_format === "upload" && detail?.foodImages?.length !== 0 ?
                                <div className="mt-[5rem]">
                                    <div className="mb-5">
                                        <h2 className="text-[24px] text-[#0F64B0] font-[500]">Menu</h2>
                                    </div>
                                    <div className="grid max-[499px]:grid-cols-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                        {detail?.foodImages?.map((item, index) => {
                                            return <div className="border rounded-[15px]" key={index}>
                                                <img src={methodModel.noImg(item)} className="max-[390px]:h-[120px] max-[499px]:h-[140px] max-[639px]:h-[160px] sm:h-[130px] md:h-[160px] xl:h-[190px] 2xl:h-[220px] w-full rounded-[15px] border object-cover"></img>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null}
                            {detail?.menu_item_format === "manual" && detail?.foods?.[0]?.item ?
                                <div className="mt-[5rem]">
                                    <div className="mb-5">
                                        <h2 className="text-[24px] text-[#0F64B0] font-[500]">Food Menu</h2>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-5">
                                        {detail?.foods?.map((item, index) => {
                                            return <div className="border flex justify-between p-4 rounded-lg bg-white" key={index}>
                                                <div className="w-[calc(100%_-_110px)] flex flex-col">
                                                    <p className="font-[500] text-[14px] text_3line capitalize">{item?.item || ""}</p>
                                                    <p className="text-[22px] font-[600] mt-auto">${item?.price || 0}</p>
                                                </div>
                                                <div className="w-[110px]">
                                                    <img src={methodModel.noImg(item?.image)} className="w-full h-[110px] object-cover rounded-lg"></img>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null}
                            {detail?.menu_item_format === "manual" && detail?.drinks?.[0]?.item ?
                                <div className="mt-[5rem]">
                                    <div className="mb-5">
                                        <h2 className="text-[24px] text-[#0F64B0] font-[500]">Drinks Menu</h2>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-5 ">
                                        {detail?.drinks?.map((item, index) => {
                                            return <div className="border flex justify-between p-4 rounded-lg bg-white" key={index}>
                                                <div className="w-[calc(100%_-_110px)] flex flex-col">
                                                    <p className="font-[500] text-[14px] text_3line capitalize">{item?.item || ""}</p>
                                                    <p className="text-[22px] font-[600] mt-auto">${item?.price || 0}</p>
                                                </div>
                                                <div className="w-[110px]">
                                                    <img src={methodModel.noImg(item?.image)} className="w-full h-[110px] object-cover rounded-lg"></img>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                : null}
                            {posts?.length !== 0 &&
                                <div className="mt-[5rem]">
                                    <div className="mb-5 flex justify-between">
                                        <h2 className="text-[24px] text-[#0F64B0] font-[500]">Post</h2>
                                        {posts?.length > 3 &&
                                            <div className='flex gap-4'>
                                                <button className='w-[30px] h-[30px]' onClick={handlePostsPrev}>
                                                    <BsChevronCompactLeft size={30} className="text-black p-[7px] rounded-full bg-[#81818121] shadow" />
                                                </button>
                                                <button className='w-[30px] h-[30px]' onClick={handlePostsNext}>
                                                    <BsChevronCompactRight size={30} className="text-black p-[7px] rounded-full bg-[#81818121] shadow" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="relative">
                                        <Swiper
                                            loop={false}
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            breakpoints={{ 1100: { slidesPerView: 3, spaceBetween: 20 } }}
                                            navigation={false}
                                            modules={[Navigation]}
                                            ref={postsRef}
                                            className="mySwiper w-full">
                                            {posts?.map((item, index) => {
                                                return <SwiperSlide key={index} className="cursor-pointer" onClick={e => { navigate(`/post/detail/${item?._id || item?.id}`) }}>
                                                    <div className="bg-white border rounded-lg p-4">
                                                        <h2 className="text_3line text-[18px] font-[600] text-[#181A2A] capitalize">{item?.title}</h2>
                                                        <p className="p_text text-[14px] text-[#585858]" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                        <div className="flex flex-wrap items-center justify-between gap-3 mt-3">
                                                            <div className="flex items-center gap-2">
                                                                <img src={methodModel.noImg(item?.addedBy?.image || "")} className="h-[30px] w-[30px] rounded-full" />
                                                                <p className="text-[12px] text-[#97989F]">{item?.addedBy?.venue_name || item?.addedBy?.fullName}</p>
                                                            </div>
                                                            <p className="text-[12px] text-[#96979f]">{moment(item?.createdAt).format("MMM DD, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            }
                            {events?.length !== 0 &&
                                <div className="mt-[5rem] mb-5">
                                    <div className="mb-5 flex justify-between">
                                        <h2 className="text-[24px] text-[#0F64B0] font-[500]">Events</h2>
                                        {events?.length > 4 &&
                                            <div className='flex gap-4'>
                                                <button className='w-[30px] h-[30px]' onClick={handleEventsPrev}>
                                                    <BsChevronCompactLeft size={30} className="text-black p-[7px] rounded-full bg-[#81818121] shadow" />
                                                </button>
                                                <button className='w-[30px] h-[30px]' onClick={handleEventsNext}>
                                                    <BsChevronCompactRight size={30} className="text-black p-[7px] rounded-full bg-[#81818121] shadow" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="relative">
                                        <Swiper
                                            loop={false}
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            breakpoints={{ 1100: { slidesPerView: 4, spaceBetween: 20 } }}
                                            navigation={false}
                                            modules={[Navigation]}
                                            ref={eventsRef}
                                            className="mySwiper w-full">
                                            {events?.map((item, index) => {
                                                return <SwiperSlide key={index} className="cursor-pointer" onClick={e => { navigate(`/EventDetails/${item?._id || item?.id}`) }}>
                                                    <div className="bg-white">
                                                        <img src={methodModel.noImg(item?.images?.[0])} className="w-full h-[180px] rounded-t-lg object-cover" />
                                                        <div className="bg-[#F6FAFF] min-h-[66px] px-4 py-3">
                                                            <h2 className="text-[14px] text-[#3A3A3A] font-[500] capitalize">{item?.title}</h2>
                                                            <p className="p_text text-[12px] text-[#3A3A3A]">{moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</p>
                                                            <p className="p_text text-[12px] text-[#3A3A3A]">{item?.location}</p>
                                                            <a href="#" className="text-[#4F4F4F] border-b-[1px] text-[11px] border-[#3A3A3A]">View Detail</a>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            }
                            {locations?.length > 0 &&
                                <div className="w-full mx-auto mt-8">
                                    <CustomMap mapView={mapView} locations={locations} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Collaborate With Venue</DialogTitle>
                            </div>
                            <form onSubmit={handleCollabration}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default VenueDetail