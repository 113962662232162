import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import Table from "../../common/Table";
import SelectDropdown from "../../common/SelectDropdown";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import moment from "moment";

const CommissionTransaction = () => {
    const user = useSelector((state) => state.user);
    const [filters, setFilter] = useState({ page: 1, count: 10, search: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    const roleOptions = [
        { id: "member", name: "Member" },
        { id: "explorer", name: "Explorer" },
    ]

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, influencerId: user?._id || user?.id }
        loader(true)
        let url = "commission/listing"
        ApiClient.get(url, filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const sortClass = (key) => {
        let cls = "fa-sort";
        if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
        else if (filters.key == key && filters.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    }
    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }
        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    }

    const handleFilter = (e, key) => {
        setFilter({ ...filters, [key]: e });
        getData({ [key]: e });
    }
    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "", role: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        getData({ page: e });
    }
    const count = (e) => {
        setFilter({ ...filters, count: e });
        getData({ ...filters, count: e });
    }

    const totalTicketPrice = (data) => {
        let total = 0
        data?.map((item) => {
            total += item.ticket_price
        })
        return total
    }

    const columns = [
        {
            key: "createdAt",
            name: "Date",
            render: (row) => {
                return <span>{moment(row?.createdAt).format("DD MMM, YYYY")}</span>
            },
        },
        {
            key: "paidBy.fullName",
            name: "Name",
            render: (row) => {
                return <span className="capitalize">{row.paidBy?.fullName || `${row.paidBy?.firstName || "--"} ${row.paidBy?.lastName || ""}`}</span>
            },
        },
        {
            key: "paidBy.email",
            name: "Email",
            render: (row) => {
                return <span>{row.paidBy?.email || '--'}</span>
            },
        },
        {
            key: "paidBy.role",
            name: "Role",
            render: (row) => {
                return <span className="capitalize">{row.paidBy?.role || '--'}</span>
            },
        },
        {
            key: "ticketsCount",
            name: "Tickets",
            render: (row) => {
                return <span>{row?.ticketsCount || "--"}</span>
            },
        },
        {
            key: "ticketData",
            name: "Total Ticket Price",
            render: (row) => {
                return <span>${totalTicketPrice(row?.ticketData)}</span>
            },
        },
        {
            key: "influencerId?.activePlanId?.platformFee",
            name: "Platform Fee",
            render: (row) => {
                return <span>{row?.influencerId?.activePlanId?.platformFee ? `${row?.influencerId?.activePlanId?.platformFee}%` : "--"}</span>
            },
        },
        {
            key: "influencerId?.activePlanId?.ticketSalesCommission",
            name: "Ticket Sales Commission",
            render: (row) => {
                return <span>{row?.influencerId?.activePlanId?.ticketSalesCommission ? `${row?.influencerId?.activePlanId?.ticketSalesCommission}%` : "--"}</span>
            },
        },
        {
            key: "amount",
            name: "Total Commission",
            render: (row) => {
                return <span>${row?.amount ? (row?.amount / 100) : 0}</span>
            },
        },
    ]

    return (
        <>
            <div className="shadow-box w-[calc(100%_-_0px)] ml-auto bg-white rounded-lg mt-6">
                <div className="border-[1px] rounded-[15px] p-5 gap-6">
                    <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">Commission Transactions</h3>
                        </div>
                    </div>
                    <div className="flex py-4 items-center flex-wrap gap-2">
                        <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <input
                                    type="text"
                                    id="simple-search"
                                    value={filters?.search}
                                    onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                    autoComplete="one-time-code"
                                    className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                                    placeholder="Search"
                                />
                                {filters?.search && (
                                    <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                )}
                            </div>
                            <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </form>
                        <div className="flex gap-2 ml-auto flex-wrap justify-end">
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="All Roles"
                                theme="search"
                                isClearable={false}
                                intialValue={filters?.role}
                                result={(e) => { handleFilter(e.value, 'role') }}
                                options={roleOptions}
                            />
                            {filters?.role ? (
                                <>
                                    <button onClick={() => clear()} className="bg-[#063688] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
                                        Reset
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <Table
                        className="mb-3"
                        firstColumnClass="width_row"
                        data={data}
                        columns={columns}
                        page={filters?.page}
                        count={filters?.count}
                        filters={filters}
                        total={total}
                        result={(e) => {
                            if (e.event == "page") pageChange(e.value);
                            if (e.event == "sort") {
                                sorting(e.value);
                                sortClass(e.value);
                            }
                            if (e.event == "count") count(e.value);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default CommissionTransaction