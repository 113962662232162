import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user";
import methodModel from "../../methods/methods";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png"
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const ChangePassword = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [form, setForm] = useState({
        confirmPassword: "",
        currentPassword: "",
        newPassword: "",
    });
    const [submitted, setSubmitted] = useState(false);
    const formValidation = [
        {
            key: "confirmPassword",
            minLength: 8,
            confirmMatch: ["confirmPassword", "newPassword"],
        },
        { key: "currentPassword", minLength: 8 },
        { key: "newPassword", minLength: 8 },
    ];
    const [eyes, setEyes] = useState({
        password: false,
        confirmPassword: false,
        currentPassword: false,
    });
    const inValidCurrentPassword = methodModel.passwordValidation(form?.currentPassword);
    const inValidNewPassword = methodModel.passwordValidation(form?.newPassword);

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid || !inValidCurrentPassword || !inValidNewPassword) return
        loader(true)
        const role = user?.role
        let payload = {
            currentPassword: form.currentPassword,
            newPassword: form.newPassword,
            id: user?._id || user?.id,
        }
        let url = "users/change/password"
        ApiClient.put(url, payload).then((res) => {
            if (res.success) {
                dispatch(logout())
                sessionStorage.clear()
                if (role === "venue" || role === "host") {
                    history("/signin")
                } else {
                    history(`/signin?role=${role}`)
                }
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-gray-50 dark:bg-gray-900 min-h-screen">
                <div className="flex flex-col items-center justify-center mx-auto lg:py-0 h-screen">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg xl:p-0">
                        <div className="p-6 pb-0">
                            <div>
                                <svg onClick={() => { history(-1) }} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"></path>
                                </svg>
                            </div>
                            <a className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white w-fit mx-auto" href="/">
                                <img className="h-8 rounded p-1 mr-2" src={logo} alt="logo" /></a>
                            <div className="main-title mb-4">
                                <h3 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
                                    Change Password
                                </h3>
                            </div>
                        </div>
                        <div className=" grid grid-cols-12">
                            <div className="col-span-12 input_form p-6 shadow-box overflow-hidden rounded-lg bg-white">
                                <form onSubmit={handleSubmit}>
                                    <div className="items-center ">
                                        <div className="">
                                            <div className="flex-col lg:flex-row  lg:items-center items-start gap-4 mb-6">
                                                <label className="text-typo text-base font-medium w-96">
                                                    Current Password<span className="text-red-600">*</span>
                                                </label>
                                                <div className="w-full">
                                                    <div className="relative">
                                                        <input
                                                            type={eyes.currentPassword ? "text" : "password"}
                                                            className="border bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                                            value={form.currentPassword}
                                                            minLength={8}
                                                            maxLength={16}
                                                            placeholder="Enter current password"
                                                            onChange={(e) =>
                                                                setForm({
                                                                    ...form,
                                                                    currentPassword: e.target.value,
                                                                })
                                                            }
                                                            required
                                                        />
                                                        <div className="absolute right-3 top-2.5 cursor-pointer text-grey-500 text-sm">
                                                            {!eyes?.currentPassword ?
                                                                <IoEyeOffOutline size={20} onClick={() => setEyes({ ...eyes, currentPassword: !eyes.currentPassword, })} />
                                                                :
                                                                <IoEyeOutline size={20} onClick={() => setEyes({ ...eyes, currentPassword: !eyes.currentPassword, })} />
                                                            }
                                                        </div>
                                                    </div>
                                                    {form?.currentPassword && !inValidCurrentPassword && submitted && (
                                                        <div className="text-red-600">{methodModel.passwordValidationMessages(form?.currentPassword)}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col lg:flex-row lg:items-center items-start gap-4 mb-6">
                                                <label className="text-typo text-base font-medium w-96">
                                                    New Password<span className="text-red-600">*</span>
                                                </label>
                                                <div className="w-full">
                                                    <div className="relative">
                                                        <input
                                                            type={eyes.password ? "text" : "password"}
                                                            className="border bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                                            value={form.newPassword}
                                                            placeholder="Enter new password"
                                                            minLength={8}
                                                            maxLength={16}
                                                            onChange={(e) =>
                                                                setForm({ ...form, newPassword: e.target.value })
                                                            }
                                                            required
                                                        />
                                                        <div className="absolute right-3 top-2.5 cursor-pointer text-grey-500 text-sm">
                                                            {!eyes?.password ?
                                                                <IoEyeOffOutline size={20} onClick={() => setEyes({ ...eyes, password: !eyes.password, })} />
                                                                :
                                                                <IoEyeOutline size={20} onClick={() => setEyes({ ...eyes, password: !eyes.password, })} />
                                                            }
                                                        </div>
                                                    </div>
                                                    {form?.newPassword && !inValidNewPassword && submitted && (
                                                        <div className="text-red-600">{methodModel.passwordValidationMessages(form?.newPassword)}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col lg:flex-row  lg:items-center items-start gap-4 mb-6">
                                                <label className="text-typo text-base font-medium w-96">
                                                    Confirm Password<span className="text-red-600">*</span>
                                                </label>
                                                <div className="relative w-full">
                                                    <input
                                                        type={eyes.confirmPassword ? "text" : "password"}
                                                        className="border bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                                        placeholder="Enter Confirm Password"
                                                        value={form.confirmPassword}
                                                        minLength={8}
                                                        maxLength={16}
                                                        onChange={(e) =>
                                                            setForm({ ...form, confirmPassword: e.target.value })
                                                        }
                                                        required
                                                    />
                                                    <div className="absolute right-3 top-2.5 cursor-pointer text-grey-500 text-sm">
                                                        {!eyes?.confirmPassword ?
                                                            <IoEyeOffOutline size={20} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword, })} />
                                                            :
                                                            <IoEyeOutline size={20} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword, })} />
                                                        }
                                                    </div>
                                                    {submitted && getError("confirmPassword").invalid ? (
                                                        <>
                                                            {getError("confirmPassword").err.confirmMatch ? (
                                                                <div className="d-block text-red-600">
                                                                    Confirm Password is not matched with New Password
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex items-end justify-end">
                                                <button type="submit" className="w-full text-white bg-[#005AAB] font-medium rounded-full text-sm px-5 py-3 text-center hover:opacity-[90%]">
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword