import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { IoCloseOutline, IoDocumentTextOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import loader from "../../methods/loader";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../redux/actions/user";
import PopUp from "../../common/PopUp";
import { useNavigate } from "react-router-dom";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import ReactGoogleAutocomplete from "react-google-autocomplete";
import environment from "../../environment";

const VendorProfile = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [form, setform] = useState({
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        mobileNo: user?.mobileNo || "",
        image: user?.image || "",
        role: user?.role,
        description: user?.description || "",
        youtube_link: user?.youtube_link || "",
        instagram_link: user?.instagram_link || "",
        facebook_link: user?.facebook_link || "",
        twitter_link: user?.twitter_link || "",
        linkedin_link: user?.linkedin_link || "",
        company_ein: user?.company_ein || "",
        ein_image: user?.ein_image || "",
        location: user?.location || "",
        country: user?.country || "",
        state: user?.state || "",
        city: user?.city || "",
        zipCode: user?.zipCode || "",
    });
    const dispatch = useDispatch()
    const [submitted, setSubmitted] = useState(false)
    const [editAble, setEditAble] = useState(false)
    const [popUp, setPopUp] = useState({ modal: false, title: "", message: "", buttonText: "", image: "" })

    useEffect(() => {
        vendorProfileDetail(user)
    }, []);

    const vendorProfileDetail = (userDetail) => {
        if (!userDetail?.company_ein || !userDetail?.ein_image) {
            setPopUp({ modal: true, title: "", image: "/assets/img/warning.png", message: "Kindly upload your document for our team to verify your account. Thank you.", buttonText: "Ok" })
            return
        }
        if (userDetail?.doc_status === "verified" && !methodModel.checkUserDetail(userDetail)) {
            setPopUp({ modal: true, title: "", image: "/assets/img/warning.png", message: "Please ensure that all mandatory fields are completed. Thank you.", buttonText: "Ok" })
        } else {
            if (userDetail?.docVerified === "N") {
                if (userDetail?.ein_image && userDetail?.doc_status === "rejected") {
                    setPopUp({ modal: true, title: "", image: "/assets/img/not-approved.jpg", message: "Your profile has not been approved by the administrator. Thank you for your understanding.", buttonText: "Ok" })
                } else {
                    setPopUp({ modal: true, title: "", image: "/assets/img/waiting.png", message: "Your documents are being reviewed by our team. We’ll notify you as soon as your account is verified. Thanks for your patience!", buttonText: "Ok" })
                }
            }
        }
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!form?.ein_image) return
        let method = "put";
        let url = "users/edit/profile";
        let value = { ...form, id: user?.id || user?._id, }
        loader(true)
        ApiClient.allApi(url, value, method).then((res) => {
            if (res.success) {
                dispatch(login_success({ ...user, ...value }))
                vendorProfileDetail({ ...user, ...value })
                setEditAble(false)
                const profileDataBefore = methodModel.checkUserDetail(user)
                const profileDataAfter = methodModel.checkUserDetail({ ...user, ...value })
                if (!profileDataBefore && profileDataAfter) {
                    setPopUp({ modal: true, title: "", image: "/assets/img/warning.png", message: "Please consider upgrading to a paid plan to access additional features on the platform.", buttonText: "Ok", redirectUrl: "/plans" })
                } else {
                    toast.success(res?.message)
                }
            }
            loader(false)
        });
    }

    const ImageUpload = (e, key) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, [key]: res?.fileName })
            }
            loader(false)
        })
    }

    const handleDrop = (e) => {
        e.preventDefault();
        uploadEIN(e);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const uploadEIN = (e) => {
        let files = e?.target?.files || e?.dataTransfer?.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file?.type)) {
            toast.error("Only doc, pdf, jpeg and png are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    const handleLocation = (place) => {
        const addressComponents = place?.address_components;
        const address = {};

        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            const types = component?.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }

        address.location = place?.formatted_address
        address.coordinates = {
            type: "Point",
            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        }
        setform((prev) => ({ ...prev, ...address }))
    }

    const addBankAccount = () => {
        const payload = {
            email: form?.email,
            businessName: `${form?.firstName} ${form?.lastName}`,
            country: "US"
        }
        loader(true)
        ApiClient.post(`account/create`, payload).then(res => {
            if (res?.data?.url) {
                window.open(res?.data?.url, "_self")
            }
            loader(false)
        })
    }
    const removeBankAccount = () => {
        const payload = {
            accountId: user?.accountDetails?.accountId
        }
        loader(true)
        ApiClient.delete(`account/delete`, payload).then(res => {
            if (res?.success) {
                dispatch(login_success({ ...user, accountDetails: {} }))
            }
            loader(false)
        })
    }
    const verifyBankAccount = () => {
        const payload = {
            accountId: user?.accountDetails?.accountId
        }
        loader(true)
        ApiClient.post(`account/link/regenerate`, payload).then(res => {
            if (res?.data?.url) {
                window.open(res?.data?.url, "_self")
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="border-[1px] rounded-[15px] p-5 gap-6">
                <div className="flex justify-between items-center gap-x-4 gap-y-2 flex-wrap mb-3">
                    <h2 className="text-[24px] font-[600] text-[#005AAB]">Profile Page</h2>
                    {user?.doc_status === "rejected" && <div type="button" onClick={() => { navigate(`/reapply/${user?.role}/${user?._id || user?.id}`) }} className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Re-apply</div>}
                    {user?.doc_status === "verified" &&
                        <div className="flex gap-2 ml-auto">
                            <div onClick={e => { navigate(`/vendorProfile/${user?._id || user?.id}`) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Preview</div>
                            <div onClick={e => { setEditAble(!editAble) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">{editAble ? "Cancel" : "Edit"}</div>
                        </div>
                    }
                </div>
                <form onSubmit={handleUpdate} autoComplete="one-time-code">
                    <TabGroup>
                        <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                            <Tab key="Info" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                Info
                            </Tab>
                            {user?.doc_status === "verified" &&
                                <>
                                    <Tab key="About" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        About
                                    </Tab>
                                    <Tab key="Social" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Social Media
                                    </Tab>
                                    <Tab key="Address" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Address
                                    </Tab>
                                    {user?.email && user?.fullName ?
                                        <Tab key="Bank" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Bank Account
                                        </Tab>
                                        : null}
                                </>
                            }
                            <Tab key="Documents" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                Documents
                            </Tab>
                        </TabList>
                        <TabPanels className="mt-3">
                            <TabPanel key="Info">
                                <ul>
                                    <li key="Info" className="relative rounded-md text-sm/6 transition">
                                        <div className="">
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                    <FormControl
                                                        type="text"
                                                        name="firstName"
                                                        label="First Name"
                                                        placeholder="Enter First Name"
                                                        value={form?.firstName}
                                                        onChange={(e) => setform({ ...form, firstName: e })}
                                                        className="disabled:cursor-not-allowed"
                                                        disabled={!editAble}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                    <FormControl
                                                        type="text"
                                                        name="lastName"
                                                        label="Last Name"
                                                        placeholder="Enter Last Name"
                                                        value={form?.lastName}
                                                        onChange={(e) => setform({ ...form, lastName: e })}
                                                        className="disabled:cursor-not-allowed"
                                                        disabled={!editAble}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                    <FormControl
                                                        type="text"
                                                        name="email"
                                                        label="Email"
                                                        placeholder="Enter Email"
                                                        value={form.email}
                                                        className={"cursor-not-allowed"}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-span-12 lg:col-span-6 mobile_number mb-3">
                                                    <FormControl
                                                        type="phone"
                                                        name="mobileNo"
                                                        label="Mobile Number"
                                                        value={form.mobileNo}
                                                        onChange={(e) => setform({ ...form, mobileNo: e })}
                                                        disabled={!editAble}
                                                    />
                                                </div>
                                                <div className="col-span-12 mb-3">
                                                    <label>Image (JPG/PNG)</label>
                                                    <div className={`flex flex-col rounded-lg gap-6 max-sm:mx-auto ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                        {form?.image ? (
                                                            <>
                                                                <div className="flex flex-wrap gap-3 mt-3">
                                                                    <div className="relative">
                                                                        <img src={methodModel.userImg(form?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                        {!editAble ? null : <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, image: "" })} size={25} />}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                                                <FiPlus />
                                                                <input
                                                                    id="dropzone-file"
                                                                    type="file"
                                                                    className="hidden"
                                                                    disabled={!editAble}
                                                                    onChange={e => ImageUpload(e, "image")}
                                                                />
                                                                Upload Image
                                                            </label>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </TabPanel>
                            {user?.doc_status === "verified" &&
                                <>
                                    <TabPanel key="About">
                                        <ul>
                                            <li key="About" className="relative rounded-md text-sm/6 transition">
                                                <div className="grid grid-cols-12 gap-4">
                                                    <div className="col-span-12 mb-3">
                                                        <FormControl
                                                            type="editor"
                                                            name="description"
                                                            label="Description"
                                                            value={form?.description}
                                                            onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                                            className={!editAble ? "cursor-not-allowed" : ""}
                                                            disabled={!editAble}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </TabPanel>
                                    <TabPanel key="Social Media">
                                        <ul>
                                            <li key="Social Media" className="relative rounded-md text-sm/6 transition">
                                                <div className="">
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="youtube_link"
                                                                label="YouTube Link"
                                                                placeholder="Enter YouTube Link"
                                                                autoComplete="one-time-code"
                                                                value={form?.youtube_link}
                                                                onChange={(e) => setform({ ...form, youtube_link: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="instagram_link"
                                                                label="Instagram Link"
                                                                placeholder="Enter Instagram Link"
                                                                autoComplete="one-time-code"
                                                                value={form?.instagram_link}
                                                                onChange={(e) => setform({ ...form, instagram_link: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="facebook_link"
                                                                label="FaceBook Link"
                                                                placeholder="Enter FaceBook Link"
                                                                autoComplete="one-time-code"
                                                                value={form?.facebook_link}
                                                                onChange={(e) => setform({ ...form, facebook_link: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="twitter_link"
                                                                label="Twitter Link"
                                                                placeholder="Enter Twitter Link"
                                                                autoComplete="one-time-code"
                                                                value={form?.twitter_link}
                                                                onChange={(e) => setform({ ...form, twitter_link: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="linkedin_link"
                                                                label="LinkedIn Link"
                                                                placeholder="Enter LinkedIn Link"
                                                                autoComplete="one-time-code"
                                                                value={form?.linkedin_link}
                                                                onChange={(e) => setform({ ...form, linkedin_link: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </TabPanel>
                                    <TabPanel key="Address" className="">
                                        <ul>
                                            <li key="Address" className="relative rounded-md text-sm/6 transition">
                                                <div className="">
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <label>Location <span className="text-red-600">*</span></label>
                                                            <ReactGoogleAutocomplete
                                                                apiKey={environment?.map_api_key}
                                                                onPlaceSelected={(place) => { handleLocation(place) }}
                                                                onChange={e => setform({ ...form, location: e.target.value })}
                                                                value={form?.location}
                                                                options={{
                                                                    types: ["(regions)"],
                                                                    componentRestrictions: { country: "us" },
                                                                }}
                                                                key="hostAddress"
                                                                placeholder="Enter Location"
                                                                required
                                                                disabled={!editAble}
                                                                className={`relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] ${!editAble ? "cursor-not-allowed" : ""}`}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="country"
                                                                label="Country"
                                                                placeholder="Enter country"
                                                                autoComplete="one-time-code"
                                                                value={form?.country}
                                                                onChange={(e) => setform({ ...form, country: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="state"
                                                                label="State"
                                                                placeholder="Enter State"
                                                                autoComplete="one-time-code"
                                                                value={form?.state}
                                                                onChange={(e) => setform({ ...form, state: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="city"
                                                                label="City"
                                                                placeholder="Enter City"
                                                                autoComplete="one-time-code"
                                                                value={form?.city}
                                                                onChange={(e) => setform({ ...form, city: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="zipCode"
                                                                label="Zip Code"
                                                                placeholder="Enter Zip Code"
                                                                autoComplete="one-time-code"
                                                                value={form?.zipCode}
                                                                onChange={(e) => setform({ ...form, zipCode: e })}
                                                                disabled={!editAble}
                                                                className={!editAble ? "cursor-not-allowed" : ""}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </TabPanel>
                                    {user?.email && user?.fullName ?
                                        <TabPanel key="Bank" className="">
                                            <ul>
                                                <li key="Bank" className="relative rounded-md text-sm/6 transition">
                                                    <div className="w-full">
                                                        {user?.accountDetails?.bankAccountNumber ?
                                                            <div className="max-w-[500px] max-[480px]:text-center rounded-lg overflow-hidden shadow-lg bg-white">
                                                                <h3 className="text-xl font-semibold bg-[#134896] text-white px-5 py-3 mb-2">{user?.accountDetails?.bank_name || "--"}</h3>
                                                                <div className="grid max-[480px]:grid-cols-1 grid-cols-2 gap-5 items-center p-5">
                                                                    <div>
                                                                        <p className="text-[22px] text-gray-600 mb-4">Account Number:</p>
                                                                        <p className="text-[18px] text-gray-600 mb-4">***-***-**** {user?.accountDetails?.bankAccountNumber || "--"}</p>
                                                                        <div className="flex items-center max-[480px]:justify-center">
                                                                            {user?.accountDetails?.transfer === "inactive" ?
                                                                                <button type="button" disabled={!editAble} className={`bg-blue-500 text-white disabled:cursor-not-allowed px-4 py-2 rounded ${editAble && "hover:bg-blue-700"}`} onClick={() => verifyBankAccount()}>Verify Your Account</button>
                                                                                :
                                                                                <button type="button" disabled={!editAble} className={`bg-red-500 text-white disabled:cursor-not-allowed px-4 py-2 rounded ${editAble && "hover:bg-red-700"}`} onClick={() => removeBankAccount()}>Remove Account</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <img src="/assets/img/back_account.jpg" className="h-[200px] w-full object-contain" />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="text-center">
                                                                <button type="button" onClick={e => addBankAccount()} className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">Add Bank Account</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        : null}
                                </>
                            }
                            <TabPanel key="Documents">
                                <ul>
                                    <li key="Documents" className="relative rounded-md text-sm/6 transition">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="company_ein"
                                                    label="Company EIN"
                                                    placeholder="Enter Company EIN"
                                                    autoComplete="one-time-code"
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    value={form?.company_ein}
                                                    onChange={(e) => setform({ ...form, company_ein: e })}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-12 mb-3">
                                                <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Upload Company EIN <span className="text-red-600">*</span>
                                                </label>
                                                {form?.ein_image ?
                                                    <div className="relative">
                                                        <IoDocumentTextOutline size={20} className={`bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain ${!editAble ? "cursor-not-allowed" : ""}`} />
                                                        {editAble && <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />}
                                                    </div>
                                                    :
                                                    <label onDrop={handleDrop} onDragOver={handleDragOver} className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                        <div className="text-center">
                                                            <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                <FiPlus />
                                                                <input
                                                                    id="dropzone-file"
                                                                    type="file"
                                                                    className="hidden"
                                                                    multiple
                                                                    disabled={!editAble}
                                                                    onChange={e => uploadEIN(e)}
                                                                />
                                                                Upload or Drag & Drop Company EIN
                                                            </div>
                                                        </div>
                                                    </label>
                                                }
                                                {!form?.ein_image && submitted &&
                                                    <span className="text-red-600">Company EIN is required</span>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </TabPanel>
                        </TabPanels>
                    </TabGroup>
                    <div>
                        {editAble &&
                            <div className="flex items-center gap-2 m-4 justify-end">
                                <div onClick={e => { setEditAble(!editAble) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Cancel</div>
                                <button type="submit" className="text-white disabled:cursor-not-allowed bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">
                                    Update
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </div>
            <PopUp popUp={popUp} setPopUp={setPopUp} redirectUrl={popUp?.redirectUrl} />
        </>
    )
}

export default VendorProfile