import { useNavigate, useParams } from "react-router-dom";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import MemberProfile from "./MemberProfile";
import VenueProfile from "./VenueProfile";
import HostProfile from "./HostProfile";
import InfluencerProfile from "./InfluencerProfile";
import EntertainmentCrewProfile from "./EntertainmentCrewProfile";
import CrewProfile from "./CrewProfile";
import VendorProfile from "./VendorProfile";
import FoodTruckVendorProfile from "./FoodTruckVendorProfile";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Profile = () => {
    const { role } = useParams()
    const user = useSelector((state)=>state.user)
    const [userRole, setUserRole] = useState(role || "")
    const navigate = useNavigate()

    useEffect(()=>{
        if(!role){
            if(user?.loggedIn){
                let slug = user?.role
                if(user?.role === "crew") slug = "entertainmentCrew"
                else if(user?.role === "crew_member") slug = "crewMember"
                else if(user?.role === "food_truck_vendor") slug = "foodTruckVendor"
                else slug = user?.role
                setUserRole(slug)
            }else{
                navigate("/signin")
            }
        }
    },[])

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 mt-6">
                    {userRole === "member" && <MemberProfile />}
                    {userRole === "venue" && <VenueProfile />}
                    {userRole === "host" && <HostProfile />}
                    {userRole === "influencer" && <InfluencerProfile />}
                    {userRole === "entertainmentCrew" && <EntertainmentCrewProfile />}
                    {userRole === "crewMember" && <CrewProfile />}
                    {userRole === "vendor" && <VendorProfile />}
                    {userRole === "foodTruckVendor" && <FoodTruckVendorProfile />}
                </div>
            </div>
        </>
    )
}

export default Profile