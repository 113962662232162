import { useEffect, useRef, useState } from "react";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'; // Add this plugin for date range selection
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import SelectDropdown from "../../common/SelectDropdown";
import PopUp from "../../common/PopUp";

const SetupSchedule = () => {
    const user = useSelector((state) => state.user)
    let [form, setForm] = useState({ applyAll: true, time_schedule: [] })
    const [events, setEvents] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const locationForm = useRef()
    const [popUp, setPopUp] = useState({ modal: false, title: "", message: "", buttonText: "" })

    const freeToTravelOptions = [
        { id: "Yes", name: "Yes" },
        { id: "No", name: "No" }
    ]

    useEffect(() => {
        if (user?._id || user?.id) {
            getHostSchedule()
        }
    }, [])

    useEffect(() => {
        locationForm.current = form
    }, [form])

    const getHostSchedule = () => {
        loader(true)
        ApiClient.get(`host/availability/listing?hostId=${user?._id || user?.id}`, {}, "", true).then(res => {
            if (res.success) {
                setEvents(res?.data?.location_availability)
            } else {
                setEvents([])
            }
            loader(false)
        })
    }

    const renderEventContent = (eventInfo) => {
        const eventData = eventInfo?.event?._def?.extendedProps || {}
        return (
            <div className="text-wrap p-2 bg-[#134896] text-white w-full">
                <p>Location: <b>{eventData?.location}</b></p>
                <p>Start Time: <b>{moment(eventData?.start_time).format("hh:mm A")}</b></p>
                <p>End Time: <b>{moment(eventData?.end_time).format("hh:mm A")}</b></p>
                <p>Free To Travel: <b>{eventData?.free_to_travel || "--"}</b></p>
            </div>
        );
    };

    const handleDateRangeSelect = (info) => {
        const startDate = info.startStr;
        const endDate = info.endStr;
        const selectedDates = [];
        const currentDate = new Date(startDate);
        while (currentDate < new Date(endDate)) {
            const formData = events?.find((item, index) => item?.date === currentDate.toISOString()) || ""
            if (!formData) {
                selectedDates.push({ date: currentDate.toISOString(), start_time: "", end_time: "", location: "", free_to_travel: "" });
            } else {
                selectedDates.push({ date: currentDate.toISOString(), start_time: new Date(formData?.start_time), end_time: new Date(formData?.end_time), location: formData?.location, free_to_travel: formData?.free_to_travel });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setForm((prev) => ({ ...prev, time_schedule: selectedDates }))
        setIsOpen(true);
    };

    const handleApplyAll = (checked) => {
        const data = form?.time_schedule[0]
        const timeSchedule = form?.time_schedule?.map((item, index) => {
            return ({ ...item, start_time: data?.start_time, end_time: data?.end_time, location: data?.location, free_to_travel: data?.free_to_travel })
        })
        if (checked) {
            setForm((prev) => ({ ...prev, applyAll: checked, time_schedule: timeSchedule }))
        } else {
            setForm((prev) => ({ ...prev, applyAll: checked }))
        }
    }
    const handleTimeSchedule = (date, index, key) => {
        let data = form?.time_schedule
        if (form?.applyAll) {
            data = data.map((item, i) => {
                return { ...item, [key]: date }
            })
        } else {
            data[index][key] = date
        }
        setForm((prev) => ({ ...prev, time_schedule: data }))
    }
    const getEndTime = (hours, min) => {
        // Get the current date
        let now = new Date();
        // Extract the current date parts (year, month, and day)
        let year = now.getFullYear();
        let month = now.getMonth(); // Note: January is 0, December is 11
        let day = now.getDate();
        // Create a new Date object for hours min on the current date
        let dateWithTime = new Date(year, month, day, hours, min, 0);
        return dateWithTime;
    }
    const addTenMinutes = (date) => {
        // Ensure the argument is a Date object
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            throw new Error("Invalid date");
        }
        // Create a new Date object to avoid mutating the original date
        let newDate = new Date(date);
        // Add 10 minutes to the new date
        newDate.setMinutes(newDate.getMinutes() + 10);
        return newDate;
    }
    const closeModal = () => {
        setIsOpen(false)
        setForm({ applyAll: true, time_schedule: [] })
    }
    const handleLocation = (place, index) => {
        const addressComponents = place?.address_components;
        let timeSchedule = locationForm.current?.time_schedule
        const address = locationForm.current?.time_schedule[index]
        for (let i = 0; i < addressComponents?.length; i++) {
            const component = addressComponents[i];
            const types = component?.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }
        address.location = place?.formatted_address
        address.coordinates = {
            type: "Point",
            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        }
        if (locationForm.current?.applyAll) {
            timeSchedule = timeSchedule.map((item, i) => {
                return { ...item, ...address, date: item?.date }
            })
        } else {
            timeSchedule[index] = address
        }
        setForm((prev) => ({ ...prev, time_schedule: timeSchedule }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            availability: form?.time_schedule
        }
        loader(true)
        if (events?.length === 0) {
            ApiClient.post(`host/location/availability`, payload).then(res => {
                if (res.success) {
                    getHostSchedule()
                } else {
                    loader(false)
                }
                setIsOpen(false)
            })
        } else {
            const eventsData = [...events]; // Your first array of objects
            const time_schedule = form?.time_schedule?.filter((item) => item?.start_time) || []; // Your second array of objects
            const uniqueMap = new Map(); // Create a map to track unique dates
            // Function to add or update an object in the map
            const addOrUpdate = (item) => {
                uniqueMap.set(item.date, { ...uniqueMap.get(item.date), ...item });
            };
            // Add events to the map
            eventsData.forEach(event => {
                addOrUpdate(event);
            });
            // Add time_schedule to the map, updating if dates match
            time_schedule.forEach(schedule => {
                addOrUpdate(schedule);
            });
            const mergedAvailability = Array.from(uniqueMap.values());
            const payload = { availability: mergedAvailability, id: user?._id || user?.id };
            ApiClient.put(`host/update/availability`, payload).then(res => {
                if (res.success) {
                    getHostSchedule()
                } else {
                    loader(false)
                }
                setIsOpen(false)
            })
        }
    }

    const dateSelectionLogic = (type) => {
        const today = new Date();
        const thirtyDaysFromNow = new Date(today);
        thirtyDaysFromNow.setDate(today.getDate() + 30);
        const sixDaysFromNow = new Date(today);
        sixDaysFromNow.setDate(today.getDate() + 6);
        const todayMidnight = new Date(today);
        todayMidnight.setHours(0, 0, 0, 0);
        return (selectInfo) => {
            const startDate = new Date(selectInfo.start);
            if (type === 'daily') {
                if (startDate > today) {
                    setPopUp({ modal: true, message: "Your current plan limits the number of days you can schedule. To schedule additional days, please upgrade your plan.", image: "/assets/img/warning.png", cancelButton: "Cancel" , buttonText: "Upgrade Plan" })
                    return false;
                }
                return startDate.toDateString() === today.toDateString();
            }
            else if (type === 'weekly') {
                if (startDate > sixDaysFromNow) {
                    setPopUp({ modal: true, message: "Your current plan limits the number of days you can schedule. To schedule additional days, please upgrade your plan.", image: "/assets/img/warning.png", cancelButton: "Cancel" , buttonText: "Upgrade Plan" })
                    return false;
                }
                return startDate >= todayMidnight && startDate <= sixDaysFromNow;
            }
            else if (type === 'monthly') {
                if (startDate > thirtyDaysFromNow) {
                    setPopUp({ modal: true, message: "Your current plan limits the number of days you can schedule. To schedule additional days, please upgrade your plan.", image: "/assets/img/warning.png", cancelButton: "Cancel" , buttonText: "Upgrade Plan" })
                    return false;
                }
                return startDate >= todayMidnight && startDate <= thirtyDaysFromNow;
            }
            else if (type === '') {
                return startDate >= todayMidnight;
            }
            return true;
        };
    };

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <div className="bg-white">
                    <DesktopSidebar />
                    <div className="container mx-auto px-5 mt-6">
                        <div className="border-[1px] rounded-[15px] p-5 gap-6">
                            <div className="w-full flex flex-col">
                                <div className="w-full flex flex-row justify-between items-center">
                                    <h3 className="font-semibold text-[24px] text-[#134896]">Manage Schedule</h3>
                                </div>
                            </div>
                            <div className="calendar_div w-full h-full">
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]} // Add the interaction plugin
                                    initialView='dayGridMonth'
                                    headerToolbar={{
                                        right: 'prev,next today',
                                        left: 'title',
                                    }}
                                    weekends={true}
                                    events={events}
                                    eventContent={renderEventContent}
                                    selectable={true} // Enable date range selection
                                    selectAllow={dateSelectionLogic(user?.activePlanId?.scheduleType || "")}
                                    select={handleDateRangeSelect} // Call the handleDateRangeSelect function on date range selection
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-6xl rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Manage Schedule</DialogTitle>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="relative phone-input overflow-x-auto border border-[#eee] sm:rounded-lg ">
                                    <table className="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 capitalize bg-gray-50">
                                        <tr>
                                            <th className="px-2 py-3 whitespace-nowrap">
                                                <label className="flex items-cneter gap-2 cursor-pointer">
                                                    <input type="checkbox" onChange={e => handleApplyAll(e.target.checked)} checked={form?.applyAll} className="h-5 w-5" />Apply for all
                                                </label>
                                            </th>
                                            <th className="px-2 py-3 whitespace-nowrap">Start Time <span className="text-red-600">*</span></th>
                                            <th className="px-2 py-3 whitespace-nowrap">End Time <span className="text-red-600">*</span></th>
                                            <th className="px-2 py-3 whitespace-nowrap">Location <span className="text-red-600">*</span></th>
                                            <th className="px-2 py-3 whitespace-nowrap">Are you free to travel <span className="text-red-600">*</span></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {form?.time_schedule?.map((item, index) => {
                                            return <tr key={index} className="bg-white border-b ">
                                                <td className="px-2 py-4 whitespace-nowrap capitalize">{moment(item?.date).format("DD MMM, YYYY")}</td>
                                                <td className="px-2 py-4 whitespace-nowrap">
                                                    <DatePicker
                                                        key={`start_time-${index}`}
                                                        selected={item?.start_time}
                                                        onChange={(date) => handleTimeSchedule(date, index, 'start_time')}
                                                        className={`border border-[#00000036] rounded-md h-10 w-[200px] p-2 ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                                        dateFormat="hh:mm a"
                                                        placeholderText="Start Time"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Start Time"
                                                        disabled={index !== 0 && form?.applyAll}
                                                        required
                                                    />
                                                </td>
                                                <td className="px-2 py-4 whitespace-nowrap">
                                                    <DatePicker
                                                        key={`end_time-${index}`}
                                                        selected={item?.end_time}
                                                        onChange={(date) => handleTimeSchedule(date, index, 'end_time')}
                                                        className={`border border-[#00000036] rounded-md h-10 w-[200px] p-2 ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                                        dateFormat="hh:mm a"
                                                        placeholderText="End Time"
                                                        minTime={item?.start_time ? addTenMinutes(item?.start_time) : getEndTime(0, 0)}
                                                        maxTime={getEndTime(23, 50)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="End Time"
                                                        disabled={index !== 0 && form?.applyAll}
                                                        required
                                                    />
                                                </td>
                                                <td className="px-2 py-4 whitespace-nowrap">
                                                    <ReactGoogleAutocomplete
                                                        key={`location-${index}`}
                                                        apiKey={environment?.map_api_key}
                                                        onPlaceSelected={(place) => handleLocation(place, index)}
                                                        onChange={e => handleTimeSchedule(e.target.value, index, "location")}
                                                        value={item?.location}
                                                        options={{ types: ["(regions)"], componentRestrictions: { country: "us" } }}
                                                        placeholder="Enter Location"
                                                        required
                                                        className={`bg-white rounded-lg h-10 w-[200px] overflow-hidden px-2 border border-[#00000036] ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                                        disabled={index !== 0 && form?.applyAll}
                                                    />
                                                </td>
                                                <td className="px-2 py-4 whitespace-nowrap">
                                                    <SelectDropdown
                                                        id="statusDropdown"
                                                        displayValue="name"
                                                        placeholder="Select Options"
                                                        theme="search"
                                                        isClearable={false}
                                                        intialValue={item?.free_to_travel}
                                                        result={(e) => handleTimeSchedule(e.value, index, "free_to_travel")}
                                                        options={freeToTravelOptions}
                                                        className={`${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                                        disabled={index !== 0 && form?.applyAll}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="my-4 mt-[2rem] flex flex-wrap justify-end gap-3">
                                    <Button type="button" onClick={e => closeModal()} className="px-5 py-2 bg-[#005AAB] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Cancel</Button>
                                    <Button type="submit" className="px-5 py-2 bg-[#005AAB] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">Save</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <PopUp popUp={popUp} setPopUp={setPopUp} redirectUrl={"/plans"} />
        </>
    );
};

export default SetupSchedule;