import { useEffect, useState } from "react";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { MdClear } from "react-icons/md";
import Table from "../../common/Table";
import SelectDropdown from "../../common/SelectDropdown";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { PiEyeLight } from "react-icons/pi";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";


const Listing = () => {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate()

    const [filters, setFilter] = useState({ page: 1, count: 10, search: "", request_status: "" })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    const roleOptions = [
        { id: "host", name: "Host" },
        { id: "influencer", name: "Influencer" },
        { id: "crew", name: "Entertainment Crew" },
        { id: "vendor", name: "Vendor" },
        { id: "food_truck_vendor", name: "Food Truck Vendor" },
    ]
    const statusOptions = [
        { id: "pending", name: "Pending" },
        { id: "rejected", name: "Rejected" },
        { id: "accepted", name: "Accepted" },
    ]

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let filter = { ...filters, ...p, id: user?._id || user?.id, type: user?.role }
        loader(true)
        ApiClient.get(`host/collaboration/listing`, filter).then((res) => {
            if (res.success) {
                setData(res.data.map((itm) => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            loader(false)
        })
    }

    const sortClass = (key) => {
        let cls = "fa-sort";
        if (filters.key == key && filters.sorder == "asc") cls = "fa-sort-up";
        else if (filters.key == key && filters.sorder == "desc")
            cls = "fa-sort-down";
        return "fa " + cls;
    }
    const sorting = (key) => {
        let sorder = "asc";
        if (filters.key == key) {
            if (filters.sorder == "asc") {
                sorder = "desc";
            } else {
                sorder = "asc";
            }
        }
        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder });
        getData({ sortBy, key, sorder });
    }

    const handleFilter = (e, key) => {
        setFilter({ ...filters, [key]: e });
        getData({ [key]: e });
    }
    const filter = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const clear = () => {
        let f = { search: "", request_status: "", role: "" }
        setFilter({ ...filters, ...f });
        getData({ ...f });
    }
    const pageChange = (e) => {
        setFilter({ ...filters, page: e });
        getData({ page: e });
    }
    const count = (e) => {
        setFilter({ ...filters, count: e });
        getData({ ...filters, count: e });
    }

    const findKey = (item) => {
        let key = "addedBy"
        if (user?.role === item?.addedBy?.role) {
            key = "addedFor"
        }
        return key
    }

    const columns = [
        {
            key: "addedBy?.fullName",
            name: `${user?.role === "venue" ? "Name" : "Venue Name"}`,
            render: (row) => {
                return <span className="capitalize">{row?.[findKey(row)]?.venue_name || row?.[findKey(row)]?.fullName || `${row?.[findKey(row)]?.firstName || "--"} ${row?.[findKey(row)]?.lastName || ""}`}</span>
            },
        },
        {
            key: "addedBy?.email",
            name: "Email",
            render: (row) => {
                return <span>{row?.[findKey(row)]?.email}</span>
            },
        },
        {
            key: "addedBy?.mobileNo",
            name: "Mobile Number",
            render: (row) => {
                return <span>{row?.[findKey(row)]?.mobileNo ? `+${row?.[findKey(row)]?.mobileNo}` : "--"}</span>
            },
        },
        ...(user.role === "venue"
            ? [
                {
                    key: "addedBy?.role",
                    name: "Role",
                    render: (row) => {
                        return <span className="capitalize">{row?.[findKey(row)]?.role === "crew" ? "Entertainment Crew" : row?.[findKey(row)]?.role === "food_truck_vendor" ? "Food Truck Vendor" : row?.[findKey(row)]?.role}</span>
                    },
                },
            ]
            : []),
        {
            key: "request_status",
            name: "Status",
            render: (row) => {
                return <div className="w-32">
                    <span className={`text-sm h-[30px] w-[100px] flex items-center !rounded capitalize ${row?.request_status == "rejected" ? "text-[red]" : row?.request_status == "accepted" ? "text-[green]" : "text-[#ff9d00]"}`}>
                        {row?.request_status}
                    </span>
                </div>
            },
        },
        {
            key: "action",
            name: "Action",
            render: (itm) => {
                return (
                    <>
                        <div className="flex items-center justify-start gap-1.5">
                            <Tooltip placement="top" title="View">
                                <p onClick={() => { navigate(`/collaboration/detail/${itm?._id || itm?.id}`) }} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                                    <PiEyeLight />
                                </p>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ]

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="container mx-auto px-5 mt-6">
                    <div className="border-[1px] rounded-[15px] p-5 gap-6">
                        <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
                            <div>
                                <h3 className="text-2xl font-semibold text-[#111827]">Collaboration Requests</h3>
                            </div>
                        </div>
                        <div className="flex py-4 items-center flex-wrap gap-2">
                            <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        id="simple-search"
                                        value={filters?.search}
                                        onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                                        autoComplete="one-time-code"
                                        className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                                        placeholder="Search"
                                    />
                                    {filters?.search && (
                                        <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                    )}
                                </div>
                                <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                    <span className="sr-only">Search</span>
                                </button>
                            </form>
                            <div className="flex gap-2 ml-auto flex-wrap justify-end">
                                {user?.role === "venue" &&
                                    <SelectDropdown
                                        id="statusDropdown"
                                        displayValue="name"
                                        placeholder="All Roles"
                                        theme="search"
                                        isClearable={false}
                                        intialValue={filters?.role}
                                        result={(e) => { handleFilter(e.value, 'role') }}
                                        options={roleOptions}
                                    />
                                }
                                <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder="All Status"
                                    theme="search"
                                    isClearable={false}
                                    intialValue={filters?.request_status}
                                    result={(e) => { handleFilter(e.value, 'request_status') }}
                                    options={statusOptions}
                                />
                                {filters?.request_status || filters?.role ? (
                                    <>
                                        <button onClick={() => clear()} className="bg-[#063688] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
                                            Reset
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <Table
                            className="mb-3"
                            firstColumnClass="width_row"
                            data={data}
                            columns={columns}
                            page={filters?.page}
                            count={filters?.count}
                            filters={filters}
                            total={total}
                            result={(e) => {
                                if (e.event == "page") pageChange(e.value);
                                if (e.event == "sort") {
                                    sorting(e.value);
                                    sortClass(e.value);
                                }
                                if (e.event == "count") count(e.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Listing