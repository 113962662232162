import { Tooltip } from "antd";
import { FiPlus } from "react-icons/fi";
import Table from "../../common/Table";
import SelectDropdown from "../../common/SelectDropdown";
import statusModel from "../../models/status.model";
import shared from "./shared";
import { PiEyeLight } from "react-icons/pi";
import { LiaEdit, LiaTrashAlt } from "react-icons/lia";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { MdClear } from "react-icons/md";
import AddEditTickets from "./AddEditTickets";
import { IoTicketOutline } from "react-icons/io5";
import PopUp from "../../common/PopUp";

const Html = ({
  sorting,
  filter,
  edit,
  view,
  statusChange,
  pageChange,
  count,
  deleteItem,
  clear,
  filters,
  setFilter,
  data,
  handleFilter,
  total = { total },
  sortClass,
  categoryOptions,
  typeOptions,
  handleTickets,
  isOpen,
  setIsOpen,
  form,
  setForm,
  getData,
  subCategoryOptions,
  user,
  modal,
  setModal,
  handleAddEvent,
}) => {

  const columns = [
    {
      key: "title",
      name: "Event Name",
      sort: true,
      render: (row) => {
        return <span className="capitalize">{row?.title}</span>
      },
    },
    {
      key: "category",
      name: "Category",
      render: (row) => {
        return <span className="capitalize">{row?.category?.name || "--"}</span>
      },
    },
    {
      key: "subCategory",
      name: "Sub Category",
      render: (row) => {
        return <>
          {row?.subCategory?.length > 0 ? (
            <>
              {row?.subCategory?.slice(0, 2).map((item, index) => (
                <span className="bg-[#063688] p-2 rounded-lg text-white mr-4 capitalize" key={index}>
                  {item?.name || "--"}
                </span>
              ))}
              {row?.subCategory?.length > 2 && <span className="bg-[#063688] p-2 rounded-lg text-white mr-4 capitalize">2+</span>}
            </>
          )
            : <span>--</span>
          }
        </>
      },
    },
    ...(user.role === "host"
      ? [
        {
          key: "venue",
          name: "Venue",
          render: (row) => {
            return <span className="capitalize">{row?.venue?.venue_name}</span>
          },
        },
      ]
      : []),
    ...(user.role === "venue"
      ? [
        {
          key: "host",
          name: "Host",
          render: (row) => {
            return <>
              {row?.host?.length > 0 && (
                <>
                  {row?.host?.slice(0, 2).map((item, index) => (
                    <span className="bg-[#f0f0f0] text-[#666] py-2 px-4 rounded-full mr-1 capitalize" key={index}>
                      {item?.fullName || item?.firstName}
                    </span>
                  ))}
                  {row?.host?.length > 2 && <span className="bg-[#f0f0f0] text-[#666] py-2 px-3 rounded-full mr-4 capitalize">2+</span>}
                </>
              )}
            </>
          },
        },
      ]
      : []),
    {
      key: "type",
      name: "Type",
      render: (row) => {
        return <span className="capitalize">{row?.type}</span>
      },
    },
    {
      key: "status",
      name: "Status",
      render: (row) => {
        return (
          <>
            <div className="w-32" onClick={() => statusChange(row)}>
              <span className={`cursor-pointer text-sm !px-3 h-[30px] w-[100px] flex items-center justify-center border border-[#EBEBEB] !rounded-full capitalize ${row?.status == "deactive" ? " bg-gray-200 text-black" : "bg-[#063688] text-white"}`}>
                {row?.status == "deactive" ? "inactive" : "active"}
              </span>
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      name: "Action",
      render: (itm) => {
        return (
          <>
            <div className="flex items-center justify-start gap-1.5">
              <Tooltip placement="top" title="View">
                <a onClick={(e) => view(itm?.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                  <PiEyeLight />
                </a>
              </Tooltip>
              {itm?.type === "paid" && user?.role === "host" && user?._id === itm?.addedById || user?.role === "venue" ?
                <>
                  <Tooltip placement="top" title="Edit">
                    <a onClick={(e) => edit(itm?.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                      <LiaEdit />
                    </a>
                  </Tooltip>
                  <Tooltip placement="top" title="Delete">
                    <span onClick={() => deleteItem(itm?.id)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                      <LiaTrashAlt />
                    </span>
                  </Tooltip>
                </>
                : null}
              {itm?.type === "paid" && user?.role === "venue" || itm?.type === "paid" && user?.role === "host" && user?._id === itm?.addedById ?
                <Tooltip placement="top" title="Tickets">
                  <span onClick={() => handleTickets(itm)} className="border cursor-pointer hover:opacity-70 rounded-lg bg-[#06368814] w-10 h-10 !text-[#063688] flex items-center justify-center text-lg">
                    <IoTicketOutline />
                  </span>
                </Tooltip>
                : null
              }
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="bg-white w-full h-screen">
      <DesktopSidebar />
      <div className="container mx-auto px-5 mt-6">
        <div className="border-[1px] rounded-[15px] p-5 gap-6">
          <div className="flex flex-wrap justify-between items-center gap-y-2 gap-x-4">
            <div>
              <h3 className="text-2xl font-semibold text-[#111827]">{shared.title}</h3>
            </div>
              <div className="flex justify-end ml-auto">
                <div onClick={e => handleAddEvent()} className="bg-[#063688] cursor-pointer leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-full gap-2">
                  <FiPlus className="text-xl text-white" /> Add {shared.addTitle}
                </div>
              </div>
          </div>
          <div className="flex py-4 items-center flex-wrap gap-2">
            <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); filter(); }}>
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-full">
                <input
                  type="text"
                  id="simple-search"
                  value={filters.search}
                  onChange={(e) => setFilter({ ...filters, search: e.target.value })}
                  autoComplete="one-time-code"
                  className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                  placeholder="Search"
                />
                {filters?.search && (
                  <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                )}
              </div>
              <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </form>
            <div className="flex gap-2 ml-auto flex-wrap justify-end">
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="All Category"
                className="capitalize"
                theme="search"
                isClearable={false}
                intialValue={filters?.category}
                result={(e) => { handleFilter(e.value, "category") }}
                options={categoryOptions}
              />
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="All Sub Category"
                className="capitalize"
                theme="search"
                isClearable={false}
                intialValue={filters?.subCategory}
                result={(e) => { handleFilter(e.value, "subCategory") }}
                options={subCategoryOptions}
              />
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="All Type"
                theme="search"
                isClearable={false}
                intialValue={filters?.type}
                result={(e) => { handleFilter(e.value, 'type') }}
                options={typeOptions}
              />
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="All Status"
                theme="search"
                isClearable={false}
                intialValue={filters?.status}
                result={(e) => { handleFilter(e.value, 'status') }}
                options={statusModel.list}
              />
              {filters?.status || filters?.type || filters?.category || filters?.subCategory ? (
                <>
                  <button onClick={() => clear()} className="bg-[#063688] leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg">
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Table
            className="mb-3"
            firstColumnClass="width_row"
            data={data}
            columns={columns}
            page={filters.page}
            count={filters.count}
            filters={filters}
            total={total}
            result={(e) => {
              if (e.event == "page") pageChange(e.value);
              if (e.event == "sort") {
                sorting(e.value);
                sortClass(e.value);
              }
              if (e.event == "count") count(e.value);
            }}
          />
        </div>
      </div>
      <AddEditTickets isOpen={isOpen} setIsOpen={setIsOpen} form={form} setForm={setForm} getData={getData} />
      <PopUp popUp={modal} setPopUp={setModal} />
    </div>
  );
};

export default Html;
