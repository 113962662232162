import { useNavigate } from "react-router-dom"
import methodModel from "../../methods/methods"
import Location from '../../assets/images/Icons/location.svg';

const VendorListing = ({ users }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className="upcoming pb-[40px]">
                <div className="">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                            All Vendors
                        </h3>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-5 mt-5 md:mt-12 max-[480px]:grid-cols-1 grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                    {users.map((item, index) => (
                        <div key={index}>
                            <div className="claimbtn overflow-hidden bg-white rounded-lg shadow-md h-full">
                                <div className="relative max-[480px]:h-[190px] h-[150px] lg:h-[190px]">
                                    <img onClick={e => { navigate(`/vendorProfile/${item?._id || item?.id}`) }} className="cursor-pointer object-cover w-full h-full" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                </div>
                                <div onClick={e => { navigate(`/vendorProfile/${item?._id || item?.id}`) }} className="cursor-pointer p-4 hh">
                                    <h2 className="ellipises_heading font-[500] text-[16px] capitalize">
                                        {item?.fullName || item?.firstName}
                                    </h2>
                                    <p className="flex gap-2 font-[400] text-[13px] mt-3">
                                        <img src={Location} alt="location_icon" className="responive mt-[-2px]" />
                                        <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location || "--"}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default VendorListing