import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { FiMinus } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import { LuPlus } from 'react-icons/lu'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader'

const PurchasedModal = ({ popUp, setPopUp, user }) => {
    const stripeFeePercentage = (2.9 / 100) // 2.9%
    const stripeFeeFixed = (0.30) // $0.30

    const handlePurchaseBoosting = (e) => {
        e.preventDefault()
        const payload = {
            email: user?.email,
            paidBy: user?._id || user?.id,
            amount: popUp?.price * popUp?.quantity,
            productName: "Extra Booster",
            quantity: popUp?.quantity,
            boostType: popUp?.type
        }
        loader(true)
        ApiClient.post(`boost/buy`, payload).then(res => {
            if (res.success) {
                loader(true)
                window.open(res?.data, "_self")
            }
        })
    }

    const handleQuantity = (type) => {
        if (type === 'add') {
            setPopUp((prev) => ({ ...prev, quantity: (prev?.quantity + 1) }))
        } else {
            setPopUp((prev) => ({ ...prev, quantity: (prev?.quantity - 1) }))
        }
    }

    const calculatePrice = (type) => {
        let total = popUp?.price
        if (type === 'total') {
            total = total * popUp?.quantity
            const stripeCharges = (total * stripeFeePercentage) + stripeFeeFixed
            return total + stripeCharges
        }
        return total
    }

    return (
        <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                    <DialogPanel transition className="bg-cover bg-[url('assets/images/bg-ticket.png')] relative w-full max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                        <form onSubmit={handlePurchaseBoosting}>
                            <div className='bg-[#ffffffa8] rounded-xl p-6'>
                                <div className="flex justify-center border-[#e9e9e9] pb-3 mb-3">
                                    <DialogTitle as="h3" className="text-[22px] text-[#005AAB] capitalize font-[600]">Purchase {popUp?.type} Boosting</DialogTitle>
                                    <IoMdClose onClick={() => setPopUp((prev) => ({ ...prev, modal: false }))} className="text-[24px] absolute top-5 right-5 text-[#7a7a7a] cursor-pointer hover:text-black" />
                                </div>
                                <div className="flex items-center justify-center gap-4">
                                    <button type='button' onClick={() => handleQuantity("subtract")} disabled={popUp?.quantity === 1} className="bg-white w-[40px] h-[40px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border"><FiMinus /></button>
                                    <p>{popUp?.quantity}</p>
                                    <button type='button' onClick={() => handleQuantity("add")} className="bg-white w-[40px] h-[40px] flex justify-center items-center p-1 disabled:cursor-not-allowed text-[#005AAB] text-center rounded-md border"><LuPlus /></button>
                                </div>
                                <div className='max-w-[200px] mx-auto mt-5'>
                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Price :<span className='text-[#000]'>${popUp?.price}</span></p>
                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Quantity :<span className='text-[#000]'>{popUp?.quantity}</span></p>
                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] mb-3'>Stripe Fee :<span className='text-[#000]'>(2.9% + $0.30)</span></p>
                                    <p className='text-[14px] flex justify-between gap-5 mx-auto text-[#0000009c] pt-3 border-t-[1px]'>Total :<span className='text-[#000]'>${calculatePrice("total")}</span></p>
                                </div>
                                <div className="flex justify-center mt-5">
                                    <button type='submit' disabled={popUp?.quantity < 1} className="bg-[#005AAB] w-[150px] p-2 disabled:cursor-not-allowed text-white rounded-full shadow-md block">Buy</button>
                                </div>
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </Dialog >
    )
}

export default PurchasedModal