import { NavigationBar } from "../Landing/NavigationBar"
import Footer from "../Footer";

const TermsOfService = () => {
    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="max-w-[1100px] mx-auto px-5">
                <div className="bg-white p-5 mb-5">
                    <div className="relative py-6">
                <h2 className="text-[28px] text-center font-[600] text-[#005AAB] mb-1">Terms of Service </h2>
                <p className="text-[#4a4949] text-right text-[12px] absolute top-[0px] right-[0px]">Effective Date: <span className="text-[#000]"> 24-12-2024</span></p>
               <p className="text-[#3a3a3a] text-center text-[14px] mb-1"> Welcome to Marc! We are excited to have you use our platform. These Terms of Service (“Terms”) govern your use of Marc’s website located 
                at [MarcLocal.com] (the “Platform”) and any related services provided by Marc. By accessing or using Marc, you agree to these Terms. </p>
                <p className="text-[#4a4949] text-center text-[14px]">If you do not agree to these Terms, please do not use the Platform. </p>
                
                <div className="mt-8">
                    <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">1.</span> Acceptance of Terms </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">By using Marc, you agree to comply with and be bound by these Terms. These Terms apply to all users of the Platform, including: </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Hosts and Venues</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Influencers</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Entertainment Crews</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Vendors</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Food Truck Vendors</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Guests & Members</li>
                </ul>

                <p className="text-[#4a4949] text-[14px]">Marc reserves the right to update or modify these Terms at any time. Changes will be effective when posted, and your
                     continued use of the Platform constitutes acceptance of the updated Terms. </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">2.</span>  Use of the Platform </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">You agree to use the Platform only for lawful purposes and in accordance with these Terms. </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">You must be at least 18 years old to use Marc. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">You are responsible for maintaining the confidentiality of your account information.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">You agree not to:</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Misrepresent yourself or impersonate others. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Violate any laws, regulations, or third-party rights.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Attempt to interfere with the Platform’s operation or security.</li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">3.</span> User Accounts </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">To access certain features, you must create an account. You agree to:  </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Provide accurate and complete information.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Keep your login credentials secure. </li>
                </ul>

                <p className="text-[#4a4949] text-[14px]">Marc reserves the right to suspend or terminate accounts that violate these Terms or engage 
                    in unauthorized activities.  </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">4.</span> Content and Listings </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">Users may upload content (e.g., event listings, profiles, images). By posting content, you grant Marc a non-exclusive, royalty-free license to use, display,
                     and distribute the content for purposes of operating the Platform.You are responsible for ensuring that your content:  </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Is accurate and lawful.</li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Does not infringe on the intellectual property rights of others.  </li>
                </ul>

                <p className="text-[#4a4949] text-[14px]">Marc reserves the right to remove any content that violates these Terms or is deemed inappropriate.</p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">5.</span> Fees and Payments </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">Some features of the Platform may require payment. </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Fees will be clearly communicated at the time of purchase. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">All payments are final and non-refundable unless stated otherwise.Marc uses third-party
                         payment processors. You agree to comply with their terms and conditions. </li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">6.</span> Limitation of Liability </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">To the fullest extent permitted by law: </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Marc is provided on an “as-is” basis. We make no guarantees regarding the availability, accuracy, or reliability of the Platform. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Marc is not liable for any damages, losses, or claims arising from:  </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"> User content. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Events, listings, or interactions facilitated through the Platform. </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2"> Any technical issues or interruptions.</li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">7.</span>  Disclaimer of Ownership and Trademark  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">Marc is a trademark of Citybird LLC, a registered company in the state of Florida. The Platform and all related services are owned and operated by Citybird LLC.  </p>
                <ul className="ml-[1rem] mb-4">
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">Citybird LLC retains all rights, title, and interest in the Marc trademark and Platform.  </li>
                    <li className="text-[#4a4949] text-[14px] list-disc mb-2">By using the Platform, you acknowledge that Marc operates as a brand under Citybird LLC and agree to these Terms accordingly.  </li>
                </ul>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">8.</span>  Termination  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">Marc may suspend or terminate your access to the Platform if you violate these Terms. Upon termination, your account and content may be removed.   </p>
                </div>
                
                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">9.</span>  Privacy  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">Your use of Marc is subject to our Privacy Policy, which explains how we collect, use, and protect your information.  </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">10.</span>  Governing Law  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4">These Terms are governed by and construed in accordance with the laws of Florida, without regard to conflicts of law principles.   </p>
                </div>

                <div className="mb-8">
                    <h2 className="flex font-[600] text-[18px] text-[#3a3a3a] gap-2 mb-1"><span className="relative top-[-2px] font-[600px] w-[30px] h-[30px] flex justify-center items-center bg-[#005AAB] text-white">11.</span>  Contact Us  </h2>
                 <p className="text-[#4a4949] text-[14px] mb-4"> If you have any questions or concerns about these Terms, please contact us at:  </p>
                 <p className="text-[#4a4949] text-[14px] mb-4"> Marc Support  </p>
                 <p className="text-[#4a4949] text-[14px] mb-4"> Info@MarcLocal.com  </p>
                 <p className="text-[#4a4949] text-[14px] mb-4"> 239-251-2826  </p>
                 <p className="text-[#4a4949] text-[14px] mb-4"> Fort Lauderdale, FL, 33308  </p>
                </div>

                </div>
                </div>
                </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TermsOfService