import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { NavigationBar } from "../Landing/NavigationBar"
import { PiSealCheckFill } from "react-icons/pi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCheck } from "react-icons/fa6";
import { IoRocketOutline } from "react-icons/io5";
import { GiChart } from "react-icons/gi";
import { FaGlobe } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { TbSpeakerphone } from "react-icons/tb";
import { RiBarChartBoxLine } from "react-icons/ri";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { GiMicrophone } from "react-icons/gi";
import { PiHamburger } from "react-icons/pi";
import { GiBreakingChain } from "react-icons/gi";
import { RiShoppingBag2Fill } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa6";


const ExperienceMarc = () => {
    const navigate = useNavigate()

    const redirection = () => {
        navigate(`/joinmarc`)
    }

    var banner = {
        dots: true,
        arrows: false,
        //   autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    var cards = {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: '180px', // Default value
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    centerPadding: '80px', // Correctly set the settings
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '0px', // Correctly set the settings
                }
            },
        ]
    };

    return (
        <>
            <div className="bg-[#D9D9D938] relative">
                <NavigationBar />
                <div className="xl:container mx-auto xl:px-5">
                    <div className="banner_home relative">
                        <Slider {...banner}>
                            <div className="section_1 bg-[url('assets/images/marc_img1.jpg')] bg-no-repeat bg-cover h-[500px] lg:h-[600px]">
                                <div className="h-full flex items-end bg-[#0000008c]">
                                    <div className="max-w-[760px] lg:max-w-[1100px] pb-[4rem] p-5">
                                        <h2 className="text-[30px] sm:text-[40px] lg:text-[55px] font-[600] leading-[38px] sm:leading-[48px] lg:leading-[62px] text-white">Enhance Your Venue and Event Hosting with Marc </h2>
                                        <p className="text-[18px] sm:text-[22px] lg:text-[30px] font-[500] leading-[28px] sm:leading-[32px] lg:leading-[38px] text-white mt-3">Connect with guests, showcase your space, and elevate your events with Marc's all-in-one platform built for hosts and venues. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section_1 bg-[url('assets/images/marc_img2.jpg')] bg-no-repeat bg-cover h-[500px] lg:h-[600px]">
                                <div className="h-full flex items-end bg-[#0000008c]">
                                    <div className="max-w-[760px] lg:max-w-[1100px] pb-[4rem] p-5">
                                        <h2 className="text-[30px] sm:text-[40px] lg:text-[55px] font-[600] leading-[38px] sm:leading-[48px] lg:leading-[62px] text-white">Enhance Your Venue and Event Hosting with Marc </h2>
                                        <p className="text-[18px] sm:text-[22px] lg:text-[30px] font-[500] leading-[28px] sm:leading-[32px] lg:leading-[38px] text-white mt-3">Connect with guests, showcase your space, and elevate your events with Marc's all-in-one platform built for hosts and venues. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section_1 bg-[url('assets/images/marc_img3.jpg')] bg-no-repeat bg-cover h-[500px] lg:h-[600px]">
                                <div className="h-full flex items-end bg-[#0000008c]">
                                    <div className="max-w-[760px] lg:max-w-[1100px] pb-[4rem] p-5">
                                        <h2 className="text-[30px] sm:text-[40px] lg:text-[55px] font-[600] leading-[38px] sm:leading-[48px] lg:leading-[62px] text-white">Enhance Your Venue and Event Hosting with Marc </h2>
                                        <p className="text-[18px] sm:text-[22px] lg:text-[30px] font-[500] leading-[28px] sm:leading-[32px] lg:leading-[38px] text-white mt-3">Connect with guests, showcase your space, and elevate your events with Marc's all-in-one platform built for hosts and venues. </p>
                                    </div>
                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
                <div className="container mx-auto sticky top-[1rem] z-20">
                    <div className="text-center mt-[2rem] mb-8">
                        <button type="button" onClick={() => redirection()} className="bg-[#134896] text-white px-5 py-3 w-[160px] rounded-[30px] hover:opacity-[90%]">Join Now</button>
                    </div>
                </div>
                <div className="mb-8">
                    <section className="mt-[6rem]">
                        <div className="container mx-auto px-5">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-[3rem] sm:gap-y-[5rem] md:gap-x-12">
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Unlock Your Potential as a Host </h2>
                                    <div className="min-h-[210px]">
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Maximize Your Exposure </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Showcase your talent and reach new audiences with Marc's nationwide network.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Simplified Event Management </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Manage your bookings and schedules seamlessly with our user-friendly tools.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Connect with Top Venues  </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Find and collaborate with venues that match your style and event needs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list1.jpg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Elevate Your Venue's Experience  </h2>
                                    <div>
                                        <div className="min-h-[210px]">
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Attract High-Quality Entertainment  </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Discover and book talented hosts to create unforgettable events at your venue.
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Easy-to-Use Promotion Tools </h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Promote your venue's events effortlessly and reach more guests.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Insights to Drive Success </h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Utilize data and analytics to better understand your audience, optimize your events, and maximize your venue's potential.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list2.jpg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Grow Your Audience as an Influencer​ </h2>
                                    <div className="min-h-[210px]">
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Expand Your Reach</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Promote your brand and connect with venues and event organizers to grow your audience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Monetize Your Influence​</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Partner with hosts and brands to create memorable events that showcase your talents and insights.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Simplified Collaboration Tools​ </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Seamlessly manage partnerships, schedules, and events through Marc's user-friendly platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list3.jpeg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Take the Spotlight with Your Entertainment Crew​  </h2>
                                    <div>
                                        <div className="min-h-[210px]">
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Showcase Your Talent​</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Find events and venues that align with your skills, style, and audience preferences.
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Streamline Bookings </h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Easily manage schedules and collaborate with hosts to plan seamless performances.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Boost Your Visibility​ </h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Promote your entertainment services to event organizers and reach wider audiences.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list4.jpeg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Fuel Your Food Truck’s Success with the Right Events​​ </h2>
                                    <div className="min-h-[210px]">
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Discover Lucrative Events</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Find events that match your offerings and attract customers looking for unique dining options.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Grow Your Business​</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Promote your food truck and increase bookings by connecting with hosts and organizers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]"> Easy Scheduling Tools​ </h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Manage your availability and event commitments effortlessly with Marc's tools.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list5.jpeg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] font-[500] mb-[2rem]">Expand Your Opportunities as a Vendor​ </h2>
                                    <div>
                                        <div className="min-h-[210px]">
                                            <div className="flex gap-x-2 mb-4">
                                                <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                <div className="w-full">
                                                    <h2 className="font-[500]">Showcase Your Products​</h2>
                                                    <p className="text-[12px] font-[300]">
                                                        Connect with event organizers to showcase and sell your goods to engaged audiences.
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Grow Your Client Base</h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Reach new customers and partners through events tailored to your offerings.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex gap-x-2 mb-4">
                                                    <FaCheck className="top-[3px] w-[20px] h-[20px] text-[#134896] relative" />
                                                    <div className="w-full">
                                                        <h2 className="font-[500]">Simplify Event Management</h2>
                                                        <p className="text-[12px] font-[300]">
                                                            Easily manage schedules, bookings, and logistics for events with Marc's tools.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/assets/img/img_list6.jpeg" className="max-[499px]:h-[220px] h-[280px] md:h-[200px] lg:h-[320px] 2xl:h-[400px] w-full object-cover"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-[8rem]">
                        <div className="container mx-auto px-5">
                            <div className="mb-[3rem]">
                                <h2 className="max-[499px]:text-[30px] text-[35px] lg:text-[55px] font-[600] text-center max-[499px]:leading-[42px] leading-[55px] lg:leading-[64px] mb-2">Bring Your Events to the Right Crowd </h2>
                                <p className="text-[18px] text-center">Marc tailors event experiences to your guests' interests and location, creating connections that count </p>
                            </div>
                            <div className="grid grid-cols-12 gap-5">
                                <div className="col-span-12 lg:col-span-5">
                                    <div className="flex gap-x-2 mb-8">
                                        <img src="/assets/img/icon_1.png" className="w-[35px] h-[35px] object-cover rounded-full" />
                                        <div className="w-full">
                                            <h2 className="text-[20px] font-[500]">Personalized Recommendations  </h2>
                                            <p className="text-[16px] font-[300]">
                                                Explorers receive event suggestions tailored to their location and interests. When they become members, they gain curated experiences
                                                and soon, exclusive insights from local influencers (“tour guides”) for even more personalized event discovery.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-x-2 mb-8">
                                        <img src="/assets/img/icon_2.png" className="w-[35px] h-[35px] object-cover rounded-full" />
                                        <div className="w-full">
                                            <h2 className="text-[20px] font-[500]">Boost Your Event Visibility  </h2>
                                            <p className="text-[16px] font-[300]">
                                                Hosts and venues can create event posts that are shared directly to their social channels and featured on Marc's homepage. With added
                                                options for boosted visibility, your events reach a wider audience, increasing engagement and attendance.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-x-2 mb-8">
                                        <img src="/assets/img/icon_3.png" className="w-[35px] h-[35px] object-cover rounded-full" />
                                        <div className="w-full">
                                            <h2 className="text-[20px] font-[500]">Innovative Marketing Solutions  </h2>
                                            <p className="text-[16px] font-[300]">
                                                Utilize Marc’s suite of marketing tools, from in-depth data analytics that give insights into guest preferences to automated
                                                emails and social promotions that keep your community engaged and coming back for more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 lg:col-span-7">
                                    <div className="max-[499px]:pr-[2rem] pr-[5rem]">
                                        <img src="/assets/img/frame_1.jpeg" className="w-full max-[499px]:h-[200px]  h-[300px] object-cover" />
                                    </div>
                                    <div className="max-[499px]:pl-[2rem] pl-[5rem] relative max-[499px]:top-[-2rem] top-[-5rem]">
                                        <img src="/assets/img/frame_2.jpeg" className="w-full max-[499px]:h-[200px] h-[300px] object-cover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-[8rem]">
                        <div className="container mx-auto px-5">
                            <div className="mb-[5rem]">
                                <h2 className="max-[499px]:text-[30px] text-[35px] lg:text-[55px] font-[600] text-center max-[499px]:leading-[42px] leading-[55px] lg:leading-[64px] mb-2">How Marc works for you </h2>
                            </div>
                            <div className="grid max-[499px]:grid-cols-1 grid-cols-2 md:grid-cols-3 gap-x-5 lg:gap-x-12 gap-y-5">
                                <div className="">
                                    <img src="/assets/img/marc_1.jpeg" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                    <div className="mt-5">
                                        <h2 className="p_text text-[18px] font-[800] text-center">Create a Profile   </h2>
                                        <p className="text-[14px] font-[300] text-[#5b5b5b] text-center mt-2 max-w-[270px] mx-auto">Set up your profile in minutes and showcase what makes your space or events unique.  </p>
                                    </div>
                                </div>
                                <div className="">
                                    <img src="/assets/img/marc_2.png" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                    <div className="mt-5">
                                        <h2 className="p_text text-[18px] font-[800] text-center">Promote Your Events  </h2>
                                        <p className="text-[14px] font-[300] text-[#5b5b5b] text-center mt-2 max-w-[270px] mx-auto">Use tailored marketing tools to advertise your events and attract the right audience effortlessly.   </p>
                                    </div>
                                </div>
                                <div className="">
                                    <img src="/assets/img/marc_3.jpeg" className="w-full max-[499px]:h-[200px] h-[180px] lg:h-[260px] 2xl:h-[300px] object-cover" />
                                    <div className="mt-5">
                                        <h2 className="p_text text-[18px] font-[800] text-center">Maximize Your Earnings    </h2>
                                        <p className="text-[14px] font-[300] text-[#5b5b5b] text-center mt-2 max-w-[270px] mx-auto">Increase your visibility, secure more bookings, and grow your revenue with enhanced promotion tools and
                                            seamless event management features.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-[6rem]">
                        <div className="container mx-auto px-5">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-10 md:gap-x-12">
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">Benefits for Hosts: </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/1.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Expand Your Reach   </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Connect with a wider audience to fill the seats and create buzz for your shows and performances.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/2.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Flexible Booking Options  </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Easily manage your schedule and accept gigs that fit your availability and event style.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/3.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Integrated Marketing Tools  </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Access powerful tools to market your events through social media, email campaigns, and Marc’s platform.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/4.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Gain Valuable Insights  </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Leverage data and analytics to understand your audience and improve your offerings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/5.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Connect with Venues Nationwide  </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Find and collaborate with venues that align with your vision and event needs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">Benefits for Venues:  </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/6.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Attract Top Talent     </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Discover and book entertainers and event hosts that elevate your venue’s offerings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/7.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Streamlined Event Management   </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Manage your event schedules, promotions, and bookings all in one convenient place.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/8.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Enhanced Visibility Feature   </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Feature your venue and events on Marc’s homepage and social channels for maximum exposure.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/9.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Data-Driven Marketing  </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Use analytics and reporting to tailor your events and promotions for optimal results.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            <img src="/assets/img/10.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Strengthen Guest Engagement    </h2>
                                                <p className="text-[12px] font-[300]">
                                                    Create unforgettable events that keep crowds coming back, building loyalty and boosting revenue.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">Benefits for Influencers:​  </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/6.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <IoRocketOutline className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Grow Your Audience</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Connect with events, venues, and brands to showcase your influence and expand your following.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/7.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <IoCalendarNumberOutline className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Seamless Collaboration Tools</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Manage your schedules, promotions, and brand partnerships with ease on Marc's platform.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/8.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <TbSpeakerphone className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Boost Your Visibility</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Promote your content to targeted audiences and amplify your reach across social channels.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">Benefits for Entertainment Crews:​​  </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/6.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <GiMicrophone className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Find the Perfect Stage​</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Discover events and venues that align with your style and performance goals.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/7.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <IoCalendarNumberOutline className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Streamline Your Bookings</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Easily manage event schedules and performance logistics with Marc's tools.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/8.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <FaRegStar className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Grow Your Presence</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Reach new audiences and build your reputation with increased event exposure.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">​Benefits for Food Truck Vendors:​​  </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/6.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <PiHamburger className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Find the Right Events​</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Discover events that attract food lovers and align with your offerings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/7.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <RiBarChartBoxLine className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Streamline Operations</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Manage schedules, locations, and event commitments in one place.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/8.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <GiBreakingChain className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Increase Your Bookings</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Connect with hosts and organizers to maximize visibility and grow your business.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="text-[25px] leading-[32px] text-center font-[600] mb-[2rem]">Benefits for Vendors: </h2>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/6.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <RiShoppingBag2Fill className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Showcase Your Offerings​</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Connect with venues to provide solutions that enhance events and engage attendees.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/7.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <GiChart className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Simplify Event Logistics</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Easily manage bookings, schedules, and event commitments through Marc’s platform.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex gap-x-2 mb-4">
                                            {/* <img src="/assets/img/8.png" className="w-[50px] h-[50px] object-contain relative top-[-5px]" /> */}
                                            <FaRegHandshake className="text-[35px] relative top-[4px] text-[#0061a5]" />
                                            <div className="w-full">
                                                <h2 className="font-[500]">Easily manage bookings, schedules, and event commitments through Marc’s platform.</h2>
                                                <p className="text-[12px] font-[300]">
                                                    Expand your opportunities by partnering with the right venues to elevate events.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-[5rem]">
                                <h3 className="text-[22px] sm:text-[30px] leading-[30px] sm:leading-[38px] font-[500] text-center">Connect with Explorers Once Your Profile is Approved </h3>
                                <button type="button" onClick={() => redirection()} className="bg-[#134896] text-white px-5 py-3 rounded-[30px] hover:opacity-[90%] mt-[2rem]">Create Your Profile and Get Started</button>
                            </div>
                        </div>
                    </section>
                    <section className="mt-[8rem]">
                        <div className="container mx-auto px-5">
                            <h2 className="text-[#134896] text-[32px] sm:text-[42px] lg:text-[55px] leading-[42px] sm:leading-[62px] lg:leading-[58px] font-[600] mb-8">Satisfied Clients  </h2>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-8">
                                <div>
                                    <div className="grid grid-cols-2 gap-5 sm:gap-x-[2rem] lg:gap-x-[3rem] xl:gap-x-[5rem] gap-y-5">
                                        <div className="mt-[2rem] sm:mt-[3rem] xl:mt-[4rem]">
                                            <img src="/assets/img/event_1.jpg" className="w-full max-[499px]:h-[170px] h-[200px] sm:h-[250px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] object-cover rounded-[20px]" />
                                        </div>
                                        <div className="">
                                            <img src="/assets/img/event_2.jpg" className="w-full max-[499px]:h-[170px] h-[200px] sm:h-[250px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] object-cover rounded-[20px]" />
                                        </div>
                                        <div className="col-span-2 max-[499px]:mx-[0rem] mx-[2rem] sm:mx-[4rem] xl:mx-[6rem]">
                                            <img src="/assets/img/event_3.jpg" className="w-full max-[499px]:h-[200px] h-[250px] sm:h-[300px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] object-cover rounded-[20px]" />
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h2 className="text-[#134896] text-[17px] xl:text-[19px] 2xl:text-[22px] font-[600]">Gina Laing </h2>
                                    <p className="text-[18px] xl:text-[21px] mb-3">Marc has been a game-changer for my events. The platform’s seamless booking and promotional tools have filled my shows with enthusiastic
                                        audiences, making every performance a success. I love how easy it is to connect with venues that match my style. </p>
                                    <h2 className="text-[#134896] text-[17px] xl:text-[19px] 2xl:text-[22px] font-[600]">Jonah Granger </h2>

                                    <p className="text-[18px] xl:text-[21px] mb-3">Using Marc has transformed how we run events at our venue. We’re now attracting top talent and filling our space with guests who
                                        keep coming back. The data insights have been invaluable for refining our promotions and boosting attendance </p>
                                    <h2 className="text-[#134896] text-[17px] xl:text-[19px] 2xl:text-[22px] font-[600]">Mimi Bristow  </h2>
                                    <p className="text-[18px] xl:text-[21px] mb-3">Marc made it simple to manage bookings and promote our events to the right people. The platform’s reach and ease of use have significantly
                                        increased our visibility and engagement. We couldn’t be happier with how it’s elevated our business. </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-[8rem]">
                        <div className="container mx-auto px-5">
                            <h2 className="max-[499px]:text-[30px] text-[35px] lg:text-[55px] font-[600] max-[499px]:leading-[42px] leading-[55px] lg:leading-[64px] mb-5 sm:mb-8">Join the Community</h2>
                            <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-3 gap-5">
                                <img src="/assets/img/pics_1.jpeg" className="w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover" />
                                <img src="/assets/img/pics_2.jpg" className="w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover" />
                                <img src="/assets/img/pics_3.jpg" className="w-full h-[180px] md:h-[220px] lg:h-[250px] 2xl:h-[280px] object-cover" />
                            </div>
                        </div>
                    </section>
                </div>

                {/* <div className="banner_home relative">
                    <Slider {...banner}>
                        <div className="section_1 bg-[url('assets/images/bg_exp.png')] bg-no-repeat bg-cover h-[500px] lg:h-[800px]">
                            <div className="container mx-auto flex justify-end items-center h-full mx-5">
                                <div className="w-[390px] sm:w-[500px] h-[360px] 2xl:w-[650px] 2xl:h-[470px]">
                                    <div className="bg-[url('assets/images/bg_card.png')] w-full h-full bg-no-repeat bg-cover sm:bg-contain flex items-center">
                                        <div className="p-8">
                                            <h2 className="text-[38px] sm:text-[52px] 2xl:text-[60px] font-[600] text-white leading-[48px] sm:leading-[58px] 2xl:leading-[70px] mb-3">Unique venue solutions for you.</h2>
                                            <p className="text-[16px] sm:text-[22px] 2xl:text-[32px] text-[#FFD343] leading-[22px] sm:leading-[28px] 2xl:leading-[38px]">Whether you’re buying, listing, or renting,
                                                we can be of assistance to you</p>
                                            <button type="button" onClick={() => redirection()} className="bg-[#E6C045] text-[14px] text-[#0D0D0D] px-6 sm:px-8 py-2 sm:py-3 rounded-xl sm:rounded-2xl mt-6">Get Started</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section_1 bg-[url('assets/images/bg_exp.png')] bg-no-repeat bg-cover h-[500px] lg:h-[800px]">
                            <div className="container mx-auto flex justify-end items-center h-full mx-5">
                                <div className="w-[390px] sm:w-[500px] h-[360px] 2xl:w-[650px] 2xl:h-[470px]">
                                    <div className="bg-[url('assets/images/bg_card.png')] w-full h-full bg-no-repeat bg-cover sm:bg-contain flex items-center">
                                        <div className="p-8">
                                            <h2 className="text-[38px] sm:text-[52px] 2xl:text-[60px] font-[600] text-white leading-[48px] sm:leading-[58px] 2xl:leading-[70px] mb-3">Unique venue solutions for you.</h2>
                                            <p className="text-[16px] sm:text-[22px] 2xl:text-[32px] text-[#FFD343] leading-[22px] sm:leading-[28px] 2xl:leading-[38px]">Whether you’re buying, listing, or renting,
                                                we can be of assistance to you</p>
                                            <button type="button" onClick={() => redirection()} className="bg-[#E6C045] text-[14px] text-[#0D0D0D] px-6 sm:px-8 py-2 sm:py-3 rounded-xl sm:rounded-2xl mt-6">Get Started</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
                <div className="">
                    <img src="/assets/img/bg_lines.png" className="w-full"></img>
                </div>
                <div className="section_2 mt-[5rem] lg:mt-[10rem]">
                    <div className="container mx-auto px-5">
                        <div className="grid grid-cols-12 items-center gap-y-[3rem] lg:gap-x-[5rem]">
                            <div className="col-span-12 lg:col-span-5 relative">
                                <img src="/assets/img/img_exp1.png" className="max-[499px]:h-[300px] h-[430px] xl:h-[440px] 2xl:h-[550px] w-full object-cover rounded-[30px] relative z-10"></img>
                                <div className="w-[100px] h-[100px] bg-[#E6C045] absolute bottom-[-15px] left-[-15px] rounded-[30px]"></div>
                            </div>
                            <div className="col-span-12 lg:col-span-7">
                                <h2 className="text-[28px] sm:text-[40px] 2xl:text-[48px] text-[#1A1A1A] font-[500] leading-[35px] sm:leading-[50px] 2xl:leading-[60px] mb-4 sm:mb-8">Flexibility Puts you in <span className="text-[#134896]">control</span></h2>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">Set your own schedule and prices</p>
                                </div>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">Offer any combination of venue services</p>
                                </div>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">Set size, capacity and other venue preferences
                                        that work for you</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_3 mt-[3rem] sm:mt-[5rem] lg:mt-[10rem]">
                    <div className="container mx-auto px-5">
                        <div className="grid grid-cols-12 items-center gap-y-[3rem] lg:gap-x-[5rem]">
                            <div className="col-span-12 lg:col-span-7">
                                <h2 className="text-[28px] sm:text-[40px] 2xl:text-[48px] text-[#1A1A1A] font-[500] leading-[35px] sm:leading-[50px] 2xl:leading-[60px] mb-4 sm:mb-8">The tools to <span className="text-[#134896]">succeed</span></h2>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">MARC’s Guarantee which include extra benefits</p>
                                </div>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">Manage your venue schedule and more</p>
                                </div>
                                <div className="flex gap-x-2 mb-4">
                                    <PiSealCheckFill className="relative sm:top-[6px] w-[25px] h-[25px] text-[#134896]" />
                                    <p className="text-[20px] sm:text-[26px] 2xl:text-[32px] text-[#1A1A1] font-[300] leading-[25px] sm:leading-[36px] 2xl:leading-[40px] w-full">24x7 support, including venue assistance</p>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-5 relative">
                                <img src="/assets/img/img_exp2.png" className="max-[499px]:h-[300px] h-[430px] xl:h-[440px] 2xl:h-[550px] w-full object-cover rounded-[30px] relative z-10"></img>
                                <div className="w-[100px] h-[100px] bg-[#E6C045] absolute bottom-[-15px] right-[-15px] rounded-[30px]"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_4 mt-[8rem] relative">
                    <div className="">
                        <img src="/assets/img/bg_lines2.png" className="w-full top-[-16px] sm:top-[-25px] absolute"></img>
                    </div>
                    <div className="bg-[url('assets/images/bg_exp2.png')] bg-no-repeat bg-cover relative z-10">
                        <div className="bg-[#000000b3] py-[4rem]">
                            <div className="container mx-auto px-5">
                                <div className="">
                                    <div className=" bg-[url('assets/images/bg_name.png')] bg-center bg-no-repeat bg-contain">
                                        <div className="max-w-[300px] sm:max-w-[400px] mx-auto text-center">
                                            <h3 className="text-[34px] sm:text-[52px] leading-[34px] sm:leading-[52px] text-white drop-shadow-[0px_2px_3px_#000]">GROW YOUR BUSINESS WITH</h3>
                                            <h2 className="text-[88px] sm:text-[135px] leading-[88px] sm:leading-[115px] font-[600] text-white drop-shadow-[0px_2px_3px_#000]">MARC</h2>
                                            <p className="text-[20px] sm:text-[30px] leading-[20px] sm:leading-[30px] text-white drop-shadow-[0px_2px_3px_#000]">Find Customers Near You</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={() => redirection()} className="bg-[#134896] text-[14px] text-white px-10 py-3 rounded-2xl mt-6">Create Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <img src="/assets/img/bg_lines3.png" className="w-full md:bottom-[-25px] absolute"></img>
                    </div>
                </div>
                <div className="section_5 relative">
                    <div className=" bg-[url('assets/images/bg_blue.png')] bg-no-repeat bg-cover pt-[5rem] pb-[5rem] relative z-10">
                        <div className="container mx-auto px-5">
                            <div className="text-center text-[34px] sm:text-[45px] text-[white] leading-[38px] sm:leading-[50px] mb-3">Reach the <span className="text-[#E6C045]">right people</span></div>
                            <p className="text-center text-[18px] text-white">Grow your community ona marketplace where millions of people look things to do.</p>
                            <div className="h-[3px] w-[90px] bg-[#E6C045] mx-auto mt-5 mb-5"></div>

                            <div className="grid md:grid-cols-12 items-center gap-[3rem] mt-[3rem] sm:mt-[6rem]">
                                <div className="col-span-6">
                                    <div className="flex items-center gap-3 mb-5">
                                        <div className="w-[50px] h-[50px] flex justify-center items-baseline bg-[#E6C045] text-[44px] text-white font-[600]"><span className="relative top-2">1.</span></div>
                                        <h3 className="w-full text-[24px] sm:text-[30px] leading-[28px] sm:leading-[35px] text-white font-[600]">Attendee Discovery</h3>
                                    </div>
                                    <p className="text-[18px] sm:text-[22px] text-white font-[300]">Personalized recommendations are tailored to
                                        attendees’  interests and location, matching them
                                        with events they’d be most interested in attending.</p>

                                </div>
                                <div className="col-span-6">
                                    <img src="/assets/img/image1.png" className="h-[250px] xl:h-[300px] 2xl:h-[350px] w-full object-cover rounded-[30px]"></img>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-12 items-center gap-[3rem] mt-[3rem] sm:mt-[6rem]">
                                <div className="col-span-6">
                                    <img src="/assets/img/image2.png" className="h-[250px] xl:h-[300px] 2xl:h-[350px] w-full object-cover rounded-[30px]"></img>
                                </div>
                                <div className="col-span-6">
                                    <div className="flex items-center gap-3 mb-5">
                                        <div className="w-[50px] h-[50px] flex justify-center items-baseline bg-[#E6C045] text-[44px] text-white font-[600]"><span className="relative top-2">2.</span></div>
                                        <h3 className="w-full text-[24px] sm:text-[30px] leading-[28px] sm:leading-[35px] text-white font-[600]">Promotional Ads</h3>
                                    </div>
                                    <p className="text-[18px] sm:text-[22px] text-white font-[300]">Promote your event across via promotions and
                                        get 14x more visibility on our homepage, related
                                        events, search results and more.</p>

                                </div>
                            </div>
                            <div className="grid md:grid-cols-12 items-center gap-[3rem] mt-[3rem] sm:mt-[6rem]">
                                <div className="col-span-6">
                                    <div className="flex items-center gap-3 mb-5">
                                        <div className="w-[50px] h-[50px] flex justify-center items-baseline bg-[#E6C045] text-[44px] text-white font-[600]"><span className="relative top-2">3.</span></div>
                                        <h3 className="w-full text-[24px] sm:text-[30px] leading-[28px] sm:leading-[35px] text-white font-[600]">Marketing Tools</h3>
                                    </div>
                                    <p className="text-[18px] sm:text-[22px] text-white font-[300]">Personalized recommendations are tailored to
                                        attendees’  interests and location, matching them
                                        with events they’d be most interested in attending.</p>

                                </div>
                                <div className="col-span-6">
                                    <img src="/assets/img/image3.png" className="h-[250px] xl:h-[300px] 2xl:h-[350px] w-full object-cover rounded-[30px]"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <img src="/assets/img/bg_lins4.png" className="w-full bottom-[-16px] md:bottom-[-35px] absolute"></img>
                    </div>
                </div>
                <div className="section_6 mt-[6rem]">
                    <div className="container mx-auto px-5">
                        <h2 className="text-center text-[28px] sm:text-[42px] leading-[35px] sm:leading-[48px] font-[400] text-[#444444]">How <b>MARC </b>works for you</h2>
                        <div className="h-[3px] w-[90px] bg-[#134896] mx-auto mt-5 mb-5"></div>
                        <div className="grid max-[499px]:grid-cols-1 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-[2rem] lg:gap-[5rem] xl:gap-[8rem] mt-[3rem] sm:mt-[5rem]">
                            <div className="">
                                <img src="/assets/img/marc_img1.png" className="w-full h-[230px] xl:h-[300px] 2xl:h-[350px] object-cover rounded-[30px]"></img>
                                <div className="pt-6">
                                    <h2 className="text-[26px] leading-[30px] font-[500] text-center mb-2">Create a Profile</h2>
                                    <p className="font-[300] text-[16px] lg:text-[20px] text-center max-w-[242px] mx-auto leading-[22px] lg:leading-[28px]">Over 1500 venues
                                        available at the moment</p>
                                </div>
                            </div>
                            <div className="">
                                <img src="/assets/img/marc_img2.png" className="w-full h-[230px] xl:h-[300px] 2xl:h-[350px] object-cover rounded-[30px]"></img>
                                <div className="pt-6">
                                    <h2 className="text-[26px] leading-[30px] font-[500] text-center mb-2">Create a Profile</h2>
                                    <p className="font-[300] text-[16px] lg:text-[20px] text-center max-w-[242px] mx-auto leading-[22px] lg:leading-[28px]">Over 1500 venues
                                        available at the moment</p>
                                </div>
                            </div>
                            <div className="">
                                <img src="/assets/img/marc_img3.png" className="w-full h-[230px] xl:h-[300px] 2xl:h-[350px] object-cover rounded-[30px]"></img>
                                <div className="pt-6">
                                    <h2 className="text-[26px] leading-[30px] font-[500] text-center mb-2">Create a Profile</h2>
                                    <p className="font-[300] text-[16px] lg:text-[20px] text-center max-w-[242px] mx-auto leading-[22px] lg:leading-[28px]">Over 1500 venues
                                        available at the moment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_7 mt-[6rem]">
                    <div className="container mx-auto px-5">
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="bg-[#134896] px-5 py-[3rem]">
                                <div className="lg:pr-[4rem]">
                                    <div className="flex gap-5">
                                        <img src="/assets/img/bg_host.png" className="h-[380px] w-[85px] object-contain mt-[4rem] max-[1023px]:absolute"></img>
                                        <div>
                                            <h2 className="text-[30px] lg:text-[34px] leading-[32px] lg:leading-[35px] font-[300] text-white">Services for <span className="font-[600]  text-[#E6C045]"> hosts.</span></h2>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#E6C045]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Branding</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#E6C045]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#E6C045]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Two</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#E6C045]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Three</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#E6C045]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Five </div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#E6C045] px-5 py-[3rem]">
                                <div className="lg:pl-[4rem]">
                                    <div className="flex gap-5">
                                        <div>
                                            <h2 className="text-[30px] lg:text-[34px] leading-[32px] lg:leading-[35px] font-[300] text-white">Services for <span className="font-[600]  text-[#134896]"> venues.</span></h2>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#134896]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Branding</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#134896]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#134896]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Two</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#134896]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Three</div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-[2rem]">
                                                <PiSealCheckFill className="relative text-[32px] top-[-2px] lg:top-[2px] w-[40px] text-[#134896]" />
                                                <div className="full">
                                                    <div className="text-[24px] lg:text-[28px] font-[600] text-white leading-[28px] lg:leading-[34px]">Title Service Five </div>
                                                    <p className="text-[14px] text-white text-[300]">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="/assets/img/bg_venue.png" className="h-[380px] w-[85px] object-contain mt-[4rem] max-[1023px]:absolute"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_8 mt-[6rem]">
                    <div className="container mx-auto px-5">
                        <div className="cards_bg">
                            <div className="text-center text-[30px] sm:text-[38px] leading-[38px] sm:leading-[42px] font-[300] text-[#444444] mb-10">Our Satisfied <span className="text-[#134896]"> Clients </span></div>
                            <Slider {...cards}>
                                <div>
                                    <div className="grid grid-cols-12 gap-3 sm:gap-8 bg-[#FAFAFA] p-5 rounded-lg">
                                        <div className="col-span-5">
                                            <img src="/assets/img/slide_img1.png" className="h-[175px] sm:h-[245px] xl:h-[350px] w-full rounded-lg object-cover"></img>
                                        </div>
                                        <div className="col-span-7">
                                            <img src="/assets/img/Quotes.png" className="w-[35px] h-[35px] ml-auto object-contain"></img>
                                            <p className="text_3line sm:line_6 text-[#202020] text-[16px] xl:text-[22px] mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                            <h2 className="text-[16px] xl:text-[18px] text-[#202020] font-[500]">Kyle Merwin</h2>
                                            <p className="text-[#808080] text-[12px] xl:text-[14px]">CO-owner</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="grid grid-cols-12 gap-3 sm:gap-8 bg-[#FAFAFA] p-5 rounded-lg">
                                        <div className="col-span-5">
                                            <img src="/assets/img/slide_img1.png" className="h-[175px] sm:h-[245px] xl:h-[350px] w-full rounded-lg object-cover"></img>
                                        </div>
                                        <div className="col-span-7">
                                            <img src="/assets/img/Quotes.png" className="w-[35px] h-[35px] ml-auto object-contain"></img>
                                            <p className="text_3line sm:line_6 text-[#202020] text-[16px] xl:text-[22px] mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                            <h2 className="text-[16px] xl:text-[18px] text-[#202020] font-[500]">Kyle Merwin</h2>
                                            <p className="text-[#808080] text-[12px] xl:text-[14px]">CO-owner</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="grid grid-cols-12 gap-3 sm:gap-8 bg-[#FAFAFA] p-5 rounded-lg">
                                        <div className="col-span-5">
                                            <img src="/assets/img/slide_img1.png" className="h-[175px] sm:h-[245px] xl:h-[350px] w-full rounded-lg object-cover"></img>
                                        </div>
                                        <div className="col-span-7">
                                            <img src="/assets/img/Quotes.png" className="w-[35px] h-[35px] ml-auto object-contain"></img>
                                            <p className="text_3line sm:line_6 text-[#202020] text-[16px] xl:text-[22px] mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                            <h2 className="text-[16px] xl:text-[18px] text-[#202020] font-[500]">Kyle Merwin</h2>
                                            <p className="text-[#808080] text-[12px] xl:text-[14px]">CO-owner</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="grid grid-cols-12 gap-3 sm:gap-8 bg-[#FAFAFA] p-5 rounded-lg">
                                        <div className="col-span-5">
                                            <img src="/assets/img/slide_img1.png" className="h-[175px] sm:h-[245px] xl:h-[350px] w-full rounded-lg object-cover"></img>
                                        </div>
                                        <div className="col-span-7">
                                            <img src="/assets/img/Quotes.png" className="w-[35px] h-[35px] ml-auto object-contain"></img>
                                            <p className="text_3line sm:line_6 text-[#202020] text-[16px] xl:text-[22px] mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                                            <h2 className="text-[16px] xl:text-[18px] text-[#202020] font-[500]">Kyle Merwin</h2>
                                            <p className="text-[#808080] text-[12px] xl:text-[14px]">CO-owner</p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="section_9 mt-[6rem]">
                    <div className="bg-[url('assets/images/blur_img.png')] bg-no-repeat bg-cover h-[380px] flex items-center p-5">
                        <div className="container mx-auto px-5">
                            <div className="text-center text-[30px] sm:text-[38px] font-[300] text-white mb-3">Partner With Us</div>
                            <p className="text-white text-[12px] text-center max-w-[510px] mx-auto mb-8">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s,</p>
                            <div className="flex flex-wrap gap-5 justify-center">
                                <button type="button" onClick={() => redirection()} className="bg-[#134896] w-[180px] text-[14px] text-white px-8 py-3 rounded-2xl">Create Account</button>
                                <button type="button" className="bg-[#E6C045] w-[180px] text-[14px] text-white px-8 py-3 rounded-2xl">Contact Us</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Footer />
            </div>
        </>
    )
}

export default ExperienceMarc


