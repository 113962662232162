import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import environment from "../../environment";
import { IoCloseOutline, IoDocumentTextOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import SelectDropdown from "../../common/SelectDropdown";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import loader from "../../methods/loader";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../redux/actions/user";
import moment from "moment";
import PopUp from "../../common/PopUp";
import { useNavigate } from "react-router-dom";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { Tooltip } from "antd";

const Profile = () => {
    const user = useSelector((state) => state.user)
    const [form, setform] = useState({
        venue_name: user?.venue_name || "",
        name: user?.name || "",
        email: user?.email || "",
        mobileNo: user?.mobileNo || "",
        image: user?.image || "",
        backgroundImage: user?.backgroundImage || "",
        subAmenities: user?.subAmenities?.length > 0 ? user?.subAmenities?.map((item) => item?._id || item) : [],
        images: user?.images || [],
        videos: user?.videos || [],
        description: user?.description || "",
        locations: user?.locations?.length > 0 ? user?.locations : [{ location: "", country: "", state: "", city: "", zipCode: "" }],
        website_link: user?.website_link || "",
        instagram_link: user?.instagram_link || "",
        facebook_link: user?.facebook_link || "",
        tiktok_link: user?.tiktok_link || "",
        youtube_link: user?.youtube_link || "",
        applyAll: user?.applyAll || true,
        time_schedule: user?.time_schedule?.length > 0 ?
            user?.time_schedule?.map((item) => {
                return ({ ...item, start_time: item?.start_time ? new Date(item?.start_time) : "", end_time: item?.end_time ? new Date(item?.end_time) : "", best_time_to_visit: item?.best_time_to_visit ? new Date(item?.best_time_to_visit) : "" })
            })
            : [
                { day: "monday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "tuesday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "wednesday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "thursday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "friday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "saturday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "sunday", open: true, start_time: "", end_time: "", best_time_to_visit: "" },
            ],
        foods: user?.foods?.length > 0 ? user?.foods : [{ item: "", price: "", image: "" }],
        drinks: user?.drinks?.length > 0 ? user?.drinks : [{ item: "", price: "", image: "" }],
        menu_item_format: user?.menu_item_format || "",
        foodImages: user?.foodImages || "",
        company_ein: user?.company_ein || "",
        ein_image: user?.ein_image || "",
    });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [amenities, setAmenities] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [editAble, setEditAble] = useState(false)
    const [popUp, setPopUp] = useState({ modal: false, title: "", message: "", buttonText: "", image: "" })

    const foodFormatOptions = [
        { id: "manual", name: "Manual" },
        { id: "upload", name: "Upload" },
    ]

    useEffect(() => {
        getAmenities()
        venueProfileDetail()
    }, []);

    const getAmenities = () => {
        ApiClient.get(`amenity/listing?status=active&sortBy=title asc`).then(res => {
            if (res.success) {
                setAmenities(res?.data?.map((item) => {
                    return ({ ...item, _id: item?.id || item?._id })
                }))
                // setAmenities(res?.data?.map((item) => {
                //     return ({ id: item?._id, name: item?.title })
                // }))
            }
        })
    }

    const venueProfileDetail = () => {
        if (user?.doc_status === "verified" && !methodModel.checkUserDetail(user)) {
            setPopUp({ modal: true, title: "", image: "/assets/img/warning.png", message: "Please ensure that all mandatory fields are completed. Thank you.", buttonText: "Ok" })
        }
        else {
            if (user?.docVerified === "N") {
                if (user?.ein_image && user?.doc_status === "rejected") {
                    setPopUp({ modal: true, title: "", image: "/assets/img/approved.png", message: "Your profile has not been approved by the administrator. Thank you for your understanding.", buttonText: "Ok" })
                } else {
                    setPopUp({ modal: true, title: "", image: "/assets/img/waiting.png", message: "Your documents are being reviewed by our team. We’ll notify you as soon as your account is verified. Thanks for your patience!", buttonText: "Ok" })
                }
            }
        }
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!form?.ein_image) return
        let method = "put";
        let url = "users/edit/profile";
        let value = { ...form, id: user?.id || user?._id }
        loader(true)
        ApiClient.allApi(url, value, method).then((res) => {
            if (res.success) {
                dispatch(login_success({ ...user, ...value }))
                setEditAble(false)
                const profileDataBefore = methodModel.checkUserDetail(user)
                const profileDataAfter = methodModel.checkUserDetail({ ...user, ...value })
                if (!profileDataBefore && profileDataAfter) {
                    setPopUp({ modal: true, title: "", image: "/assets/img/warning.png", message: "Please consider upgrading to a paid plan to access additional features on the platform.", buttonText: "Ok", redirectUrl: "/plans" })
                } else {
                    toast.success(res?.message)
                }
            }
            loader(false)
        });
    }

    const ImagesUpload = (e, key) => {
        let files = Array.from(e.target.files);
        const acceptedTypes = ['image/jpeg', 'image/png']; // Add more image types if needed
        const sizeLimits = { min: 0, max: 3 }; // Adjust size limits as necessary (in MB)
        const maxImages = 10 - Number(form?.[key]?.length || 0)

        const { filteredFiles, invalidFiles, errorMsg } = methodModel.multipleImageValidation(files, acceptedTypes, sizeLimits, maxImages);
        if (errorMsg) {
            toast.error(errorMsg);
        }
        if (filteredFiles.length === 0) {
            return;
        }

        let images = form?.[key] || []
        loader(true)
        ApiClient.multiImageUpload('upload/multiple-images', filteredFiles, {}, "files").then(res => {
            if (res.success) {
                const data = res?.files?.map((item) => item?.fileName)
                images.push(...data)
                setform({ ...form, [key]: images })
            }
            loader(false)
        })
    }

    const handleImageDelete = (index, key) => {
        const data = [...form?.[key]]
        data.splice(index, 1)
        setform({ ...form, [key]: data })
    }

    const handleTimeSchedule = (date, index, key) => {
        let data = form?.time_schedule
        if (form?.applyAll) {
            data = data.map((item, i) => {
                if (item?.open) {
                    return { ...item, [key]: date }
                } else {
                    return { ...item, start_time: "", end_time: "", best_time_to_visit: "" }
                }
            })
        } else {
            data[index][key] = date
        }
        setform((prev) => ({ ...prev, time_schedule: data }))
    }

    const handleTimeScheduleCheckboxes = (check, index, key) => {
        let data = form?.time_schedule
        data[index][key] = check
        if (!check) {
            data[index]["start_time"] = ""
            data[index]["end_time"] = ""
            data[index]["best_time_to_visit"] = ""
        }
        setform((prev) => ({ ...prev, time_schedule: data }))
    }

    const handleApplyAll = (checked) => {
        const data = form?.time_schedule[0]
        const timeSchedule = [
            { day: "monday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "tuesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "wednesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "thursday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "friday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "saturday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "sunday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
        ]
        if (checked) {
            setform((prev) => ({ ...prev, applyAll: checked, time_schedule: timeSchedule }))
        } else {
            setform((prev) => ({ ...prev, applyAll: checked }))
        }
    }

    const handleAddMoreInput = (e, index, key, object) => {
        let data = form?.[object]
        data[index][key] = e
        setform((prev) => ({ ...prev, [object]: data }))
    }
    const handleAddMore = (key) => {
        const data = [...form?.[key], { item: "", price: "", image: "" }]
        setform((prev) => ({ ...prev, [key]: data }))
    }
    const handleRemoveAddMore = (index, key) => {
        if (!editAble) return
        const data = form?.[key]?.filter((item, i) => i !== index)
        setform((prev) => ({ ...prev, [key]: data }))
    }

    const ImageUpload = (e, index, key, object) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        let data = form?.[object]
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                data[index][key] = res?.fileName
                setform((prev) => ({ ...prev, [object]: data }))
            }
            loader(false)
        })
    }

    const handleMenuImage = (index, key, object) => {
        let data = form?.[object]
        data[index][key] = ""
        setform((prev) => ({ ...prev, [object]: data }))
    }

    const handleDrop = (e) => {
        e.preventDefault();
        uploadEIN(e);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const uploadEIN = (e) => {
        let files = e?.target?.files || e?.dataTransfer?.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only doc, pdf, jpeg and png are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    const venueProfileImageUpload = (e, key) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, [key]: res?.fileName })
            }
            loader(false)
        })
    }

    const handleLocationsAutoComplete = (place, index) => {
        const addressComponents = place?.address_components;
        const address = {};
        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const types = component.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }
        address.location = place?.formatted_address
        address.coordinates = {
            type: "Point",
            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        }
        let data = [...form?.locations]
        data[index] = address
        setform((prev) => ({ ...prev, locations: data }))
    }
    const handleAddMoreLocations = () => {
        if (!editAble) return
        const data = [...form?.locations, { location: "", country: "", state: "", city: "", zipCode: "" }]
        setform((prev) => ({ ...prev, locations: data }))
    }

    const getEndTime = (hours, min) => {
        // Get the current date
        let now = new Date();
        // Extract the current date parts (year, month, and day)
        let year = now.getFullYear();
        let month = now.getMonth(); // Note: January is 0, December is 11
        let day = now.getDate();
        // Create a new Date object for hours min on the current date
        let dateWithTime = new Date(year, month, day, hours, min, 0);
        return dateWithTime;
    }
    const addTenMinutes = (date) => {
        // Ensure the argument is a Date object
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            throw new Error("Invalid date");
        }
        // Create a new Date object to avoid mutating the original date
        let newDate = new Date(date);
        // Add 10 minutes to the new date
        newDate.setMinutes(newDate.getMinutes() + 10);
        return newDate;
    }

    const videoUpload = (e) => {
        const acceptedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg']; // Add more video types if needed
        const sizeLimits = { min: 1, max: 30 }; // Adjust size limits as necessary (in MB)
        let files = Array.from(e.target.files);
        const maxVideos = 5 - Number(form?.videos?.length || 0)
        const videoDuration = 60

        const fileArray = [];
        const metadataPromises = files.map(file => {
            return new Promise((resolve) => {
                const videoElement = document.createElement('video');
                videoElement.preload = 'metadata'; // Load only metadata to get duration
                videoElement.onloadedmetadata = () => {
                    const durationInSeconds = videoElement.duration;
                    if (durationInSeconds > videoDuration) {
                        toast.error(`Videos exceeding a duration of ${videoDuration} seconds will be disregarded during upload. Please ensure that all videos are ${videoDuration} seconds or shorter`);
                        resolve(null); // Resolve with null for invalid video
                    } else {
                        fileArray.push(file);
                        resolve(file); // Resolve with the file if valid
                    }
                };
                const url = URL.createObjectURL(file);
                videoElement.src = url;
            });
        });

        Promise.all(metadataPromises).then(() => {
            const { filteredFiles, invalidFiles, errorMsg } = methodModel.multipleVideoValidation(fileArray, acceptedVideoTypes, sizeLimits, maxVideos);
            if (errorMsg) {
                toast.error(errorMsg);
            }
            if (filteredFiles.length === 0) {
                return;
            }

            let videosArray = form?.videos || []
            loader(true)
            ApiClient.multiImageUpload('upload/multiple-videos', filteredFiles, {}, "files").then(res => {
                if (res.success) {
                    const data = res?.files?.map((item) => item?.fileName)
                    videosArray.push(...data)
                    setform({ ...form, videos: videosArray })
                }
                loader(false)
            })
        })
    }
    const handleDelete = (index, key) => {
        let data = [...form?.[key]]
        data.splice(index, 1)
        setform({ ...form, [key]: data })
    }

    const handleCheckbox = (checked, item, key) => {
        let array = form?.[key]
        if (checked) {
            array.push(item)
        } else {
            array = array?.filter((itm) => itm !== item)
        }
        setform((prev) => ({ ...prev, [key]: array }))
    }

    const addBankAccount = () => {
        const payload = {
            email: form?.email,
            businessName: form?.venue_name,
            country: "US"
        }
        loader(true)
        ApiClient.post(`account/create`, payload).then(res => {
            if (res?.data?.url) {
                window.open(res?.data?.url, "_self")
            }
            loader(false)
        })
    }
    const removeBankAccount = () => {
        const payload = {
            accountId: user?.accountDetails?.accountId
        }
        loader(true)
        ApiClient.delete(`account/delete`, payload).then(res => {
            if (res?.success) {
                dispatch(login_success({ ...user, accountDetails: {} }))
            }
            loader(false)
        })
    }
    const verifyBankAccount = () => {
        const payload = {
            accountId: user?.accountDetails?.accountId
        }
        loader(true)
        ApiClient.post(`account/link/regenerate`, payload).then(res => {
            if (res?.data?.url) {
                window.open(res?.data?.url, "_self")
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="w-full flex flex-col justify-center">
                <div className="w-full flex flex-col border-[1px] rounded-[15px] p-5 gap-6">
                    <div className="flex justify-between items-center gap-x-4 gap-y-2 flex-wrap mb-3">
                        <h2 className="text-[24px] font-[600] text-[#005AAB]">Profile Page</h2>
                        {user?.doc_status === "rejected" && <div type="button" onClick={() => { navigate(`/reapply/venue/${user?._id || user?.id}`) }} className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Re-apply</div>}
                        {user?.doc_status === "verified" &&
                            <div className="flex gap-2 ml-auto">
                                <div onClick={e => { navigate(`/venue/detail/${user?._id || user?.id}`) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Preview</div>
                                <div onClick={e => { setEditAble(!editAble) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">{editAble ? "Cancel" : "Edit"}</div>
                            </div>
                        }
                    </div>
                    <form onSubmit={handleUpdate} autoComplete="one-time-code">
                        <TabGroup>
                            <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                                {user?.doc_status === "verified" &&
                                    <Tab key="Venue" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                        Venue Location
                                    </Tab>
                                }
                                <Tab key="Contact" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Contact Info
                                </Tab>
                                {user?.doc_status === "verified" &&
                                    <>
                                        <Tab key="Gallery" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Gallery
                                        </Tab>
                                        <Tab key="Time" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Hours of operation
                                        </Tab>
                                        <Tab key="Social" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Social Media
                                        </Tab>
                                        <Tab key="Amenities" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Amenities
                                        </Tab>
                                        <Tab key="Menu" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                            Menu
                                        </Tab>
                                        {user?.email && user?.venue_name ?
                                            <Tab key="Bank" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                                Bank Account
                                            </Tab>
                                            : null}
                                    </>
                                }
                                <Tab key="Documents" className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap">
                                    Documents
                                </Tab>
                            </TabList>
                            <TabPanels className="mt-3">
                                {user?.doc_status === "verified" &&
                                    <TabPanel TabPanel key="Venue" className="">
                                        <ul>
                                            <li key="Venue" className="relative rounded-md text-sm/6 transition">
                                                <div className="">
                                                    <div className="">
                                                        <div className="grid grid-cols-12 gap-4">
                                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                                <FormControl
                                                                    type="text"
                                                                    name="venue_name"
                                                                    label="Venue Name"
                                                                    placeholder="Enter Venue Name"
                                                                    autoComplete="one-time-code"
                                                                    value={form?.venue_name}
                                                                    onChange={(e) => setform({ ...form, venue_name: e })}
                                                                    disabled={!editAble}
                                                                    className={!editAble && "cursor-not-allowed"}
                                                                    required
                                                                />
                                                            </div>
                                                            {form?.locations?.map((item, index) => {
                                                                return <div className="col-span-12 border border-[1px] p-5 rounded-md bg-[#f6faff]" key={index}>
                                                                    <div className="grid grid-cols-12 gap-4">
                                                                        <div className="col-span-12 lg:col-span-6">
                                                                            <label>Location <span className="text-red-600">*</span></label>
                                                                            <ReactGoogleAutocomplete
                                                                                apiKey={environment?.map_api_key}
                                                                                onPlaceSelected={(place) => { handleLocationsAutoComplete(place, index) }}
                                                                                onChange={e => handleAddMoreInput(e.target.value, index, "location", "locations")}
                                                                                value={item?.location}
                                                                                options={{
                                                                                    types: ["(regions)"],
                                                                                    componentRestrictions: { country: "us" },
                                                                                }}
                                                                                key={`venueLocation${index}`}
                                                                                placeholder="Enter Location"
                                                                                required
                                                                                disabled={!editAble}
                                                                                className={`relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] ${!editAble && "cursor-not-allowed"}`}
                                                                            />
                                                                        </div>
                                                                        <div className="col-span-12 lg:col-span-6">
                                                                            <FormControl
                                                                                type="text"
                                                                                name="country"
                                                                                label="Country"
                                                                                placeholder="Enter country"
                                                                                autoComplete="one-time-code"
                                                                                value={item?.country}
                                                                                onChange={(e) => handleAddMoreInput(e, index, "country", "locations")}
                                                                                required
                                                                                disabled={!editAble}
                                                                                className={!editAble && "cursor-not-allowed"}
                                                                            />
                                                                        </div>
                                                                        <div className="col-span-12 lg:col-span-6">
                                                                            <FormControl
                                                                                type="text"
                                                                                name="state"
                                                                                label="State"
                                                                                placeholder="Enter State"
                                                                                autoComplete="one-time-code"
                                                                                value={item?.state}
                                                                                onChange={(e) => handleAddMoreInput(e, index, "state", "locations")}
                                                                                required
                                                                                disabled={!editAble}
                                                                                className={!editAble && "cursor-not-allowed"}
                                                                            />
                                                                        </div>
                                                                        <div className="col-span-12 lg:col-span-6">
                                                                            <FormControl
                                                                                type="text"
                                                                                name="city"
                                                                                label="City"
                                                                                placeholder="Enter City"
                                                                                autoComplete="one-time-code"
                                                                                value={item?.city}
                                                                                onChange={(e) => handleAddMoreInput(e, index, "city", "locations")}
                                                                                required
                                                                                disabled={!editAble}
                                                                                className={!editAble && "cursor-not-allowed"}
                                                                            />
                                                                        </div>
                                                                        <div className="col-span-12 lg:col-span-6">
                                                                            <FormControl
                                                                                type="text"
                                                                                name="zipCode"
                                                                                label="Zip Code"
                                                                                placeholder="Enter Zip Code"
                                                                                autoComplete="one-time-code"
                                                                                value={item?.zipCode}
                                                                                onChange={(e) => handleAddMoreInput(e, index, "zipCode", "locations")}
                                                                                required
                                                                                disabled={!editAble}
                                                                                className={!editAble && "cursor-not-allowed"}
                                                                            />
                                                                        </div>
                                                                        {form?.locations?.length > 1 && editAble ?
                                                                            <div className="col-span-12 gap-4 flex justify-end">
                                                                                <Tooltip position="top" title="Remove Location">
                                                                                    <div className={`bg-red-600 p-3 text-white rounded-lg cursor-pointer w-fit h-fit`} onClick={e => handleRemoveAddMore(index, "locations")}><GrSubtractCircle size={20} /></div>
                                                                                </Tooltip>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            })}
                                                            {editAble &&
                                                                <Tooltip position="top" title="Add More Locations">
                                                                    <div className="col-span-12 gap-4 flex ml-auto">
                                                                        <div className={`bg-[#005AAB] p-3 text-white rounded-lg cursor-pointer w-fit h-fit`} onClick={e => handleAddMoreLocations()}>
                                                                            <IoMdAddCircleOutline size={20} />
                                                                        </div>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                            {/* <div className="col-span-12 lg:col-span-6 mb-3">
                                                <label>Location <span className="text-red-600">*</span></label>
                                                <ReactGoogleAutocomplete
                                                    apiKey={environment?.map_api_key}
                                                    onPlaceSelected={(place) => { handleLocation(place) }}
                                                    onChange={e => setform({ ...form, location: e.target.value })}
                                                    value={form?.location}
                                                    options={{
                                                        types: ["(regions)"],
                                                        componentRestrictions: { country: "us" },
                                                    }}
                                                    key="venueLocation"
                                                    placeholder="Enter Location"
                                                    disabled={!editAble}
                                                    required
                                                    className={`relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] ${!editAble && "cursor-not-allowed"}`}
                                                />
                                            </div>
                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="country"
                                                    label="Country"
                                                    placeholder="Enter country"
                                                    autoComplete="one-time-code"
                                                    value={form?.country}
                                                    onChange={(e) => setform({ ...form, country: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="state"
                                                    label="State"
                                                    placeholder="Enter State"
                                                    autoComplete="one-time-code"
                                                    value={form?.state}
                                                    onChange={(e) => setform({ ...form, state: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="city"
                                                    label="City"
                                                    placeholder="Enter City"
                                                    autoComplete="one-time-code"
                                                    value={form?.city}
                                                    onChange={(e) => setform({ ...form, city: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-12 lg:col-span-6 mb-3">
                                                <FormControl
                                                    type="number"
                                                    name="zipCode"
                                                    label="Zip Code"
                                                    placeholder="Enter Zip Code"
                                                    autoComplete="one-time-code"
                                                    value={form?.zipCode}
                                                    onChange={(e) => setform({ ...form, zipCode: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    maxlength="6"
                                                    required
                                                />
                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </TabPanel>
                                }
                                <TabPanel key="Contact" className="">
                                    <ul>
                                        <li key="Contact" className="relative rounded-md text-sm/6 transition">
                                            <div className="">
                                                <div className="grid grid-cols-12 gap-4">
                                                    <div className="col-span-12 lg:col-span-6 mb-3">
                                                        <FormControl
                                                            type="text"
                                                            name="name"
                                                            label="Name"
                                                            placeholder="Enter Name"
                                                            autoComplete="one-time-code"
                                                            value={form?.name}
                                                            onChange={(e) => setform({ ...form, name: e })}
                                                            disabled={!editAble}
                                                            className={!editAble && "cursor-not-allowed"}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-span-12 lg:col-span-6 mb-3">
                                                        <FormControl
                                                            type="text"
                                                            name="email"
                                                            label="Email"
                                                            placeholder="Enter Email"
                                                            autoComplete="one-time-code"
                                                            className={"cursor-not-allowed"}
                                                            value={form.email}
                                                            disabled
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-span-12 lg:col-span-6 mobile_number mb-3">
                                                        <FormControl
                                                            type="phone"
                                                            name="mobileNo"
                                                            autoComplete="one-time-code"
                                                            label="Mobile Number"
                                                            disabled={!editAble}
                                                            className={!editAble && "cursor-not-allowed"}
                                                            value={form?.mobileNo}
                                                            onChange={(e) => setform({ ...form, mobileNo: e })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-12 gap-4">
                                                    <div className="col-span-12 lg:col-span-6 mb-3">
                                                        <label>Profile Image (JPG/PNG)</label>
                                                        <div className="flex flex-col rounded-lg gap-6 max-sm:mx-auto">
                                                            {form?.image ? (
                                                                <>
                                                                    <div className="flex flex-wrap gap-3 mt-3">
                                                                        <div className="relative">
                                                                            <img src={methodModel.userImg(form?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                            {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, image: "" })} size={25} />}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[250px]`} style={{ gap: '8px' }}>
                                                                    <FiPlus />
                                                                    <input
                                                                        id="dropzone-file"
                                                                        type="file"
                                                                        className="hidden"
                                                                        disabled={!editAble}
                                                                        onChange={e => venueProfileImageUpload(e, "image")}
                                                                    />
                                                                    Upload Profile Image
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-span-12 lg:col-span-6 mb-3">
                                                        <label>Background Image (JPG/PNG)</label>
                                                        <div className="flex flex-col rounded-lg gap-6 max-sm:mx-auto">
                                                            {form?.backgroundImage ? (
                                                                <>
                                                                    <div className="flex flex-wrap gap-3 mt-3">
                                                                        <div className="relative">
                                                                            <img src={methodModel.userImg(form?.backgroundImage)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                            {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, backgroundImage: "" })} size={25} />}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[250px]`} style={{ gap: '8px' }}>
                                                                    <FiPlus />
                                                                    <input
                                                                        id="dropzone-file"
                                                                        type="file"
                                                                        className="hidden"
                                                                        disabled={!editAble}
                                                                        onChange={e => venueProfileImageUpload(e, "backgroundImage")}
                                                                    />
                                                                    Upload Background Image
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </TabPanel>
                                {user?.doc_status === "verified" &&
                                    <>
                                        <TabPanel key="Gallery" className="">
                                            <ul>
                                                <li key="Gallery" className="relative rounded-md text-sm/6 transition">
                                                    <div className="">
                                                        <div className="p-5">
                                                            <div className="mb-3">
                                                                <label>Venue Images (JPG/PNG) <span className="text-red-600">*</span></label>
                                                                <div className={`flex flex-col rounded-lg gap-6 max-sm:mx-auto`}>
                                                                    {form?.images?.length !== 10 &&
                                                                        <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                                                            <FiPlus />
                                                                            <input
                                                                                id="dropzone-file"
                                                                                type="file"
                                                                                className="hidden"
                                                                                disabled={!editAble}
                                                                                multiple
                                                                                onChange={e => ImagesUpload(e, "images")}
                                                                            />
                                                                            Upload Images
                                                                        </label>
                                                                    }
                                                                    {form?.images?.length > 0 ?
                                                                        <span className="flex flex-wrap gap-3">
                                                                            {form?.images?.map((item, index) => {
                                                                                return <div className="relative" key={index}>
                                                                                    <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, 'images')} size={25} />}
                                                                                </div>
                                                                            })}
                                                                        </span> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label>Venue Videos (MP4/WEBM/OGG)</label>
                                                                <div className="flex flex-col rounded-lg gap-6 max-sm:mx-auto">
                                                                    {form?.videos?.length !== 5 &&
                                                                        <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                                                            <FiPlus />
                                                                            <input
                                                                                id="dropzone-file"
                                                                                type="file"
                                                                                className="hidden"
                                                                                accept="video/*"
                                                                                disabled={!editAble}
                                                                                multiple
                                                                                onChange={videoUpload}
                                                                            />
                                                                            Upload Videos
                                                                        </label>
                                                                    }
                                                                    {form?.videos?.length > 0 ?
                                                                        <span className="flex flex-wrap gap-3">
                                                                            {form?.videos?.map((item, index) => {
                                                                                return <div className="relative mt-2 w-[200px] rounded-lg" key={index}>
                                                                                    <video width="320" height="240" controls>
                                                                                        <source src={methodModel.video(item)} type="video/mp4" />
                                                                                        <source src={methodModel.video(item)} type="video/ogg" />
                                                                                        Your browser does not support the video tag.
                                                                                    </video>
                                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleDelete(index, "videos")} size={25} />}
                                                                                </div>
                                                                            })}
                                                                        </span>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-span-2 mb-3">
                                                                <FormControl
                                                                    type="editor"
                                                                    name="description"
                                                                    label="Description"
                                                                    className={`h-[200px] ${!editAble && "cursor-not-allowed"}`}
                                                                    disabled={!editAble}
                                                                    value={form?.description}
                                                                    onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel key="Time" className="">
                                            <ul>
                                                <li key="Time" className="relative rounded-md text-sm/6 transition">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="overflow-auto">
                                                                <table className="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                                    <tr>
                                                                        <th className="px-2 py-3 cursor-pointer whitespace-nowrap">
                                                                            <label className={`flex items-cneter gap-2 ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                                                <input type="checkbox" disabled={!editAble} onChange={e => handleApplyAll(e.target.checked)} checked={form?.applyAll} className={`h-5 w-5 ${!editAble && "cursor-not-allowed"}`} />Apply for all
                                                                            </label>
                                                                        </th>
                                                                        <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Open/Close</th>
                                                                        <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Start Time <span className="text-red-600">*</span></th>
                                                                        <th className="px-2 py-3 cursor-pointer whitespace-nowrap">End Time <span className="text-red-600">*</span></th>
                                                                        <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Best Time To Visit </th>
                                                                    </tr>
                                                                    {form?.time_schedule?.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td className="px-2 py-4 whitespace-nowrap undefined capitalize">{item?.day}</td>
                                                                            <td className="px-2 py-4 whitespace-nowrap undefined capitalize">
                                                                                <input type="checkbox" checked={item?.open} onChange={e => handleTimeScheduleCheckboxes(e.target.checked, index, 'open')} />
                                                                            </td>
                                                                            <td className="px-2 py-4 whitespace-nowrap undefined">
                                                                                <DatePicker
                                                                                    selected={item?.start_time}
                                                                                    onChange={(date) => handleTimeSchedule(date, index, 'start_time')}
                                                                                    className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                                    dateFormat="hh:mm a"
                                                                                    placeholderText="Start Time"
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={15}
                                                                                    timeCaption="Start Time"
                                                                                    disabled={!editAble || index !== 0 && form?.applyAll}
                                                                                    required={item?.open}
                                                                                />
                                                                            </td>
                                                                            <td className="px-2 py-4 whitespace-nowrap undefined">
                                                                                <DatePicker
                                                                                    selected={item?.end_time}
                                                                                    onChange={(date) => handleTimeSchedule(date, index, 'end_time')}
                                                                                    className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                                    dateFormat="hh:mm a"
                                                                                    placeholderText="End Time"
                                                                                    minTime={item?.start_time ? addTenMinutes(item?.start_time) : getEndTime(0, 0)}
                                                                                    maxTime={getEndTime(23, 50)}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={15}
                                                                                    timeCaption="End Time"
                                                                                    disabled={!editAble || index !== 0 && form?.applyAll}
                                                                                    required={item?.open}
                                                                                />
                                                                            </td>
                                                                            <td className="px-2 py-4 whitespace-nowrap undefined">
                                                                                <DatePicker
                                                                                    selected={item?.best_time_to_visit}
                                                                                    onChange={(date) => handleTimeSchedule(date, index, 'best_time_to_visit')}
                                                                                    className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                                    dateFormat="hh:mm a"
                                                                                    placeholderText="Best Time To Visit"
                                                                                    minTime={item?.start_time ? getEndTime(moment(item?.start_time).format("HH"), moment(item?.start_time).format("mm")) : getEndTime(0, 0)}
                                                                                    maxTime={item?.end_time ? getEndTime(moment(item?.end_time).format("HH"), moment(item?.end_time).format("mm")) : getEndTime(23, 50)}
                                                                                    showTimeSelect
                                                                                    showTimeSelectOnly
                                                                                    timeIntervals={15}
                                                                                    timeCaption="Best Time"
                                                                                    disabled={!editAble || index !== 0 && form?.applyAll}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel key="Social" className="">
                                            <ul>
                                                <li key="Social" className="relative rounded-md text-sm/6 transition">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="grid grid-cols-12 gap-4">
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="website_link"
                                                                        label="Website Link"
                                                                        placeholder="Enter Website Link"
                                                                        autoComplete="one-time-code"
                                                                        value={form?.website_link}
                                                                        onChange={(e) => setform({ ...form, website_link: e })}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="instagram_link"
                                                                        label="Instagram Link"
                                                                        placeholder="Enter Instagram Link"
                                                                        autoComplete="one-time-code"
                                                                        value={form?.instagram_link}
                                                                        onChange={(e) => setform({ ...form, instagram_link: e })}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="facebook_link"
                                                                        label="Facebook Link"
                                                                        placeholder="Enter Facebook Link"
                                                                        autoComplete="one-time-code"
                                                                        value={form?.facebook_link}
                                                                        onChange={(e) => setform({ ...form, facebook_link: e })}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="tiktok_link"
                                                                        label="Tiktok Link"
                                                                        placeholder="Enter Tiktok Link"
                                                                        autoComplete="one-time-code"
                                                                        value={form?.tiktok_link}
                                                                        onChange={(e) => setform({ ...form, tiktok_link: e })}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                    />
                                                                </div>
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="youtube_link"
                                                                        label="Youtube Link"
                                                                        placeholder="Enter Youtube Link"
                                                                        autoComplete="one-time-code"
                                                                        value={form?.youtube_link}
                                                                        onChange={(e) => setform({ ...form, youtube_link: e })}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel key="Amenities" className="">
                                            <ul>
                                                <li key="Amenities" className="relative rounded-md text-sm/6 transition">
                                                    <div className="grid grid-cols-12 gap-5">
                                                        {amenities?.map((itm, i) => {
                                                            if (itm?.subAmenities?.length > 0) {
                                                                return <div className="col-span-12 mb-5" key={i}>
                                                                    <div className="">
                                                                        <div className="text-[20px] text-[#005AAB] font-[600] mb-3 capitalize" key={i}>{itm?.title}</div>
                                                                        <div className="flex items-center gap-3 flex-wrap">
                                                                            {itm?.subAmenities.map((item, index) => (
                                                                                <label className={`relative p-3 ${form?.subAmenities.includes(item?._id) ? "bg-white border-[2px] border-[#005AAB]" : "bg-white border-[2px] border-white"} shadow-[0px_0px_15px_-1px_#c1c1c1] rounded-lg text-gray-600 capitalize ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`} key={index}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={item?._id}
                                                                                        disabled={!editAble}
                                                                                        checked={form?.subAmenities.includes(item?._id)}
                                                                                        onChange={(e) => handleCheckbox(e.target.checked, item?._id, "subAmenities")}
                                                                                        className={`absolute hidden ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}
                                                                                    />
                                                                                    <span className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                                                        <span>
                                                                                            <img src={methodModel.noImg(item?.image)} className="w-[100px] h-[80px] rounded-sm object-contain" />
                                                                                        </span>
                                                                                        <div className="flex items-center min-h-[36px]">
                                                                                            <span className={`p_text mr-0 mt-2  ${form?.subAmenities.includes(item?._id) ? "text-[#005AAB]" : "text-black"} capitalize flex justify-center leading-[14px] text-center w-[100px] text-[12px]`}>{" "}{item?.title}</span>
                                                                                        </div>
                                                                                    </span>
                                                                                </label>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel key="Menu" className="">
                                            <ul>
                                                <li key="Menu" className="relative rounded-md text-sm/6 transition">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="grid grid-cols-12 gap-4">
                                                                <div className="col-span-12 lg:col-span-6 mb-3">
                                                                    <label>Menu Format <span className="text-red-600">*</span></label>
                                                                    <SelectDropdown
                                                                        id="statusDropdown"
                                                                        displayValue="name"
                                                                        className="mt-1"
                                                                        placeholder="Select Choose Format"
                                                                        theme="search"
                                                                        intialValue={form?.menu_item_format}
                                                                        result={(e) => setform({ ...form, menu_item_format: e?.value })}
                                                                        options={foodFormatOptions}
                                                                        disabled={!editAble}
                                                                        isClearable={false}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            {form?.menu_item_format === "manual" &&
                                                                <>
                                                                    <div className="mb-3">
                                                                        <label>Foods</label>
                                                                        <div className="grid grid-cols-12 gap-3">
                                                                            <div className="col-span-12">
                                                                                {form?.foods?.map((itm, index) => {
                                                                                    return <div key={index} className="flex mb-3">
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            name="foods"
                                                                                            placeholder="Enter Item"
                                                                                            autoComplete="one-time-code"
                                                                                            value={itm?.item}
                                                                                            onChange={(e) => handleAddMoreInput(e, index, "item", "foods")}
                                                                                            disabled={!editAble}
                                                                                            className={!editAble && "cursor-not-allowed"}
                                                                                            required
                                                                                        />
                                                                                        <FormControl
                                                                                            type="number"
                                                                                            name="price"
                                                                                            placeholder="Enter Price ($)"
                                                                                            autoComplete="one-time-code"
                                                                                            className={`mx-3 ${!editAble && "cursor-not-allowed"}`}
                                                                                            value={itm?.price}
                                                                                            onChange={(e) => handleAddMoreInput(e, index, "price", "foods")}
                                                                                            disabled={!editAble}
                                                                                            maxlength={10}
                                                                                            required
                                                                                        />
                                                                                        <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                                                            {itm?.image ? (
                                                                                                <>
                                                                                                    <div className="relative flex flex-wrap gap-3 mt-3">
                                                                                                        <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                                                        {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "foods")} size={25} />}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                                                    <FiPlus />
                                                                                                    <input
                                                                                                        id="dropzone-file"
                                                                                                        type="file"
                                                                                                        disabled={!editAble}
                                                                                                        className="hidden"
                                                                                                        onChange={e => ImageUpload(e, index, "image", "foods")}
                                                                                                    />
                                                                                                    Upload Image
                                                                                                </label>
                                                                                            )}
                                                                                        </div>
                                                                                        {form?.foods?.length > 1 && editAble &&
                                                                                            <Tooltip position="top" title="Remove Food">
                                                                                                <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "foods")}><GrSubtractCircle size={20} /></div>
                                                                                            </Tooltip>
                                                                                        }
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                            {editAble &&
                                                                                <div className="col-span-12 flex justify-end">
                                                                                    <Tooltip position="top" title="Add More Foods">
                                                                                        <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("foods")}>
                                                                                            <IoMdAddCircleOutline size={20} />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label>Drinks</label>
                                                                        <div className="grid grid-cols-12 gap-3">
                                                                            <div className="col-span-12">
                                                                                {form?.drinks?.map((itm, index) => {
                                                                                    return <div key={index} className="flex mb-3">
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            name="drinks"
                                                                                            placeholder="Enter Item"
                                                                                            autoComplete="one-time-code"
                                                                                            value={itm?.item}
                                                                                            onChange={(e) => handleAddMoreInput(e, index, "item", "drinks")}
                                                                                            disabled={!editAble}
                                                                                            className={!editAble && "cursor-not-allowed"}
                                                                                            required
                                                                                        />
                                                                                        <FormControl
                                                                                            type="number"
                                                                                            name="price"
                                                                                            placeholder="Enter Price ($)"
                                                                                            autoComplete="one-time-code"
                                                                                            className={`mx-3 ${!editAble && "cursor-not-allowed"}`}
                                                                                            disabled={!editAble}
                                                                                            value={itm?.price}
                                                                                            onChange={(e) => handleAddMoreInput(e, index, "price", "drinks")}
                                                                                            maxlength={10}
                                                                                            required
                                                                                        />
                                                                                        <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                                                            {itm?.image ? (
                                                                                                <>
                                                                                                    <div className="relative flex flex-wrap gap-3 mt-3">
                                                                                                        <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                                                        {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "drinks")} size={25} />}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                                                    <FiPlus />
                                                                                                    <input
                                                                                                        id="dropzone-file"
                                                                                                        type="file"
                                                                                                        className="hidden"
                                                                                                        disabled={!editAble}
                                                                                                        onChange={e => ImageUpload(e, index, "image", "drinks")}
                                                                                                    />
                                                                                                    Upload Image
                                                                                                </label>
                                                                                            )}
                                                                                        </div>
                                                                                        {form?.drinks?.length > 1 && editAble &&
                                                                                            <Tooltip position="top" title="Remove Drink">
                                                                                                <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "drinks")}><GrSubtractCircle size={20} /></div>
                                                                                            </Tooltip>
                                                                                        }
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                            {editAble &&
                                                                                <div className="col-span-12 flex justify-end">
                                                                                    <Tooltip position="top" title="Add More Drinks">
                                                                                        <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("drinks")}>
                                                                                            <IoMdAddCircleOutline size={20} />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {form?.menu_item_format === "upload" &&
                                                                <>
                                                                    <label>Upload Food Images (JPG/PNG)</label>
                                                                    <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                                                        {form?.foodImages?.length !== 10 &&
                                                                            <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px] `} style={{ gap: '8px' }}>
                                                                                <FiPlus />
                                                                                <input
                                                                                    id="dropzone-file"
                                                                                    type="file"
                                                                                    className="hidden"
                                                                                    disabled={!editAble}
                                                                                    multiple
                                                                                    onChange={e => ImagesUpload(e, 'foodImages')}
                                                                                />
                                                                                Upload Images
                                                                            </label>
                                                                        }
                                                                        {form?.foodImages?.length > 0 ?
                                                                            <span className="flex flex-wrap gap-3">
                                                                                {form?.foodImages?.map((item, index) => {
                                                                                    return <div className="relative" key={index}>
                                                                                        <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                                        {editAble && <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, "foodImages")} size={25} />}
                                                                                    </div>
                                                                                })}
                                                                            </span> : null
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </TabPanel>
                                        {user?.email && user?.venue_name ?
                                            <TabPanel key="Bank" className="">
                                                <ul>
                                                    <li key="Bank" className="relative rounded-md text-sm/6 transition">
                                                        <div className="w-full">
                                                            {user?.accountDetails?.bankAccountNumber ?
                                                                <div className="max-w-[500px] max-[480px]:text-center rounded-lg overflow-hidden shadow-lg bg-white">
                                                                    <h3 className="text-xl font-semibold bg-[#134896] text-white px-5 py-3 mb-2">{user?.accountDetails?.bank_name || "--"}</h3>
                                                                    <div className="grid max-[480px]:grid-cols-1 grid-cols-2 gap-5 items-center p-5">
                                                                        <div>
                                                                            <p className="text-[22px] text-gray-600 mb-4">Account Number:</p>
                                                                            <p className="text-[18px] text-gray-600 mb-4">***-***-**** {user?.accountDetails?.bankAccountNumber || "--"}</p>
                                                                            <div className="flex items-center max-[480px]:justify-center">
                                                                                {user?.accountDetails?.transfer === "inactive" ?
                                                                                    <button type="button" disabled={!editAble} className={`bg-blue-500 text-white disabled:cursor-not-allowed px-4 py-2 rounded ${editAble && "hover:bg-blue-700"}`} onClick={() => verifyBankAccount()}>Verify Your Account</button>
                                                                                    :
                                                                                    <button type="button" disabled={!editAble} className={`bg-red-500 text-white disabled:cursor-not-allowed px-4 py-2 rounded ${editAble && "hover:bg-red-700"}`} onClick={() => removeBankAccount()}>Remove Account</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <img src="/assets/img/back_account.jpg" className="h-[200px] w-full object-contain" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="text-center">
                                                                    <button type="button" onClick={e => addBankAccount()} className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">Add Bank Account</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </li>
                                                </ul>
                                            </TabPanel>
                                            : null}
                                    </>}
                                <TabPanel key="Documents" className="">
                                    <ul>
                                        <li key="Documents" className="relative rounded-md text-sm/6 transition">
                                            <div className="">
                                                <div className="">
                                                    <div className="grid grid-cols-12 gap-4">
                                                        <div className="col-span-12 lg:col-span-6 mb-3">
                                                            <FormControl
                                                                type="text"
                                                                name="company_ein"
                                                                label="Company EIN"
                                                                placeholder="Enter Company EIN"
                                                                autoComplete="one-time-code"
                                                                disabled={!editAble}
                                                                className={!editAble && "cursor-not-allowed"}
                                                                value={form?.company_ein}
                                                                onChange={(e) => setform({ ...form, company_ein: e })}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-span-12 mb-3">
                                                            <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                                                Upload Company EIN <span className="text-red-600">*</span>
                                                            </label>
                                                            {form?.ein_image ?
                                                                <div className="relative">
                                                                    <IoDocumentTextOutline size={20} className="bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                    {editAble && <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />}
                                                                </div>
                                                                :
                                                                <label onDrop={handleDrop} onDragOver={handleDragOver} className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer">
                                                                    <div className="text-center">
                                                                        <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                            <FiPlus />
                                                                            <input
                                                                                id="dropzone-file"
                                                                                type="file"
                                                                                className="hidden"
                                                                                multiple
                                                                                disabled={!editAble}
                                                                                onChange={e => uploadEIN(e)}
                                                                            />
                                                                            Upload EIN Or Drag & Drop EIN
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            }
                                                            {!form?.ein_image && submitted &&
                                                                <span className="text-red-600">Company EIN is required</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                        <div>
                            {user?.doc_status === "verified" &&
                                <div className="flex items-center gap-2 mt-3 justify-end">
                                    {editAble &&
                                        <>
                                            <div onClick={e => { setEditAble(!editAble) }} type="button" className="text-white bg-[#005AAB] font-medium rounded-full text-sm py-2.5 px-5 text-center cursor-pointer">Cancel</div>
                                            <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-3 text-center">
                                                Update
                                            </button>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <PopUp popUp={popUp} setPopUp={setPopUp} redirectUrl={popUp?.redirectUrl || ""} />
        </>
    )
}

export default Profile