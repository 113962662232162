import { useEffect, useState } from "react"
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import { socket } from "../../socket"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import methodModel from "../../methods/methods"
import Pagination from "react-js-pagination";
import { MdClear } from "react-icons/md"
import loader from "../../methods/loader"


const Notifications = () => {
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const [notification, setNotification] = useState([])
    const [unReadNotifications, setUnReadNotifications] = useState(0)
    const [filter, setFilter] = useState({ page: 1, count: 10, total: 0 })

    useEffect(() => {
        getNotificationsUnreadCount()
        getNotifications()
    }, [])

    const getNotificationsUnreadCount = () => {
        socket.emit("unread-notification-count", { senderId: user?._id || user?.id })
        socket.on("unread-notification-count-received", (response => {
            if (response.success) {
                setUnReadNotifications(response?.unreadCount || 0)
            }
        }))
    }

    const getNotifications = (p = {}) => {
        const payload = {
            ...filter,
            ...p,
            senderId: user?._id || user?.id
        }
        loader(true)
        socket.emit("notification-list", payload)
        socket.on("notification-list-received", (response => {
            if (response?.success) {
                setNotification(response?.data)
                setFilter((prev) => ({ ...prev, total: response?.total }))
            }
            loader(false)
        }))
    }

    const notificationRedirection = (item) => {
        socket.emit("read-single-notification", { notificationId: item?._id || item?.id })
        navigate(`/message/${item?.from?._id || item?.from?.id}`)
    }

    const readAllNotifications = () => {
        socket.emit("read-all-notification", { senderId: user?._id || user?.id })
        socket.on("notification-read", (response => {
            if (response?.success) {
                setNotification([])
                setUnReadNotifications(0)
                setFilter((prev) => ({ ...prev, total: 0 }))
            }
        }))
    }

    const handlePagination = (e) => {
        setFilter((prev) => ({ ...prev, page: e }))
        getNotifications({ page: e })
    }

    const clear = () => {
        let f = { search: "" };
        setFilter({ ...filter, ...f });
        getNotifications({ ...f });
    };
    const handleSearch = (p = {}) => {
        let f = { page: 1, ...p }
        setFilter({ ...filter, ...f });
        getNotifications({ ...f });
    };

    return (
        <>
            <div className="bg-white w-full h-screen">
                <DesktopSidebar />
                <div className="bg-[#f1f1f1] h-full overflow-auto pt-5 pb-5">
                    <div className="container mx-auto px-5 pb-5">
                        <div className="flex justify-between items-center gap-x-5 pb-3 mb-5 border-b">
                            <h2 className="text-[20px] font-[600] text-[#134896]">Notifiactions</h2>
                            <div className="flex gap-3 items-center">
                                <form className="flex items-center max-w-sm gap-2" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                                    <label htmlFor="simple-search" className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <input
                                            type="text"
                                            id="simple-search"
                                            value={filter.search}
                                            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                            autoComplete="one-time-code"
                                            className=" border border-[#d1d1d1] text-gray-900 text-sm rounded-full focus:ring-orange-500 block w-full ps-5 p-2.5 pr-10"
                                            placeholder="Search"
                                        />
                                        {filter?.search && (
                                            <MdClear className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer" size={20} onClick={(e) => clear()} />
                                        )}
                                    </div>
                                    <button type="submit" className="p-2.5 text-sm font-medium text-white bg-[#063688] rounded-full border border-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <svg
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                        <span className="sr-only">Search</span>
                                    </button>
                                </form>
                                {unReadNotifications !== 0 && <p onClick={() => readAllNotifications()} className="bg-white px-5 py-2 h-fit border rounded-md text-[12px] cursor-pointer">Read All</p>}
                            </div>
                        </div>
                        <div className="">
                            {notification?.length === 0 && <div className="w-full bg-[#fff] shadow rounded-lg p-3 mb-1 text-center">
                                <img src="/assets/img/notification_image.png" className="w-[300px] text-center mx-auto" />
                            </div>}
                            {notification?.map((item, index) => {
                                return <div onClick={e => notificationRedirection(item)} className="flex w-full bg-[#fff] shadow rounded-lg p-3 mb-1" key={index}>
                                    <img src={methodModel.userImg(item?.from?.image)} className="w-[40px] h-[40px] object-cover rounded-full border bg-white" />
                                    <div className="ml-2">
                                        <h2 className="text-[12px] font-[600]"> {item?.from?.role === "venue" ? item?.from?.venue_name : item?.from?.fullName}</h2>
                                        <p className="text-[12px] text-[#2a2a2a]">{item?.message}</p>
                                        <p className="text-[#6a6a6a] text-[10px]">{moment(item?.createdAt).fromNow()}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                        {filter?.total > filter?.count ?
                            <div className="pagination_div w-[85%] text-right mx-auto">
                                <Pagination
                                    activePage={filter?.page}
                                    itemsCountPerPage={filter?.count}
                                    totalItemsCount={filter?.total}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                                    activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                                    onChange={e => handlePagination(e)}
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notifications