import { FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import methodModel from "../../../methods/methods";
import loader from "../../../methods/loader";
import { NavigationBar } from "../../Landing/NavigationBar";
import { useSelector } from "react-redux";

const ProfilePage = () => {
    const { id } = useParams()
    const user = useSelector(state => state.user)
    const [data, setData] = useState("")

    useEffect(() => {
        if (id) {
            getDetail()
        }
    }, [id])

    const getDetail = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setData(res?.data)
            }
            loader(false)
        })
    }

    const handleLikeFollowEvent = (type) => {
        const payload = {
            id: data?.id || data?._id,
            type: type,
            userId: user?._id || user?.id,
            addedTo: "crew_member",
        }
        loader(true)
        ApiClient.post(`event/${type}`, payload).then(res => {
            if (res.success) {
                getDetail()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="">
                <NavigationBar />
                <div className="py-5">
                    <div className="container mx-auto px-5">
                        <div className="">
                            <div className="grid grid-cols-12 gap-5">
                                <div className="col-span-12 md:col-span-5">
                                    <div className="">
                                        <img src={methodModel.userImg(data?.image)} className="max-[480px]:h-[250px] h-[300px] sm:h-[400px] md:h-[300px] lg:h-[500px] w-full object-cover rounded-2xl" />
                                        <div className="text-center mt-3">
                                            <div className='flex flex-wrap justify-center gap-3'>
                                                {data?.youtube_link && <FaYoutube onClick={() => { window.open(data?.youtube_link, "_blank") }} className="text-[#838383] cursor-pointer hover:text-[#005AAB] text-[20px] z-10 border rounded-full w-[30px] h-[30px] p-1 shadow-lg" />}
                                                {data?.instagram_link && <PiInstagramLogoFill onClick={() => { window.open(data?.instagram_link, "_blank") }} className="text-[#838383] cursor-pointer hover:text-[#005AAB] text-[20px] z-10 border rounded-full w-[30px] h-[30px] p-1 shadow-lg" />}
                                                {data?.facebook_link && <FaFacebook onClick={() => { window.open(data?.facebook_link, "_blank") }} className="text-[#838383] cursor-pointer hover:text-[#005AAB] text-[20px] z-10 border rounded-full w-[30px] h-[30px] p-1 shadow-lg" />}
                                                {data?.twitter_link && <FaXTwitter onClick={() => { window.open(data?.twitter_link, "_blank") }} className="text-[#838383] cursor-pointer hover:text-[#005AAB] text-[20px] z-10 border rounded-full w-[30px] h-[30px] p-1 shadow-lg" />}
                                                {data?.linkedin_link && <FaLinkedin onClick={() => { window.open(data?.linkedin_link, "_blank") }} className="text-[#838383] cursor-pointer hover:text-[#005AAB] text-[20px] z-10 border rounded-full w-[30px] h-[30px] p-1 shadow-lg" />}
                                            </div>
                                            {user?.role === "venue" || user?.role === "host" || user?.role === "influencer" ?
                                                <div className="text-center mt-3">
                                                    <button type="button" onClick={e => handleLikeFollowEvent("follow")} className="text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-2 px-5 text-center cursor-pointer hover:opacity-[90%]">
                                                        {data?.follow_data?.some((item) => item?.addedBy === user?._id) ? "Unfollow" : "Follow"}
                                                    </button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-7">
                                    <div className="">
                                        <h2 className="text-[24px] font-[600] text-[#005AAB] mb-3">{data?.fullName || `${data?.firstName || ""} ${data?.lastName || ""}`}</h2>
                                        <h2 className="text-[#005AAB]">{data?.email || "--"}</h2>
                                        <h2 className="text-[#005AAB]">{data?.mobileNo ? `+${data?.mobileNo}` : "--"}</h2>
                                        <p className="text-[14px] font-[300]" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePage