import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { LuLogOut, LuUser } from "react-icons/lu";
import { IoSettingsOutline } from 'react-icons/io5';
import methodModel from '../../methods/methods';
import { login_success, logout } from '../../redux/actions/user';
import ApiClient from '../../methods/api/apiClient';
import { CiSearch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { search_success } from '../../redux/actions/search';
import { IoIosMailUnread, IoMdClose } from 'react-icons/io';
import { socket } from '../../socket';
import { IoIosNotifications } from "react-icons/io";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import moment from 'moment';
import { Tooltip } from 'antd';
import { RxDashboard } from "react-icons/rx";


export const NavigationBar = ({ }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const [state, setState] = useState(false)
    const token = sessionStorage.getItem("token")
    const currentUrl = window.location.pathname;
    const searchData = useSelector((state) => state.search.data)
    const [search, setSearch] = useState({ name: searchData?.name || "", location: searchData?.location || "" })
    const [notification, setNotification] = useState([])
    const [unReadNotifications, setUnReadNotifications] = useState(0)

    const navigation = [
        { title: "Find Events", path: "/Event", show: methodModel.checkUserDetail(user) && user?.activePlan || user?.role === "member" || user?.role === "crew_member" ? true : false },
        { title: "Experience Marc", path: "/experiencemarc", show: methodModel.checkUserDetail(user) && user?.activePlan || user?.role === "member" || user?.role === "crew_member" ? true : false },
        { title: "About", path: "/aboutus", show: user?.role === "member" || !methodModel.checkUserDetail(user) ? false : true },
        { title: "Get Help", path: "/gethelp", show: user?.role === "member" || !methodModel.checkUserDetail(user) ? false : true },
        { title: "Plans", path: "/plans", show: !token || user?.role === "member" || user?.role === "crew_member" || !methodModel.checkUserDetail(user) ? false : true },
        { title: token ? "My Orders" : "Track Order", path: "/myorder", show: user?.role === "member" ? true : false },
        { title: "Transactions", path: "/member/transaction", show: user?.role === "member" ? true : false },
        { title: "Scanner", path: "/scanner", show: user?.role === "venue" && methodModel.checkUserDetail(user) && user?.activePlan || user?.role === "host" && methodModel.checkUserDetail(user) && user?.activePlan ? true : false },
    ]

    useEffect(() => {
        // For User Locations
        let lat = ""
        let lng = ""
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    lat = position?.coords?.latitude
                    lng = position?.coords?.longitude
                    sessionStorage.setItem("userLat", lat)
                    sessionStorage.setItem("userLong", lng)
                }
            );
        }
        // For User Device Token
        let deviceToken = sessionStorage.getItem("deviceToken")
        if(!deviceToken){
            sessionStorage.setItem("deviceToken",getDeviceToken())
        }
        
        if (token && user?.role !== "member") {
            getProfileData()
        }
        if (user?.docVerified === "N") {
            if (user?.role === "venue") {
                navigate('/venue/profile')
            } else if (user?.role === "host") {
                navigate('/host/profile')
            } else {
                return
            }
        }
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".menu-btn")) setState(false);
        };
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }, [])

    // For Sockets
    useEffect(() => {
        getNotificationsUnreadCount()
        getNotifications()

        socket.emit('user-online', { UserId: user?._id || user?.id });
        // Event listener to check the user status
        socket.on('status-updated', (response) => { });
        // Event listener for when the connection is disconnected
        socket.on('disconnect', () => {
            console.log('Socket Disconnected');
        });
    }, [])

    const getDeviceToken = () => {
        const userAgent = navigator.userAgent;
        const screenResolution = `${window.screen.width}x${window.screen.height}`;
        const language = navigator.language;
        // Combine details to form a unique token
        const rawToken = `${userAgent}-${screenResolution}-${language}`;
        const deviceToken = btoa(rawToken); // Base64 encode the token
        return deviceToken;
    };

    const getNotificationsUnreadCount = () => {
        socket.emit("unread-notification-count", { senderId: user?._id || user?.id })
        socket.on("unread-notification-count-received", (response => {
            if (response.success) {
                setUnReadNotifications(response?.unreadCount || 0)
            }
        }))
    }

    const getNotifications = () => {
        socket.emit("notification-list", { senderId: user?._id || user?.id, page: 1, count: 5 })
        socket.on("notification-list-received", (response => {
            if (response?.success) {
                setNotification(response?.data)
            }
        }))
    }

    // Hitting this api to update user detail in redux persist
    const getProfileData = () => {
        ApiClient.get(`user/detail?id=${user?.id || user?._id}`).then(res => {
            if (res.success) {
                const data = res?.data
                dispatch(login_success({
                    ...user,
                    ...data,
                    doc_status: data?.doc_status,
                    docVerified: data?.docVerified,
                    venue_name: data?.venue_name,
                    name: data?.name,
                    email: data?.email,
                    mobileNo: data?.mobileNo,
                    ein_image: data?.ein_image,
                    company_ein: data?.company_ein,
                    firstName: data?.firstName || "",
                    lastName: data?.lastName || "",
                    fullName: data?.firstName ? `${data?.firstName} ${data?.lastName || ""}` : "",
                }))
            }
        })
    }

    const checkActiveHeader = (path) => {
        if (currentUrl === "/providersignup") return "text-white"
        else if (path === currentUrl) return "text-[#005AAB]"
        else return "text-[#343434]"
    }

    const Logout = () => {
        dispatch(logout());
        socket.emit('user-offline', { userId: user?._id || user?.id });
        socket.disconnect();
        sessionStorage.clear()
        navigate("/")
    }

    const hideModules = (item) => {
        let value = false
        if (item?.path === "/plans" && !item?.show || item?.path === "/member/transaction" && !item?.show || item?.path === "/scanner" && !item?.show) {
            value = true
        } else {
            value = false
        }
        return value
    }

    const handleRedirection = (path) => {
        if (path === "/Event") {
            dispatch(search_success(search))
            navigate(path)
        } else {
            if (path) {
                navigate(path)
            } else {
                return
            }
        }
    }
    const clearSearch = () => {
        setSearch({ ...search, name: "" })
        dispatch(search_success({ name: "", location: search?.location }))
    }

    const showFields = () => {
        let value = false
        if (currentUrl === "/" || currentUrl === "/Event") {
            value = true
        }
        return value
    }

    const redirectProfile = () => {
        if (user?.role === "venue") return "/venue/profile"
        else if (user?.role === "host") return "/host/profile"
        else if (user?.role === "member") return "/member/profile"
        else if (user?.role === "influencer") return "/influencer/profile"
        else if (user?.role === "crew") return "/entertainmentCrew/profile"
        else if (user?.role === "crew_member") return "/crewMember/profile"
        else if (user?.role === "vendor") return "/vendor/profile"
        else if (user?.role === "food_truck_vendor") return "/foodTruckVendor/profile"
        else return ""
    }

    const notificationRedirection = (item) => {
        socket.emit("read-single-notification", { notificationId: item?._id || item?.id })
        navigate(`/message/${item?.from?._id || item?.from?.id}`)
    }

    const readAllNotifications = () => {
        socket.emit("read-all-notification", { senderId: user?._id || user?.id })
        socket.on("notification-read", (response => {
            if (response?.success) {
                setNotification([])
                setUnReadNotifications(0)
            }
        }))
    }

    const loginButtons = (type) => {
        return <>
            <div className={`flex flex-col w-full justify-evenly items-center lg:mt-0 lg:flex ${type === "Desktop" ? "max-lg:hidden" : ""}`}>
                {!sessionStorage.getItem('token') ?
                    <div className='flex w-full gap-x-3 items-center lg:justify-end lg:space-y-0 md:mt-0'>
                        <button type='button' className="items-center block text-[14px] lg:text-[16px] text-nowrap font-semibold text-[#005AAB] hover:text-white bg-white hover:bg-[#005AAB] border py-2 px-5 border-[#005AAB] rounded-full lg:inline-flex" onClick={() => navigate('/signin')}>Login</button>
                        <button type='button' className="items-center flex justify-center text-[14px] lg:text-[16px] text-nowrap font-semibold py-2 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-white rounded-full lg:inline-flex" onClick={() => navigate('/joinmarc')}>Signup</button>
                    </div>
                    :
                    <div className='flex items-center gap-4 w-fit max-lg:mr-auto lg:ml-auto'>
                        {user?.role !== "member" &&
                            <Menu>
                                <MenuButton className="relative">
                                    <IoIosNotifications size={25} className='text-[18px] cursor-pointer text-[#005AAB]' />
                                    {unReadNotifications !== 0 && <span className='bg-red-500 text-[9px] text-white rounded-full px-[5px] h-[15px] flex justify-center items-center absolute -top-2 left-[10px]'>{unReadNotifications}</span>}
                                </MenuButton>
                                <MenuItems
                                    transition
                                    anchor="bottom end"
                                    className="w-[300px] origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                >
                                    <div className={`bg-white right-0 shadow-[0px_0px_15px_-8px_#000] rounded-[10px]`}>
                                        <h2 className='flex justify-between items-center bg-[#0463b9] px-5 py-3 text-white rounded-t-[10px]'>Notifications
                                            {unReadNotifications !== 0 &&
                                                <span>
                                                    <Tooltip title="Mark As Read">
                                                        <IoIosMailUnread onClick={() => readAllNotifications()} className='text-white cursor-pointer' size={20} />
                                                    </Tooltip>
                                                </span>
                                            }
                                        </h2>
                                        <div className=''>
                                            <div className='max-h-[calc(100vh_-_192px)] overflow-auto'>
                                                {notification?.length === 0 && <div className='px-5 py-2 bg-white text-black text-center cursor-pointer border-b min-h-[150px] rounded-b-[10px] flex justify-center items-center'>No Notifications</div>}
                                                {notification?.map((item, index) => {
                                                    return <MenuItem key={index} onClick={(e) => notificationRedirection(item)}>
                                                        <div className='flex px-5 py-2 bg-white hover:bg-[#eaf1f6] cursor-pointer border-b'>
                                                            <img src={methodModel.userImg(item?.from?.image)} className='w-9 h-9 object-cover rounded-full border' />
                                                            <div className='ml-2'>
                                                                <h3 className='p_text text-[13px] text-[#2a2a2a] leading-[15px]'>
                                                                    <span className='text-[#000] font-[600]'>{item?.from?.role === "venue" ? item?.from?.venue_name : item?.from?.fullName}</span> {item?.message}
                                                                </h3>
                                                                <p className='text-[#6a6a6a] text-[10px]'>{moment(item?.createdAt).fromNow()}</p>
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                })}
                                            </div>
                                            {notification?.length !== 0 && <button onClick={e => { navigate("/notifications") }} className='bg-white border-t w-full text-[#134896] font-[500] text-[12px] text-center rounded-b-[10px] px-5 py-2'>Show All Notification</button>}
                                        </div>
                                    </div>
                                </MenuItems>
                            </Menu>
                        }
                        <Popover>
                            <PopoverButton className="focus:outline-0 mt-1">
                                <div className="flex items-center">
                                    <div className="flex items-center">
                                        <img alt="image" src={methodModel.userImg(user?.image)} className="h-10 w-10 min-w-10 rounded-full object-cover" />
                                    </div>
                                </div>
                            </PopoverButton>
                            <PopoverPanel anchor="bottom" className="flex flex-col w-52 bg-gray-50 p-2 mt-2 shadow z-[9999999]">
                                <Link to={redirectProfile()} className="group flex w-full items-center text-sm gap-3 rounded-lg hover:bg-[#005AAB] hover:text-white py-2.5 px-4 data-[focus]:bg-white/10">
                                    <LuUser className="size-4 fill-white/30" />Profile
                                </Link>
                                {methodModel.checkUserDetail(user) && user?.activePlan || user?.role === "member" || user?.role === "influencer" || user?.role === "crew" || user?.role === "crew_member" ?
                                    <>
                                        {user?.role !== "influencer" || user?.role !== "crew" || user?.role !== "crew_member" ?
                                            <Link to="/home" className="group flex w-full items-center text-sm gap-3 rounded-lg hover:bg-[#005AAB] hover:text-white py-2.5 px-4 data-[focus]:bg-white/10">
                                                <RxDashboard className="size-4 fill-white/30" />Dashboard
                                            </Link>
                                            : null
                                        }
                                        <Link to="/change-password" className="group flex w-full items-center text-sm gap-3 rounded-lg hover:bg-[#005AAB] hover:text-white py-2.5 px-4 data-[focus]:bg-white/10">
                                            <IoSettingsOutline className="size-4 fill-white/30" />Change Password
                                        </Link>
                                    </>
                                    : null
                                }
                                <div className="my-1 h-px bg-black/5" />
                                <button className="group flex w-full items-center text-sm gap-3 rounded-lg hover:bg-[#005AAB] hover:text-white py-2.5 px-4 data-[focus]:bg-white/10" onClick={() => Logout()}>
                                    <LuLogOut className="size-4 fill-white/30" />Logout
                                </button>
                            </PopoverPanel>
                        </Popover>
                    </div>
                }
            </div>
        </>
    }

    return (
        <nav className={`font-poppins pt-1 pb-1 md:text-sm ${state ? "h-screen overflow-auto fixed left-0 top-0 z-30 w-full bg-white border md:shadow-none md:border-none" : ""}`}>
            <div className="2xl:container mx-auto px-5">
                <div className="gap-x-4 items-center w-[100%] mx-auto lg:flex h-[57px] lg:h-[80px]">
                    <div className="flex w-full items-center justify-between py-1 h-full">
                        <img src={Logo} onClick={() => { navigate("/") }} alt="Float UI logo" className='cursor-pointer w-[80px] sm:w-[100px] object-contain' />
                        {/* Mobile View */}
                        <div className="flex items-center gap-4 lg:hidden">
                            {loginButtons("Mobile")}
                            <button className={`menu-btn ${state ? "text-[#005AAB]" : "text-[#005AAB]"}`} onClick={() => setState(!state)}>
                                {state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                )
                                }
                            </button>
                        </div>
                    </div>
                    <ul className={`justify-center items-center space-y-6 lg:flex gap-5 xl:gap-7 lg:space-y-0 ${state ? 'block mt-5' : 'bg-[transparent] hidden'}`}>
                        {showFields() &&
                            <li className='max-lg:hidden'>
                                <div className='flex max-[639px]:flex-wrap gap-2'>
                                    <div className='relative max-[639px]:w-full'>
                                        <input type='text' value={search?.name} onChange={e => setSearch({ ...search, name: e.target.value })} className='max-[639px]:w-full sm:max-w-[180px] h-[38px] text-[14px] rounded-[30px] px-3 border-[1px] pl-8 border-[#dfdfdf]' placeholder='Search...'></input>
                                        <CiSearch className='absolute top-[10px] left-3 text-[18px] text-[#606060]' />
                                        {search?.name && <IoMdClose className="absolute top-[14px] right-3 text-[15px] cursor-pointer" onClick={() => clearSearch()} />}
                                    </div>
                                    <div className='relative max-[639px]:w-full'>
                                        <input type='text' value={search?.location} onChange={e => setSearch({ ...search, location: e.target.value })} className='max-[639px]:w-full sm:max-w-[180px] h-[38px] text-[14px] rounded-[30px] px-3 border-[1px] pl-8 border-[#dfdfdf]' placeholder='Loaction..'></input>
                                        <CiLocationOn className='absolute top-[10px] left-3 text-[18px] text-[#606060]' />
                                    </div>
                                </div>
                            </li>
                        }
                        {navigation.map((item, id) => {
                            if (hideModules(item) || token && !item?.show) {
                                return null
                            } else {
                                return <li key={id} className="text-[14px] text-nowrap font-[500]">
                                    <span
                                        onClick={e => handleRedirection(item.path)}
                                        className={`text-[14px] xl:text-[16px] cursor-pointer ${checkActiveHeader(item?.path)} block ${state ? 'hover:text-[#005AAB]' : ' hover:text-[#005AAB]'}`}
                                    >
                                        {item.title}
                                    </span>
                                </li>
                            }
                        })
                        }
                    </ul>
                    {/* Desktop View */}
                    {loginButtons("Desktop")}
                </div>
            </div>
        </nav>
    );
};
export default NavigationBar