import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import PurchasedModal from "./PurchasedModal"
import ApiClient from "../../methods/api/apiClient"
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import loader from "../../methods/loader"
import UseModal from "./UseModal"
import moment from "moment"
import Pagination from "react-js-pagination";
import PopUp from "../../common/PopUp"
import environment from "../../environment"

const Boosting = () => {
  const user = useSelector((state) => state.user)
  const [boostingDetail, setBoostingDetail] = useState({})
  const [boostingListing, setBoostingListing] = useState([])
  const [filter, setFilter] = useState({ page: 1, count: 5 })
  const [total, setTotal] = useState(0)
  const [popUp, setPopUp] = useState({ modal: false, price: "", type: "", quantity: 0 })
  const [useModal, setUseModal] = useState({ modal: false, boostType: "", usedCategory: "", eventId: "", location: "", amenities: "" })
  const [modal, setModal] = useState({ modal: false, title: "", message: "", buttonText: "", image: "" })

  useEffect(() => {
    getBoostingDetails()
    getBoostingListing()
  }, [])

  const getBoostingDetails = () => {
    loader(true)
    ApiClient.get(`boost/listing?purchasedBy=${user?._id || user?.id}`).then(res => {
      if (res.success) {
        setBoostingDetail(res?.data)
      }
      loader(false)
    })
  }
  const getBoostingListing = (f = {}) => {
    const applyFilters = { ...filter, ...f, usedBy: user?._id || user?.id }
    loader(true)
    ApiClient.get(`boost/used/listing`, applyFilters).then(res => {
      if (res.success) {
        setBoostingListing(res?.data)
        setTotal(res?.total)
      }
      loader(false)
    })
  }

  const handlePagination = (e) => {
    setFilter((prev) => ({ ...prev, page: e }))
    getBoostingListing({ page: e })
  }

  const handleUseBoosting = (boostType) => {
    if (environment.starterPlanIds?.some((item) => item === user?.activePlanId?._id)) {
      setModal({ modal: true, title: "", image: "/assets/img/warning.png", message: "Your current plan does not include the boosting feature. To access this functionality, you will need to upgrade to a higher plan.", buttonText: "Ok", redirectUrl: "/plans" })
    } else {
      setUseModal({ modal: true, boostType: boostType })
    }
  }

  const handlePurchaseBoosting = (boostType, key) => {
    if (environment.starterPlanIds?.some((item) => item === user?.activePlanId?._id)) {
      setModal({ modal: true, title: "", image: "/assets/img/warning.png", message: "Your current plan does not include the boosting feature. To access this functionality, you will need to upgrade to a higher plan.", buttonText: "Ok", redirectUrl: "/plans" })
    } else {
      setPopUp({ modal: true, price: user?.activePlanId?.[key], type: boostType, quantity: 1 })
    }
  }

  return (
    <>
      <div className="bg-[#D9D9D938] relative">
        <DesktopSidebar />
      </div>
      <div className="container mx-auto px-5">
        <div className="pb-8 pt-8">
          <div className="">
            <div className="bg-[#F1F1F1] pb-[4rem] p-5 rounded-[15px] mb-10">
              <h2 className="text-[22px] sm:text-[24px] font-[500] text-[#134896] border-b border-[#cacaca] pb-5 mb-8">BOOSTING DETAIL</h2>
              <div className="grid max-[480px]:grid-cols-1 grid-cols-2 lg:grid-cols-4 gap-5 xl:gap-[3rem] px-[0rem] xl:px-[5rem]">
                <div className="bg-white rounded-lg text-center p-5">
                  <img src="/assets/img/boost_1.png" className="w-[65px] h-[65px] object-contain mx-auto mb-2" />
                  <h3 className="text-[18px] sm:text-[17px] text-[#134896] font-[500]">Daily Boosting</h3>
                  <p className="text-[#7c7c7c] my-2 text-[20px] font-[500]">{boostingDetail?.totalDailyBoosts || "0"}</p>
                  {user?.isBoostUsed ? user?.boostType === "daily" ?
                    <span className="bg-[#134896] px-7 py-2 text-white text-[12px] rounded-full h-fit">Active</span>
                    : null :
                    <button type="button" onClick={e => handleUseBoosting("daily")} disabled={boostingDetail?.totalDailyBoosts === 0} className="bg-[#134896] px-7 py-2 disabled:cursor-not-allowed text-white text-[12px] rounded-full h-fit hover:opacity-[90%]">Use</button>
                  }
                </div>
                <div className="bg-white rounded-lg text-center p-5">
                  <img src="/assets/img/boost_2.png" className="w-[65px] h-[65px] object-contain mx-auto mb-2" />
                  <h3 className="text-[18px] sm:text-[17px] text-[#134896] font-[500]">Weekly Boosting</h3>
                  <p className="text-[#7c7c7c] my-2 text-[20px] font-[500]">{boostingDetail?.totalWeeklyBoosts || "0"}</p>
                  {user?.isBoostUsed ? user?.boostType === "weekly" ?
                    <span className="bg-[#134896] px-7 py-2 text-white text-[12px] rounded-full h-fit">Active</span>
                    : null :
                    <button type="button" onClick={e => handleUseBoosting("weekly")} disabled={boostingDetail?.totalWeeklyBoosts === 0} className="bg-[#134896] px-7 py-2 disabled:cursor-not-allowed text-white text-[12px] rounded-full h-fit hover:opacity-[90%]">Use</button>
                  }
                </div>
                <div className="bg-white rounded-lg text-center p-5">
                  <img src="/assets/img/boost_3.png" className="w-[65px] h-[65px] object-contain mx-auto mb-2" />
                  <h3 className="text-[18px] sm:text-[17px] text-[#134896] font-[500]">Daily Boosting Price </h3>
                  <p className="text-[#7c7c7c] my-2 text-[20px] font-[500]">{user?.activePlanId?.dailyBoostingPrice ? `$${user?.activePlanId?.dailyBoostingPrice}` : ""}</p>
                  <div className="">
                    <button type="button" onClick={e => handlePurchaseBoosting("daily", "dailyBoostingPrice")} className="bg-[#134896] px-7 py-2 text-white text-[12px] rounded-full h-fit hover:opacity-[90%]">Buy</button>
                  </div>
                </div>
                <div className="bg-white rounded-lg text-center p-5">
                  <img src="/assets/img/boost_4.png" className="w-[65px] h-[65px] object-contain mx-auto mb-2" />
                  <h3 className="text-[18px] sm:text-[17px] text-[#134896] font-[500]">Weekly Boosting Price</h3>
                  <p className="text-[#7c7c7c] my-2 text-[20px] font-[500]">{user?.activePlanId?.weeklyBoostingPrice ? `$${user?.activePlanId?.weeklyBoostingPrice}` : ""}</p>
                  <div className="">
                    <button type="button" onClick={e => handlePurchaseBoosting("weekly", "weeklyBoostingPrice")} className="bg-[#134896] px-7 py-2 text-white text-[12px] rounded-full h-fit hover:opacity-[90%]">Buy</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#F1F1F1] p-5 rounded-[15px] mb-10 max-w-[980px] mx-auto">
              <h2 className="text-[22px] sm:text-[24px] font-[500] text-[#134896] border-b border-[#cacaca] pb-5">BOOSTING HISTORY</h2>
              <div className="pb-4">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="whitespace-nowrap">
                      <tr className="border-b border-[#cacaca]">
                        <th className="p-4 text-left text-[15px] text-[#134896]">Start Date & Time</th>
                        <th className="p-4 text-left text-[15px] text-[#134896]">End Date & Time</th>
                        <th className="p-4 text-left text-[15px] text-[#134896]">Type</th>
                        <th className="p-4 text-left text-[15px] text-[#134896]">Category</th>
                      </tr>
                    </thead>
                    <tbody className="whitespace-nowrap">
                      {boostingListing?.map((item, index) => {
                        return <tr className="odd:bg-blue-50" key={index}>
                          <td className="p-4 text-sm text-gray-800">{moment(item?.startDate).format("DD MMM, YYYY hh:mm A")}</td>
                          <td className="p-4 text-sm text-gray-800">{moment(item?.endDate).format("DD MMM, YYYY hh:mm A")}</td>
                          <td className="p-4 text-sm text-gray-800 capitalize">{item?.boostType || "--"}</td>
                          <td className="p-4 text-sm text-gray-800 capitalize">{item?.usedCategory || "--"}</td>
                        </tr>
                      })}
                      {boostingListing?.length === 0 &&
                        <tr className="odd:bg-blue-50">
                          <td colspan="4" className="p-4 text-sm text-gray-800 text-center">Boosting Not Used Yet</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                  </div>

                  {total > filter?.count ?
                    <div className="pagination_div w-[100%] text-right mx-auto">
                      <Pagination
                        activePage={filter?.page}
                        itemsCountPerPage={filter?.count}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        hideFirstLastPages
                        prevPageText="Previous"
                        nextPageText="Next"
                        itemClass="bg-white px-2 text-[#8f8f8f] rounded-md"
                        activeClass="!bg-[#005AAB] px-2 !text-white rounded-md"
                        onChange={e => handlePagination(e)}
                      />
                    </div>
                    : null}

              </div>
            </div>
          </div>
        </div>
      </div>
      <PurchasedModal popUp={popUp} setPopUp={setPopUp} user={user} />
      <UseModal useModal={useModal} setUseModal={setUseModal} user={user} getBoostingDetails={getBoostingDetails} getBoostingListing={getBoostingListing} />
      <PopUp popUp={modal} setPopUp={setModal} redirectUrl={modal?.redirectUrl} />
    </>
  )
}

export default Boosting