import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "antd";
import FormControl from "../../common/FormControl";
import shared from "./shared";
import { IoCloseOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import SelectDropdown from "../../common/SelectDropdown";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import DatePicker from "react-datepicker";
import { IoIosAddCircleOutline, IoIosArrowBack, IoMdAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { toast } from "react-toastify";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import environment from "../../environment";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { useSelector } from "react-redux";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { Dialog, DialogPanel } from '@headlessui/react'
import Select from "react-select"

const AddEdit = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.user)
  const [form, setform] = useState({
    title: "",
    images: [],
    description: "",
    isOnline: true,
    link: "",
    host: user?.role === "host" ? [user?._id || user?.id] : [],
    venue: user?.role === "venue" ? user?._id || user?.id : "",
    coHost: [],
    vendors: [],
    category: "",
    subCategory: "",
    tickets: "",
    price_of_ticket: "",
    eventStartDate: "",
    eventEndDate: "",
    menu_item_format: "",
    foods: [{ item: "", price: "", image: "" }],
    drinks: [{ item: "", price: "", image: "" }],
    foodImages: [],
    type: "",
    platformFeeType: ""
  })
  const history = useNavigate()
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setsubCategoryOptions] = useState([])
  const [hostOptions, setHostOptions] = useState([])
  const [collabrationListOptions, setCollabrationListOptions] = useState([])
  const [collabrationCoHostOptions, setCollabrationCoHostOptions] = useState([])
  const [collabrationVendorOptions, setcollabrationVendorOptions] = useState([])
  const [coHostOptions, setCoHostOptions] = useState([])
  const [vendorOptions, setvendorOptions] = useState([])
  const [venueLocations, setVenueLocations] = useState([])
  const [tab, setTab] = useState(0)
  const [popUp, setPopUp] = useState({ modal: false, host: "", coHost: [], vendors: [], type: "" })

  const platformFeeOptions = [
    { id: "additional", name: "Additional" },
    { id: "absorb", name: "Absorb" }
  ]

  const foodFormatOptions = [
    { id: "manual", name: "Manual" },
    { id: "upload", name: "Upload" },
  ]

  const audienceOptions = [
    { id: "18+", name: "18+" },
    { id: "21+", name: "21+" },
  ]
  const extraFoodOptions = [
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" }
  ]
  const typeOption = [
    { id: "paid", name: "Paid" },
    { id: "free", name: "Free" }
  ]

  useEffect(() => {
    getAllCategories()
    getAllSubCategories()
    if (user?.role === "venue") getAllHost()
    getCollabrationList()
    getCoHostOptions()
    getVendorOptions()
    if (id) {
      loader(true);
      ApiClient.get(shared.detailApi, { id }).then((res) => {
        if (res.success) {
          setform({
            title: res?.payload?.title,
            images: res?.payload?.images || [],
            description: res?.payload?.description || "",
            link: res?.payload?.link,
            category: res?.payload?.category?.id || res?.payload?.category?._id,
            subCategory: res?.payload?.subCategory?.map((item) => item?._id || item?.id),
            tickets: String(res?.payload?.tickets || ""),
            host: res?.payload?.host?.map((item) => item?.id || item?._id),
            venue: res?.payload?.venue?.id || res?.payload?.venue?._id,
            coHost: res?.payload?.coHost?.map((item) => ({ label: item?.fullName, value: item?._id || item?.id })),
            vendors: res?.payload?.vendors?.map((item) => ({ label: item?.fullName, value: item?._id || item?.id })),
            eventStartDate: res?.payload?.eventStartDate ? new Date(res?.payload?.eventStartDate) : "",
            eventEndDate: res?.payload?.eventEndDate ? new Date(res?.payload?.eventEndDate) : "",
            foods: res?.payload?.foods || [{ item: "", price: "", image: "" }],
            drinks: res?.payload?.drinks || [{ item: "", price: "", image: "" }],
            menu_item_format: res?.payload?.menu_item_format,
            foodImages: res?.payload?.foodImages || [],
            type: res?.payload?.type,
            platformFeeType: res?.payload?.platformFeeType,
            venue_location: res?.payload?.venue_location,
            location: res?.payload?.location,
            country: res?.payload?.country,
            state: res?.payload?.state,
            city: res?.payload?.city,
            zipCode: res?.payload?.zipCode,
            short_description: res?.payload?.short_description,
            audience: res?.payload?.audience,
            attention: res?.payload?.attention,
            extraFood: res?.payload?.extraFood,
            id: res?.payload?.id || res?.payload?._id
          })
          setVenueLocations(res?.payload?.venue?.locations?.map((item) => {
            return ({ ...item, id: item?.location, name: item?.location })
          }))
        }
        loader(false);
      });
    } else {
      let venueLocation = []
      if (user?.role === "venue") {
        venueLocation = user?.locations?.length > 0 ? user?.locations?.map((item) => { return ({ ...item, id: item?.location, name: item?.location }) }) : []
      }
      setVenueLocations(venueLocation)
    }
  }, [])

  const getAllCategories = () => {
    ApiClient.get('category/listing?status=active&sortBy=name asc').then(res => {
      if (res.success) {
        setCategoryOptions(res?.data?.map((item) => {
          return ({ id: item?.id || item?._id, name: item?.name })
        }))
      }
    })
  }
  const getAllSubCategories = (id = "") => {
    ApiClient.get(`sub/listing?status=active&sortBy=name asc&categoryId=${id || ""}`).then(res => {
      if (res.success) {
        setsubCategoryOptions(res?.data?.map((item) => {
          return ({ id: item?.id || item?._id, name: item?.name })
        }))
      }
    })
  }
  const getAllHost = () => {
    ApiClient.get('user/listing?status=active&role=host&sortBy=fullName asc').then(res => {
      if (res.success) {
        setHostOptions(res?.data?.map((item) => {
          return ({ id: item?.id || item?._id, name: item?.fullName || item?.firstName })
        }))
      }
    })
  }
  const getCollabrationList = () => {
    ApiClient.get(`users/collaborated/listing?id=${user?._id || user?.id}&type=${user?.role}`).then(res => {
      if (res.success) {
        const influencersList = []
        const crewList = []
        const hostVenueList = []
        const vendorList = []
        const foodTruckList = []
        res?.data?.map((item) => {
          if (item?.[findKey(item)]?.role === "influencer") {
            influencersList.push(item?.[findKey(item)])
          } else if (item?.[findKey(item)]?.role === "crew") {
            crewList.push(item?.[findKey(item)])
          } else if (item?.[findKey(item)]?.role === "vendor") {
            vendorList.push(item?.[findKey(item)])
          } else if (item?.[findKey(item)]?.role === "food_truck_vendor") {
            foodTruckList.push(item?.[findKey(item)])
          } else if (item?.[findKey(item)]?.role === "host" || item?.[findKey(item)]?.role === "venue") {
            hostVenueList.push(item?.[findKey(item)])
          } else {
            return
          }
        })
        setCollabrationListOptions(hostVenueList?.map((item) => {
          return ({ id: item?._id || item?.id, name: item?.venue_name || item?.fullName || `${item?.firstName || ''} ${item?.lastName || ''}` })
        }))
        setCollabrationCoHostOptions([
          {
            label: "Influencers", options: influencersList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          },
          {
            label: "Entertainment Crew", options: crewList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          }
        ])
        setcollabrationVendorOptions([
          {
            label: "Vendor", options: vendorList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          },
          {
            label: "Food Truck Vendor", options: foodTruckList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          }
        ])
      }
    })
  }

  const findKey = (item) => {
    let key = "addedBy"
    if (user?.role === item?.addedBy?.role) {
      key = "addedFor"
    }
    return key
  }

  const getCoHostOptions = () => {
    ApiClient.get(`user/influencer/listing`).then(res => {
      if (res.success) {
        const influencersList = res?.data?.filter((item) => item?.role === "influencer")
        const crewList = res?.data?.filter((item) => item?.role === "crew")
        setCoHostOptions([
          {
            label: "Influencers", options: influencersList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          },
          {
            label: "Entertainment Crew", options: crewList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          }
        ])
      }
    })
  }

  const getVendorOptions = () => {
    ApiClient.get(`user/vendor/foodvendor/listing`).then(res => {
      if (res.success) {
        const vendorList = res?.data?.filter((item) => item?.role === "vendor")
        const foodTruckList = res?.data?.filter((item) => item?.role === "food_truck_vendor")
        setvendorOptions([
          {
            label: "Vendor", options: vendorList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          },
          {
            label: "Food Truck Vendor", options: foodTruckList?.map((item) => {
              return ({ label: item?.fullName, value: item?._id || item?.id, role: item?.role })
            })
          }
        ])
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tab !== 3) {
      setTab(tab + 1)
      return
    }
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      isOnline: form?.isOnline ? true : false,
      subCategory: form?.subCategory?.length > 0 ? form?.subCategory : [],
      coHost: form?.coHost?.map((item) => item?.value),
      vendors: form?.vendors?.map((item) => item?.value)
    }
    if (id) {
      method = "put";
      url = shared.editApi;
    } else {
      delete value.id;
      value = { ...value, addedType: "self" }
    }
    if (form?.type === "free") {
      value.isOnline = false
    }
    if (form?.extraFood === 'no') {
      delete value?.foods
      delete value?.drinks
      delete value?.foodImages
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        history(`/${shared.url}`);
      }
      loader(false);
    });
  }

  const ImagesUpload = (e, key) => {
    let files = Array.from(e.target.files);
    const acceptedTypes = ['image/jpeg', 'image/png']; // Add more image types if needed
    const sizeLimits = { min: 0, max: 3 }; // Adjust size limits as necessary (in MB)
    const maxImages = 10 - Number(form?.[key]?.length || 0)

    const { filteredFiles, invalidFiles, errorMsg } = methodModel.multipleImageValidation(files, acceptedTypes, sizeLimits, maxImages);
    if (errorMsg) {
      toast.error(errorMsg);
    }
    if (filteredFiles.length === 0) {
      return;
    }

    loader(true)
    let images = form?.[key] || []
    ApiClient.multiImageUpload('upload/multiple-images', filteredFiles, {}, "files").then(res => {
      if (res.success) {
        const data = res?.files?.map((item) => item?.fileName)
        images.push(...data)
        setform({ ...form, [key]: images })
      }
      loader(false)
    })
  }

  const handleImageDelete = (index, key) => {
    const data = [...form?.[key]]
    data.splice(index, 1)
    setform({ ...form, [key]: data })
  }

  const handleAddMoreInput = (e, index, key, object) => {
    let data = form?.[object]
    data[index][key] = e
    setform((prev) => ({ ...prev, [object]: data }))
  }
  const handleAddMore = (key) => {
    const data = [...form?.[key], { item: "", price: "", image: "" }]
    setform((prev) => ({ ...prev, [key]: data }))
  }
  const handleRemoveAddMore = (index, key) => {
    const data = form?.[key]?.filter((item, i) => i !== index)
    setform((prev) => ({ ...prev, [key]: data }))
  }
  const ImageUpload = (e, index, key, object) => {
    let files = e.target.files
    let file = files.item(0)
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
    if (!allowedTypes.includes(file.type)) {
      toast.error("Only JPG and PNG images are allowed.");
      return;
    }
    let data = form?.[object]
    loader(true)
    ApiClient.postFormData('upload/image', { file: file }).then(res => {
      if (res.success) {
        data[index][key] = res?.fileName
        setform((prev) => ({ ...prev, [object]: data }))
      }
      loader(false)
    })
  }
  const handleMenuImage = (index, key, object) => {
    let data = form?.[object]
    data[index][key] = ""
    setform((prev) => ({ ...prev, [object]: data }))
  }

  const handleVenue = (e) => {
    const venueDetail = collabrationListOptions?.find((item) => item?.id === e?.value)
    setVenueLocations(venueDetail?.venue_id?.locations?.map((item) => {
      return ({ ...item, id: item?.location, name: item?.location })
    }))
    setform((prev) => ({
      ...prev,
      venue: venueDetail?.id,
    }))
  }

  const handleLocation = (place) => {
    const addressComponents = place?.address_components;
    const address = {};

    for (let i = 0; i < addressComponents?.length; i++) {
      const component = addressComponents[i];
      const types = component.types;
      if (types.includes('country')) {
        address.country = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        address.state = component.long_name;
      }
      if (types.includes('locality')) {
        address.city = component.long_name;
      }
      if (types.includes('postal_code')) {
        address.zipCode = component.long_name;
      }
    }

    address.location = place?.formatted_address
    address.coordinates = {
      type: "Point",
      coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
    }
    setform((prev) => ({ ...prev, ...address }))
  }
  const handleVenueLocations = (e) => {
    const venueDetail = venueLocations?.find((item) => item?.id === e?.value)
    setform((prev) => ({
      ...prev,
      venue_location: venueDetail?.id,
      location: venueDetail?.location,
      country: venueDetail?.country,
      state: venueDetail?.state,
      city: venueDetail?.city,
      zipCode: venueDetail?.zipCode,
      coordinates: venueDetail?.coordinates
    }))
  }

  const getEndTime = (hours, min) => {
    // Get the current date
    let now = new Date();
    // Extract the current date parts (year, month, and day)
    let year = now.getFullYear();
    let month = now.getMonth(); // Note: January is 0, December is 11
    let day = now.getDate();
    // Create a new Date object for hours min on the current date
    let dateWithTime = new Date(year, month, day, hours, min, 0);
    return dateWithTime;
  }
  const addTenMinutes = (date) => {
    // Ensure the argument is a Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    // Create a new Date object to avoid mutating the original date
    let newDate = new Date(date);
    // Add 10 minutes to the new date
    newDate.setMinutes(newDate.getMinutes() + 10);
    return newDate;
  }

  const handleCategoryField = (e) => {
    setform({ ...form, category: e, subCategory: "" })
    getAllSubCategories(e)
  }

  const handlePopUp = (e) => {
    e.preventDefault()
    if (popUp?.type === "Hosts") {
      const findHostDetail = hostOptions?.filter((item) => popUp?.host?.some((itm) => itm === item?.id))
      setCollabrationListOptions((prev) => ([...prev, ...findHostDetail]))
      setform((prev) => ({ ...prev, host: popUp?.host }))
    } else if (popUp?.type === "Vendor") {
      const findVendorDetail = collabrationVendorOptions?.map((item) => {
        if (item?.label === "Food Truck Vendor") {
          const data = [...item?.options, ...popUp?.vendors?.filter((itm) => itm?.role === "food_truck_vendor")]
          const uniqueData = data.filter((value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
          );
          return ({ ...item, options: uniqueData })
        }
        if (item?.label === "Vendor") {
          const data = [...item?.options, ...popUp?.vendors?.filter((itm) => itm?.role === "vendor")]
          const uniqueData = data.filter((value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
          );
          return ({ ...item, options: uniqueData })
        }

      })
      setcollabrationVendorOptions(findVendorDetail)
      setform((prev) => ({ ...prev, vendors: popUp?.vendors }))
    } else {
      const findCoHostDetail = collabrationCoHostOptions?.map((item) => {
        if (item?.label === "Influencers") {
          const data = [...item?.options, ...popUp?.coHost?.filter((itm) => itm?.role === "influencer")]
          const uniqueData = data.filter((value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
          );
          return ({ ...item, options: uniqueData })
        }
        if (item?.label === "Entertainment Crew") {
          const data = [...item?.options, ...popUp?.coHost?.filter((itm) => itm?.role === "crew")]
          const uniqueData = data.filter((value, index, self) =>
            index === self.findIndex((t) => t.value === value.value)
          );
          return ({ ...item, options: uniqueData })
        }
      })
      setCollabrationCoHostOptions(findCoHostDetail)
      setform((prev) => ({ ...prev, coHost: popUp?.coHost }))
    }
    setPopUp({ modal: false, host: "", coHost: "", vendors: "", type: "" })
  }

  return (
    <>
      <div className="">
        <div className="bg-white w-full h-screen">
          <DesktopSidebar />
          <form onSubmit={handleSubmit} autoComplete="one-time-code" className="add_page w-full p-5">
            <div className="">
              <div className="flex items-center mb-4">
                <Tooltip placement="top" title="Back">
                  <Link to={`/${shared.url}`} className="!px-2 py-2 flex items-center justify-center rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all bg-white mr-3">
                    <IoIosArrowBack />
                  </Link>
                </Tooltip>
                <div>
                  <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                    {form && form.id ? "Edit" : "Add"} {shared.addTitle}
                  </h3>
                </div>
              </div>
              <div className="border-[1px] rounded-[15px] p-5 gap-6">
                <div className="flex w-full justify-center ">
                  <div className="w-full">
                    <TabGroup selectedIndex={tab} onChange={e => setTab(e)}>
                      <TabList className="flex gap-4 border-b-[1px] border-black/10 mb-8 overflow-auto scroll_hide">
                        <Tab
                          key="Info"
                          className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap"
                        >
                          Info
                        </Tab>
                        <Tab
                          key="Gallery"
                          className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap"
                        >
                          Gallery
                        </Tab>
                        <Tab
                          key="EventDetail"
                          className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap"
                        >
                          Event Detail
                        </Tab>
                        <Tab
                          key="Food"
                          className="pb-2 px-3 text-sm/6 data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-[#0063a3] font-[300] data-[selected]:font-[600] whitespace-nowrap"
                        >
                          Food & Drinks
                        </Tab>

                      </TabList>
                      <TabPanels className="mt-3">
                        <TabPanel key="Info" className="">
                          <ul>
                            <li key="Info" className="relative rounded-md text-sm/6 transition">
                              <div className="">
                                <div className="grid grid-cols-12 gap-4">
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <FormControl
                                      type="text"
                                      name="title"
                                      label="Event Name"
                                      placeholder="Enter Event Name"
                                      autoComplete="one-time-code"
                                      value={form?.title}
                                      onChange={(e) => setform({ ...form, title: e })}
                                      required
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <label>Category <span className="text-red-600">*</span></label>
                                    <SelectDropdown
                                      id="statusDropdown"
                                      displayValue="name"
                                      className="mt-1 capitalize"
                                      placeholder="Select Category"
                                      theme="search"
                                      intialValue={form?.category}
                                      result={(e) => handleCategoryField(e?.value)}
                                      options={categoryOptions}
                                      isClearable={false}
                                      required
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <label>Sub Category </label>
                                    <MultiSelectDropdown
                                      id="statusDropdown"
                                      placeholder="Select Sub Category"
                                      intialValue={form?.subCategory}
                                      className="mt-1 capitalize"
                                      result={(e) => setform({ ...form, subCategory: e?.value })}
                                      options={subCategoryOptions}
                                    />
                                  </div>
                                  {user?.role === "host" &&
                                    <>
                                      <div className="col-span-12 lg:col-span-6 mb-3">
                                        <label>Venue <span className="text-red-600">*</span></label>
                                        <SelectDropdown
                                          id="statusDropdown"
                                          displayValue="name"
                                          className="mt-1 capitalize"
                                          placeholder="Select Venue"
                                          theme="search"
                                          intialValue={form?.venue}
                                          result={(e) => handleVenue(e)}
                                          options={collabrationListOptions}
                                          isClearable={false}
                                          required
                                        />
                                      </div>
                                    </>
                                  }
                                  {venueLocations?.length > 0 ?
                                    <div className="col-span-12 lg:col-span-6 mb-3">
                                      <label>Venue Locations</label>
                                      <SelectDropdown
                                        id="statusDropdown"
                                        displayValue="name"
                                        className="mt-1 capitalize"
                                        placeholder="Select Venue Location"
                                        theme="search"
                                        intialValue={form?.venue_location}
                                        result={(e) => handleVenueLocations(e)}
                                        options={venueLocations}
                                        isClearable={false}
                                      />
                                    </div>
                                    : null}
                                  {user?.role === "venue" &&
                                    <div className="col-span-12 lg:col-span-6 mb-3 flex gap-2 items-end">
                                      <div className="w-full">
                                        <label>Host</label>
                                        <MultiSelectDropdown
                                          id="statusDropdown"
                                          placeholder="Select Hosts"
                                          intialValue={form?.host}
                                          className="mt-1 capitalize"
                                          result={(e) => setform({ ...form, host: e?.value })}
                                          options={collabrationListOptions}
                                        />
                                      </div>
                                      <Tooltip title="Add New Hosts">
                                        <div onClick={e => setPopUp((prev) => ({ ...prev, modal: true, type: "Hosts" }))} className="p-2 text-white cursor-pointer bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm">
                                          <IoIosAddCircleOutline size={25} />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  }
                                  <div className="col-span-12 lg:col-span-6 mb-3 flex gap-2 items-end">
                                    <div className="w-full">
                                      <label>Co-host</label>
                                      <Select
                                        placeholder="Select Co-host"
                                        className="text-capitalize"
                                        value={form?.coHost}
                                        onChange={(e) => setform({ ...form, coHost: e })}
                                        isClearable
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={collabrationCoHostOptions}
                                      />
                                    </div>
                                    <Tooltip title="Add New Co-Hosts">
                                      <div onClick={e => setPopUp((prev) => ({ ...prev, modal: true, type: "Co-Hosts" }))} className="p-2 text-white cursor-pointer bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm">
                                        <IoIosAddCircleOutline size={25} />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3 flex gap-2 items-end">
                                    <div className="w-full">
                                      <label>Vendor/Food Truck Vendor</label>
                                      <Select
                                        placeholder="Select Vendor/Food Truck Vendor"
                                        className="text-capitalize"
                                        value={form?.vendors}
                                        onChange={(e) => setform({ ...form, vendors: e })}
                                        isClearable
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={collabrationVendorOptions}
                                      />
                                    </div>
                                    <Tooltip title="Add New Vendor/Food Truck Vendor">
                                      <div onClick={e => setPopUp((prev) => ({ ...prev, modal: true, type: "Vendor" }))} className="p-2 text-white cursor-pointer bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm">
                                        <IoIosAddCircleOutline size={25} />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <label>Event Location <span className="text-red-600">*</span></label>
                                    <ReactGoogleAutocomplete
                                      apiKey={environment?.map_api_key}
                                      onPlaceSelected={(place) => { handleLocation(place) }}
                                      onChange={e => setform({ ...form, location: e.target.value })}
                                      value={form?.location}
                                      options={{
                                        types: ["(regions)"],
                                        componentRestrictions: { country: "us" },
                                      }}
                                      key="venueLocation"
                                      placeholder="Enter Event Location"
                                      required
                                      className="relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036]"
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <FormControl
                                      type="text"
                                      name="country"
                                      label="Country"
                                      placeholder="Enter country"
                                      autoComplete="one-time-code"
                                      value={form?.country}
                                      onChange={(e) => setform({ ...form, country: e })}
                                      required
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <FormControl
                                      type="text"
                                      name="state"
                                      label="State"
                                      placeholder="Enter State"
                                      autoComplete="one-time-code"
                                      value={form?.state}
                                      onChange={(e) => setform({ ...form, state: e })}
                                      required
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <FormControl
                                      type="text"
                                      name="city"
                                      label="City"
                                      placeholder="Enter City"
                                      autoComplete="one-time-code"
                                      value={form?.city}
                                      onChange={(e) => setform({ ...form, city: e })}
                                      required
                                    />
                                  </div>
                                  <div className="col-span-12 lg:col-span-6 mb-3">
                                    <FormControl
                                      type="text"
                                      name="zipCode"
                                      label="Zip Code"
                                      placeholder="Enter Zip Code"
                                      autoComplete="one-time-code"
                                      value={form?.zipCode}
                                      onChange={(e) => setform({ ...form, zipCode: e })}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="Gallery" className="">
                          <ul>
                            <li key="Gallery" className="relative rounded-md text-sm/6 transition">
                              <div className="">
                                <div className="mb-3">
                                  <label>Images (JPG/PNG)</label>
                                  <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                    {form?.images?.length !== 10 &&
                                      <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                        <FiPlus />
                                        <input
                                          id="dropzone-file"
                                          type="file"
                                          className="hidden"
                                          multiple
                                          onChange={e => ImagesUpload(e, "images")}
                                        />
                                        Upload Images
                                      </label>
                                    }
                                    {form?.images?.length > 0 ?
                                      <span className="flex flex-wrap gap-3">
                                        {form?.images?.map((item, index) => {
                                          return <div className="relative" key={index}>
                                            <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                            <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, "images")} size={25} />
                                          </div>
                                        })}
                                      </span> : null
                                    }
                                  </div>
                                </div>
                                <div className="col-span-2 mb-3">
                                  <FormControl
                                    type="editor"
                                    name="short_description"
                                    label="Short Description"
                                    value={form?.short_description}
                                    onChange={(e) => setform((prev) => ({ ...prev, short_description: e }))}
                                  />
                                </div>
                                <div className="col-span-2 mb-3">
                                  <FormControl
                                    type="editor"
                                    name="description"
                                    label="Long Description"
                                    value={form?.description}
                                    onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="EventDetail" className="">
                          <ul>
                            <li key="EventDetail" className="relative rounded-md text-sm/6 transition">
                              <div className="">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                  {/* <div className="mb-3">
                                    <FormControl
                                      type="text"
                                      name="link"
                                      label="URL"
                                      placeholder="Enter URL"
                                      autoComplete="one-time-code"
                                      value={form?.link}
                                      onChange={(e) => setform({ ...form, link: e })}
                                      required
                                    />
                                  </div> */}
                                  <div className="mb-3">
                                    <label>Event Type <span className="text-red-600">*</span></label>
                                    <SelectDropdown
                                      id="statusDropdown"
                                      displayValue="name"
                                      className="mt-1"
                                      placeholder="Select Event Type"
                                      theme="search"
                                      intialValue={form?.type}
                                      result={(e) => setform({ ...form, type: e?.value })}
                                      options={typeOption}
                                      isClearable={false}
                                      required
                                    />
                                  </div>
                                  {form?.type === "free" &&
                                    <div className="mb-3">
                                      <FormControl
                                        type="number"
                                        name="tickets"
                                        label="Audience Capacity"
                                        placeholder={`Enter Audience Capacity`}
                                        autoComplete="one-time-code"
                                        maxlength={10}
                                        value={form?.tickets}
                                        onChange={(e) => setform({ ...form, tickets: e })}
                                        required
                                      />
                                    </div>
                                  }
                                  {form?.type === "paid" &&
                                    <div className="mb-3">
                                      <label>Platform Fee Type<span className="text-red-600">*</span></label>
                                      <SelectDropdown
                                        id="statusDropdown"
                                        displayValue="name"
                                        className="mt-1"
                                        placeholder="Select Platform Fee Type"
                                        theme="search"
                                        intialValue={form?.platformFeeType}
                                        isClearable={false}
                                        result={(e) => setform({ ...form, platformFeeType: e?.value })}
                                        options={platformFeeOptions}
                                        required
                                      />
                                    </div>
                                  }
                                  <div className="mb-3">
                                    <label className="text-sm mb-2 block">Event Start Date & Time <span className="text-red-600">*</span></label>
                                    <div>
                                      <DatePicker
                                        selected={form?.eventStartDate}
                                        onChange={(date) => setform((prev) => ({ ...prev, eventStartDate: date, eventEndDate: "" }))}
                                        className="relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] "
                                        minDate={new Date()}
                                        dateFormat="MM/dd/yyyy hh:mm a"
                                        placeholderText="Event Start Date & Time"
                                        showTimeSelect
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label className="text-sm mb-2 block">Event End Date & Time <span className="text-red-600">*</span></label>
                                    <div>
                                      <DatePicker
                                        selected={form?.eventEndDate}
                                        onChange={(date) => setform((prev) => ({ ...prev, eventEndDate: date }))}
                                        className="relative bg-[#f9f9f9] w-full h-10 text-[14px] text-[#333] overflow-hidden px-2 border border-[#00000036] "
                                        minDate={form?.eventStartDate || new Date()}
                                        minTime={form?.eventStartDate ? addTenMinutes(form?.eventStartDate) : getEndTime(0, 0)}
                                        dateFormat="MM/dd/yyyy hh:mm a"
                                        placeholderText="Event End Date & Time"
                                        maxTime={getEndTime(23, 50)}
                                        showTimeSelect
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label>Audience <span className="text-red-600">*</span></label>
                                    <SelectDropdown
                                      id="statusDropdown"
                                      displayValue="name"
                                      className="mt-1"
                                      placeholder="Select Audience"
                                      theme="search"
                                      intialValue={form?.audience}
                                      result={(e) => setform({ ...form, audience: e?.value })}
                                      options={audienceOptions}
                                      isClearable={false}
                                      required
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <FormControl
                                      type="textarea"
                                      name="attention"
                                      label="Attention"
                                      placeholder={"Enter attention"}
                                      autoComplete="one-time-code"
                                      value={form?.attention}
                                      onChange={(e) => setform({ ...form, attention: e })}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                        <TabPanel key="Food" className="">
                          <ul>
                            <li key="Food" className="relative rounded-md text-sm/6 transition">
                              <div className="">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                  <div className="mb-3">
                                    <label>Special Food <span className="text-red-600">*</span></label>
                                    <SelectDropdown
                                      id="statusDropdown"
                                      displayValue="name"
                                      className="mt-1"
                                      placeholder="Select Special Food"
                                      theme="search"
                                      intialValue={form?.extraFood}
                                      result={(e) => setform({ ...form, extraFood: e?.value })}
                                      options={extraFoodOptions}
                                      isClearable={false}
                                      required
                                    />
                                  </div>
                                  {form?.extraFood === 'yes' &&
                                    <div className="mb-3">
                                      <label>Menu Format <span className="text-red-600">*</span></label>
                                      <SelectDropdown
                                        id="statusDropdown"
                                        displayValue="name"
                                        className="mt-1"
                                        placeholder="Select Choose Format"
                                        theme="search"
                                        intialValue={form?.menu_item_format}
                                        result={(e) => setform({ ...form, menu_item_format: e?.value })}
                                        options={foodFormatOptions}
                                        isClearable={false}
                                        required
                                      />
                                    </div>
                                  }
                                </div>
                                {form?.extraFood === 'yes' &&
                                  <>
                                    {form?.menu_item_format === "manual" &&
                                      <>
                                        <div className="mb-3">
                                          <label>Foods</label>
                                          <div className="grid grid-cols-12 gap-3">
                                            <div className="col-span-12">
                                              {form?.foods?.map((itm, index) => {
                                                return <div key={index} className="flex gap-4 mb-3">
                                                  <FormControl
                                                    type="text"
                                                    name="foods"
                                                    placeholder="Enter Item"
                                                    autoComplete="one-time-code"
                                                    value={itm?.item}
                                                    onChange={(e) => handleAddMoreInput(e, index, "item", "foods")}
                                                    required
                                                  />
                                                  <FormControl
                                                    type="number"
                                                    name="price"
                                                    placeholder="Enter Price ($)"
                                                    autoComplete="one-time-code"
                                                    className=""
                                                    value={itm?.price}
                                                    onChange={(e) => handleAddMoreInput(e, index, "price", "foods")}
                                                    maxlength={10}
                                                    required
                                                  />
                                                  <div className="flex gap-4">
                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[180px] gap-6 max-sm:mx-auto">
                                                      {itm?.image ? (
                                                        <>
                                                          <div className="relative flex flex-wrap gap-3 mt-3">
                                                            <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                            <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "foods")} size={25} />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                          <FiPlus />
                                                          <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            className="hidden"
                                                            onChange={e => ImageUpload(e, index, "image", "foods")}
                                                          />
                                                          Upload Image
                                                        </label>
                                                      )}
                                                    </div>
                                                    {form?.foods?.length > 1 &&
                                                      <div className="bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "foods")}><GrSubtractCircle size={20} /></div>
                                                    }
                                                  </div>
                                                </div>
                                              })}
                                            </div>
                                            <div className="col-span-12 flex justify-end">
                                              <div className="bg-[#063688] p-3 text-white rounded-lg cursor-pointer w-fit" onClick={e => handleAddMore("foods")}>
                                                <IoMdAddCircleOutline size={20} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-3">
                                          <label>Drinks</label>
                                          <div className="grid grid-cols-12 gap-3">
                                            <div className="col-span-12">
                                              {form?.drinks?.map((itm, index) => {
                                                return <div key={index} className="flex gap-4 mb-3">
                                                  <FormControl
                                                    type="text"
                                                    name="drinks"
                                                    placeholder="Enter Item"
                                                    autoComplete="one-time-code"
                                                    value={itm?.item}
                                                    onChange={(e) => handleAddMoreInput(e, index, "item", "drinks")}
                                                    required
                                                  />
                                                  <FormControl
                                                    type="number"
                                                    name="price"
                                                    placeholder="Enter Price ($)"
                                                    autoComplete="one-time-code"
                                                    className=""
                                                    value={itm?.price}
                                                    onChange={(e) => handleAddMoreInput(e, index, "price", "drinks")}
                                                    maxlength={10}
                                                    required
                                                  />
                                                  <div className="flex gap-4">
                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[180px] gap-6 max-sm:mx-auto">
                                                      {itm?.image ? (
                                                        <>
                                                          <div className="relative flex flex-wrap gap-3 mt-3">
                                                            <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                            <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "drinks")} size={25} />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                          <FiPlus />
                                                          <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            className="hidden"
                                                            onChange={e => ImageUpload(e, index, "image", "drinks")}
                                                          />
                                                          Upload Image
                                                        </label>
                                                      )}
                                                    </div>
                                                    {form?.drinks?.length > 1 &&
                                                      <div className="bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "drinks")}><GrSubtractCircle size={20} /></div>
                                                    }
                                                  </div>
                                                </div>
                                              })}
                                            </div>
                                            <div className="col-span-12 flex justify-end">
                                              <div className="bg-[#063688] p-3 text-white rounded-lg cursor-pointer w-fit" onClick={e => handleAddMore("drinks")}>
                                                <IoMdAddCircleOutline size={20} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    }
                                    {form?.menu_item_format === "upload" &&
                                      <>
                                        <label>Upload Food Images (JPG/PNG)</label>
                                        <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                          {form?.foodImages?.length !== 5 &&
                                            <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px] `} style={{ gap: '8px' }}>
                                              <FiPlus />
                                              <input
                                                id="dropzone-file"
                                                type="file"
                                                className="hidden"
                                                multiple
                                                onChange={e => ImagesUpload(e, 'foodImages')}
                                              />
                                              Upload Images
                                            </label>
                                          }
                                          {form?.foodImages?.length > 0 ?
                                            <span className="flex flex-wrap gap-3">
                                              {form?.foodImages?.map((item, index) => {
                                                return <div className="relative" key={index}>
                                                  <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                  <IoCloseOutline className="absolute -top-2 -right-2 cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, "foodImages")} size={25} />
                                                </div>
                                              })}
                                            </span> : null
                                          }
                                        </div>
                                      </>
                                    }
                                  </>
                                }
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </div>
                <div className="text-right">
                  {tab !== 0 &&
                    <button type="button" onClick={e => setTab(tab - 1)} className="text-white bg-[#063688] mr-3 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                      Back
                    </button>
                  }
                  <button type="submit" className="text-white bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mt-3">
                    {tab !== 3 ? "Next" : form?.id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog open={popUp?.modal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPopUp((prev) => ({ ...prev, modal: true }))}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
            <DialogPanel transition className={`bg-[url('assets/images/colorful.png')] bg-cover bg-no-repeat w-full max-w-lg rounded-xl backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0`}>
              <div className="bg-[#fff9] rounded-xl p-10 relative">
                <form onSubmit={handlePopUp}>
                  <div className="mb-3">
                    <label>{popUp?.type === "Vendor" ? "Vendor/Food Truck Vendor" : popUp?.type}</label>
                    {popUp?.type === "Hosts" ?
                      <MultiSelectDropdown
                        id="statusDropdown"
                        placeholder="Select Hosts"
                        intialValue={popUp?.host}
                        className="mt-1 capitalize"
                        result={(e) => setPopUp((prev) => ({ ...prev, host: e?.value }))}
                        options={hostOptions}
                        required
                      />
                      :
                      <>
                        {popUp?.type == "Vendor" ?
                          <Select
                            placeholder="Select Vendor/Food Truck Vendor"
                            className="capitalize"
                            value={popUp?.vendors}
                            onChange={(e) => setPopUp((prev) => ({ ...prev, vendors: e }))}
                            isClearable
                            closeMenuOnSelect={false}
                            required
                            isMulti
                            options={vendorOptions}
                          />
                          :
                          <Select
                            placeholder="Select Co-host"
                            className="capitalize"
                            value={popUp?.coHost}
                            onChange={(e) => setPopUp((prev) => ({ ...prev, coHost: e }))}
                            isClearable
                            closeMenuOnSelect={false}
                            required
                            isMulti
                            options={coHostOptions}
                          />}
                      </>

                    }
                  </div>
                  <div className="flex justify-end gap-3">
                    <button type="button" onClick={e => setPopUp((prev) => ({ ...prev, modal: false, host: "", coHost: "", type: "", vendors: "" }))} className="border border-[#063688] text-[#063688] font-medium rounded-full text-sm px-5 py-2.5 mt-3">Back</button>
                    <button type="submit" className="text-white bg-[#063688] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 mt-3">Select</button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddEdit;
