import { useEffect, useRef, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts';

const BoostingPeakTimeCharts = ({ filter }) => {
    const [data, setData] = useState([]);
    const user = useSelector((state) => state.user);
    const chartRef = useRef();

    useEffect(() => {
        getData();
    }, [filter]);

    useEffect(() => {
        renderChart();
    }, [data]);

    const getData = () => {
        ApiClient.get(`boost/analytics/listing/by/time?userId=${user?._id || user?.id}&boostCategory=${filter?.categoryType}&boostType=${filter?.boostType}&startDate=${filter?.startDate}&endDate=${filter?.endDate}`)
            .then((res) => {
                if (res.success) {
                    const formattedData = res?.data?.map((item) => {
                        return [
                            `${item?.hour}:00`, // X-axis: hour (time)
                            item?.dayOfWeek, // Y-axis: dayOfWeek (0 = Sun, 1 = Mon)
                            item?.totalUsers // Value for the heatmap
                        ];
                    });
                    setData(formattedData);
                }
            })
            .catch(error => {
                console.error("API request failed", error);
            });
    };

    const renderChart = () => {
        const chartInstance = echarts.getInstanceByDom(chartRef.current);
        if (chartInstance) {
            chartInstance.dispose();
        }
        const peakScansOption = {
            tooltip: {
                position: "top",
            },
            xAxis: {
                type: "category",
                data: [
                    "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
                    "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "0:00", "1:00",
                    "2:00", "3:00", "4:00", "5:00"
                ],
            },
            yAxis: {
                type: "category",
                data: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                splitArea: {
                    show: true,
                },
            },
            visualMap: {
                min: 0,
                max: 50,
                calculable: false,
                orient: "horizontal",
                left: "center",
                bottom: "0%",
                inRange: {
                    color: ['#004d92']
                },
            },
            series: [
                {
                    name: "Hike",
                    type: "heatmap",
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: "#ffffff",
                        },
                    },
                },
            ],
        };
        const newChartInstance = echarts.init(chartRef.current);
        newChartInstance.setOption(peakScansOption);
    };

    return (
        <>
            <div className="col-md-12 mb-3">
                <div className="border_box">
                    <div ref={chartRef} style={{ height: "400px", width: "100%" }}></div>
                </div>
            </div>
        </>
    );
};

export default BoostingPeakTimeCharts;
