import { useDispatch, useSelector } from "react-redux"
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar"
import methodModel from "../../methods/methods";
import { useEffect, useRef, useState } from "react";
import { socket } from "../../socket";
import loader from "../../methods/loader";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import FormControl from "../../common/FormControl";
import { toast } from "react-toastify";
import { FaFile, FaTrash } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { GoDotFill } from "react-icons/go";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Tooltip } from "antd";
import { login_success } from "../../redux/actions/user";
import PopUp from "../../common/PopUp";


const Chat = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const ConversationIdRef = useRef(null);
  const extensionsAllow = ["jpg", "jpeg", "png", 'mp4', 'webm', 'ogg', 'doc', 'pdf'];
  const [recentChatList, setRecentChatList] = useState([])
  const [userDetail, setUserDetail] = useState()
  const [activeChat, setActiveChat] = useState({ id: "", message: "", chat: { message: [] }, conversationId: "", file: "" })
  const [chatLoader, setChatLoader] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [filter, setFilter] = useState({ page: 1, limit: 5 })
  const chatBackup = useRef()
  const [modal, setModal] = useState({ modal: false, message: "" })

  let venueId = methodModel.getPrams("venueId")
  let postId = methodModel.getPrams("postId")
  let applyPostId = methodModel.getPrams("applyPostId")

  useEffect(() => {
    getRecentChatListing()
    socket.on("message-received", (response => {
      if (response.success) {
        if (response?.newMessage?.conversationId === chatBackup.current._id) {
          let oldChat = chatBackup.current
          let oldMessages = chatBackup.current?.message || []
          oldMessages.push(response?.newMessage)
          const uniqueMessages = oldMessages?.filter((item, index, self) =>
            item?.messageId &&
            self.findIndex(msg => msg.messageId === item.messageId) === index
          );
          oldChat.message = uniqueMessages
          setActiveChat((prev) => ({ ...prev, chat: oldChat }))
          readAllMessage(response?.newMessage?.conversationId, response?.newMessage?.to?._id)
        } else {
          getRecentChatListing()
        }
      }
    }))
  }, [])

  // Scroll to the bottom when messages change
  useEffect(() => {
    const chatContainer = document.getElementById('chatDiv');
    if (chatContainer) {
      chatContainer.scroll({
        top: chatContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [activeChat?.chat?.message]);  // Trigger when messages change

  const getRecentChatListing = () => {
    socket.emit('resent-chat-listing', { senderId: user?._id || user?.id });
  }

  socket.on("chat-listing-response", (response => {
    if (response.success) {
      loader(true)
      setRecentChatList(response?.result)
      if (id && venueId && applyPostId && applyPostId) {
        const findActiveChat = response?.result?.find((item) => item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id === id)?._id
        setActiveChat((prev) => ({ ...prev, conversationId: findActiveChat || "", id: id }))
        loadRecentChat(findActiveChat || "")
      }
      // Checking the recent chat if there is no chat and have id in params so we get that user detail by hitting api and showing active chat to that id
      if (response?.result?.length === 0 || !checkRecentChat(response?.result)) {
        if (id) {
          ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
              setUserDetail(res?.data)
              setActiveChat((prev) => ({ ...prev, id: id }))
            }
            loader(false)
          })
        } else {
          loader(false)
        }
      } else {
        loader(false)
      }
    }
  }))

  const loadRecentChat = (chatId) => {
    if (chatId) {
      if (activeChat?.chat?.message?.length === 0) setChatLoader(true)
      ConversationIdRef.current = chatId
      socket.emit("chat-load", { conversationId: chatId })
    }
  }

  socket.on("messages-loaded", (response => {
    if (response.success) {
      if (response?.allMessages?.members?.includes(id) && response?.allMessages?._id == ConversationIdRef?.current) {
        chatBackup.current = response?.allMessages
        setActiveChat((prev) => ({ ...prev, chat: { ...response?.allMessages }, }))
        if (response?.allMessages?.message?.length > 0 && venueId && postId && applyPostId) {
          venueId = null
          postId = null
          applyPostId = null
          navigate(`/message/${id}`, { replace: true })
        }
      }
    }
    setChatLoader(false)
    loader(false)
  }))

  const handleSendMessage = (e) => {
    e.preventDefault()
    if (user?.activePlanId?.conversationTypePerMonth === "count" && user?.conversationPerMonth >= user?.activePlanId?.conversationPerMonth) {
      setModal({ modal: true, message: "Your current plan limit has been reached. For more conversation, please consider upgrading your plan.", image: "/assets/img/waiting.png", buttonText: "Ok" })
      setActiveChat((prev) => ({ ...prev, message: "", file: "" }))
      return
    }
    setDisableButton(true)
    const venueId = methodModel.getPrams("venueId")
    const postId = methodModel.getPrams("postId")
    const applyPostId = methodModel.getPrams("applyPostId")
    let payload = {
      senderId: user?._id || user?.id,
      recipientId: activeChat?.id,
      message: activeChat?.message || "",
      media: activeChat?.file || "",
    }
    if (activeChat?.conversationId) {
      payload = { ...payload, conversationId: activeChat?.conversationId }
    }
    if (venueId && postId && applyPostId) {
      payload = { ...payload, venueId: venueId, postId: postId, applyPostId: applyPostId }
    }
    socket.emit("new-message", payload)
    socket.on("message-sent", (response) => {
      if (response.success) {
        let oldChat = activeChat?.chat
        let oldMessages = activeChat?.chat?.message || []
        oldMessages.push(response?.newMessage)
        const uniqueMessages = oldMessages?.filter((item, index, self) =>
          item?.messageId &&
          self.findIndex(msg => msg.messageId === item.messageId) === index
        );
        oldChat.message = uniqueMessages
        setActiveChat((prev) => ({ ...prev, chat: oldChat, message: "", file: "", conversationId: response?.conversationId }))
        // loadRecentChat(response?.conversationId)
        if (venueId && postId && applyPostId) {
          navigate(`/message/${id}`, { replace: true })
        }
      }
      setDisableButton(false)
    })
  }

  const readAllMessage = (chatId, userId) => {
    socket.emit("read-all", { conversationId: chatId, recipientId: userId, senderId: user?._id })
    setTimeout(() => {
      dispatch(login_success({ ...user, read_all: Math.random() }))
      getRecentChatListing()
    }, 2000);
  }

  const handleActiveChat = (item) => {
    loader(true)
    setActiveChat((prev) => ({ ...prev, id: item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id, conversationId: item?._id }))
    readAllMessage(item?._id, item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id)
    loadRecentChat(item?._id)
    navigate(`/message/${item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id}`, { replace: true })
  }

  const checkRecentChat = (data) => {
    let value = false
    if (id) {
      value = data?.some((item) => id === item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id)
    } else {
      value = true
    }
    return value
  }

  const uploadFile = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    const fileExtension = file?.name?.split(".").pop().toLowerCase();
    let isAllow = extensionsAllow.includes(fileExtension);
    if (!isAllow) {
      toast.error("Please upload a valid file. Accepted formats include image files in JPG and PNG, video files in MP4, WebM, and OGG, as well as document files in DOC and PDF formats.")
      return
    }
    loader(true)
    let url = checkMediaFormat(file?.name) === "image" ? "upload/image" : checkMediaFormat(file?.name) === "video" ? "upload/video" : "upload/document";
    ApiClient.postFormData(url, { file: file }).then((res) => {
      if (res.success) {
        setActiveChat((prev) => ({ ...prev, file: res?.fileName }))
      }
      loader(false)
    });
  }

  const checkMediaFormat = (file) => {
    let format = ""
    const fileExtension = file?.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      format = "image";
    }
    if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      format = "video";
    }
    if (["doc", "pdf"].includes(fileExtension)) {
      format = "doc"
    }
    return format
  }

  const deleteMessage = (item) => {
    socket.emit("delete-message", { conversationId: ConversationIdRef.current, messageId: item?.messageId })
  }
  socket.on("message-deleted", (response => {
    setActiveChat((prev) => ({ ...prev, chat: response?.allMessages }))
  }))

  return (
    <>
      <div className="bg-[#D9D9D938]">
        <DesktopSidebar />
        <div>
          <div className="container mx-auto px-5">
            <div className="flex antialiased text-gray-800">
              <div className="flex flex-row h-full w-full overflow-x-hidden">
                <div className="py-6 px-3 w-64 bg-white rounded-xl flex-shrink-0 mt-6 mb-6">
                  <div className=" bg-indigo-40 border-b pb-3 w-full">
                    <div className="flex gap-3 items-center">
                      <div className="h-10 w-10 rounded-full border relative">
                        <img src={methodModel.userImg(user?.image)} alt="Avatar" className="h-full w-full rounded-full object-cover" />
                        <GoDotFill className="text-[#00cb00] absolute bottom-[-5px] right-[2px]" />
                      </div>
                      <div>
                        <div className="text-[12px] font-semibold mt-2 capitalize">{user?.role === "venue" ? user?.venue_name : user?.fullName || `${user?.firstName || ""} ${user?.lastName}`}</div>
                        <div className="text-[10px] text-gray-500 capitalize">{user?.role}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-4">
                    <div className="flex flex-col space-y-1 h-[calc(100vh_-_331px)] overflow-y-auto">
                      {recentChatList?.length === 0 ?
                        <>
                          {!checkRecentChat(recentChatList) ?
                            <div className={`flex items-center cursor-pointer flex-row bg-gray-100 rounded-md p-2`}>
                              <div className="relative">
                                <img src={methodModel.userImg(userDetail?.image)} className="h-8 w-8 object-cover rounded-full" />
                                <GoDotFill className="text-[#b9b9b9] absolute bottom-[-4px] right-[0px]" />
                              </div>
                              <div className="ml-2">
                                <div className="text-[13px] font-semibold truncate">{userDetail?.role === "venue" ? userDetail?.venue_name : userDetail?.fullName || `${userDetail?.firstName || ""} ${userDetail?.lastName}`}</div>
                              </div>
                            </div>
                            :
                            <div className="text-center">No Recent Chats</div>
                          }
                        </>
                        :
                        <>
                          {!checkRecentChat(recentChatList) &&
                            <div className={`flex items-center cursor-pointer flex-row bg-gray-100 rounded-md p-2`}>
                              <div className="relative">
                                <img src={methodModel.userImg(userDetail?.image)} className="h-8 w-8 object-cover rounded-full" />
                                <GoDotFill className="text-[#b9b9b9] absolute bottom-[-4px] right-[0px]" />
                              </div>
                              <div className="ml-2">
                                <div className="text-[13px] font-semibold truncate">{userDetail?.role === "venue" ? userDetail?.venue_name : userDetail?.fullName || `${userDetail?.firstName || ""} ${userDetail?.lastName}`}</div>
                              </div>
                            </div>
                          }
                          {recentChatList?.map((item, index) => {
                            return <div onClick={e => handleActiveChat(item)} className={`flex cursor-pointer ${activeChat?.id === item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?._id && "bg-[#eef7ff]"} items-center flex-row hover:bg-[#eef7ff] rounded-md p-2`} key={index}>
                              <div className="relative">
                                <img src={methodModel.userImg(item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?.image)} className="bg-white border h-8 w-8 object-cover rounded-full" />
                                {item?.isOnline?.[item?.isOnline?.[0]?._id === user?._id ? [1] : [0]]?.online ?
                                  <GoDotFill className="text-[#00cb00] absolute bottom-[-4px] right-[0px]" />
                                  :
                                  <GoDotFill className="text-[#b9b9b9] absolute bottom-[-4px] right-[0px]" />
                                }
                              </div>
                              <div className="ml-2">
                                <div className="flex items-center justify-between">
                                  <div className="text-[12px] font-semibold truncate capitalize">
                                    {item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?.role === "venue" ?
                                      item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?.venue_name :
                                      item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?.fullName || item?.message?.[item?.message?.to?._id === user?._id ? "from" : "to"]?.firstName}
                                  </div>
                                  {item?.unReadCount !== 0 &&
                                    <div className="bg-red-500 rounded-full px-2">{item?.unReadCount}</div>
                                  }
                                </div>
                              </div>
                            </div>
                          })}
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-auto h-full p-6">
                  {activeChat?.id ?
                    <div className="flex flex-col flex-auto flex-shrink-0 rounded-xl bg-gray-100 h-full p-4">
                      {chatLoader ?
                        <div className="">
                          <div className="bg-[url('https://media.istockphoto.com/id/1201667381/vector/customer-support-related-seamless-pattern-and-background-with-line-icons.jpg?s=612x612&w=0&k=20&c=Z1btZZJLEFG-Eg79Ty7sYIzzxLQx1Cp7Pqn4d-JwcH4=')] rounded-lg bg-cover bg-no-repeat">
                            <div className="flex justify-center items-center flex-col h-[calc(100vh_-_333px)] overflow-auto bg-[#ffffffe8] rounded-lg relative py-3">
                              Loading...
                            </div>
                          </div>
                        </div>
                        :
                        <div className="flex flex-col h-full overflow-x-auto mb-4">
                          <div className="bg-[url('https://media.istockphoto.com/id/1201667381/vector/customer-support-related-seamless-pattern-and-background-with-line-icons.jpg?s=612x612&w=0&k=20&c=Z1btZZJLEFG-Eg79Ty7sYIzzxLQx1Cp7Pqn4d-JwcH4=')] rounded-lg bg-cover bg-no-repeat">
                            <div className="flex flex-col h-[calc(100vh_-_333px)] overflow-auto bg-[#f4f4f4e8] rounded-lg relative py-3" id="chatDiv">
                              <div className="grid grid-cols-12 gap-y-2">
                                {activeChat?.chat?.message?.map((item, index) => {
                                  if (!item?.isHide) {
                                    return <div className={`${item?.from?._id === user?._id ? "col-start-6 col-end-13" : "col-start-1 col-end-8"} p-3 rounded-lg`} key={index}>
                                      <div className={`flex gap-2 ${item?.from?._id === user?._id ? "items-end justify-start gap-2 flex-row-reverse" : "flex-row"}`}>
                                        {item?.from?._id === user?._id &&
                                          <Menu>
                                            <MenuButton>
                                              <BiDotsVerticalRounded />
                                            </MenuButton>
                                            <MenuItems
                                              transition
                                              anchor="bottom end"
                                              className="w-fit bg-white origin-top-right rounded-md border text-sm/6 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                            >
                                              <MenuItem>
                                                <button type="button" onClick={e => deleteMessage(item)} className="group text-[13px] flex w-full items-center gap-2 rounded-md py-1 px-2 hover:bg-[#f1f1f1]">
                                                  <FaTrash className="size-4 text-[#da0000]" />Delete
                                                </button>
                                              </MenuItem>
                                            </MenuItems>
                                          </Menu>
                                        }
                                        <img src={methodModel.userImg(item?.from?.image)} className="h-9 w-9 object-cover rounded-full" />
                                        <div className="grid grid-cols-1 gap-1 w-full">
                                          {checkMediaFormat(item?.media) === "image" &&
                                            <Tooltip title="Click to open">
                                              <img src={methodModel.noImg(item?.media)} onClick={() => { window.open(item?.media, "_blank") }} className="w-[140px] h-[140px] object-cover rounded-lg" alt="img" />
                                            </Tooltip>
                                          }
                                          {checkMediaFormat(item?.media) === "video" &&
                                            <video width="320" height="240" controls className="w-[200px] h-[110px] rounded-lg">
                                              <source src={methodModel.video(item?.media)} type="video/mp4" />
                                              <source src={methodModel.video(item?.media)} type="video/ogg" />
                                              Your browser does not support the video tag.
                                            </video>
                                          }
                                          {checkMediaFormat(item?.media) === "doc" &&
                                            <Tooltip title="Click to open">
                                              <div onClick={() => { window.open(item?.media, "_blank") }} className="cursor-pointer">
                                                <img src={"/assets/img/document_icon.png"} className="w-[140px] h-[140px] object-cover rounded-lg" alt="img" />
                                              </div>
                                            </Tooltip>
                                          }
                                          {item?.message &&
                                            <div className={`relative h-fit rounded-tr-md rounded-br-md rounded-bl-md text-sm bg-white py-2 px-4 shadow w-fit ${item?.from?._id === user?._id ? "!bg-[#134896] !rounded-tl-md !rounded-tr-[0px] text-white ml-auto" : ""}`}>
                                              <div className="h-fit">{item?.message}</div>
                                              <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                                {item?.isRead ? "Seen" : ""}
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  }
                                })}
                                {activeChat?.chat?.message?.length === 0 &&
                                  <div className="col-span-12 text-center absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center">No Recent Chat</div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="flex flex-row items-center rounded-md bg-white w-full p-4">
                        <div>
                          <button type="button" className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                            <label className="cursor-pointer">
                              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                              </svg>
                              <input id="dropzone-file" type="file" value={""} className="hidden" onChange={e => uploadFile(e)} />
                            </label>
                          </button>
                        </div>
                        <form onSubmit={handleSendMessage} className="flex items-end w-full">
                          <div className="flex-grow ml-4">
                            <div className="relative w-full">
                              {activeChat?.file &&
                                <div className="flex items-center gap-1 ml-2 relative">
                                  <FaFile size={15} />
                                  File Uploaded
                                  <RxCross2 size={17} className="hover:text-red-600 cursor-pointer" onClick={e => setActiveChat((prev) => ({ ...prev, file: "" }))} />
                                </div>
                              }
                              <FormControl
                                type="text"
                                name="message"
                                placeholder="Type a message"
                                autoComplete="one-time-code"
                                value={activeChat?.message}
                                onChange={(e) => setActiveChat({ ...activeChat, message: e })}
                                className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                                required={activeChat?.message || activeChat?.file ? false : true}
                              />
                            </div>
                          </div>
                          <div className="ml-4">
                            <button type="submit" disabled={disableButton} className="flex disabled:cursor-not-allowed justify-center bg-[#134896] rounded-md text-white px-4 py-2 flex-shrink-0">
                              <span>Send</span>
                              <span className="ml-2 relative top-[3px]">
                                <svg className="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    :
                    <div className="h-[calc(100vh_-_213px)] flex justify-center items-center bg-white rounded-xl ">
                      <img src="https://unblast.com/wp-content/uploads/2020/10/Live-Chat-Vector-Illustration.jpg" className="max-w-[400px]"></img>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp popUp={modal} setPopUp={setModal} />
    </>
  )
}

export default Chat