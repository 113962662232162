import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';

const EChartsComponent = ({ data, period }) => {
  const user = useSelector((state) => state.user);
  const chartDom = useRef(null);
  const myChart = useRef(null);

  useEffect(() => {
    myChart.current = echarts.init(chartDom.current);
    const formattedData = formatData(data, period);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: '#ccc',
            type: 'solid',
            width: 1,
          },
        },
        backgroundColor: 'rgba(50,50,50,0.7)',
        textStyle: {
          color: '#fff',
        },
        formatter: (params) => {
          let tooltipContent = '';
          params.forEach((param) => {
            // Add $ sign to the sales and amount data in tooltip
            if (param.seriesName.includes('Total Amount') || param.seriesName.includes('Total Sales')) {
              tooltipContent += `${param.seriesName}: $${param.value}<br/>`;
            } else {
              tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      xAxis: {
        type: 'category',
        nameLocation: 'middle',
        data: formattedData.xAxisData,
      },
      yAxis: [
        {
          name: `Total ${user?.role === "member" ? "Amount" : "Sales"}`,
          type: 'value',
          position: 'left',
          axisLabel: {
            formatter: (value) => `$${value}`,  // Add $ sign to y-axis values
          },
        },
        {
          name: 'Total Tickets',
          type: 'value',
          position: 'right',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        },
      ],
      series: [
        {
          name: `Total ${user?.role === "member" ? "Amount" : "Sales"}`,
          type: 'line',
          data: formattedData.salesData,
          showSymbol: true,
          symbolSize: 8,
          lineStyle: {
            color: '#2a2c30',
            width: 2,
          },
          itemStyle: {
            color: '#2a2c30',
          },
          yAxisIndex: 0,
        },
        {
          name: 'Total Tickets',
          type: 'line',
          data: formattedData.countData,
          showSymbol: true,
          symbolSize: 8,
          lineStyle: {
            color: '#f84c1e',
            width: 2,
          },
          itemStyle: {
            color: '#f84c1e',
          },
          yAxisIndex: 1,
        },
      ],
    };
    myChart.current.setOption(option);
    return () => {
      if (myChart.current) {
        myChart.current.dispose();
      }
    };
  }, [data, period]);

  const formatData = (data, period) => {
    const xAxisData = [];
    const salesData = [];
    const countData = [];

    data.forEach(item => {
      let label = '';
      if (period === 'monthly') {
        label = `${item.id.month} ${item.id.year}`;
      } else if (period === 'weekly') {
        label = `${item.id.month} Week ${item.id.week}`;
      } else if (period === 'yearly') {
        label = `${item.id.year}`;
      }

      xAxisData.push(label);
      salesData.push(item.totalSales);
      countData.push(item.count);
    });

    return { xAxisData, salesData, countData };
  };

  return (
    <div
      ref={chartDom}
      style={{ width: '100%', height: '250px' }}
    ></div>
  );
};

export default EChartsComponent;
