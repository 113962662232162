import { NavigationBar } from "../Landing/NavigationBar"
import { FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useSelector } from "react-redux";
import FormControl from "../../common/FormControl";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'


const ProfilePage = () => {
    const { id } = useParams()
    const user = useSelector((state) => state.user)
    const [detail, setDetail] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ description: "" })

    useEffect(() => {
        getVendorDetail()
    }, [])

    const getVendorDetail = () => {
        loader(true)
        ApiClient.get(`user/detail?id=${id}`).then(res => {
            if (res.success) {
                setDetail(res?.data)
            }
            loader(false)
        })
    }

    const getCollaborationStatus = () => {
        const colabData = detail?.collaboraterDetail
        const userId = user?._id || user?.id
        let value = ""
        colabData?.filter((item) => {
            if (item?.addedBy?._id === userId) {
                value = item?.request_status
            }
        })
        return value
    }

    const handleColab = () => {
        if (getCollaborationStatus()) {
            return
        } else {
            setForm({ description: "" })
            setIsOpen(true)
        }
    }

    const handleCollabration = (e) => {
        e.preventDefault()
        const payload = {
            venueId: id,
            description: form?.description,
        }
        loader(true)
        ApiClient.post(`host/collaborate`, payload).then(res => {
            if (res.success) {
                getVendorDetail()
                setIsOpen(false)
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="">
                <NavigationBar />
                <div className="">
                    <div className="bg-cover bg-no-repeat w-full bg-[url('https://img.freepik.com/free-vector/gradient-blue-background_23-2149337036.jpg?semt=ais_hybrid')]">
                        <div className="bg-[#0000003d]">
                            <div className="container mx-auto px-5 relative">
                                <div className="max-[639px]:flex-wrap flex items-center pt-5 pb-10 min-h-[300px]">
                                    <img src={methodModel.userImg(detail?.image)} className="bg-white w-[150px] h-[150px] sm:w-[200px] sm:h-[200px] object-cover border-[4px] border-white rounded-full"></img>
                                    <div className="max-[639px]:w-full max-[639px]:ml-0 ml-8">
                                        <h2 className="text-white text-[22px] sm:text-[28px] font-[600]">{detail?.fullName || `${detail?.firstName || "--"} ${detail?.lastName || ""}`}</h2>
                                        <p className="text-white mb-1">{detail?.email}</p>
                                        <p className="flex flex-wrap gap-x-2 mb-1">
                                            <span className="text-white text-[12px] font-[300]">{detail?.location || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.country || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.state || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.city || "--"},</span>
                                            <span className="text-white text-[12px] font-[300]">{detail?.zipCode || "--"}</span>
                                        </p>
                                        {detail?.mobileNo &&
                                            <p className="text-white text-[12px] font-[300] mb-1">+{detail?.mobileNo}</p>
                                        }
                                        {user?.role === "venue" &&
                                            <div>
                                                <button type="button" onClick={e => handleColab()} className={`text-white h-fit bg-[#005AAB] font-medium rounded-full text-[12px] py-[6px] px-5 text-center ${getCollaborationStatus() ? "cursor-not-allowed" : "cursor-pointer hover:opacity-[90%]"}`}>{getCollaborationStatus() ? <span className="capitalize">Collaboration Status: {getCollaborationStatus()}</span> : "Collaborate with Vendor"}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {detail?.youtube_link || detail?.instagram_link || detail?.facebook_link || detail?.twitter_link || detail?.linkedin_link ?
                                    <div className="absolute bottom-[20px] right-[20px]">
                                        <div className='flex flex-wrap gap-5'>
                                            {detail?.youtube_link && <FaYoutube onClick={() => { window.open(detail?.youtube_link, "_blank") }} className="text-white cursor-pointer hover:text-[#e5b23f] text-[20px]" />}
                                            {detail?.instagram_link && <PiInstagramLogoFill onClick={() => { window.open(detail?.instagram_link, "_blank") }} className="text-white cursor-pointer hover:text-[#e5b23f] text-[20px]" />}
                                            {detail?.facebook_link && <FaFacebook onClick={() => { window.open(detail?.facebook_link, "_blank") }} className="text-white cursor-pointer hover:text-[#e5b23f] text-[20px]" />}
                                            {detail?.twitter_link && <FaXTwitter onClick={() => { window.open(detail?.twitter_link, "_blank") }} className="text-white cursor-pointer hover:text-[#e5b23f] text-[20px]" />}
                                            {detail?.linkedin_link && <FaLinkedin onClick={() => { window.open(detail?.linkedin_link, "_blank") }} className="text-white cursor-pointer hover:text-[#e5b23f] text-[20px]" />}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="pb-5">
                        <div className="container mx-auto px-5">
                            <div className="">
                                <div className="w-full">
                                    <TabGroup>
                                        <TabList className="flex border-b bg-white overflow-auto scroll_hide mb-5">
                                            <Tab key="ABOUT" className="py-2 px-4 text-[12px] data-[selected]:text-[#0063a3] focus:outline-none data-[selected]:border-b-[2px] border-r-[1px] border-[#e5e7eb] data-[selected]:border-b-[#0063a3] font-[300] data-[selected]:font-[600] ">
                                                ABOUT
                                            </Tab>
                                        </TabList>
                                        <TabPanels className="mt-3">
                                            <TabPanel key="ABOUT" className="rounded-xl bg-white/5">
                                                <p className="text-[#6d6d6d] font-[300] mb-8" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                            </TabPanel>
                                        </TabPanels>
                                    </TabGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-[#005AAB]">Collaborate with Vendor</DialogTitle>
                            </div>
                            <form onSubmit={handleCollabration}>
                                <div className="my-4 flex flex-wrap justify-center gap-5">
                                    <FormControl
                                        type="textarea"
                                        name="description"
                                        label="Description"
                                        placeholder="Enter Description"
                                        autoComplete="one-time-code"
                                        value={form?.description}
                                        onChange={(e) => setForm({ ...form, description: e })}
                                        required
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button type="button" onClick={() => setIsOpen(false)} className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 border border-[#005AAB] text-[#005AAB]">Cancel</button>
                                    <button type="submit" className="flex items-center justify-center font-semibold rounded-full px-5 py-2 my-3 text-white bg-[#005AAB]">Send</button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ProfilePage